import React from 'react';
import { USBGrid, USBColumn } from '@usb-shield/components-react';
import FooterLinks from '../../molecules/FooterLinks';
import './globalfooter.css';
import journeyLine from '/assets/img/journeyline.svg';

const GlobalFooter = () => {
  return (
    <>
      {/* <div className="footer-bg">
        <USBColumn largeSpan="16" />
        <FooterLinks />
        <img
          src="/assets/img/journeyline.svg"
          alt="footer"
          className="border-line"
        />
      </div> */}

      <USBGrid addClasses="footer-bg">
        <USBColumn largeSpan={2} />
        <USBColumn largeSpan={12}>
          <FooterLinks />
        </USBColumn>
      </USBGrid>
      <USBGrid addClasses="footer-bg">
        <USBColumn largeSpan={2} />
        <USBColumn largeSpan={16}>
          <img src={journeyLine} alt="footer" className="border-line" />
        </USBColumn>
      </USBGrid>
    </>
  );
};

GlobalFooter.propTypes = {};

export default GlobalFooter;
