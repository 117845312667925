// @flow
import type { KeyValue, ContentValueModel } from '../../types/common.types';

export type UserProfileModel = {
  profileData: Object,
  userType: Object,
  profileServerData: Object,
  profileValidatedData: Object,
  profileEditInfo: Boolean,
  countryList: ContentValueModel[],
  isProfileFormChanged: Boolean
}
const initialState: UserProfileModel = {
  profileData: null,
  userType: {},
  profileServerData: {},
  profileValidatedData: {},
  profileEditInfo: false,
  countryList: []
}

const UserProfilerRducer = (state: UserProfileModel = initialState, action: Object) => {
  switch (action.type) {
    case "CREATE_PROFILE":
      return {
        ...state,
        profileData: action.payload
      };
    case "USER_TYPE_SELECTION":
      return {
        ...state,
        userType: action.payload
      };
    case "SERVER_PROFILE_DATA":
      return {
        ...state,
        profileServerData: action.payload
      };
    case "PROFILE_VALIDATED_DATA":
        return {
          ...state,
          profileValidatedData: action.payload
        };  
    case "ADD_COUNTRY_LIST":
      return {
        ...state,
        countryList: action.payload
      }
    case "RESET_PREVIOUS_USER_DATA":
      return {
        countryList: state.countryList
      }
    case "PROFILE_EDIT_INFO_CLICK":
      return {
          ...state,
          profileEditInfo: action.payload
        }
    case "PROFILE_FORM_DATA_CHANGED":
      return {
          ...state,
          isProfileFormChanged: action.payload
        }    
    default:
      return state;
  }
};

export default UserProfilerRducer;