import React, { useState, useEffect, useContext } from 'react';
// import Router from 'next/router'
import { USBGrid, USBColumn } from '@usb-shield/components-react';
import USBBreadcrumb from '@usb-shield/components-react/dist/cjs/breadcrumb';
// import Layout from '../../components/Layout/Layout'
import moment from 'moment';
import { endpoints } from '../../../config/connections';
import { DocContext } from '../context/DocContext';
import callApi from '../../components/utilities/callApi';
import Loader from '../../components/utilities/Loader';
import { saveAsFile, helpAndFaq } from '../../components/utilities/Utils';
import './DocumentTemplate.css';
import { useHistory } from 'react-router-dom';

export const loadList = (setDocuments, setLoading) => {
  setLoading(true);
  const listUrl = `${endpoints.envUrl}/document-management/file-download/v1/documents/DocumentTemplates/files`;
  callApi
    .getApiData(listUrl)
    .then((response) => {
      if (response.status === 200) {
        const data = response.data.filter((item) => {
          return item.category.toLowerCase() !== 'guide';
        });
        setDocuments(data);
      }
      setLoading(false);
    })
    .catch(() => {
      setLoading(false);
    });
};

export const downloadDocument = (id, setLoading) => {
  setLoading(true);
  const apiURL = `${endpoints.api}/document-management/file-download/v1/files/${id}`;
  callApi
    .getApiData(apiURL)
    .then((result) => {
      const fileName = result.data.fileName.replace(
        'Content-Disposition: attachment; filename=',
        ''
      );
      saveAsFile(result.data.binary, fileName);
      setLoading(false);
    })
    .catch(() => {
      setLoading(false);
    });
};

export const firstLetterUpper = (name) => {
  const name1 = name.replace(/-/gi, ' ');
  const name2 = name1.replace(/_/gi, ' ');
  const name3 = name2.toLowerCase().split(' ');
  name3[0] = name3[0].charAt(0).toUpperCase() + name3[0].slice(1);
  return name3.join(' ');
};

export const textContentTemplate = (data, setLoading) => {
  // FILE TYPE / EXTENTION
  const docName = data.documentName.split('.');
  const documentName = firstLetterUpper(docName[0]);
  // UPDATED DATE
  const dateField = data.uploadDate.split('T');
  const temp = dateField[0].replace(/-/gi, '/');
  const uploadDate = moment(temp, 'YYYY/MM/DD').format('MM/DD/YYYY');
  return (
    <div className="txt-content-item">
      <button
        type="button"
        data-testid="download-file"
        className="inline-button"
        onClick={() => downloadDocument(data.filenetId, setLoading)}
      >
        &nbsp;
        {documentName.charAt(0).toUpperCase() + documentName.slice(1)}
      </button>
      &nbsp;
      <span className="extname">
        &#40;
        {data.mimeType}
        &#41;
      </span>
      &nbsp;&nbsp;&nbsp;
      <span className="updatedOn">
        Updated &nbsp;
        {uploadDate}
        &nbsp;
      </span>
    </div>
  );
};

export const downloadFAQ = (setLoading) => {
  helpAndFaq(setLoading);
};

const DocumentTemplate = () => {
  const [loading, setLoading] = useState(false);
  const [documents, setDocuments] = useState([]);
  const { setCurrentMenu } = useContext(DocContext);
  let history = useHistory();

  useEffect(() => {
    if (!sessionStorage.getItem('tok')) {
      history.push('/');
    } else {
      setCurrentMenu('doctemplates');
      loadList(setDocuments, setLoading);
    }
  }, []);

  return (
    <>
      <USBGrid addClasses="content-frame">
        <USBColumn largeSpan={8}>
          <USBBreadcrumb
            id="documenttemplate"
            text="Home"
            breadcrumbs={[
              // {
              //   text: 'My portfolio',
              //   href: '/ReportingDashboard',
              // },
              {
                text: 'Document templates',
                href: '',
              },
            ]}
          />
        </USBColumn>
        <USBColumn largeSpan={8} />
      </USBGrid>
      <USBGrid addClasses="content-frame doctemplate-page">
        <USBColumn largeSpan={16}>
          {loading ? <Loader /> : null}
          <div className="doctemplate-head">
            <div className="doc-template-head">
              <h1 className="heading-h1">Document templates</h1>
              <div className="doc-content">
                Get a jump start on your reporting requirements with our
                document templates.
                <br />
                You’ll find templates organized by reporting requirement type.
                Select a document to download it.
                <br />
              </div>
            </div>
            <div className="report-download doc-template-download">
              <div className="report-download-title">ADDITIONAL DOCUMENTS</div>
              <button
                type="button"
                className="download-link"
                onClick={() => downloadFAQ(setLoading)}
              >
                Guide &amp; FAQs
              </button>
              <span className="report-download-block">(PDF)</span>
            </div>
          </div>
          {documents &&
            documents.map((item,index) => {
              const categoryName = firstLetterUpper(item.category);
              return (
                <div key={index}>
                  <h3 className="doctype-subhead">{categoryName}</h3>
                  <div className="txt-content">
                    {item.documentMetadata &&
                      item.documentMetadata.map((data) => {
                        return textContentTemplate(data, setLoading);
                      })}
                  </div>
                </div>
              );
            })}
        </USBColumn>
        {/* <USBColumn largeSpan={4}>
          <div className="report-download">
            <div className="report-download-title">ADDITIONAL DOCUMENTS</div>
            <button
              type="button"
              className="download-link"
              onClick={() => downloadFAQ(setLoading)}
            >
              Guide &amp; FAQs
            </button>
            <span className="report-download-block">(PDF)</span>
          </div>
        </USBColumn> */}
      </USBGrid>
    </>
  );
};

export default DocumentTemplate;
