import React from 'react';
// import logo from '../../../public/images/Loading.gif'
import logo from '../../assets/Loading.gif'
// import logo from '../../assets/Artboard.png';
const Spinner = () => {
    return (
        <div className="loading-icon-wrapper">
            <div className="loading-icon-backdrop"></div>
            <img src={logo} alt="Logo"  />

        </div>
    )
}
export default Spinner;