export const UserRoleConfig = {
    superAdmin: 'super_admins',
    customer: 'portal_admin',
    customerContributor: 'portal_contributor', 
    frequentVendor: 'vendor',
    specialVendor:'SpecialVendor'
}

export const UserRoleConfigKeyMapper = {
    sideAdmin: 'site administrator',
    sideAdminVendor: 'site administrator(vendor)',
    contributor: 'contributor',
}
export const UserRoleHelperText = {
    contributor: 'A contributor can upload, download and comment on documents.',
    sideAdmin: 'A site administrator has access to all reporting requirements. They can upload, download and comment on documents. They can also add new users and edit existing user information.',
    sideAdminVendor: 'A site administrator has access to all reporting requirements. They can upload, download and comment on documents. They can also add new users and edit existing user information.',
}