import { createBrowserHistory, createMemoryHistory } from 'history'
let history
let createHistory

if (typeof document !== 'undefined') {
    createHistory = createBrowserHistory
} else {
    createHistory = createMemoryHistory
}
history = createHistory()

export default history