import { store } from "../shared/store";


const LoadingHelper = {
    _recordApiCount: 0,

    inFlight: () => {
        if (LoadingHelper._recordApiCount === 0 && !store.getState().showLoading) {
            store.dispatch({
                type: 'SHOW_LOADING_ICON',
                payload: true
            })
        }
        LoadingHelper._recordApiCount++;
    },

    outFlight: () => {
        LoadingHelper._recordApiCount--;
        if (LoadingHelper._recordApiCount === 0 && store.getState().showLoading) {
            store.dispatch({
                type: 'SHOW_LOADING_ICON',
                payload: false
            })
        }
    }
}

export default LoadingHelper;