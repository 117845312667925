import React, { useState, useEffect } from 'react';

const TokenGenerator = (props) => {
  const [tokenInput, setTokenInput] = useState('');
  const handleChange = (event) => {
    setTokenInput(event.target.value);
    // window.cdcAccessToken=event.target.value;
  };

  const onProceedHandler = () => {
    props.update(tokenInput);
  };

  useEffect(() => {
    const jwtToken = sessionStorage.getItem('tok');
    if(!jwtToken){
      window.location.href="/";
    }
    props.update(jwtToken);
  }, []);

  return (
    <React.Fragment>
      <br></br>
      <p>Please paste the access token/JWT token here to proceed</p>
      <input type="text" id="txtAccessToken" onChange={handleChange}></input>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <button type="button" onClick={() => onProceedHandler()}>
        Proceed{' '}
      </button>
    </React.Fragment>
  );
};

export default TokenGenerator;
