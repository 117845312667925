// @flow

import React from 'react';
import {  USBProgressIndicator } from '@usb-shield/components-react/dist/cjs';

type Props = {
    currentStep: number,
    id: string
}

const ProgressIndicator = (props: Props) => {
    return (
        <div className="sp-progress-bar-container sp-mr-b-40">
            <div className="sp-progress-bar">
                <USBProgressIndicator
                    id={props.id}
                    percentSuffixLabel='% Complete'
                    PERCENTAGE='percentage'
                    ariaProgressLabelFirst='Your Progress: Step'
                    ariaProgressLabelMid='of'
                    ariaProgressLabelLast='Completed'
                    currentStep={props.currentStep}
                    variant='percentage'
                >
                    <USBProgressIndicator.Step>
                        <span>Step1</span>
                    </USBProgressIndicator.Step>
                    <USBProgressIndicator.Step>
                        <span></span>
                    </USBProgressIndicator.Step>
                    <USBProgressIndicator.Step>
                        <span></span>
                    </USBProgressIndicator.Step>
                    <USBProgressIndicator.Step>
                        <span></span>
                    </USBProgressIndicator.Step>
                    <USBProgressIndicator.Step>
                        <span>Step2</span>
                    </USBProgressIndicator.Step>
                </USBProgressIndicator>
            </div>
        </div>

    )
}

export default ProgressIndicator;