import React, { useState, useEffect } from "react";
import { Prompt } from "react-router-dom";

const useWarningMessage = (message = "Are you sure?") => {
    // a flag to show/hide the warning
    const [changed, setChanged] = useState(false);

    useEffect(() => {
        // Detatcing browser close
        window.onbeforeunload = changed && (() => message);
        // to remove the event listener
        return () => {
            window.onbeforeunload = null;
        }
    }, [changed]);

    const routerPrompt = <Prompt when={changed} message={message} />;
    return [routerPrompt, () => setChanged(true), () => setChanged(false)]
}

export default useWarningMessage;