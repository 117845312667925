import React, { useState, useEffect } from 'react';
import moment from 'moment';
import USBTable from '@usb-inner-src/react-table';
const UserListTable = ({ fullTableData}) => {
    const paginationLabels = {
        previousButton: 'Previous page',
        nextButton: 'Next page',
        unit: 'Page',
        preposition: 'of',
        rowDropdown: 'Per page',
    }

    const defaultTableHeaderconfigs = {
        isSortable: false,
        wrapContent: true,
        dataType: 'node',
        sortValues: ['Oldest', 'Newest'],
        headerAlignment: 'left',
    }

    const tableHeaders = [
        
        {
            headerName: 'First Name',
            fieldFor: 'first',
            ...defaultTableHeaderconfigs,
            
        },
        {
            headerName: 'Last Name',
            fieldFor: 'second',
            ...defaultTableHeaderconfigs,
        },
        {
            headerName: 'Email',
            fieldFor: 'third',
            ...defaultTableHeaderconfigs,
        },
        {
            headerName: 'Last Read',
            fieldFor: 'fourth',
            ...defaultTableHeaderconfigs,
        },
       
    ]


    const [rangeArray, setRangeArray] = useState(['25', '50', '75', '100']);

    const updateRangeArray = (pageCount) => {
        const rangeArrayTemp = rangeArray.filter((e) => e !== pageCount);
        rangeArrayTemp.sort(function (a, b) {
            return a - b;
        });
        rangeArrayTemp.unshift(pageCount);
        setRangeArray(rangeArrayTemp);
    };

    const paginationtableData = () => {
        let paginationData = [];
        console.log("paginatioooooon",fullTableData);
        if (fullTableData.length != 0) {
            paginationData = [];
            fullTableData.map((item) => {
                paginationData.push(
                    tblContentTemplate(item)
                );
            });
        } else {
            return [{  second: '-', third: '-', fourth: '-', sixth: '-', seventh: '-',  ninth: '-' }]
        }
        return paginationData;
    }
    
    const tblContentTemplate = (item) => {
        const firstNameHTML = <div className="span-bold">{item.firstName}</div>
        const LastNameHTML = <div className="span-bold">{item.lastName}</div>
        const emailIdHtml = <div className="span-bold">{item.emailId}</div>          
        const lastReadDateHtml = <div>{item.lastRead ? moment(item.lastRead).format('MM/DD/YYYY HH:mm:ss') : '-'}</div>
        
        return { first: firstNameHTML, second: LastNameHTML, third: emailIdHtml,  fourth: lastReadDateHtml}
        
    };

    return (
        <>
        
            {<USBTable
                addClasses="pagination-container"
                displayVariant="auto"
                useBorder="true"
                paginationOptions={rangeArray}
                handleItemCountChange={(itemCount) =>
                    updateRangeArray(itemCount)
                }
                paginationLabels={{ ...paginationLabels }}
                paginationPosition="both"
                columnData={tableHeaders}
                tableData={paginationtableData()}
            />
            }
            
        </>
    )
}

export default UserListTable;