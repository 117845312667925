// @flow
import { UserRoleConfig } from '../../config/user-role-config.js';

export type UserTypeModel = {
    user: Object;
    isFrequentVendor: boolean;
    isSuperAdmin: boolean;
    isCustomer: boolean;
    svcJWTTokenSaved: string;
}

const initialState: UserTypeModel = {
    user: null,
    isFrequentVendor: false,
    isSuperAdmin: false,
    isCustomer: false,
    svcJWTTokenSaved: ''

}
const CurrentUserReducer = (state: UserTypeModel = initialState, action: Object) => {
    switch (action.type) {
        case "SET_CURRENT_USER":
            console.log("WRITING TOKEN TO REDUX SIDE-SETP2 Extend token--->", action.payload);
            return {
                ...state,
                user: action.payload,
                isSuperAdmin: action.payload.custom_data.role.toLowerCase().includes(UserRoleConfig.superAdmin),
                isCustomer: action.payload.custom_data.role.toLowerCase().includes(UserRoleConfig.customer)
            };
        case "IS_FREQUENT_VENDOR":
            return {
                ...state,
                isFrequentVendor: action.payload
            }
        case "SET_JWT_TOKEN":
            return {
                ...state,
                svcJWTTokenSaved: action.payload
            }
        case "SET_LOGIN_USER_PARENT_COMPANY_INFO":
            return {
                ...state,
                user: {
                    ...state.user,
                    parentCompanyInfo: action.payload
                }
            }
        default:
            return state;
    }
};

export default CurrentUserReducer;