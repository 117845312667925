import { EDMDocumentViewer } from '@usb-edm/react-document-viewer';
import { EDMSideModal } from '@usb-edm/react-side-modal';
import React, { useEffect, useRef, useState } from 'react';

import {
  USBGrid,
  USBColumn,
  USBButton,
  USBModal,
  USBModalHeader,
  USBModalBody,
  USBModalFooter,
  USBModalCloseIcon,
  USBPagination,
  USBSelect,
  USBLink,
} from '@usb-shield/components-react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useLocation,
} from 'react-router-dom';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import { Checkbox } from 'primereact/checkbox';
import callApi from '../../components/utilities/callApi';
import {
  saveAsFile,
  getQS,
  previewFile,
} from '../../components/utilities/Utils';
import { endpoints } from '../../../config/connections';
import { USBIconShow, USBIconClose } from '@usb-shield/react-icons';
import greenTick from '../../../assets/img/green-tick.svg';
import greyCheck from '../../../assets/img/grey_check.png';
import threeDots from '../../../assets/img/threedots.png';
import useUserRole from '../../components/Hooks/use-UserRole';

const getFileListsfromSessionStorage = () => {
  let fileLists = [];
  if (sessionStorage.getItem('fileNetIdLists')) {
    fileLists = JSON.parse(sessionStorage.getItem('fileNetIdLists'));
  }
  console.log('getting');
  //console.log(fileLists);
  return fileLists;
};

const removeFileListSessionStorage = (name) => {
  // console.log('removing');
  let fileLists = getFileListsfromSessionStorage();
  // console.log(fileLists);
  // fileLists = fileLists.filter(fileList => fileList.name !== name);
  if (fileLists.length > 1) {
    console.log('removing');
    const index = fileLists.indexOf(name);
    if (index > -1) {
      fileLists.splice(index, 1);
    }
    // console.log(fileLists);
    sessionStorage.setItem('fileNetIdLists', JSON.stringify(fileLists));
  } else {
    console.log('deleting session');
    sessionStorage.removeItem('fileNetIdLists');
    fileLists = [];
  }
  console.log(fileLists);
};

const appendFileListSessionStorage = (name) => {
  console.log('appending', name);
  const fileLists = getFileListsfromSessionStorage();
  // console.log(fileLists);
  fileLists.push(name);
  console.log(fileLists);
  // console.log(JSON.stringify(fileLists));
  sessionStorage.setItem('fileNetIdLists', JSON.stringify(fileLists));
};

const getDownloadListsfromSessionStorage = () => {
  let fileLists = [];
  if (sessionStorage.getItem('fileDownloadIdLists')) {
    fileLists = JSON.parse(sessionStorage.getItem('fileDownloadIdLists'));
  }
  return fileLists;
};

const removeDownloadListSessionStorage = (
  id,
  setDownloadEnabled,
  count,
  setCount
) => {
  // console.log('removing');
  if (id != null) {
    let fileLists = getDownloadListsfromSessionStorage();
    // console.log(fileLists);
    // fileLists = fileLists.filter(fileList => fileList.name !== name);
    if (fileLists.length > 1) {
      // console.log('removing');
      const index = fileLists.indexOf(id);
      if (index > -1) {
        fileLists.splice(index, 1);
      }
      const temp = fileLists.length;
      setCount(temp);
      // console.log(fileLists);
      sessionStorage.setItem('fileDownloadIdLists', JSON.stringify(fileLists));
    } else {
      const temp = fileLists.length;
      setCount(temp - 1);
      setDownloadEnabled(false);
      // console.log('deleting session');
      sessionStorage.removeItem('fileDownloadIdLists');
      fileLists = [];
    }
  }

  // console.log(fileLists);
};

const appendDownloadListSessionStorage = (
  id,
  setDownloadEnabled,
  count,
  setCount
) => {
  // console.log('appending', id);
  if (id != null) {
    const fileLists = getDownloadListsfromSessionStorage();
    if (fileLists.indexOf(id) == -1) {
      fileLists.push(id);
      const temp = fileLists.length;
      setCount(temp);
    }
    // console.log(JSON.stringify(fileLists));
    setDownloadEnabled(true);
    sessionStorage.setItem('fileDownloadIdLists', JSON.stringify(fileLists));
  }
};

const isPresentInStorage = (id, setActionsDrop) => {
  // console.log('removing');
  const fileLists = getDownloadListsfromSessionStorage();
  let checked = false;
  if (fileLists.length >= 1 && id != null) {
    const index = fileLists.indexOf(id);
    if (index > -1) {
      checked = true;
    }
  }
  setActionsDrop(0);

  return checked;
};

export const selectDownloadFile = (
  items,
  setActionsDrop,
  getClosingBindersListingNoAPI,
  setReportingDetailsData,
  reportingDetailsData,
  setLoading,
  selectAll,
  setSelectAll,
  setDownloadEnabled,
  count,
  setCount,
  index
) => {
  setLoading(true);
  const newData = reportingDetailsData;
  items.forEach((item, i) => {
    // console.log('e', item.downloadId);
    if (item.downloadId != null && (item.edmStatus !== 'SentToEDM' && item.edmStatus !== 'SentToEdmTemp' && item.edmStatus !== 'Failed Temp' && item.edmStatus !== 'Failed')) {
      const tempIndex = index == -1 ? i : index;
      // select all is clicked
      // console.log(selectAll);
      if (index == -1 && item.downloadId && (item.edmStatus !== 'SentToEDM' && item.edmStatus !== 'SentToEdmTemp' && item.edmStatus !== 'Failed Temp' && item.edmStatus !== 'Failed')) {
        if (!selectAll && item.downloadId && (item.edmStatus !== 'SentToEDM' && item.edmStatus !== 'SentToEdmTemp' && item.edmStatus !== 'Failed Temp' && item.edmStatus !== 'Failed')) {
          appendDownloadListSessionStorage(
            item.downloadId,
            setDownloadEnabled,
            count,
            setCount
          );
          newData[tempIndex].checked = true;
        } else {
          if (isPresentInStorage(item.downloadId, setActionsDrop)) {
            removeDownloadListSessionStorage(
              item.downloadId,
              setDownloadEnabled,
              count,
              setCount
            );
            newData[tempIndex].checked = false;
          } else {
            appendDownloadListSessionStorage(
              item.downloadId,
              setDownloadEnabled,
              count,
              setCount
            );
            newData[tempIndex].checked = true;
          }
        }
        // individual checkox is clicked
      } else {
        if (selectAll && item.downloadId && (item.edmStatus !== 'SentToEDM' && item.edmStatus !== 'SentToEdmTemp' && item.edmStatus !== 'Failed Temp' && item.edmStatus !== 'Failed')) {
          // select all option is checked
          if (isPresentInStorage(item.downloadId, setActionsDrop)) {
            setSelectAll(false);
            removeDownloadListSessionStorage(
              item.downloadId,
              setDownloadEnabled,
              count,
              setCount
            );
            newData[tempIndex].checked = false;
          } else {
            appendDownloadListSessionStorage(
              item.downloadId,
              setDownloadEnabled,
              count,
              setCount
            );
            newData[tempIndex].checked = true;
          }
        } else {
          if (isPresentInStorage(item.downloadId, setActionsDrop)) {
            removeDownloadListSessionStorage(
              item.downloadId,
              setDownloadEnabled,
              count,
              setCount
            );
            newData[tempIndex].checked = false;
          } else {
            if (
              getDownloadListsfromSessionStorage().length ==
              reportingDetailsData.length - 1
            ) {
              setSelectAll(true);
            }
            appendDownloadListSessionStorage(
              item.downloadId,
              setDownloadEnabled,
              count,
              setCount
            );
            newData[tempIndex].checked = true;
          }
        }
      }
    }
  });
  // console.log(newData);
  setActionsDrop(0);
  getClosingBindersListingNoAPI(setReportingDetailsData, newData);

  setTimeout(() => {
    setLoading(false);
  }, 500);
  // window.location.reload();
  // console.log(reportingDetailsData);
  // getClosingBindersListing(setReportingDetailsData);
};

export const markFinal = (
  final,
  item,
  setActionsDrop,
  getClosingBindersListing,
  getClosingBindersListingNoAPI,
  setReportingDetailsData,
  reportingDetailsData,
  setLoading,
  setIsDirty
) => {
  setIsDirty(true);
  if (item.MarkedasFinalValue === false) {
    Object.assign(item, { MarkedasFinalValue: true });
    appendFileListSessionStorage(item.id);
    // item.MarkedasFinalValue = true;
  } else if (item.MarkedasFinalValue === true) {
    Object.assign(item, { MarkedasFinalValue: false });
    removeFileListSessionStorage(item.id);
    //  item.MarkedasFinalValue = false;
  }

  // const newData = reportingDetailsData;
  // console.log(newData);
  setActionsDrop(0);
  // getClosingBindersListingNoAPI(setReportingDetailsData, newData);
  // console.log(reportingDetailsData);

  setTimeout(() => {
    setLoading(false);
  }, 1000);
  // getClosingBindersListing(setReportingDetailsData);
};

export const markAsFinal = (
  id,
  reportingDetailsData,
  setActionsDrop,
  getClosingBindersListing,
  getClosingBindersListingNoAPI,
  setReportingDetailsData,
  renamedFlag,
  setFailedFlag,
  setMessageContent,
  setSuccessFlag,
  setLoading,
  setIsDirty
) => {
  setSuccessFlag(false);
  setFailedFlag(false);
  if (renamedFlag) {
    setLoading(true);
    reportingDetailsData.forEach((item) => {
      if (item.MarkedasFinalValue === false && item.id === id) {
        markFinal(
          true,
          item,
          setActionsDrop,
          getClosingBindersListing,
          getClosingBindersListingNoAPI,
          setReportingDetailsData,
          reportingDetailsData,
          setLoading,
          setIsDirty
        );
      } else if (item.MarkedasFinalValue === true && item.id === id) {
        markFinal(
          false,
          item,
          setActionsDrop,
          getClosingBindersListing,
          getClosingBindersListingNoAPI,
          setReportingDetailsData,
          reportingDetailsData,
          setLoading,
          setIsDirty
        );
      }
    });
  } else {
    const contentTxt = (
      <div>
        Before you can mark a document as final, you&apos;ll need to rename it.
        Go to the&nbsp;
        <span className="font-bold">Actions</span>
        menu, then&nbsp;
        <span className="font-bold">Edit.</span>
      </div>
    );
    setFailedFlag(true);
    setMessageContent(contentTxt);
    setTimeout(() => {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
    }, 10);
    setTimeout(() => {
      setFailedFlag(false);
      // setLoading(false);
    }, 5000);
  }
};

export const toggleAccordion = (id, openedAccordion, setOpenedAccordion) => {
  if (openedAccordion === id) {
    setOpenedAccordion(0);
  } else {
    setOpenedAccordion(id);
  }
  // setOpenedAccordion(id)
};

export const showActionsDropdown = (id, actionsDrop, setActionsDrop) => {
  if (actionsDrop !== id) {
    setActionsDrop(id);
  } else {
    setActionsDrop('0');
  }
};

export const handleKeyDown = (event, id, actionsDrop, setActionsDrop) => {
  if (event.keyCode === 13 || event.keyCode === 32) {
    showActionsDropdown(id, actionsDrop, setActionsDrop);
  }
  if (event.keyCode === 27) {
    setActionsDrop('0');
  }
};

export const threeDotKeyDown = (event, setActionsDrop) => {
  if (event.keyCode === 27) {
    setActionsDrop('0');
  }
};

export const versionKeyDown = (
  event,
  id,
  openedAccordion,
  setOpenedAccordion
) => {
  if (event.keyCode === 13 || event.keyCode === 32) {
    toggleAccordion(id, openedAccordion, setOpenedAccordion);
  }
  if (event.keyCode === 27) {
    setOpenedAccordion(0);
  }
};

export const downloadDocument = (
  id,
  setLoading,
  setActionsDrop,
  setFileDownload,
  getClosingBindersListing,
  setReportingDetailsData
) => {
  // console.log('item2', id);
  //const did = getQS('id');
  setLoading(true);
  setFileDownload(true);

  const apiURL = `${endpoints.api}/document-management/file-download/v1/files?fileIdList=${id}`;
  callApi
    .getApiData(apiURL)
    .then((result) => {
      console.log('result', result);
      getClosingBindersListing(setReportingDetailsData);
      //
      saveAsFile(
        JSON.parse(result.data[0].binaryContent).binary,
        JSON.parse(result.data[0].binaryContent).fileName
      );

      setLoading(false);
      setActionsDrop('0');
    })
    .catch(() => {
      // console.log('error', error)
      setLoading(false);
      setActionsDrop('0');
    });
};

export const confirmReplace = (
  setErrorModal,
  setLegalHold,
  setErrorModalTxt,
  setErrorModalTitle
) => {
  setErrorModal(true);
  setLegalHold(true);
  const contentTxt = (
    <div>
      For retention purposes, this file can&apos;t be replaced right now.
    </div>
  );
  setErrorModalTxt(contentTxt);
  setErrorModalTitle('Unable to replace file.');
};

export const replaceFile = (
  event,
  item,
  setSuccessFlag,
  setFailedFlag,
  setMessageContent,
  setLoading,
  setActionsDrop,
  getClosingBindersListing,
  getClosingBindersListingNoAPI,
  setReportingDetailsData
) => {
  setSuccessFlag(false);
  setFailedFlag(false);
  const acceptedFileExtentions = [
    'pdf',
    'xls',
    'xlsx',
    'doc',
    'docx',
    'xml',
    'jpeg',
    'jpg',
  ];
  const formData = new FormData();
  formData.append('reqId', getQS('id'));
  formData.append('file', event.target.files[0]);
  // formData.append('areaToStore ', 'Temporary')
  formData.append('user ', sessionStorage.getItem('loggedUser'));
  const ext = event.target.value.split('.').pop();
  if (event.target.files[0].size > 104857600) {
    setFailedFlag(true);
    setMessageContent(
      `We were unable to upload your document. Make sure your file size is less than 1000MB and try again.`
    );
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
    setTimeout(() => {
      setFailedFlag(false);
    }, 5000);
    return false;
  }
  if (acceptedFileExtentions.indexOf(ext.toLowerCase()) < 0) {
    // chk += 1
    setFailedFlag(true);
    setMessageContent(
      `We were unable to upload the document. Make sure your file is in a supported format (${acceptedFileExtentions.join(
        ','
      )}) and try again.`
    );
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
    setTimeout(() => {
      setFailedFlag(false);
    }, 5000);
    return false;
  }
  const userId = sessionStorage.getItem('loggedUser');
  const docId = getQS('id');
  const filenetId = item.downloadId ? item.downloadId : item.id;
  setLoading(true);
  // const url = `${endpoints.api}/document-management/file-upload/v1/users/${userId}/documents/${docId}/files`
  const url = `${endpoints.api}/document-management/file-upload/v1/users/userId/documents/${docId}/files/fileReplace?fileId=${filenetId}`;
  return callApi
    .postApiUpload(url, formData)
    .then((response) => {
      if (response.status === 200) {
        getClosingBindersListing(setReportingDetailsData);
        setSuccessFlag(true);
        setMessageContent(`You’ve successfully uploaded your document.`);
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: 'smooth',
        });
        setIsDirty(true);
        setTimeout(() => {
          setSuccessFlag(false);
        }, 5000);
      } else {
        // console.log('upload failed')
      }
      setLoading(false);
      setActionsDrop('0');
    })
    .catch(() => {
      // console.log(error)
      setLoading(false);
      setActionsDrop('0');
    });
};

export const handleKeyDownReplace = (e, replaceInputFile) => {
  if (e.keyCode === 13 || e.keyCode === 32) {
    e.preventDefault();
    replaceInputFile.click();
  }
  return false;
};

export const editFileName = (
  id,
  setLoading,
  getClosingBindersListing,
  getClosingBindersListingNoAPI,
  setReportingDetailsData,
  reportingDetailsData,
  setCloseRenameModal,
  setRenamedTextReceived,
  setCurrentFilenetId,
  renameFocus
) => {
  setLoading(true);
  const url = `${endpoints.api}/document-management/file-upload/v1/files/getFileRename?fileId=${id}`;
  callApi
    .getApiDataRequirement(url)
    .then((response) => {
      if (response.status === 200) {
        setCurrentFilenetId(id);
        setCloseRenameModal(true);
        setRenamedTextReceived(response.data);
        setLoading(false);
        // getClosingBindersListing(setReportingDetailsData)
      }
    })
    .catch(() => {
      setLoading(false);
      // console.log(error)
    });
  renameFocus.current.focus();
};

export const confirmDelete = (
  data,
  setDeleteId,
  setErrorModal,
  setErrorModalTxt,
  setErrorModalTitle,
  setLegalHold
) => {
  setDeleteId(data.id);
  setErrorModal(true);
  if (data.legalHoldFlag === 'true') {
    setLegalHold(true);
    const contentTxt = (
      <div>
        For retention purposes, this file can&apos;t be deleted right now.
      </div>
    );
    setErrorModalTxt(contentTxt);
    setErrorModalTitle('Unable to delete file.');
  } else {
    setLegalHold(false);
    setErrorModalTxt('You can’t undo this action');
    setErrorModalTitle('Are you sure you want to delete this document?');
  }
};

const ClosingBindersList = ({
  setLoading,
  reportingDetailsData,
  openedAccordion,
  setOpenedAccordion,
  employee,
  actionsDrop,
  setActionsDrop,
  getClosingBindersListing,
  getClosingBindersListingNoAPI,
  requirementResults,
  setSuccessFlag,
  setFailedFlag,
  setMessageContent,
  setDownloadEnabled,
  count,
  setCount,
  setDeleteId,
  setErrorModal,
  setErrorModalTxt,
  setErrorModalTitle,
  setReportingDetailsData,
  setCloseRenameModal,
  setRenamedTextReceived,
  setCurrentFilenetId,
  setLegalHold,
  setFileDownload,
  selectAll,
  setSelectAll,
  setIsDirty,
}) => {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [fileData, setFileData] = useState(null);
  const [fileName, setFilename] = useState(null);

  const preview = (id, setLoading, setActionsDrop) => {
    setLoading(true);
    const apiURL = `${endpoints.api}/document-management/file-download/v1/files?fileIdList=${id}`;
    callApi
      .getApiData(apiURL)
      .then((result) => {
        const fileurl = previewFile(
          JSON.parse(result.data[0].binaryContent).binary,
          JSON.parse(result.data[0].binaryContent).fileName
        );
        setFileData(fileurl);
        setIsOpenModal(true);
        setLoading(false);
        setActionsDrop('0');
      })
      .catch(() => {
        // console.log('error', error)
        setLoading(false);
        setActionsDrop('0');
      });
  };

  let replaceInputFile = '';
  const handleClickOutside = (event) => {
    const domNode = ReactDOM.findDOMNode(this);
    if (!domNode || !domNode.contains(event.target)) {
      if (
        event.srcElement.className !== 'threedots-anchor' &&
        event.srcElement.className !== 'threedots-upload'
      ) {
        setActionsDrop('0');
      }
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
  }, []);
  const renameFocus = useRef(null);
  const toolbar = {
    // print: true,
    // open: true,
    // download: true,
    // delete: false,
    // rotate: true
    // zoomInOut: true,
    // zoomSelect: true,
  };
  const location = useLocation();
  const tempUserRole = useUserRole();
  return (
    <div className="page-content">
      {reportingDetailsData && reportingDetailsData.length === 0 ? (
        ''
      ) : (
        <div className="listFrame lisHeading">

           <div className="uploadedBy">
                    <strong className="tooltip-on-hover version-name">Document</strong>
           </div>
           <div className="uploadedBy">Uploaded On</div>
           <div className="uploadedBy">Upload Status</div>
           <div className="uploadedBy">File Scan</div>
        </div>
      )}

      {reportingDetailsData && reportingDetailsData.length > 0
        ? reportingDetailsData.map((item, index) => (
            <div
              className={'listContainer'}
              key={item.id}
              data-testid="file-container"
            >
              <div>
                <EDMSideModal
                  modalTitle={'Preview'}
                  handleModalClose={() => setIsOpenModal(false)}
                  isOpen={isOpenModal && fileData}
                >
                  <EDMDocumentViewer
                    file={fileData}
                    // handleDocChange={handleStatusUpdate}
                    toolbar={toolbar}
                    documentView="single"
                    // setDocLoadError={setDocLoadError}
                    // docLoadError={docLoadError}
                  ></EDMDocumentViewer>
                </EDMSideModal>
              </div>
              <div className="listFrame parentContainer">
               
              <div className="uploadedBy">
                    <strong className="tooltip-on-hover version-name">
                    {item.fullName.length > 0 ? item.fullName : item.name}
                    </strong>
                </div>

                <div className="uploadedBy">
                  {item?.uploadedDate?.slice(0, 19).replace("T"," ")}
                </div>

                <div className="uploadedBy">
                  {item.uploadedByName}
                </div>

                <div className="uploadedBy">
                  {item.edmStatus}
                </div>

                
               

            
              </div>
            </div>
          ))
        : ''}
    </div>
  );
};

ClosingBindersList.propTypes = {
  setLoading: PropTypes.func.isRequired,
  reportingDetailsData: PropTypes.any.isRequired,
  openedAccordion: PropTypes.number.isRequired,
  setOpenedAccordion: PropTypes.func.isRequired,
  employee: PropTypes.bool.isRequired,
  actionsDrop: PropTypes.string.isRequired,
  setActionsDrop: PropTypes.func.isRequired,
  getClosingBindersListing: PropTypes.func.isRequired,
  getClosingBindersListingNoAPI: PropTypes.func.isRequired,
  requirementResults: PropTypes.any.isRequired,
  setSuccessFlag: PropTypes.func.isRequired,
  setFailedFlag: PropTypes.func.isRequired,
  setMessageContent: PropTypes.func.isRequired,
  setDownloadEnabled: PropTypes.func.isRequired,
  count: PropTypes.func.isRequired,
  setCount: PropTypes.func.isRequired,
  setDeleteId: PropTypes.func.isRequired,
  setErrorModal: PropTypes.func.isRequired,
  setErrorModalTxt: PropTypes.func.isRequired,
  setErrorModalTitle: PropTypes.func.isRequired,
  setReportingDetailsData: PropTypes.func.isRequired,
  setCloseRenameModal: PropTypes.func.isRequired,
  setRenamedTextReceived: PropTypes.func.isRequired,
  setCurrentFilenetId: PropTypes.func.isRequired,
  setLegalHold: PropTypes.func.isRequired,
};

export default ClosingBindersList;
