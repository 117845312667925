import { store } from '../../shared/store';

export default class UserRoleDropdownList {
    currentState = null;
    parentCompanyInformation = null;

    defaultRole = {
        content: 'Select role',
        value: ''
    };

    roleKeys = {
        contributor: 'Contributor',
        admin: 'Site Administrator',
        // vendor: 'Site administrator (Vendor)', 
        contributorVendor: 'Contributor (Vendor)'
    }

    siteAdminVendor = {
        content: 'Site administrator (vendor)',
        value: this.roleKeys.vendor
    }

    roleList = [
        this.defaultRole,
        {
            content: 'Site administrator',
            value: this.roleKeys.admin
        }, {
            content: 'Contributor',
            value: this.roleKeys.contributor
        }
    ];

    contributorVendor = {
        content: 'Contributor (vendor)',
        value: this.roleKeys.contributorVendor
    }
    constructor() {
        this.currentState = store.getState();
        this.parentCompanyInformation = this.currentState.parentCompanyInformation;
    }

    isParentCompanyVendor() {
        return this.parentCompanyInformation && this.parentCompanyInformation.parentCompany && (this.parentCompanyInformation.parentCompany.businessProfileType?.toLowerCase() === 'vendor' || this.parentCompanyInformation.parentCompany.businessProfileType?.toLowerCase() === 'specialvendor')
    }

    isUserTypeNotListedVendor() {
        return (this.currentState.userProfileInformation.userType !== undefined && 
                this.currentState.userProfileInformation.userType.type === 'Vendor' &&
                this.currentState.userProfileInformation.userType.subType !== undefined &&
                this.currentState.userProfileInformation.userType.subType.parentCompanyName === 'Not Listed');
    }

    isFrequentVendorUser() {
        return this.parentCompanyInformation && this.parentCompanyInformation.parentCompany && (this.parentCompanyInformation.parentCompany.businessProfileType?.toLowerCase() === 'vendor' || this.parentCompanyInformation.parentCompany.businessProfileType?.toLowerCase() === 'specialvendor')
    }

    getUserDropdownList() {
        if (this.isFrequentVendorUser()){
            return this.roleList
        }else if (this.isParentCompanyVendor()) {
            return this.roleList;
        }else if(this.isUserTypeNotListedVendor()){
            return [this.contributorVendor]
        }
        return this.roleList;
    }

    getProfileRole() {
        if(this.isUserTypeNotListedVendor()){
            return this.roleKeys.contributorVendor
        }else{
            return '';
        }
    }
}