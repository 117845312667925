import React, { useState, useEffect } from 'react';
// import Link from 'next/link'
import './footerLinks.css';
import Loader from '../../utilities/Loader';
import { helpAndFaq } from '../../utilities/Utils';

export const downloadFAQ = (setLoading) => {
  helpAndFaq(setLoading);
};

const FooterLinks = () => {
  const [loading, setLoading] = useState(false);
  const [loggedUser, setLoggedUser] = useState(false);

  useEffect(() => {
    if (
      sessionStorage.getItem('loggedUser') !== '' &&
      sessionStorage.getItem('loggedUser') !== null
    ) {
      setLoggedUser(true);
    }
  });

  return (
    <>
      <nav className="footer-nav">
        {loading ? <Loader /> : null}
        <div className="link-container">
          <ul className="links">
            <li>
              <a href="/FooterTOU" target="_blank" rel="noopener noreferrer">
                Terms of Use
              </a>
            </li>
            <li>
              <div className="divider" />
            </li>
            <li>
              <a
                href="https://emp.usbank.com/about-us-bank/privacy.html"
                target="_blank"
                rel="noopener noreferrer"
              >
                Privacy
              </a>
            </li>
            <li>
              <div className="divider" />
            </li>
            <li>
              <a
                href="https://emp.usbank.com/about-us-bank/privacy/security.html#onlinetracking"
                target="_blank"
                rel="noopener noreferrer"
              >
                Online Tracking and Advertising
              </a>
            </li>
            <li>
              <div className="divider" />
            </li>
            <li>
              <a
                href="https://emp.usbank.com/about-us-bank/accessibility.html"
                target="_blank"
                rel="noopener noreferrer"
              >
                Accessibility
              </a>
            </li>
          </ul>
          {loggedUser ? (
            <ul className="links">
              <li>
                <div className="divider" />
              </li>
              <li>
                <button
                  type="button"
                  className="faq-link"
                  onClick={() => downloadFAQ(setLoading)}
                >
                  Guide &amp; FAQs (PDF)
                </button>
              </li>
            </ul>
          ) : (
            ''
          )}
        </div>
      </nav>
    </>
  );
};

export default FooterLinks;
