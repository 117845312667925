import { getAuthServiceURL, getSvcServiceURL, getDocumentmgmtServiceURL , getDocumentmgmtServiceURLuser} from '../../config/connections';
import Axios from 'axios';
import GraphQlQuery from '../../utilities/graphQL-query';
import { endpoints } from '../../../config/connections';
import callApi from '../../../src/components/utilities/callApi';

const getUpdateQueryBody = (profile, status) => {
    return {
        "requestType": {
            "serviceType": "CDC_PROF_MAINT",
            "serviceSubType": "EDIT_PROFILE"
        },
        "data": {
            "intent": "EDIT_PROFILE",
            "profileID": profile.profileID,
            "userID": profile.userID,
            "firstName": profile.firstName,
            "lastName": profile.lastName,
            "middleName": profile.middleName=(profile.middleName===undefined)?"":profile.middleName,
            "email": profile.email,
            "phoneNumbers": {
                "mobilePhone": profile.phoneNumbers?.mobilePhone,
                "businessPhone": profile.phoneNumbers?.businessPhone
            },
            "address": {
                "addressLine1": profile.address?.addressLine1,
                "addressLine2": profile.address?.addressLine2,
                "city": profile.address?.city,
                "state": profile.address?.state,
                "country": profile.address?.country,
                "zipCode": profile.address?.zipCode
            },
            "parentEIN": profile.parentEIN,
            "parentCompanyName": profile.parentCompanyName,
            "parentCompanyLegalEntityID": profile.parentCompanyLegalEntityID,
            "profileRole": profile.profileRole,
            "status": status,
            "usbRelationship": profile.usbRelationship,
            // "lastEditedBy": ,
            "sourceType": "CDC"
        }
    }
}


const getStatus = (status) => {
    switch (status) {
        case "Active":
           return 'Deactivated';
            break;
        default:
            return '';
            break;
    }
}

const getFrqVendorUpdateQueryBody = (profile, status) => {
    let flag = (profile.status === 'Active') ? false : true;
    let vendorNumber = profile.userID.replace('ASSOCIATED_EIN_', '');

    let currentAsignedUrl = getDocumentmgmtServiceURL({
        service: 'getProfilePermissionsforParentEIN',
        userid: `${profile.userID.replace('ASSOCIATED_EIN_', '')}`
    });
 
    return Axios.get(currentAsignedUrl, { cdcType: "docmanagement",  headers:{
        'jwt':`${sessionStorage.getItem('tok')}`,
        'user-id':`${profile.userID.replace('ASSOCIATED_EIN_', '')}`
    }})
    .then(responseObj => {

        const assignedEntitlmentsForCurrentEin = responseObj.data.find(item => item.number === profile.parentEIN.replace('FRQ_',''));

        return {
            "vendorNumber": vendorNumber,
            "vendorName": profile.lastName,
            "activation": flag,
            "permissions": [
                {
                    "name": "CDC",
                    "parentEntitys": [
                        assignedEntitlmentsForCurrentEin
                    ]
                }
            ]
        }

    }).catch((error) => {
        
        return Promise.reject(error);
          
    });    
}

const getFrqVendorSRUpdateQueryBody = (profile, status) => {
    let  vendorNumber = profile.userID.replace('ASSOCIATED_EIN_','');
    let profileStatus  = (profile.status === 'Active')? 'Deactivated' : 'Active';
return {
    "requestType": {
        "serviceType": "CDC_PROF_MAINT",
        "serviceSubType": "EDIT_PROFILE"
     },
   "data": {
     "intent":"EDIT_PROFILE",
     "profileID": profile.profileID,
    "userID": profile.userID,
    "firstName": "",
    "lastName": profile.lastName,
    "middleName": "",
    "email": "",
    "address": {
      "addressLine1": "18101 Von Marman Avenue",
      "addressLine2": "KIA",
      "city": "Minneapolis",
      "state": "MN",
      "country": "Hennepin",
      "zipCode": "7485412"
    },
    "status": profileStatus,
    "parentEIN": profile.parentEIN,
    "usbRelationship": "Company Contact",
    "statusCode": "201",
    "parentCompanyName": profile.parentCompanyName,
    "parentCompanyLegalEntityID": profile.parentCompanyLegalEntityID,
    "profileRole": profile.profileRole,
    "phoneNumbers": {
      "homePhone": "111-456-7890",
      "mobilePhone": "111-789-1230",
      "businessPhone": "111-012-3456"
    },
     "profileRoleChanged" :"true",
     "previousProfileRole" :"Contributor",
      "hostCompanyThatCreatesUnlistedVendor":{
          "hostEIN": "38-3885926",
          "hostCompanyName":"10 VRV 2008 LLC",
          "hostCompanyLegalEntityID":"30125"
      }
    }
  }
  
}


const getUnlisredVendorActivateDeactivateBody = (profile, status,hostEIN) => {    
  
    let intent='DE_ACTIVATE_UNLISTED_VENDOR';
    if(status==="Deactivate"){
        intent='DE_ACTIVATE_UNLISTED_VENDOR';
    }else{
        intent='ACTIVATE_UNLISTED_VENDOR';
    }
    return {
            "requestType": {
                "serviceType": "CDC_PROF_MAINT",
                "serviceSubType": "EDIT_PROFILE"
            },
            "data": {
            "intent":intent,
            "profileID": profile.profileID,            
            "parentEIN": hostEIN          
    }
  }
  
}


const getUnlisredVendorMasterDeactivateBody = (profile, status,hostEIN) => {     
    let intent='MASTER_DE_ACTIVATE_ALL_EINs_FOR_UNLISTED_VENDOR';
    
    return {
            "requestType": {
                "serviceType": "CDC_PROF_MAINT",
                "serviceSubType": "EDIT_PROFILE"
            },
            "data": {
            "intent":intent,
            "profileID": profile.profileID,            
            "parentEIN": ""          
    }
  }  
}


//= async () =>

export function deactivateUser(profile,hostEIN,parentCompanyDropdownSelection,usrRolePID) {
    if (profile.parentEIN.includes('FRQ')) {
        return function (dispatch, getState) {
            let baseUrL = getDocumentmgmtServiceURL({ service: 'vendor_association_and_deassociation' });

            let flag = (profile.status === 'Active') ? false : true;
            let vendorNumber = profile.userID.replace('ASSOCIATED_EIN_', '');

            let currentAsignedUrl = getDocumentmgmtServiceURL({
                service: 'getProfilePermissionsforParentEIN',
                userid: `${profile.userID.replace('ASSOCIATED_EIN_', '')}`
            });

            return Axios.get(currentAsignedUrl, { cdcType: "docmanagement",  headers:{
                'jwt':`${sessionStorage.getItem('tok')}`,
                'user-id':`${profile.userID.replace('ASSOCIATED_EIN_', '')}`
            }})
            .then(responseObj => {

                const assignedEntitlmentsForCurrentEin = responseObj.data.find(item => item.number === profile.parentEIN.replace('FRQ_',''));
                
                let body = {
                    "vendorNumber": vendorNumber,
                    "vendorName": profile.lastName,
                    "activation": flag,
                    "permissions": [
                        {
                            "name": "CDC",
                            "parentEntitys": [
                                assignedEntitlmentsForCurrentEin
                            ]
                        }
                    ]
                };

                if(assignedEntitlmentsForCurrentEin===undefined){

                    body = {
                        "vendorNumber": vendorNumber,
                        "vendorName": profile.lastName,
                        "activation": flag,
                        "permissions": [
                            {
                                "name": "CDC",
                                "parentEntitys": [
                                    
                                ]
                            }
                        ]
                    };
                }
                const heder = { cdcType : 'docmanagement' }
                return Axios.put(baseUrL, body,heder).then((res) => {
                    console.log('Deactivated');
                    const body = getFrqVendorSRUpdateQueryBody(profile, 'Deactivated');
                    Axios.post(getSvcServiceURL({ service: 'update' }),body , { cdcType: "servicing_Update" })
                    .then((res) => {
                        return Promise.resolve(true);
                    }, error => {
                        return Promise.reject(error);
                    })
                }, (error) => {
                    return Promise.reject(error)
                });
                

            }).catch((error) => {
                
                return Promise.reject(error);
                
            });
        };

    } else if (profile.parentEIN.includes('Not Listed')){
        return function (dispatch, getState) {
            let baseUrL = getDocumentmgmtServiceURL({ service: 'vendor_association_and_deassociation' });

            let flag = (profile.status === 'Active') ? false : true;
            let vendorNumber = 'Non listed';
            const config = {
                cdcType: "docmanagement",
                headers:{
                    'jwt':`${sessionStorage.getItem('tok')}`,
                    'user-id':`${profile.userID.replace('ASSOCIATED_EIN_', '')}`
                }
            }
            let currentAsignedUrl = getDocumentmgmtServiceURLuser({
                service: 'getProfilePermissionsforUser',
                userid: `${profile.userID.replace('ASSOCIATED_EIN_', '')}`
            });

            return Axios.get(currentAsignedUrl,config)
            .then(responseObj => {

                let assignedEntitlmentsForCurrentEin = responseObj.data.find(item => item.number === hostEIN);
                               
                let body = {
                    "vendorProfileId" : profile.profileID,
                    "vendorNumber": vendorNumber,
                    "vendorName": 'Non listed',
                    "activation": false,
                    "permissions": [
                        {
                            "name": "CDC",
                            "parentEntitys": [
                                assignedEntitlmentsForCurrentEin
                            ]
                        }
                    ]
                };
                if(assignedEntitlmentsForCurrentEin===undefined){
                    body = {
                        "vendorProfileId" : profile.profileID,
                        "vendorNumber": vendorNumber,
                        "vendorName": 'Non listed',
                        "activation": false,
                        "permissions": [
                            {
                                "name": "CDC",
                                "parentEntitys": [
                                    
                                ]
                            }
                        ]
                    };
                }
                const heder = { cdcType : 'docmanagement' }

                if(usrRolePID==='employee_signon'){ 
                    if(hostEIN===undefined && parentCompanyDropdownSelection===undefined){ // MASTER DEACTIVATE  :: This is executed for CDC Employee login 
                            body = {
                                "vendorProfileId" : profile.profileID,
                                "vendorNumber": vendorNumber,
                                "vendorName": 'Non listed',
                                "activation": false,
                                "permissions": [
                                    {
                                        "name": "CDC",
                                        "parentEntitys": responseObj.data.map(ent => {
                                            return {
                                                ...ent
                                            }
                                        }) 
                                        
                                    }
                                ]
                            };

                            /*return Axios.put(baseUrL, body,heder).then((res) => {
                                console.log('Deactivated');
                                const body = getUnlisredVendorActivateDeactivateBody(profile, 'Deactivate',parentCompanyDropdownSelection);
                                Axios.post(getSvcServiceURL({ service: 'update' }),body , { cdcType: "servicing_Update" })
                                .then((res) => {
                                    return Promise.resolve(true);
                                }, error => {
                                    return Promise.reject(error);
                                })
                            }, (error) => {
                                return Promise.reject(error)
                            });*/

                           
                            /*let authURL = getAuthServiceURL({ service: 'deactivate' });
                            return Axios.post(authURL, {
                                        userID: profile.userID
                                    }, { cdcType: "auth_non_cdc_user" }).then((res) => {    */                        
                                            return Axios.put(baseUrL, body,heder).then((res) => {
                                                    console.log('Deactivated');
                                                    const body = getUnlisredVendorMasterDeactivateBody(profile, 'Deactivate',hostEIN);
                                                    return Axios.post(getSvcServiceURL({ service: 'update' }),body , { cdcType: "servicing_Update" })
                                                    .then((res) => {                                        
                                                            const body = getUpdateQueryBody(profile, 'Deactivated');
                                                            return Axios.post(getSvcServiceURL({ service: 'update' }), body, { cdcType: "servicing_Update" })
                                                                .then((res) => {
                                                                    return Promise.resolve(true);
                                                                }, error => {
                                                                    return Promise.reject(error);
                                                                })							
                                                        
                                                    }, error => {
                                                        return Promise.reject(error);
                                                    });   
                                            }, (error) => {
                                                return Promise.reject(error)
                                            });                                                            
                                        
                                    /*}, (error) => {
                                        return Promise.reject(error);
                                    });*/
                    }else if((hostEIN===undefined || hostEIN==='Not Listed') && parentCompanyDropdownSelection!==undefined){ //Normal Soft Deactivat from Employee
                        assignedEntitlmentsForCurrentEin = responseObj.data.find(item => item.number === parentCompanyDropdownSelection);
                        if(assignedEntitlmentsForCurrentEin===undefined){
                            body = {
                                "vendorProfileId" : profile.profileID,
                                "vendorNumber": vendorNumber,
                                "vendorName": 'Non listed',
                                "activation": false,
                                "permissions": [
                                    {
                                        "name": "CDC",
                                        "parentEntitys": []                    
                                                         
                                    }
                                ]
                            };
                        }else{
                            body = {
                                "vendorProfileId" : profile.profileID,
                                "vendorNumber": vendorNumber,
                                "vendorName": 'Non listed',
                                "activation": false,
                                "permissions": [
                                    {
                                        "name": "CDC",
                                        "parentEntitys": [
                                            assignedEntitlmentsForCurrentEin
                                        ]
                                    }
                                ]
                            };

                        }                            
                        
                        return Axios.put(baseUrL, body,heder).then((res) => {
                            console.log('Deactivated');
                            const body = getUnlisredVendorActivateDeactivateBody(profile, 'Deactivate',parentCompanyDropdownSelection);
                            Axios.post(getSvcServiceURL({ service: 'update' }),body , { cdcType: "servicing_Update" })
                            .then((res) => {
                                return Promise.resolve(true);
                            }, error => {
                                return Promise.reject(error);
                            })
                        }, (error) => {
                            return Promise.reject(error)
                        });

                    }     
                }else{ // Individual Deactivate :: THis is executed for Customer Admin Login
                    return Axios.put(baseUrL, body,heder).then((res) => {
                        console.log('Deactivated');
                        const body = getUnlisredVendorActivateDeactivateBody(profile, 'Deactivate',hostEIN);
                        Axios.post(getSvcServiceURL({ service: 'update' }),body , { cdcType: "servicing_Update" })
                        .then((res) => {
                            return Promise.resolve(true);
                        }, error => {
                            return Promise.reject(error);
                        })
                    }, (error) => {
                        return Promise.reject(error)
                    });
                }

            }).catch((error) => {
                
                return Promise.reject(error);
                
            });
        };
    }    
    else {
        return function (dispatch, getState) {
            let baseUrL = getAuthServiceURL({ service: 'deactivate' });
            return Axios.post(baseUrL, {
                userID: profile.userID
            }, { cdcType: "auth_non_cdc_user" }).then((res) => {
                const body = getUpdateQueryBody(profile, 'Deactivated');
                Axios.post(getSvcServiceURL({ service: 'update' }), body, { cdcType: "servicing_Update" })
                    .then((res) => {
                        return Promise.resolve(true);
                    }, error => {
                        return Promise.reject(error);
                    })
            }, (error) => {
                return Promise.reject(error)
            });
        };
    } 

}
export function deleteUser(profile, userID){
        return function (dispatch, getState) {
            const baseUrl =`${endpoints.apigee}/document-management/coreservice/user-management/v1/delete/user`;
            // let data_raw = {
            //     'emailId':profile.email,
            //     'profileId' : profile.userID, the user getting deleted
            //     'deletedByUserId':userID
            // }
            return Axios.delete(baseUrl,
                { headers:{'user-id': sessionStorage.getItem("loggedUser"),},
                data : {
                    "emailId" : profile.email,
                    "deletedByUserId" : sessionStorage.getItem("loggedUser"),
                    "profileId" : profile.profileID,
                    "firstName" : profile.firstName,
                    "lastName" : profile.lastName,
                    "userId" : profile.userID,
                    "parentCompany" : profile.parentCompanyName,
                    "parentCompanyId" : profile.parentEIN
                }
                }
            )
            .then(responseObj => {
                return responseObj;
            }).catch((error) => {
                
                return error;
                
            });
        };

    }




export function reactivateUser(profile,hostEIN,parentCompanyDropdownSelection,usrRolePID) {
    if(profile.parentEIN.includes('FRQ')){
        return function (dispatch, getState) {
            let baseUrL = getDocumentmgmtServiceURL({ service: 'vendor_association_and_deassociation' });
            //const body = getFrqVendorUpdateQueryBody(profile, 'Deactivated');          
            

            let flag = (profile.status === 'Active') ? false : true;
            let vendorNumber = profile.userID.replace('ASSOCIATED_EIN_', '');

            let currentAsignedUrl = getDocumentmgmtServiceURL({
                service: 'getProfilePermissionsforParentEIN',
                userid: `${profile.userID.replace('ASSOCIATED_EIN_', '')}`
            });

            return Axios.get(currentAsignedUrl, { cdcType: "docmanagement",  headers:{
                'jwt':`${sessionStorage.getItem('tok')}`,
                'user-id':`${profile.userID.replace('ASSOCIATED_EIN_', '')}`
            }})
            .then(responseObj => {

                    let  assignedEntitlmentsForCurrentEin = responseObj.data.find(item => item.number === profile.parentEIN.replace('FRQ_',''));
                    let body={};
                    if(assignedEntitlmentsForCurrentEin===null || assignedEntitlmentsForCurrentEin===undefined){
                         body = {
                            "vendorNumber": vendorNumber,
                            "vendorName": profile.lastName,
                            "activation": flag,
                            "permissions": [
                                {
                                    "name": "CDC",
                                    "parentEntitys": [
                                        
                                    ]
                                }
                            ]
                        }; 
                    }else{
                         body = {
                            "vendorNumber": vendorNumber,
                            "vendorName": profile.lastName,
                            "activation": flag,
                            "permissions": [
                                {
                                    "name": "CDC",
                                    "parentEntitys": [
                                        assignedEntitlmentsForCurrentEin
                                    ]
                                }
                            ]
                        }; 

                    }

                               
            
                    const heder = { cdcType : 'docmanagement' }
                    return Axios.put(baseUrL, body,heder).then((res) => {
                    console.log('Deactivated');
                    const body = getFrqVendorSRUpdateQueryBody(profile, 'Deactivated');
                   return Axios.post(getSvcServiceURL({ service: 'update' }),body , { cdcType: "servicing_Update" })
                    .then((res) => {
                        return Promise.resolve(true);
                    }, error => {
                        return Promise.reject(error);
                    })
                    }, (error) => {
                        return Promise.reject(error)
                    });
                }).catch((error) => {
                
                    return Promise.reject(error);
                    
                });

        };

    }else if (profile.parentEIN.includes('Not Listed')){
        return function (dispatch, getState) {
            let baseUrL = getDocumentmgmtServiceURL({ service: 'vendor_association_and_deassociation' });

            let flag = (profile.status === 'Active') ? false : true;
            let vendorNumber = 'Non listed';
            const config = {
                cdcType: "docmanagement",
                headers:{
                    'jwt':`${sessionStorage.getItem('tok')}`,
                    'user-id':`${profile.userID.replace('ASSOCIATED_EIN_', '')}`
                }
            }
            let currentAsignedUrl = getDocumentmgmtServiceURLuser({
                service: 'getProfilePermissionsforUser',
                userid: `${profile.userID.replace('ASSOCIATED_EIN_', '')}`
            });

            return Axios.get(currentAsignedUrl,config)
            .then(responseObj => {

                let assignedEntitlmentsForCurrentEin = responseObj.data.find(item => item.number === hostEIN);
                
                let body = {
                    "vendorProfileId" : profile.profileID,
                    "vendorNumber": vendorNumber,
                    "vendorName": 'Non listed',
                    "activation": true,
                    "permissions": [
                        {
                            "name": "CDC",
                            "parentEntitys": [
                                assignedEntitlmentsForCurrentEin
                            ]
                        }
                    ]
                };
                
                if(hostEIN===undefined && responseObj.data.length==0){
                    //assignedEntitlmentsForCurrentEin=responseObj.data;
                    body = {
                        "vendorProfileId" : profile.profileID,
                        "vendorNumber": vendorNumber,
                        "vendorName": 'Non listed',
                        "activation": true,
                        "permissions": [
                            {
                                "name": "CDC",
                                "parentEntitys": [
                                   
                                ]
                            }
                        ]
                    };
                }

                if(assignedEntitlmentsForCurrentEin===undefined){
                    //assignedEntitlmentsForCurrentEin=responseObj.data;
                    body = {
                        "vendorProfileId" : profile.profileID,
                        "vendorNumber": vendorNumber,
                        "vendorName": 'Non listed',
                        "activation": true,
                        "permissions": [
                            {
                                "name": "CDC",
                                "parentEntitys": [
                                   
                                ]
                            }
                        ]
                    };
                }
                
                
                if(usrRolePID==='employee_signon'){ 

                    if(hostEIN===undefined && parentCompanyDropdownSelection===undefined){ // MASTER REACTIVATE  :: This is executed for CDC Employee login 
                        
                        /*const heder = { cdcType : 'docmanagement' }
                        return Axios.put(baseUrL, body,heder).then((res) => {
                            console.log('Reactivate');
                            const body = getUnlisredVendorActivateDeactivateBody(profile, 'Reactivate',parentCompanyDropdownSelection);
                            Axios.post(getSvcServiceURL({ service: 'update' }),body , { cdcType: "servicing_Update" })
                            .then((res) => {
                                return Promise.resolve(true);
                            }, error => {
                                return Promise.reject(error);
                            })
                        }, (error) => {
                            return Promise.reject(error)
                        });*/

                       
                        /*let baseAuthUrL = getAuthServiceURL({ service: 'reactivate' });
                        return Axios.post(baseAuthUrL, {
                            userID: profile.userID
                        }, { cdcType: "auth_non_cdc_user" }).then((res) => { */
                            getUpdateQueryBody(profile)
                            const svcBody = getUpdateQueryBody(profile, 'Active');
                            return Axios.post(getSvcServiceURL({ service: 'update' }), svcBody, { cdcType: "servicing_Update" })
                                .then((res) => {
                                        const heder = { cdcType : 'docmanagement' }
                                        return Axios.put(baseUrL, body,heder).then((res) => {
                                            console.log('Reactivate');                                        
                                            return Promise.resolve(true);                                       
                                        }, (error) => {
                                            return Promise.reject(error)
                                        });                                
                                })
                        /*}, (error) => {
                            return Promise.reject(error)
                        });*/
                    
                    }else if((hostEIN===undefined || hostEIN==='Not Listed') && parentCompanyDropdownSelection!==undefined){ //Normal Soft Deactivat from Employee
                        
                        assignedEntitlmentsForCurrentEin = responseObj.data.find(item => item.number === parentCompanyDropdownSelection);
                        
                        if(assignedEntitlmentsForCurrentEin===undefined){
                            body = {
                                "vendorProfileId" : profile.profileID,
                                "vendorNumber": vendorNumber,
                                "vendorName": 'Non listed',
                                "activation": true,
                                "permissions": [
                                    {
                                        "name": "CDC",
                                        "parentEntitys": [
                                           
                                        ]
                                    }
                                ]
                            };
                        }
                        else{

                            body = {
                                "vendorProfileId" : profile.profileID,
                                "vendorNumber": vendorNumber,
                                "vendorName": 'Non listed',
                                "activation": true,
                                "permissions": [
                                    {
                                        "name": "CDC",
                                        "parentEntitys": assignedEntitlmentsForCurrentEin
                                    }
                                ]
                            };
                        }

                        const heder = { cdcType : 'docmanagement' }
                        return Axios.put(baseUrL, body,heder).then((res) => {
                            console.log('Reactivate');
                            const body = getUnlisredVendorActivateDeactivateBody(profile, 'Reactivate',parentCompanyDropdownSelection);
                            Axios.post(getSvcServiceURL({ service: 'update' }),body , { cdcType: "servicing_Update" })
                            .then((res) => {
                                return Promise.resolve(true);
                            }, error => {
                                return Promise.reject(error);
                            })
                        }, (error) => {
                            return Promise.reject(error)
                        });
                    }

                }else{

                    const heder = { cdcType : 'docmanagement' }
                    return Axios.put(baseUrL, body,heder).then((res) => {
                        console.log('Reactivate');
                        const body = getUnlisredVendorActivateDeactivateBody(profile, 'Reactivate',hostEIN);
                        Axios.post(getSvcServiceURL({ service: 'update' }),body , { cdcType: "servicing_Update" })
                        .then((res) => {
                            return Promise.resolve(true);
                        }, error => {
                            return Promise.reject(error);
                        })
                    }, (error) => {
                        return Promise.reject(error)
                    });
                }
                

            }).catch((error) => {
                
                return Promise.reject(error);
                
            });
        };
    } 
    else{
    return function (dispatch, getState) {
        let baseUrL = getAuthServiceURL({ service: 'reactivate' });
        return Axios.post(baseUrL, {
            userID: profile.userID
        }, { cdcType: "auth_non_cdc_user" }).then((res) => {
            getUpdateQueryBody(profile)
            const body = getUpdateQueryBody(profile, 'Active');
            Axios.post(getSvcServiceURL({ service: 'update' }), body, { cdcType: "servicing_Update" })
                .then((res) => {
                    return Promise.resolve(true);
                })
        }, (error) => {
            return Promise.reject(error)
        });
    };
}
}

export function resendInvitation(profile) {
    return function (dispatch, getState) {
        let baseUrL = getAuthServiceURL({ service: 'resendInvitation' });
        return Axios.post(baseUrL, {
            firstName: profile.firstName,
            lastName: profile.lastName,
            userID: profile.userID,
            email: profile.email
        }, { cdcType: "auth" }).then((res) => {
            return Promise.resolve(true);
        }, (error) => {
            return Promise.reject(error)
        });
    };
}

export function unlock(profile) {
    return function (dispatch, getState) {
        let baseUrL = getAuthServiceURL({ service: 'unlock' });
        const graphURL = getSvcServiceURL({ service: 'graph' });
        const isUserAcceptedTnC = GraphQlQuery.getCurrentUserRole(profile.userID);   
        

        const queryProfile = GraphQlQuery.getProfileListWithEmail(profile.email);
        const graphURLProfile = getSvcServiceURL({ service: 'graph' });
        return Axios.post(graphURLProfile, queryProfile, { cdcType: "servicing" }).then((res) => {
          console.log('TempList Parent Company ::', res.data);
    

                return Axios.put(baseUrL, { userID: profile.userID ,
                    email:profile.email,
                    phoneNumbers:{
                        mobilePhone: res.data.data.getProfileList.profiles[0].phoneNumbers.mobilePhone
                    }    
                
                }, { cdcType: "auth" }).then((dataResponse) => {            
                    const bodyPending = getUpdateQueryBody(profile, 'Pending');
                    const bodyActive = getUpdateQueryBody(profile, 'Active');
                    Axios.post(graphURL, isUserAcceptedTnC, { cdcType: "servicing" })
                    .then((res) => {
                    console.log(res);              
                    const data = res.data.data;
                    if(data===null || data===undefined){
                        Axios.post(getSvcServiceURL({ service: 'update' }), bodyPending, { cdcType: "servicing_Update" })
                        .then((res) => {  
                            return Promise.resolve(true);
                        })
                    }else if(data.IsTncAccpeted.TnCAccpetedStatus){
                        Axios.post(getSvcServiceURL({ service: 'update' }), bodyActive, { cdcType: "servicing_Update" })
                        .then((res) => {  
                            return Promise.resolve(true);
                        })
                    }      
                    
                    }).catch(error => {
                        return Promise.reject(error)
                    });   

                    return Promise.resolve(true);
                    
                }, (error) => {
                    return Promise.reject(error)
                });        
        }).catch((error) => {
            return Promise.reject(error)
        });
        
        

        
    };
}