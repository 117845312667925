// const API_URL = process.env.API_URL || ''

// For local development uncomment below code
import {enableCDCServicing} from  '../src/config/connections';

const defaultEndpoints = {
  apigee: "https://it-api.usbank.com",
  // apigee:"https://it1-usb-digital-api-common.us.bank-dns.com",
  // envUrl: "http://localhost:8090",
  envUrl: "https://it1-docmanagement.us.bank-dns.com",
  termsofuse: "https://content.usbank.com",
  api: "https://it1-docmanagement.us.bank-dns.com",
  manageUser:
    "https://it4-onlinebanking.usbank.com/digital/servicing/ui/customer-profile",
  myProfile:
    "https://it4-onlinebanking.usbank.com/digital/servicing/ui/customer-profile/my-profile",
  authCodeUrl: "https://it-sso.us.bank-dns.com",
  redirect: "http://localhost:8080",

  manifestPath:
  "https://it4-onlinebanking.usbank.com/auth/login/wmf/latest/manifest.json",
manifestPathTimeout:
  "https://it3-onlinebanking.usbank.com/digital/auth/challenge/es/latest/manifest.json",
manifestPathLoginHelp:
  "https://it3-onlinebanking.usbank.com/digital/loginhelp/wmf/latest/manifest.json",
  
  transmit: "https://it1-transmit-obc.us.bank-dns.com:9943",
  servicingAPI:
  enableCDCServicing==false ? "https://it-api.usbank.com/customer-management/graphql/v1" :"https://it-api.usbank.com/document-management/coreservice/user-management/v1/cdcservice/graphql",
  // servicingAPI: "https://it1-usb-digital-api-common.us.bank-dns.com/digital/servicing/customer-mgmt-cdc/retrievals/graphql",
  updateCredentialsApi:
    "https://it-api.usbank.com/authentication/customer-auth/credentials/v1/reset",
  createCredentialsApi:
    "https://it-api.usbank.com/authentication/customer-auth/credentials/v1/create",
  configApiURL:
    "https://it-api.usbank.com/authentication/customer-auth/app-config/v1/config",
  configAK: "zV2JDobreS0n3GBzU7fhgbN6toHArF6v",
  routingKey: "it4",
  authRoutingKey: "it4",
};

// local dev
// const defaultEndpoints = {
//   apigee: "https://dev-api.usbank.com",
//   envUrl: "http://dev01-docmgmt.us.bank-dns.com",
//   api: "http://dev01-docmgmt.us.bank-dns.com",
//   manageUser: `http://dev5-digital-servicing-api.us.bank-dns.com/digital/servicing/ui/customer-profile?rand='${Math.round(
//     Math.random() * 10000000
//   )}`,
//   authCodeUrl: "https://it-sso.us.bank-dns.com",
//   transmit: "https://it-transmit-obc.us.bank-dns.com:9943",
//   redirect: "http://localhost:8080",
//   manifestPath:
//     "https://it4-onlinebanking.usbank.com/auth/login/wmf/latest/manifest.json",
//   manifestPathTimeout:
//     "https://it4-onlinebanking.usbank.com/digital/auth/challenge/es/latest/manifest.json",
//   manifestPathLoginHelp:
//     "https://it5-onlinebanking.usbank.com/digital/loginhelp/wmf/latest/manifest.json",
//   transmit: "https://it1-transmit-obc.us.bank-dns.com:9943",
//   servicingAPI: "https://dev-api.usbank.com/customer-management/graphql/v1",
// // servicingAPI: "https://it1-usb-digital-api-common.us.bank-dns.com/digital/servicing/customer-mgmt-cdc/retrievals/graphql",
//   updateCredentialsApi:
//     "https://it-api.usbank.com/authentication/customer-auth/credentials/v1/reset",
//   createCredentialsApi:
//     "https://it-api.usbank.com/authentication/customer-auth/credentials/v1/create",
//   configApiURL:
//     "https://it-api.usbank.com/authentication/customer-auth/app-config/v1/config",
//   configAK: "zV2JDobreS0n3GBzU7fhgbN6toHArF6v",
//   routingKey: "it4",
//   authRoutingKey: "it4",
// };

// Uncomment below code when deploying to Prod
// const defaultEndpoints = {
//   apigee: 'https://api.usbank.com',
//   envUrl: 'https://docmanagement.us.bank-dns.com',
//   termsofuse:'https://content.usbank.com',
//   api: 'https://docmanagement.us.bank-dns.com',
//   manageUser:
//     'https://onlinebanking.usbank.com/digital/servicing/ui/customer-profile',
//   myProfile:
//     'https://onlinebanking.usbank.com/digital/servicing/ui/customer-profile/my-profile',
//   authCodeUrl: 'https://uat-sso.us.bank-dns.com',
//   redirect: 'https://docmanagement.usbank.com',
//   transmit: 'https://transec.usbank.com',
//   manifestPath : 'https://onlinebanking.usbank.com/auth/login/wmf/latest/manifest.json',
//   manifestPathTimeout : "https://onlinebanking.usbank.com/digital/auth/challenge/es/latest/manifest.json",
//   manifestPathLoginHelp : 'https://onlinebanking.usbank.com/digital/loginhelp/wmf/latest/manifest.json',
//   servicingAPI: 'https://api.usbank.com/customer-management/graphql/v1',
//   updateCredentialsApi:
//     'https://api.usbank.com/authentication/customer-auth/credentials/v1/reset',
//   createCredentialsApi:
//     'https://api.usbank.com/authentication/customer-auth/credentials/v1/create',
//   configApiURL:
//     'https://api.usbank.com/authentication/customer-auth/app-config/v1/config',
//   configAK: 'gNShJPNcOlv0A4bbQ3EqJbf52ukCM6lV',
//   routingKey: '',
//   authRoutingKey: ''
// }

// Uncomment below code when deploying to UAT
// const defaultEndpoints = {
//   apigee: "https://alpha-api.usbank.com",
//   envUrl: "https://uat1-docmanagement.us.bank-dns.com",
//   termsofuse: "https://uat1-content.usbank.com",
//   api: "https://uat1-docmanagement.us.bank-dns.com",
//   manageUser:
//     "https://uat3-onlinebanking.usbank.com/digital/servicing/ui/customer-profile",
//   myProfile:
//     "https://uat3-onlinebanking.usbank.com/digital/servicing/ui/customer-profile/my-profile",
//   authCodeUrl: "https://uat-sso.us.bank-dns.com",
//   redirect: "https://uat1-docmanagement.usbank.com",
//   transmit: "https://uat2-transec.usbank.com",
//   manifestPath : 'https://uat5-onlinebanking.usbank.com/auth/login/wmf/latest/manifest.json',
//   manifestPathTimeout : "https://uat4-onlinebanking.usbank.com/digital/auth/challenge/es/latest/manifest.json",
//   manifestPathLoginHelp : 'https://uat5-onlinebanking.usbank.com/digital/loginhelp/wmf/latest/manifest.json',
//   servicingAPI: "https://alpha-api.usbank.com/customer-management/graphql/v1",
//   updateCredentialsApi:
//     "https://alpha-api.usbank.com/authentication/customer-auth/credentials/v1/reset",
//   createCredentialsApi:
//     "https://alpha-api.usbank.com/authentication/customer-auth/credentials/v1/create",
//   configApiURL:
//     "https://alpha-api.usbank.com/authentication/customer-auth/app-config/v1/config",
//   configAK: "G6Fl3fdtwBEJXTF8WBFmyLF3xTJO3NiU",
//   routingKey: "uat4",
//   authRoutingKey: "uat4",
// };

// Uncomment below code when deploying to IT
// const defaultEndpoints = {
//   apigee: "https://it-api.usbank.com",
//   envUrl: "https://it1-docmanagement.us.bank-dns.com",
//   termsofuse: "https://content.usbank.com",
//   api: "https://it1-docmanagement.us.bank-dns.com",
//   manageUser:
//     "https://it4-onlinebanking.usbank.com/digital/servicing/ui/customer-profile",
//   myProfile:
//     "https://it4-onlinebanking.usbank.com/digital/servicing/ui/customer-profile/my-profile",
//   authCodeUrl: "https://it-sso.us.bank-dns.com",
//   redirect: "https://it1-docmanagement.us.bank-dns.com",
//   transmit: "https://it1-transmit-obc.us.bank-dns.com:9943",
//   manifestPath:
//     "https://it4-onlinebanking.usbank.com/auth/login/wmf/latest/manifest.json",
//   manifestPathTimeout:
//     "https://it4-onlinebanking.usbank.com/digital/auth/challenge/es/latest/manifest.json",
//   manifestPathLoginHelp:
//     "https://it5-onlinebanking.usbank.com/digital/loginhelp/wmf/latest/manifest.json",
//   // servicingAPI: "https://it-api.usbank.com/customer-management/graphql/v1",
//   servicingAPI: "https://it1-usb-digital-api-common.us.bank-dns.com/digital/servicing/customer-mgmt-cdc/retrievals/graphql",
//   updateCredentialsApi:
//     "https://it-api.usbank.com/authentication/customer-auth/credentials/v1/reset",
//   createCredentialsApi:
//     "https://it-api.usbank.com/authentication/customer-auth/credentials/v1/create",
//   configApiURL:
//     "https://it-api.usbank.com/authentication/customer-auth/app-config/v1/config",
//   configAK: "zV2JDobreS0n3GBzU7fhgbN6toHArF6v",
//   routingKey: "it4",
//   authRoutingKey: "it4",
// };

// Uncomment below code when deploying to Dev
// const defaultEndpoints = {
//   apigee: 'https://dev-api.usbank.com',
//   envUrl: 'http://dev01-docmgmt.us.bank-dns.com',
//   api: 'http://dev01-docmgmt.us.bank-dns.com',
//   manageUser: `http://dev5-digital-servicing-api.us.bank-dns.com/digital/servicing/ui/customer-profile?rand='${Math.round(Math.random() * 10000000)}`,
//   authCodeUrl: 'https://it-sso.us.bank-dns.com',
//   transmit: 'https://it-transmit-obc.us.bank-dns.com:9943',
//   redirect: 'http://dev01-docmgmt.us.bank-dns.com',
//   servicingAPI: 'https://dev-api.usbank.com/customer-management/graphql/v1',
// // servicingAPI: "https://it1-usb-digital-api-common.us.bank-dns.com/digital/servicing/customer-mgmt-cdc/retrievals/graphql",
//   updateCredentialsApi: 'https://it-api.usbank.com/authentication/customer-auth/credentials/v1/update',
//   createCredentialsApi: 'https://it-api.usbank.com/authentication/customer-auth/credentials/v1/create',
//   configApiURL : "https://it-api.usbank.com/authentication/customer-auth/app-config/v1/config",
//   configAK: "zV2JDobreS0n3GBzU7fhgbN6toHArF6v",
//   routingKey: 'dev1',
//   authRoutingKey: 'dev1',
//   myProfile: 'https://it4-onlinebanking.usbank.com/digital/servicing/ui/customer-profile/my-profile'
// }

const isOnEnv = (env) => {
  const domain = typeof window !== "undefined" ? window.location.origin : "";
  return domain.split(".")[0].indexOf(env) !== -1;
};

const isProd = () => {
  const domain = typeof window !== "undefined" ? window.location.host : "";
  return domain.indexOf("docmanagement") === 0;
};

const accessOrigin = () => {
  let origin = "";
  const domain = typeof window !== "undefined" ? window.location.origin : "";
  const dns = domain.substring(
    domain.lastIndexOf(".", domain.lastIndexOf(".") - 1) + 1
  );

  if (dns === "bank-dns.com") {
    origin = "internal";
  } else if (dns === "usbank.com") {
    origin = "external";
  } else {
    origin = "internal";
  }
  return origin;
};

const setEndpointURLs = () => {
  let transmitUrl = "";
  let redirectUrl = "";

  if (isOnEnv("localhost") || isOnEnv("127")) {
    return defaultEndpoints;
  }

  if (isOnEnv("dev01")) {
    return {
      apigee:"https://it1-usb-digital-api-common.us.bank-dns.com",
      envUrl: "https://it1-docmanagement.us.bank-dns.com",
      termsofuse: "https://content.usbank.com",
      api: "https://it1-docmanagement.us.bank-dns.com",
      manageUser:
        "https://dev5-digital-servicing-api.us.bank-dns.com/digital/servicing/ui/customer-profile",
      authCodeUrl: "https://it-sso.us.bank-dns.com",
      transmit: "https://it1-transmit-obc.us.bank-dns.com:9943",
      redirect: "https://dev01-docmgmt.us.bank-dns.com",
      // servicingAPI: "https://dev-api.usbank.com/customer-management/graphql/v1",
      servicingAPI:
      enableCDCServicing==false ? "https://it1-usb-digital-api-common.us.bank-dns.com/customer-management/graphql/v1" :"https://it1-usb-digital-api-common.us.bank-dns.com/digital/servicing/customer-mgmt-cdc/retrievals/graphql",
      manifestPath:
        "https://it2-onlinebanking.usbank.com/auth/login/wmf/latest/manifest.json",
      manifestPathTimeout:
        "https://it3-onlinebanking.usbank.com/digital/auth/challenge/es/latest/manifest.json",
      manifestPathLoginHelp:
        "https://it3-onlinebanking.usbank.com/digital/loginhelp/wmf/latest/manifest.json",
      updateCredentialsApi:
        "https://it-api.usbank.com/authentication/customer-auth/credentials/v1/reset",
      createCredentialsApi:
        "https://it-api.usbank.com/authentication/customer-auth/credentials/v1/create",
      configApiURL:
        "https://it-api.usbank.com/authentication/customer-auth/app-config/v1/config",
      routingKey: "it4",
      routingUpdateKey: "it4",
      authRoutingKey: "it4",
    };
  }

  if (isOnEnv("it1")) {
    if (accessOrigin() === "internal") {
      transmitUrl = "https://it1-transmit-obc.us.bank-dns.com:9943";
      redirectUrl = "https://it1-docmanagement.us.bank-dns.com";
    } else if (accessOrigin() === "external") {
      transmitUrl = "https://it1-transmit-obc.us.bank-dns.com:9943";
      redirectUrl = "https://it1-docmanagement.usbank.com";
    }

    return {
      apigee: "https://it-api.usbank.com",
      envUrl: "https://it1-docmanagement.us.bank-dns.com",
      api: redirectUrl,
      termsofuse: "https://it2-content.usbank.com",
      manageUser:
        "https://it1-onlinebanking.usbank.com/digital/servicing/ui/customer-profile",
      myProfile:
        "https://it1-onlinebanking.usbank.com/digital/servicing/ui/customer-profile/my-profile",
      authCodeUrl: "https://it-sso.us.bank-dns.com",
      redirect: redirectUrl,
      transmit: transmitUrl,
      manifestPath:
        "https://it2-onlinebanking.usbank.com/auth/login/wmf/latest/manifest.json",
      manifestPathTimeout:
        "https://it3-onlinebanking.usbank.com/digital/auth/challenge/es/latest/manifest.json",
      manifestPathLoginHelp:
        "https://it3-onlinebanking.usbank.com/digital/loginhelp/wmf/latest/manifest.json",
      servicingAPI:
      enableCDCServicing==false ? "https://it-api.usbank.com/customer-management/graphql/v1" : "https://it-api.usbank.com/document-management/coreservice/user-management/v1/cdcservice/graphql",
      // servicingAPI: "https://it1-usb-digital-api-common.us.bank-dns.com/digital/servicing/customer-mgmt-cdc/retrievals/graphql",
      updateCredentialsApi:
        "https://it-api.usbank.com/authentication/customer-auth/credentials/v1/reset",
      createCredentialsApi:
        "https://it-api.usbank.com/authentication/customer-auth/credentials/v1/create",
      configApiURL:
        "https://it-api.usbank.com/authentication/customer-auth/app-config/v1/config",
      configAK: "zV2JDobreS0n3GBzU7fhgbN6toHArF6v",
      routingKey: "it1",
      routingUpdateKey: "it1",
      authRoutingKey: "it1",
    };
  }


  if (isOnEnv("it2")) {
    if (accessOrigin() === "internal") {
      transmitUrl = "https://it1-transmit-obc.us.bank-dns.com:9943";
      redirectUrl = "https://it2-docmanagement.us.bank-dns.com";
    } else if (accessOrigin() === "external") {
      transmitUrl = "https://it1-transmit-obc.us.bank-dns.com:9943";
      redirectUrl = "https://it2-docmanagement.usbank.com";
    }

    return {
      apigee: "https://it-api.usbank.com",
      envUrl: "https://it1-docmanagement.us.bank-dns.com",
      api: "https://it1-docmanagement.us.bank-dns.com",
      termsofuse: "https://it2-content.usbank.com",
      manageUser:
        "https://it1-onlinebanking.usbank.com/digital/servicing/ui/customer-profile",
      myProfile:
        "https://it1-onlinebanking.usbank.com/digital/servicing/ui/customer-profile/my-profile",
      authCodeUrl: "https://it-sso.us.bank-dns.com",
      redirect: redirectUrl,
      transmit: transmitUrl,
      manifestPath:
        "https://it2-onlinebanking.usbank.com/auth/login/wmf/latest/manifest.json",
      manifestPathTimeout:
        "https://it3-onlinebanking.usbank.com/digital/auth/challenge/es/latest/manifest.json",
      manifestPathLoginHelp:
        "https://it3-onlinebanking.usbank.com/digital/loginhelp/wmf/latest/manifest.json",
      servicingAPI:
      enableCDCServicing==false ? "https://it-api.usbank.com/customer-management/graphql/v1" : "https://it-api.usbank.com/document-management/coreservice/user-management/v1/cdcservice/graphql",
      // servicingAPI: "https://it1-usb-digital-api-common.us.bank-dns.com/digital/servicing/customer-mgmt-cdc/retrievals/graphql",
      updateCredentialsApi:
        "https://it-api.usbank.com/authentication/customer-auth/credentials/v1/reset",
      createCredentialsApi:
        "https://it-api.usbank.com/authentication/customer-auth/credentials/v1/create",
      configApiURL:
        "https://it-api.usbank.com/authentication/customer-auth/app-config/v1/config",
      configAK: "zV2JDobreS0n3GBzU7fhgbN6toHArF6v",
      routingKey: "it1",
      routingUpdateKey: "it1",
      authRoutingKey: "it1",
    };
  }


  if (isOnEnv("uat1")) {
    if (accessOrigin() === "internal") {
      transmitUrl = "https://uat1-transmit-obc.us.bank-dns.com:9943";
      redirectUrl = "https://uat1-docmanagement.us.bank-dns.com";
    } else if (accessOrigin() === "external") {
      transmitUrl = "https://uat1-transec.usbank.com";
      redirectUrl = "https://uat1-docmanagement.usbank.com";
    }

    return {
      apigee: "https://alpha-api.usbank.com",
      envUrl: "https://uat1-docmanagement.us.bank-dns.com",
      // envUrl: "https://perf1-usb-digital-api-common-cbc.us.bank-dns.com",
      api: redirectUrl,
      termsofuse: "https://uat1-content.usbank.com",
      manageUser:
        "https://uat3-onlinebanking.usbank.com/digital/servicing/ui/customer-profile",
      myProfile:
        "https://uat3-onlinebanking.usbank.com/digital/servicing/ui/customer-profile/my-profile",
      authCodeUrl: "https://uat-sso.us.bank-dns.com",
      redirect: redirectUrl,
      transmit: transmitUrl,
      servicingAPI:
      enableCDCServicing==false ? "https://alpha-api.usbank.com/customer-management/graphql/v1" : "https://alpha-api.usbank.com/document-management/coreservice/user-management/v1/cdcservice/graphql",
      updateCredentialsApi:
        "https://alpha-api.usbank.com/authentication/customer-auth/credentials/v1/reset",
      createCredentialsApi:
        "https://alpha-api.usbank.com/authentication/customer-auth/credentials/v1/create",
      configApiURL:
        "https://alpha-api.usbank.com/authentication/customer-auth/app-config/v1/config",
      configAK: "G6Fl3fdtwBEJXTF8WBFmyLF3xTJO3NiU",
      routingKey: "uat1",
      routingUpdateKey: "uat1",
      authRoutingKey: "uat1",
      manifestPath:
        "https://uat2-onlinebanking.usbank.com/auth/login/wmf/latest/manifest.json",
      manifestPathTimeout:
        "https://uat3-onlinebanking.usbank.com/digital/auth/challenge/es/latest/manifest.json",
      manifestPathLoginHelp:
      "https://uat2-onlinebanking.usbank.com/digital/loginhelp/wmf/latest/manifest.json",
    };
  }

  if (isOnEnv("uat2")) {
    if (accessOrigin() === "internal") {
      transmitUrl = "https://uat1-transmit-obc.us.bank-dns.com:9943";
      redirectUrl = "https://uat2-docmanagement.us.bank-dns.com";
    } else if (accessOrigin() === "external") {
      transmitUrl = "https://uat1-transec.usbank.com";
      redirectUrl = "https://uat2-docmanagement.usbank.com";
    }

    return {
      apigee: "https://alpha-api.usbank.com",
      envUrl: "https://uat1-docmanagement.us.bank-dns.com",
      // envUrl: "https://perf1-usb-digital-api-common-cbc.us.bank-dns.com",
      api: redirectUrl,
      termsofuse: "https://uat1-content.usbank.com",
      manageUser:
        "https://uat3-onlinebanking.usbank.com/digital/servicing/ui/customer-profile",
      myProfile:
        "https://uat3-onlinebanking.usbank.com/digital/servicing/ui/customer-profile/my-profile",
      authCodeUrl: "https://uat-sso.us.bank-dns.com",
      redirect: redirectUrl,
      transmit: transmitUrl,
      servicingAPI:
      enableCDCServicing==false ? "https://alpha-api.usbank.com/customer-management/graphql/v1" : "https://alpha-api.usbank.com/document-management/coreservice/user-management/v1/cdcservice/graphql",
      updateCredentialsApi:
        "https://alpha-api.usbank.com/authentication/customer-auth/credentials/v1/reset",
      createCredentialsApi:
        "https://alpha-api.usbank.com/authentication/customer-auth/credentials/v1/create",
      configApiURL:
        "https://alpha-api.usbank.com/authentication/customer-auth/app-config/v1/config",
      configAK: "G6Fl3fdtwBEJXTF8WBFmyLF3xTJO3NiU",
      routingKey: "uat1",
      routingUpdateKey: "uat1",
      authRoutingKey: "uat1",
      manifestPath:
        "https://uat2-onlinebanking.usbank.com/auth/login/wmf/latest/manifest.json",
      manifestPathTimeout:
        "https://uat3-onlinebanking.usbank.com/digital/auth/challenge/es/latest/manifest.json",
      manifestPathLoginHelp:
      "https://uat2-onlinebanking.usbank.com/digital/loginhelp/wmf/latest/manifest.json",
    };
  }



  if (isProd()) {
    if (accessOrigin() === "internal") {
      transmitUrl = "https://transmit-obc.us.bank-dns.com:9943";
      redirectUrl = "https://docmanagement.us.bank-dns.com";
    } else if (accessOrigin() === "external") {
      transmitUrl = "https://transec.usbank.com";
      redirectUrl = "https://docmanagement.usbank.com";
    }

    return {
      apigee: "https://api.usbank.com",
      envUrl: "https://docmanagement.usbank.com",
      api: redirectUrl,
      termsofuse: "https://content.usbank.com",
      manageUser:
        "https://onlinebanking.usbank.com/digital/servicing/ui/customer-profile",
      myProfile:
        "https://onlinebanking.usbank.com/digital/servicing/ui/customer-profile/my-profile",
      authCodeUrl: "https://sso.us.bank-dns.com",
      redirect: redirectUrl,
      transmit: transmitUrl,
      // servicingAPI: "https://api.usbank.com/customer-management/graphql/v1",
      servicingAPI:
      enableCDCServicing==false ? "https://api.usbank.com/customer-management/graphql/v1" : "https://api.usbank.com/document-management/coreservice/user-management/v1/cdcservice/graphql",

      updateCredentialsApi:
        "https://api.usbank.com/authentication/customer-auth/credentials/v1/reset",
      createCredentialsApi:
        "https://api.usbank.com/authentication/customer-auth/credentials/v1/create",
      configApiURL:
        "https://api.usbank.com/authentication/customer-auth/app-config/v1/config",
      configAK: "gNShJPNcOlv0A4bbQ3EqJbf52ukCM6lV",
      routingKey: "",
      routingUpdateKey: "prod",
      authRoutingKey: "",
      manifestPath:
        "https://onlinebanking.usbank.com/auth/login/wmf/latest/manifest.json",
      manifestPathTimeout:
        "https://onlinebanking.usbank.com/digital/auth/challenge/es/latest/manifest.json",
      manifestPathLoginHelp:
        "https://onlinebanking.usbank.com/digital/loginhelp/wmf/latest/manifest.json",
    };
  }

  return defaultEndpoints;
};

// eslint-disable-next-line import/prefer-default-export
export const endpoints = setEndpointURLs();
