import React, { createContext, useState, useEffect } from 'react';
import moment from 'moment';
import axios from 'axios';
import rateLimit from 'axios-rate-limit';
import { endpoints } from '../../../config/connections';
import callApi from '../../components/utilities/callApi';

/**
 * @typedef {Object} Metadata
 * @property {boolean} showSyndicationFundInd
 * @property {string} reportingEntity
 * @property {string} docType
 * @property {string} parentCompanyName
 * @property {string} asOfDate
 * @property {string} submittedDate
 * @property {string} reportingEntityReport
 * @property {string} asOfDateReport
 * @property {string} submittedDateReport
 * @property {string} dealName
 * @property {string} dealId
 * @property {string} syndicationFundName
 *
 */

/**
 * @typedef {Object} ReportingFilters
 * @property {string} requirementName
 * @property {string} reportingEntity
 * @property {string} parentCompany
 * @property {string} asOfDate
 * @property {string} cbDate
 * @property {string} submittedDate
 * @property {string} requirementNameReport
 * @property {string} reportingEntityReport
 * @property {string} asOfDateReport
 * @property {string} submittedDateReport
 * @property {string} dealName
 * @property {string} dealId
 * @property {string} syndicationFundName
 * @property {string} status
 * @property {string} following
 */

/**
 * @typedef {Object} ReportingData
 * @property {string} asOfDate
 * @property {string} submittedDate
 * @property {string} category
 * @property {boolean} docTemplateFlg
 * @property {string} docType
 * @property {string} dueDate
 * @property {string} id
 * @property {boolean} isDeleted
 * @property {Metadata} metadata
 * @property {string} notificationDate
 * @property {string} requirementName
 * @property {string} status
 * @property {string} asOfDateReport
 * @property {string} submittedDateReport
 * @property {string} requirementNameReport
 * @property {string} following
 */

/**
 * @type React.Context
 */
export const DashboardContext = createContext();

/**
 * Applies any filters found in session storage to the
 *
 * @param {ReportingData[]} reportResults A filtered list of reporting data.
 * If no session filters exist, this filtered list will be used.
 * @param {ReportingData[]} reportResultsFull The list of all reporting data.
 * If session filters exist, this full list will be used.
 * @returns {ReportingData[]} The reporting data filtered using existing session filters.
 */


const addAriaLabel = () => {
  const searchElement = document.getElementsByClassName('p-dropdown-trigger');
  for (let i = 0; i < searchElement.length; i += 1) {
    searchElement[i].setAttribute('aria-label', 'Click to show more');
  }
};

/**
 * Defines the default options for the dropdown filters.
 *
 * @type [{content:string, value:string}]
 */
const filterDropdownDefault = [
  {
    content: 'Select',
    value: '',
  },
];

/**
 * @param {{ children: React.ReactNode; }} props
 */
const DashboardContextProvider = (props) => {
  const [employeeName, setEmployeeName] = useState([]);
  const [employeeNameFull, setEmployeeNameFull] = useState([]);
  // const [employeeNameId, setEmployeeNameId] = useState('')
  const [reportResults, setReportResults] = useState([]);
  const [reportResultsFull, setReportResultsFull] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [totalResults, setTotalResults] = useState(0);
  const [reportingEntity, setReportingEntity] = useState([
    ...filterDropdownDefault,
  ]);
  const [reportingEntityCpy, setReportingEntityCpy] = useState([]);
  const [reportingEntityReport, setReportingEntityReport] = useState([
    ...filterDropdownDefault,
  ]);
  const [reportData, setReportData] = useState([]);
  const [dealName, setDealName] = useState([...filterDropdownDefault]);
  const [dealId, setDealId] = useState([...filterDropdownDefault]);
  const [syndicationFund, setSyndicationFund] = useState([
    ...filterDropdownDefault,
  ]);
  const [dealNameCpy, setDealNameCpy] = useState([]);
  const [dealIdCpy, setDealIdCpy] = useState([]);
  const [syndicationFundCpy, setSyndicationFundCpy] = useState([]);
  const [requirementName, setRequirementName] = useState([
    ...filterDropdownDefault,
  ]);
  const [requirementNameReport, setRequirementNameReport] = useState([
    ...filterDropdownDefault,
  ]);
  const [statusDrop, setStatusDrop] = useState([...filterDropdownDefault]);
  const [followCheck, setFollowcheck] = useState([...filterDropdownDefault]);
  const [parentCompany, setParentCompany] = useState([
    ...filterDropdownDefault,
  ]);
  const [parentCompanyCpy, setParentCompanyCpy] = useState([]);
  const [asOfDate, setAsOfDate] = useState([...filterDropdownDefault]);
  const [submittedDate, setSubmittedDate] = useState([
    ...filterDropdownDefault,
  ]);

  const [asOfDateReport, setAsOfDateReport] = useState([
    ...filterDropdownDefault,
  ]);
  const [submittedDateReport, setSubmittedDateReport] = useState([
    ...filterDropdownDefault,
  ]);

  const [loading, setLoading] = useState(false);
  // const [statusValue, setStatusValue] = useState('')
  const [searchData, setSearchData] = useState([]);
  const [sortBy, setSortBy] = useState([]);
  const [sortByValue, setSortByValue] = useState('dateAsc');
  const [searchValue, setSearchValue] = useState('');
  const [dashboardData, setDashboardData] = useState({
    search: '',
    reportingEntityValue: '',
    parentCompanyValue: '',
    asOfDateValue: '',
    cbDateValue:'',
    submittedDateValue: '',
    dealNameValue: '',
    dealIdValue:'',
    requirementNameValue: '',

    reportingEntityValueReport: '',
    asOfDateValueReport: '',
    submittedDateValueReport: '',
    requirementNameValueReport: '',

    syndicationfundValue: '',
    statusValue: '',
    followingValue: '',
  });
  const [activeFilters, setActiveFilters] = useState({
    requirementName: '',
    reportingEntity: '',
    parentCompany: '',
    asOfDate: '',
    cbDate: '',
    submittedDate: '',

    requirementNameReport: '',
    reportingEntityReport: '',
    asOfDateReport: '',
    submittedDateReport: '',

    dealName: '',
    dealId:'',
    status: '',
    syndicationFundName: '',
    following: '',
  });
  const [showSyndication, setShowSyndication] = useState(false);

  /**
   * Initializes the data filters by user role and status.
   *
   * @param {ReportingData[]} resultResponse The list of all reporting data.
   * @returns {ReportingData[]} A filtered list of reporting data.
   */
  const initialFilter = (resultResponse) => {
    const results = [];
    // const today = new Date()
    // const prev = new Date().setDate(today.getDate() - 180)
    // const next = new Date().setDate(today.getDate() + 90)
    // const prevDate = Date.parse(moment(new Date(prev)).format('MM/DD/YYYY'))
    // const nextDate = Date.parse(moment(new Date(next)).format('MM/DD/YYYY'))
    resultResponse.forEach((value) => {
      // const chkDate = Date.parse(value.dueDate)
      if (
        sessionStorage.getItem('userrole') === 'portaladmin' ||
        sessionStorage.getItem('userrole') === 'customer' || 
        JSON.parse(sessionStorage.getItem('remote_user'))
      ) {
        if (
          // chkDate <= nextDate &&
          // chkDate >= prevDate &&
          value.status === 'Not Submitted'
        ) {
          results.push(value);
        }
      } else if (
        sessionStorage.getItem('userrole') === 'employee' &&
        value.status === 'Submitted'
      ) {
        results.push(value);
      }
    });

    return results;
  };

  /*
  const initialApi = (userId, completedStatus) => {
    let apiURL
    if (sessionStorage.getItem('userrole') === 'employee') {
      if(JSON.parse(sessionStorage.getItem('sessionFilters')) && JSON.parse(sessionStorage.getItem('sessionFilters')).status.toLowerCase() === 'completed') {
        apiURL = `${endpoints.apigee}/document-management/cdc/requirements/v1/employee/${userId}/documents?completedDocs=${true}`
      } else {
        apiURL = `${endpoints.apigee}/document-management/cdc/requirements/v1/employee/${userId}/documents?completedDocs=${completedStatus}`
      }
      // apiURL = `${endpoints.apigee}/document-management/cdc/requirements/v1/employee/${userId}/documents`
    } else if(JSON.parse(sessionStorage.getItem('sessionFilters')) && JSON.parse(sessionStorage.getItem('sessionFilters')).status.toLowerCase() === 'completed') {
      // apiURL = `${endpoints.apigee}/document-management/cdc/landing-page/v1/users/${userId}/requirement-details?completedDocs=${true}`
      apiURL = getAPIResponse(userId)
    } else {
      // apiURL = `${endpoints.apigee}/document-management/cdc/landing-page/v1/users/${userId}/requirement-details?completedDocs=${completedStatus}`
      apiURL = getAPIResponse(userId)
    }
    return callApi.getApiData(apiURL)
  }
*/

  /**
   * Populates the drop down filters and search criteria.
   *
   * @param {ReportingData[]} results The list of all reporting data.
   */
  const loadFilterS = (results, indicator) => {
    // console.log(indicator,"indi");
    let j = 0;
    // , results, completedStatus, initialCall

    const reportingEntityData = [...filterDropdownDefault];
    const parentCompanyData = [...filterDropdownDefault];
    const asOfDateData = [...filterDropdownDefault];
    const submittedDateData = [...filterDropdownDefault];
    const dealNameData = [...filterDropdownDefault];
    const dealIdData = [...filterDropdownDefault]
    const requirementNameData = [...filterDropdownDefault];

    const syndicationFundData = [...filterDropdownDefault];
    /* let currentResults
    if(initialCall) {
      currentResults = resultsFull
    } else {
      currentResults = results
    } */

    results.forEach((value) => {
      // console.log(value,"check");
      if (value.metadata.showSyndicationFundInd === true) {
        j += 1;
      }
      if(indicator=="CB" || indicator?.isCB){
        // console.log(indicator);
        if (
          reportingEntityData.filter(
            (e) => e.value === value.docType
          ).length === 0 &&
          value.docType !== ''
        ) {
          // console.log(value.metadata.docType,"check");
          reportingEntityData.push({
            content: value.docType,
            value: value.docType,
          });
        }
      } else {
        if (
          reportingEntityData.filter(
            (e) => e.value === value.metadata.reportingEntity
          ).length === 0 &&
          value.metadata.reportingEntity !== ''
        ) {
          reportingEntityData.push({
            content: value.metadata.reportingEntity,
            value: value.metadata.reportingEntity,
          });
        }  
      }
      if (
        parentCompanyData.filter(
          (e) => e.value === value.metadata.parentCompanyName
        ).length === 0 &&
        value.metadata.parentCompanyName !== ''
      ) {
        parentCompanyData.push({
          content: value.metadata.parentCompanyName,
          value: value.metadata.parentCompanyName,
        });
      }
     
      if (
        asOfDateData.filter((e) => e.value === value.asOfDate).length === 0 &&
        value.asOfDate !== ''
      ) {
        asOfDateData.push({
          content: value.asOfDate,
          value: value.asOfDate,
        });
      }
      if (
        submittedDateData.filter((e) => e.value === value.submittedDate)
          .length === 0 &&
        value.submittedDate !== ''
      ) {
        submittedDateData.push({
          content: value.submittedDate,
          value: value.submittedDate,
        });
      }

      if (
        dealNameData.filter((e) => e.value === value.metadata.dealName)
          .length === 0 &&
        value.metadata.dealName !== '' &&
        value.metadata.dealName !== null
      ) {
        dealNameData.push({
          content: value.metadata.dealName,
          value: value.metadata.dealName,
        });
      }
      if (
        dealIdData.filter((e) => e.value === value.metadata.dealId)
          .length === 0 &&
        value.metadata.dealId !== '' &&
        value.metadata.dealId !== null
      ) {
        dealIdData.push({
          content: value.metadata.dealId,
          value: value.metadata.dealId,
        });
      }
      if (
        requirementNameData.filter((e) => e.value === value.category).length ===
        0 &&
        value.category !== '' && (indicator!='requirementName' || indicator.column!='requirementName')
      ) {
        requirementNameData.push({
          content: value.category,
          value: value.category,
        });
      }

      // if (
      //   statusData.filter(e => e.value === value.status).length === 0 &&
      //   value.status !== ''
      // ) {
      //   statusData.push({
      //     label: value.status,
      //     value: value.status
      //   })
      // }
      if (
        syndicationFundData.filter(
          (e) => e.value === value.metadata.syndicationFundName
        ).length === 0 &&
        value.metadata.syndicationFundName !== '' &&
        value.metadata.syndicationFundName !== null &&
        sessionStorage.getItem('userrole') === 'employee'
        // value.metadata.showSyndicationFundInd === true
      ) {
        syndicationFundData.push({
          content: value.metadata.syndicationFundName,
          value: value.metadata.syndicationFundName,
        });
      }
      // syndication fund view change for customer and vendor starts
      if (
        syndicationFundData.filter(
          (e) => e.value === value.metadata.syndicationFundName
        ).length === 0 &&
        value.metadata.syndicationFundName !== '' &&
        value.metadata.syndicationFundName !== null &&
        (value.metadata.dealName === '' || value.metadata.dealName === null) &&
        sessionStorage.getItem('userrole') !== 'employee' &&
        value.metadata.showSyndicationFundInd === true
      ) {
        syndicationFundData.push({
          content: value.metadata.syndicationFundName,
          value: value.metadata.syndicationFundName,
        });
      }
      // syndication fund view change for customer and vendor ends
    });
    console.log(j, 'syndication fundind total');
    if (j === 0) {
      setShowSyndication(false);
    } else {
      setShowSyndication(true);
    }
    reportingEntityData.sort((a, b) => {
      if (a.value < b.value) {
        return -1;
      }
      if (a.value > b.value) {
        return 1;
      }
      return 0;
    });

    parentCompanyData.sort((a, b) => {
      if (a.value < b.value) {
        return -1;
      }
      if (a.value > b.value) {
        return 1;
      }
      return 0;
    });
    asOfDateData.sort((a, b) => {
      if (a.value < b.value) {
        return -1;
      }
      if (a.value > b.value) {
        return 1;
      }
      return 0;
    });
    submittedDateData.sort((a, b) => {
      if (a.value < b.value) {
        return -1;
      }
      if (a.value > b.value) {
        return 1;
      }
      return 0;
    });

    dealNameData.sort((a, b) => {
      if (a.value < b.value) {
        return -1;
      }
      if (a.value > b.value) {
        return 1;
      }
      return 0;
    });
    dealIdData.sort((a, b) => {
      if (a.value < b.value) {
        return -1;
      }
      if (a.value > b.value) {
        return 1;
      }
      return 0;
    });

    // const statusDataTemp = []
    // statusData = statusData.filter(obj => {
    //   if (obj.value.toLowerCase() === 'not submitted') {
    //     statusDataTemp.push(obj)
    //   }
    //   return obj.value.toLowerCase() !== 'not submitted'
    // })
    // statusData = statusData.filter(submittedObj => {
    //   if (submittedObj.value.toLowerCase() === 'submitted') {
    //     statusDataTemp.push(submittedObj)
    //   }
    //   return submittedObj.value.toLowerCase() !== 'submitted'
    // })
    // statusData = statusData.filter(underReviewObj => {
    //   if (underReviewObj.value.toLowerCase() === 'under review') {
    //     statusDataTemp.push(underReviewObj)
    //   }
    //   return underReviewObj.value.toLowerCase() !== 'under review'
    // })
    // statusData = statusData.filter(needsActionObj => {
    //   if (needsActionObj.value.toLowerCase() === 'needs action') {
    //     statusDataTemp.push(needsActionObj)
    //   }
    //   return needsActionObj.value.toLowerCase() !== 'needs action'
    // })
    // statusData = statusData.filter(completedObj => {
    //   if (completedObj.value.toLowerCase() === 'completed') {
    //     statusDataTemp.push(completedObj)
    //   }
    //   return completedObj.value.toLowerCase() !== 'completed'
    // })
    // const statusDataFirst = [{ label: 'Status', value: '' }]
    // statusData = [...statusDataFirst, ...statusDataTemp, ...statusData]
    const statusData = [
      { content: 'Select', value: 'Status' },
      { content: 'Not Submitted', value: 'Not Submitted' },
      { content: 'Submitted', value: 'Submitted' },
      { content: 'Under Review', value: 'Under Review' },
      { content: 'Needs Action', value: 'Needs Action' },
      { content: 'Completed', value: 'Completed' },
    ];
    const followData = [
      { content: 'Select', value: 'Follow' },
      { content: 'Yes', value: 'true' },
      { content: 'No', value: 'false' },
    ];

    syndicationFundData.sort((a, b) => {
      if (a.value < b.value) {
        return -1;
      }
      if (a.value > b.value) {
        return 1;
      }
      return 0;
    });
    // console.log(reportingEntityData);
    setReportingEntity(reportingEntityData);
    setParentCompany(parentCompanyData);
    setAsOfDate(asOfDateData);
    setSubmittedDate(submittedDateData);
    setDealName(dealNameData);
    setDealId(dealIdData)

    // CHANGE
    // Document type list gets refreshed - length = 2, upon selection. So restricted setting the document 
    // type list again. The length will be greater than 2 only for the first time so the list will be as it is 
    // even after user selecting a particular doc type
    if (indicator!='requirementName') {
      setRequirementName((prev) => {
        if(requirementNameData.filter((docType)=>{
          docType.value === activeFilters.requirementName
        }).length==0 && activeFilters.requirementName !==""){
          let docTypeInSessionFilter = {content : activeFilters.requirementName, value : activeFilters.requirementName}
          let temp =  [docTypeInSessionFilter, ...requirementNameData]
          return temp.filter((docType1, i, arr)=>{
            return arr.findIndex(docType2 => docType1.value == docType2.value) == i;
          }).sort((a, b) => {
            if (a.value < b.value) {
              return -1;
            }
            if (a.value > b.value) {
              return 1;
            }
            return 0;
          });
        } else {
          return requirementNameData.sort((a, b) => {
            if (a.value < b.value) {
              return -1;
            }
            if (a.value > b.value) {
              return 1;
            }
            return 0;
          });
        }
        });
    }
    setStatusDrop(statusData);
    setFollowcheck(followData);
    setSyndicationFund(syndicationFundData);
    // console.log(indicator, reportingEntityData);
    const index = [
      'parentCompany',
      'reportingEntity',
      'dealName',
      'dealId',
      'fundName',
      'requirementName',
      'asOfDate',
      'cbDate',
      'submittedDate','followCheck','syndicationFundName'
    ].indexOf(indicator);
    switch (index) {
      case -1: {
        setParentCompanyCpy(parentCompanyData);
        setReportingEntityCpy(reportingEntityData);
        setDealNameCpy(dealNameData);
        setDealIdCpy(dealIdData)
        setSyndicationFundCpy(syndicationFundData);
        break;
      }
      case 0:{
        setDealNameCpy(dealNameData);
        setDealIdCpy(dealIdData)
        if(syndicationFundData.length>2){
          setSyndicationFundCpy(syndicationFundData);
        }
        break;
      }
      case 5:  case 6 :case 7 : case 8: case 9:{
        setDealNameCpy(dealNameData);
        setDealIdCpy(dealIdData)
       
        if(syndicationFundData.length>2){
          setSyndicationFundCpy(syndicationFundData);
        }
        break;
      }
      case 5:  case 6 :case 7 : case 8: case 9: case 10:{
        // if(reportingEntityData.length>2){
        //   setReportingEntityCpy(reportingEntityData);
        // }
        setDealNameCpy(dealNameData);
        setDealIdCpy(dealIdData)
       
        if(syndicationFundData.length>2){
          setSyndicationFundCpy(syndicationFundData);
        }
        break;
      }
      //  case 6 :case 7 : case 8: {
      //   setSyndicationFundCpy(syndicationFundData);
      //   break;
      // }
      case 1: {
        if(reportingEntityData.length>2){
          setReportingEntityCpy(reportingEntityData);
        }
        setDealNameCpy(dealNameData);
        setDealIdCpy(dealIdData)
        setSyndicationFundCpy(syndicationFundData);
        break;
      }
      case 2: {
        setSyndicationFundCpy(syndicationFundData);
        break;
      }
      case 3: {
        setSyndicationFundCpy(syndicationFundData);
        break;
      }
    }

    // console.log(reportingEntityData)

    // Search and Sort begins
    const tempSearchData = [{ label: 'Search by', value: '' }];
    if (
      sessionStorage.getItem('userrole') === 'employee' ||
      sessionStorage.getItem('servicingrole') === 'vendorsiteadmin' ||
      sessionStorage.getItem('servicingrole') === 'vendorcontributor'
    ) {
      tempSearchData.push({ label: 'Parent company', value: 'parentCompany' });
    }
    if (
      sessionStorage.getItem('userrole') === 'employee' ||
      sessionStorage.getItem('servicingrole') === 'vendorsiteadmin' ||
      sessionStorage.getItem('servicingrole') === 'vendorcontributor'
    ) {
      tempSearchData.push({ label: 'As of Date', value: 'asOfDate' });
    }
    if (
      sessionStorage.getItem('userrole') === 'employee' ||
      sessionStorage.getItem('servicingrole') === 'vendorsiteadmin' ||
      sessionStorage.getItem('servicingrole') === 'vendorcontributor'
    ) {
      tempSearchData.push({ label: 'Closing Binder Date', value: 'cbDate' });
    }
    if (
      sessionStorage.getItem('userrole') === 'employee' ||
      sessionStorage.getItem('servicingrole') === 'vendorsiteadmin' ||
      sessionStorage.getItem('servicingrole') === 'vendorcontributor'
    ) {
      tempSearchData.push({ label: 'Received date', value: 'submittedDate' });
    }
    tempSearchData.push(
      { label: 'Legal entity', value: 'legalEntity' },
      { label: 'Deal name', value: 'dealName' },
      { label: 'Deal number', value: 'dealNumber' }
    );
    if (syndicationFundData.length > 1) {
      tempSearchData.push({ label: 'Fund name', value: 'syndicationFundName' });
    }
    tempSearchData.push(
      { label: 'Document type', value: 'documentType' },
      { label: 'Status', value: 'status' }
    );
    let sortByTemp = [
      { label: 'Legal entity A-Z', value: 'legalDesc' },
      { label: 'Legal entity Z-A', value: 'legalAsc' },
      { label: 'Document type A-Z', value: 'docDesc' },
      { label: 'Document type Z-A', value: 'docAsc' },
      { label: 'Recent submissions', value: 'submittedDesc' },
      { label: 'Older submissions', value: 'submittedAsc' },
    ];
    if (dealNameData.length > 1) {
      sortByTemp.push(
        { label: 'Deal name A-Z', value: 'dealDesc' },
        { label: 'Deal name Z-A', value: 'dealAsc' }
      );
    }
    if (syndicationFundData.length > 1) {
      sortByTemp.push(
        { label: 'Fund name A-Z', value: 'fundAsc' },
        { label: 'Fund name Z-A', value: 'fundDesc' }
      );
    }
    sortByTemp.sort((a, b) => {
      if (a.label < b.label) {
        return -1;
      }
      if (a.label > b.label) {
        return 1;
      }
      return 0;
    });
    const sortByTempFirst = [
      { label: 'Due soon', value: 'dateAsc' },
      { label: 'Due later', value: 'dateDesc' },
    ];
    sortByTemp = [...sortByTempFirst, ...sortByTemp];
    setSearchData(tempSearchData);
    setSortBy(sortByTemp);
  };

  /**
   * Empties the filters.
   *
   * @param {ReportingFilters} activeFiltersTemp The active filters
   */
  const filterBySessionVariables = (reportResults, reportResultsFull) => {
    if (JSON.parse(sessionStorage.getItem('sessionFilters')) === null) {
      return reportResults;
    }
    let initialData = reportResultsFull;
    const sessionFilters = JSON.parse(sessionStorage.getItem('sessionFilters'));
    let tempFilters = sessionFilters
    let filterValues = []
    let resetFilters = []
    let doNotReset = []
    // Object.entries(sessionFilters).forEach(([key,value])=>{
    //   if(value!=='' && key!="status"){
    //     filterValues.push(value)
    //   }
    // })
    // // console.log(filterValues,'filteress')
    // if(filterValues.length>0){
    //   reportResultsFull.forEach((obj)=>{
    //     Object.entries(sessionFilters).forEach(([key,item])=>{
    //       if(
    //         (item+'').toLowerCase() == (obj.metadata.reportingEntity + '').toLowerCase() ||
    //         (item+'').toLowerCase() == (obj.metadata.reportingEntityReport + '').toLowerCase() ||
    //         (item+'').toLowerCase() == (obj.metadata.parentCompanyName + '').toLowerCase() ||
    //         (item+'').toLowerCase() == (obj?.asOfDate + '').toLowerCase() ||
    //         item == moment(obj?.submittedDate).format('MM/DD/YYYY') ||
    //         (item+'').toLowerCase() == (obj.category + '').toLowerCase() ||
    //         (item+'').toLowerCase() == (obj?.asOfDateReport + '').toLowerCase() ||
    //         item == moment(obj?.submittedDateReport + '').format('MM/DD/YYYY') ||
    //         (item+'').toLowerCase() == (obj.metadata.dealName + '').toLowerCase() ||
    //         (item+'').toLowerCase() == (obj.metadata.dealId + '').toLowerCase() ||
    //         (item+'').toLowerCase() == (obj.metadata.syndicationFundName + '').toLowerCase() ||
    //         ((item+'').toLowerCase() == (obj.status + '').toLowerCase() && item !='Status')
    //       ){
    //         if(!doNotReset.includes(key) && item!=='')
    //         doNotReset.push(key)
    //       } else {
    //         if(!resetFilters.includes(key) && item!=='')
    //         resetFilters.push(key)
    //       }
    //     })
    //   })
    //   // console.log(resetFilters)
      
    //   if(resetFilters.length>0){
        
    //     resetFilters.forEach((key)=>{
    //         if(doNotReset.length>0 && !doNotReset.includes(key) && key!='status'){
    //           tempFilters[key] = ''
    //         }
            
    //     })
    //     sessionStorage.setItem('sessionFilters', JSON.stringify(tempFilters))
    //     setActiveFilters(tempFilters)
    //   }
    // }
    
    if (tempFilters.reportingEntity !== '') {
      initialData = initialData.filter((obj) => {
        let objReportingEntity = '' + obj.metadata.reportingEntity;
        let sessionReporting = '' + tempFilters.reportingEntity;
        return (
          objReportingEntity.toLowerCase() === sessionReporting.toLowerCase()
        );
      });
      if(initialData.length<=0){
        initialData = reportResultsFull
      }
    }
  
    if (tempFilters.reportingEntityReport !== '') {
      initialData = initialData.filter(
        (obj) =>
          (obj.metadata.reportingEntityReport + '').toLowerCase() ===
          (tempFilters.reportingEntityReport + '').toLowerCase()
      );
      if(initialData.length<=0){
        initialData = reportResultsFull
      }
    }
  
    if (tempFilters.parentCompany !== '') {
      initialData = initialData.filter(
        (obj) =>
          (obj.metadata.parentCompanyName + '').toLowerCase() ===
          (tempFilters.parentCompany + '').toLowerCase()
      );
      if(initialData.length<=0){
        initialData = reportResultsFull
      }
    }
    if (tempFilters.asOfDate !== '') {
      initialData = initialData.filter(
        (obj) => obj.asOfDate == tempFilters?.asOfDate
      );
      if(initialData.length<=0){
        initialData = reportResultsFull
      }
    }
    if (tempFilters.submittedDate !== '') {
      initialData = initialData.filter(
        (obj) =>
          moment(obj.submittedDate).format('MM/DD/YYYY') ===
          tempFilters?.submittedDate
      );
      if(initialData.length<=0){
        initialData = reportResultsFull
      }
    }
    if (tempFilters.requirementName !== '') {
      initialData = initialData.filter(
        (obj) =>
          (obj.category + '').toLowerCase() ===
          (tempFilters.requirementName + '').toLowerCase()
      );
      if(initialData.length<=0){
        initialData = reportResultsFull
      }
      
    }
  
    if (tempFilters.asOfDateReport !== '') {
      initialData = initialData.filter(
        (obj) => obj.asOfDateReport == tempFilters?.asOfDateReport
      );
      if(initialData.length<=0){
        initialData = reportResultsFull
      }
    }
    if (tempFilters.submittedDateReport !== '') {
      initialData = initialData.filter(
        (obj) =>
          moment(obj.submittedDateReport).format('MM/DD/YYYY') ===
          tempFilters?.submittedDateReport
      );
      if(initialData.length<=0){
        initialData = reportResultsFull
      }
    }
    if (tempFilters.requirementNameReport !== '') {
      initialData = initialData.filter(
        (obj) =>
          (obj.category + '').toLowerCase() ===
          (tempFilters.requirementNameReport + '').toLowerCase()
      );
      if(initialData.length<=0){
        initialData = reportResultsFull
      }
    }
    if (tempFilters.dealName !== '') {
      initialData = initialData.filter(
        (obj) =>
          (obj.metadata.dealName + '').toLowerCase() ===
          (tempFilters.dealName + '').toLowerCase()
      );
      if(initialData.length<=0){
        initialData = reportResultsFull
      }
    }
    if (tempFilters.dealId !== '') {
      initialData = initialData.filter(
        (obj) =>
          (obj.metadata.dealId + '').toLowerCase() ===
          (tempFilters.dealId + '').toLowerCase()
      );
      if(initialData.length<=0){
        initialData = reportResultsFull
      }
    }
    if (tempFilters.status !== '' && tempFilters.status !== 'Status') {
      initialData = initialData.filter(
        (obj) =>
          (obj.status + '').toLowerCase() ===
          (tempFilters.status + '').toLowerCase()
      );
      if(initialData.length<=0){
        initialData = reportResultsFull
      }
    }
    if (tempFilters.syndicationFundName !== '') {
      initialData = initialData.filter(
        (obj) =>
          (obj.metadata.syndicationFundName + '').toLowerCase() ===
          (tempFilters.syndicationFundName + '').toLowerCase()
      );
      if(initialData.length<=0){
        initialData = reportResultsFull
      }
    }
    return initialData
  };
  const emptyFilters = (activeFiltersTemp) => {
    const reportingEntityData = [...filterDropdownDefault];
    const parentCompanyData = [...filterDropdownDefault];
    const asOfDateData = [...filterDropdownDefault];
    const submittedDateData = [...filterDropdownDefault];
    const dealNameData = [...filterDropdownDefault];
    const dealIdData = [...filterDropdownDefault];
    const requirementNameData = [...filterDropdownDefault];

    const reportingEntityDataReport = [...filterDropdownDefault];
    const asOfDateDataReport = [...filterDropdownDefault];
    const submittedDateDataReport = [...filterDropdownDefault];
    const requirementNameDataReport = [...filterDropdownDefault];

    const statusData = [...filterDropdownDefault];
    const followData = [...filterDropdownDefault];
    const syndicationFundData = [...filterDropdownDefault];

    if (activeFiltersTemp.reportingEntity !== '') {
      reportingEntityData.push({
        content: activeFiltersTemp.reportingEntity,
        value: activeFiltersTemp.reportingEntity,
      });
    }

    if (activeFiltersTemp.reportingEntityReport !== '') {
      reportingEntityDataReport.push({
        content: activeFiltersTemp.reportingEntityReport,
        value: activeFiltersTemp.reportingEntityReport,
      });
    }

    if (activeFiltersTemp.parentCompany !== '') {
      parentCompanyData.push({
        content: activeFiltersTemp.parentCompany,
        value: activeFiltersTemp.parentCompany,
      });
    }
    if (activeFiltersTemp.asOfDate !== '') {
      asOfDateData.push({
        content: activeFiltersTemp.asOfDate,
        value: activeFiltersTemp.asOfDate,
      });
    }
    if (activeFiltersTemp.submittedDate !== '') {
      submittedDateData.push({
        content: activeFiltersTemp.submittedDate,
        value: activeFiltersTemp.submittedDate,
      });
    }

    if (activeFiltersTemp.asOfDateReport !== '') {
      asOfDateDataReport.push({
        content: activeFiltersTemp.asOfDateReport,
        value: activeFiltersTemp.asOfDateReport,
      });
    }
    if (activeFiltersTemp.submittedDateReport !== '') {
      submittedDateDataReport.push({
        content: activeFiltersTemp.submittedDateReport,
        value: activeFiltersTemp.submittedDateReport,
      });
    }
    if (activeFiltersTemp.dealName !== '') {
      dealNameData.push({
        content: activeFiltersTemp.dealName,
        value: activeFiltersTemp.dealName,
      });
    }
    if (activeFiltersTemp.dealId !== '') {
      dealIdData.push({
        content: activeFiltersTemp.dealId,
        value: activeFiltersTemp.dealId,
      });
    }
    if (activeFiltersTemp.requirementName !== '') {
      requirementNameData.push({
        content: activeFiltersTemp.requirementName,
        value: activeFiltersTemp.requirementName,
      });
    }
    if (activeFiltersTemp.requirementNameReport !== '') {
      requirementNameDataReport.push({
        content: activeFiltersTemp.requirementNameReport,
        value: activeFiltersTemp.requirementNameReport,
      });
    }
    if (activeFiltersTemp.status !== '') {
      statusData.push({
        content: activeFiltersTemp.status,
        value: activeFiltersTemp.status,
      });
    }
    if (activeFiltersTemp.syndicationFundName !== '') {
      syndicationFundData.push({
        content: activeFiltersTemp.syndicationFundName,
        value: activeFiltersTemp.syndicationFundName,
      });
    }
    setStatusDrop(statusData);
    //  CHANGE
    // Disabled setting the options array for follow so all the values : Select,yes,no
    // are still there on selection of single value

    // setFollowcheck(followData);


    setDealName(dealNameData);
    setParentCompany(parentCompanyData);
    setAsOfDate(asOfDateData);
    setSubmittedDate(submittedDateData);
    setReportingEntity(reportingEntityData);
    setRequirementName(requirementNameData);

    setAsOfDateReport(asOfDateDataReport);
    setSubmittedDateReport(submittedDateDataReport);
    setReportingEntityReport(reportingEntityDataReport);
    setRequirementNameReport(requirementNameDataReport);

    setSyndicationFund(syndicationFundData);
  };

  /**
   * Loads the data from the the API response.
   * 
   *
   * @param {ReportingData[]} responseArray The list of all reporting data.
   */
  const loadData = (responseArray,indicator) => {
    // , completedStatus, initialCall
    // setLoading(true)

    // let apiURL
    // if (sessionStorage.getItem('userrole') === 'employee') {
    //   if(JSON.parse(sessionStorage.getItem('sessionFilters')) && JSON.parse(sessionStorage.getItem('sessionFilters')).status.toLowerCase() === 'completed') {
    //     apiURL = `${endpoints.apigee}/document-management/cdc/requirements/v1/employee/${userId}/documents?completedDocs=${true}`
    //   } else {
    //     apiURL = `${endpoints.apigee}/document-management/cdc/requirements/v1/employee/${userId}/documents?completedDocs=${completedStatus}`
    //   }
    //   // apiURL = `${endpoints.apigee}/document-management/cdc/requirements/v1/employee/${userId}/documents`
    // } else if(JSON.parse(sessionStorage.getItem('sessionFilters')) && JSON.parse(sessionStorage.getItem('sessionFilters')).status.toLowerCase() === 'completed') {
    //   apiURL = `${endpoints.apigee}/document-management/cdc/landing-page/v1/users/${userId}/requirement-details?completedDocs=${true}`
    //   // apiURL = `${endpoints.apigee}/document-management/cdc/requirements/v1/employee/${userId}/documents?completedDocs=${true}`
    // } else {
    //   apiURL = `${endpoints.apigee}/document-management/cdc/landing-page/v1/users/${userId}/requirement-details?completedDocs=${completedStatus}`
    //   // apiURL = `${endpoints.apigee}/document-management/cdc/requirements/v1/employee/${userId}/documents?completedDocs=${completedStatus}`
    // }

    // initialApi( userId, completedStatus )
    // getAPIResponse(userId)

    // .then(resultResponse => {
    // const unfilteredResults = getAPIResponse(userId)
    // console.log(unfilteredResults)

    const results = initialFilter(responseArray);
    loadFilterS(responseArray,indicator);
    const filteredResults = filterBySessionVariables(results, responseArray);
    // console.log(filteredResults,"filteress")
    filteredResults.sort((a, b) => {
      const key1 = new Date(a.dueDate);
      const key2 = new Date(b.dueDate);
      if (key1 < key2) {
        return -1;
      }
      if (key1 === key2) {
        return 0;
      }
      return 1;
    });

    // SORT on load ends
    setTotalPages(Math.ceil(filteredResults.length / 10));
    setTotalResults(filteredResults.length);
    setReportResults(filteredResults);
    setReportResultsFull(responseArray);
    addAriaLabel();
    setLoading(false)
    // })
    // .catch(() => {
    //   // console.log('error', error)
    //   setTotalPages(1)
    //   setTotalResults(0)
    //   setReportResults([])
    //   setReportResultsFull([])
    //   setLoading(false)
    // })
  };

  /**
   * Gets the reporting data from the API.
   *
   * @param {string} userId The ID of the user associated to the reporting data.
   * @param {string} statusArg The status value of the reporting data to get.
   */
  const sequntialCall = async (url) => {
    const https = rateLimit(
      axios.create({
        headers: {
          Accept: 'application/json',
          'Correlation-ID': `${sessionStorage.getItem(
            'loggedUser'
          )} ${sessionStorage.getItem('correlationId')}`,
          'Client-Application': 'CDC',
          'jwt': `${sessionStorage.getItem('tok')}`,
          'user-id': `${sessionStorage.getItem('assignedUser')}`,
          Authorization: `Bearer ${sessionStorage.getItem('at')}`,
        },
      }),
      { maxRequests: 7, perMilliseconds: 1000, maxRPS: 7 }
    );
    const resp = await https.get(url)
    return resp.data.docs;
  }
  const dataIterator = async (arryCount, defaulturl, allData) => {
    let finalData = [];
    for (let i = 1; i <= arryCount; i++) {
      const apiResponse = await sequntialCall(defaulturl + `${i}`)
      finalData = [...allData, ...apiResponse];


    }
    return finalData;
  }
  const getAPIResponse = async (userId, statusArg, remote) => {
    setLoading(true);
    let urlPathVal1 = '';
    let urlPathVal2 = '';
    let urlPathVal3 = '';
    let statusParam = '';

    if (statusArg !== '') {
      statusParam = statusArg;
    } else if (
      JSON.parse(sessionStorage.getItem('sessionFilters')) &&
      JSON.parse(sessionStorage.getItem('sessionFilters')).status !== ''
    ) {
      statusParam = JSON.parse(sessionStorage.getItem('sessionFilters')).status == "Status" ? '' : JSON.parse(sessionStorage.getItem('sessionFilters')).status;
    } else if (sessionStorage.getItem('userrole') === 'employee') {
      statusParam = 'Submitted';
    } else {
      statusParam = 'Not Submitted';
    }
    if (sessionStorage.getItem('userrole') === 'employee' && !remote) {
      urlPathVal1 = 'requirements';
      urlPathVal2 = 'employee';
      urlPathVal3 = 'documents';
    } else {
      if (sessionStorage.getItem('view_as')) {
        urlPathVal1 = 'requirements';
        urlPathVal2 = 'employee';
        urlPathVal3 = 'documents';
      } else {
        urlPathVal1 = 'landing-page';
        urlPathVal2 = 'users';
        urlPathVal3 = 'requirement-details';
      }
    }

    const callTypeBefore = 'before';
    const callTypeFuture = 'future';


    const apiURLFirst = `${endpoints.apigee}/document-management/cdc/${urlPathVal1}/v1/${urlPathVal2}/userId/${urlPathVal3}?callType=${callTypeBefore}&startDay=-9999&endDay=9999&status=${statusParam}&recordsCount=`;

    let allData = [];
    const https = rateLimit(
      axios.create({
        headers: {
          Accept: 'application/json',
          'Correlation-ID': `${sessionStorage.getItem(
            'loggedUser'
          )} ${sessionStorage.getItem('correlationId')}`,
          'Client-Application': 'CDC',
          Authorization: `Bearer ${sessionStorage.getItem('at')}`,
          'jwt': `${sessionStorage.getItem('tok')}`,
          'user-id': sessionStorage.getItem('remote_user') ? JSON.parse(sessionStorage.getItem('remote_user')).userID : `${sessionStorage.getItem('assignedUser')}`
        },
      }),
      { maxRequests: 7, perMilliseconds: 1000, maxRPS: 7 }
    );


    const https_logged_user = rateLimit(
      axios.create({
        headers: {
          Accept: 'application/json',
          'Correlation-ID': `${sessionStorage.getItem(
            'loggedUser'
          )} ${sessionStorage.getItem('correlationId')}`,
          'Client-Application': 'CDC',
          Authorization: `Bearer ${sessionStorage.getItem('at')}`,
          'jwt': `${sessionStorage.getItem('tok')}`,
          'user-id': sessionStorage.getItem('loggedUser') ? sessionStorage.getItem('loggedUser') : `${sessionStorage.getItem('assignedUser')}`
        },
      }),
      { maxRequests: 7, perMilliseconds: 1000, maxRPS: 7 }
    );


    let docIds = [];
    let userID = sessionStorage.getItem('loggedUser');
    let userrole = sessionStorage.getItem('userrole');

    if (userID && userrole === 'employee') {
      const res= await https_logged_user
        .get(
          `${endpoints.apigee}/document-management/cdc/requirements/v1/requirements-subscribe/user/userId`
        )
          sessionStorage.setItem('maxCount', res.data.maxCount);
          sessionStorage.setItem('followingCount', res.data.documentIds.length);
          // res?.data?.documentIds?.forEach((element) => {
          //   docIds.push(element);
          // });
          docIds=res?.data?.documentIds? [...res?.data?.documentIds]:[]
    }

    https.get(apiURLFirst + 0).then(async (response) => {
      let count = response.data.count;
      if (response?.data?.docs?.length > 0) {
        allData = response.data.docs;
        if (response?.data?.docs?.length > 9998 && count >= 1) {
          const responseData = await dataIterator(count, apiURLFirst, allData);
          allData = responseData;
        }
        allData.forEach((o) => {
          if (docIds.includes(o.id)) {
            o.following = true;
          } else {
            o.following = false;
          }
        });
        loadData(allData);
      } else {
        loadData([])
      }
      //for landning page 
      if (urlPathVal1 === 'landing-page') {
        allData = response.data;
        allData.map((o) => {
          if (docIds.includes(o.id)) {
            o.following = true;
          } else {
            o.following = false;
          }
        });
        loadData(allData);
      }
      setLoading(false);
    })
      .catch((e) => {
        console.log('DC651:', e);
        setTotalPages(1);
        setTotalResults(0);
        setReportResults([]);
        setReportResultsFull([]);
        setLoading(false);
      });

  };

  const getCBAPIResponse = (userId, statusArg, remote) => {
    setLoading(true);
    let urlPathVal1 = '';
    let urlPathVal2 = '';
    let urlPathVal3 = '';
    let statusParam = '';
    // console.log(statusArg,JSON.parse(sessionStorage.getItem('sessionFilters')).status);
    if (statusArg !== '') {
      statusParam = statusArg;
    } else if (
      JSON.parse(sessionStorage.getItem('sessionFilters')) &&
      JSON.parse(sessionStorage.getItem('sessionFilters')).status !== ''
    ) {
      statusParam = JSON.parse(sessionStorage.getItem('sessionFilters')).status;
    } else if (sessionStorage.getItem('userrole') === 'employee' && !remote) {
      statusParam = 'Submitted';
    } else {
      statusParam = 'Not Submitted';
    }
    if (sessionStorage.getItem('userrole') === 'employee' && !remote) {
      urlPathVal1 = 'requirements';
      urlPathVal2 = 'employee';
      urlPathVal3 = 'documents';
    } else {
      if (sessionStorage.getItem('view_as')) {
        urlPathVal1 = 'requirements';
        urlPathVal2 = 'employee';
        urlPathVal3 = 'documents';
      } else {
        urlPathVal1 = 'landing-page';
        urlPathVal2 = 'users';
        urlPathVal3 = 'requirement-details';
      }
    }

    const callTypeBefore = 'before';
    const callTypeFuture = 'future';


    const apiURLFirst = `${endpoints.apigee}/document-management/cdc/${urlPathVal1}/v1/${urlPathVal2}/userId/${urlPathVal3}?callType=${callTypeBefore}&startDay=-9999&endDay=9999&status=${statusParam}&isClosingBinder=true&recordsCount=`;

    let allData = [];
    
    const https = rateLimit(
      axios.create({
        headers: {
          Accept: 'application/json',
          'Correlation-ID': `${sessionStorage.getItem(
            'loggedUser'
          )} ${sessionStorage.getItem('correlationId')}`,
          'Client-Application': 'CDC',
          Authorization: `Bearer ${sessionStorage.getItem('at')}`,
          'jwt': `${sessionStorage.getItem('tok')}`,
          'user-id': sessionStorage.getItem('remote_user') ? JSON.parse(sessionStorage.getItem('remote_user')).userID : `${sessionStorage.getItem('assignedUser')}`
        },
      }),
      { maxRequests: 7, perMilliseconds: 1000, maxRPS: 7 }
    );

    const https_logged_user = rateLimit(
      axios.create({
        headers: {
          Accept: 'application/json',
          'Correlation-ID': `${sessionStorage.getItem(
            'loggedUser'
          )} ${sessionStorage.getItem('correlationId')}`,
          'Client-Application': 'CDC',
          Authorization: `Bearer ${sessionStorage.getItem('at')}`,
          'jwt': `${sessionStorage.getItem('tok')}`,
          'user-id': sessionStorage.getItem('loggedUser') ? sessionStorage.getItem('loggedUser') : `${sessionStorage.getItem('assignedUser')}`
        },
      }),
      { maxRequests: 7, perMilliseconds: 1000, maxRPS: 7 }
    );

    const docIds = [];
    let userID = sessionStorage.getItem('loggedUser');
    let userrole = sessionStorage.getItem('userrole');

    if (userID && userrole === 'employee') {
      https_logged_user
        .get(
          `${endpoints.apigee}/document-management/cdc/requirements/v1/requirements-subscribe/user/userId`

        )
        .then((res) => {
          sessionStorage.setItem('maxCount', res.data.maxCount);
          sessionStorage.setItem('followingCount', res.data.documentIds.length);
          res.data.documentIds.forEach((element) => {
            docIds.push(element);
          });
        });
    }

    // First api logic
    // FORMATTING CHANGE
    https.get(apiURLFirst + 0).then(async (response) => {
      // console.log("responseoooooooo", response);
      let count = response.data.count;
      if (response?.data?.docs?.length > 0) {
        allData = response.data.docs;
        if (response?.data?.docs?.length > 9998 && count >= 1) {
          const responseData = await dataIterator(count, apiURLFirst, allData);
          allData = responseData;
        }
        allData.map((o) => {
          if (docIds.includes(o.id)) {
            o.following = true;
          } else {
            o.following = false;
          }
        });
        loadData(allData,"CB");
      } else {
        loadData([])
      }
      //for landning page 
      if (urlPathVal1 === 'landing-page') {
        allData = response.data;
        allData.map((o) => {
          if (docIds.includes(o.id)) {
            o.following = true;
          } else {
            o.following = false;
          }
        });
        loadData(allData,"CB");
      }
    })
      .catch((e) => {
        console.log('DC651:', e);
        setTotalPages(1);
        setTotalResults(0);
        setReportResults([]);
        setReportResultsFull([]);
        setLoading(false);
      });

  };
  const loadReportData = (responseArray) => {
    setReportData(responseArray);
  };

  const getReportAPIResponse = (
    userId,
    category,
    doctype,
    fromAsOfDate,
    toAsOfDate
  ) => {
    let categoryTemp = category;
    let doctypeTemp = doctype;
    let fromAsOfDateTemp = fromAsOfDate;
    let toAsOfDateTemp = toAsOfDate;
    if (
      toAsOfDateTemp == null ||
      toAsOfDateTemp == undefined ||
      toAsOfDateTemp == ''
    ) {
      toAsOfDateTemp = '2025-01-01';
    } else {
      toAsOfDateTemp = moment(toAsOfDateTemp).format('YYYY-MM-DD');
    }
    if (
      fromAsOfDateTemp == null ||
      fromAsOfDateTemp == undefined ||
      fromAsOfDateTemp == ''
    ) {
      fromAsOfDateTemp = '2015-01-01';
    } else {
      fromAsOfDateTemp = moment(fromAsOfDateTemp).format('YYYY-MM-DD');
    }
    if (doctypeTemp == null || doctypeTemp == undefined || doctypeTemp == '') {
      doctypeTemp = '';
    }
    if (
      categoryTemp == null ||
      categoryTemp == undefined ||
      categoryTemp == ''
    ) {
      categoryTemp = '';
    }
    setLoading(true);
    let apiURLFirst = '';
    apiURLFirst = `${endpoints.apigee}/document-management/cdc/requirements/v1/portalSubmissionStats?doctype=${doctypeTemp}&category=${categoryTemp}&fromAsOfDate=${fromAsOfDateTemp}&toAsOfDate=${toAsOfDateTemp}`;
    const allData = [];
    const https = rateLimit(
      axios.create({
        headers: {
          Accept: 'application/json',
          'Correlation-ID': `${sessionStorage.getItem(
            'loggedUser'
          )} ${sessionStorage.getItem('correlationId')}`,
          'Client-Application': 'CDC',
          Authorization: `Bearer ${sessionStorage.getItem('at')}`,
        },
      }),
      { maxRequests: 7, perMilliseconds: 1000, maxRPS: 7 }
    );

    axios
      .all([https.get(apiURLFirst)])
      .then(
        axios.spread((...response) => {
          loadReportData(response[0].data);
          setLoading(false);
        })
      )
      .catch((e) => {
        console.log('DC651:', e);
        setReportData([]);
        setLoading(false);
      });
  };

  /**
   * Gets all the employee names that the logged in employee has a access to.
   *
   * @param {string} userId The ID of the logged in employee user.
   */
  const loadEmployeeNames = (userId) => {
    setLoading(true);

    const getEmployeeNameURL = `${endpoints.apigee}/document-management/cdc/requirements/v1/employees/employeeId`;
    const user = sessionStorage.getItem('loggedUser');
    const empData = sessionStorage.getItem('employeeNameData');
    if (!empData) {
      callApi
        .getApiData(getEmployeeNameURL)
        .then((getEmployeeNameResult) => {
          const employeeNameData = [
            {
              firstName: 'You (default)',
              lastName: '',
              fullName: 'You',
              userId: user,
            },
          ];
          getEmployeeNameResult.data.allEmployees.forEach((value) => {
            const allNames = [];
            allNames.push(value.lastName?.replace(',',''));
            allNames.push(value.firstName?.replace(',',''));
            let fullNameValue = '';
            if (value.lastName !== '') {
              fullNameValue = allNames.join(', ');
            }
            const bankId = [];
            bankId.push(value.userId);
            bankId.push('@cdcemp');
            const empId = bankId.join('');
            const employeeId = empId.replace(';', '%3B');
            employeeNameData.push({
              firstName: value.firstName,
              lastName: value.lastName,
              fullName: fullNameValue,
              userId: employeeId,
            });
          });
          employeeNameData.sort((a, b) => {
            if (a.lastName < b.lastName) {
              return -1;
            }
            if (a.lastName > b.lastName) {
              return 1;
            }
            return 0;
          });
          setEmployeeName(employeeNameData);
          setEmployeeNameFull(employeeNameData);
          sessionStorage.setItem(
            'employeeNameData',
            JSON.stringify(employeeNameData)
          );
          // setLoading(false)
        })
        .catch(() => {
          // console.log('error', error)
          // setLoading(false)
        });
    } else {
      setEmployeeName(JSON.parse(empData));
      setEmployeeNameFull(JSON.parse(empData));
    }
  };

  /**
   * Clears the filters.
   *
   * @param {string} assignedUser The ID of the assigned user.
   */
  const clearFilter = (assignedUser) => {
    // console.log(assignedUser);
    // reportResultsFull, dashboardData, setDashboardData, setActiveFilters, searchValue, setSortByValue, setReportResults, setTotalResults, setTotalPages, loadFilterS, setSearchValue, getAPIResponse
    const dashboardDataTemp = {
      search: '',
      reportingEntityValue: '',
      parentCompanyValue: '',
      asOfDateValue: '',
      cbDateValue:'',
      submittedDateValue: '',
      dealNameValue: '',
      dealIdValue:'',
      requirementNameValue: '',

      reportingEntityValueReport: '',
      asOfDateValueReport: '',
      submittedDateValueReport: '',
      requirementNameValueReport: '',

      syndicationfundValue: '',
      statusValue: '',
      followingValue: '',
    };
    const activeFiltersTemp = {
      requirementName: '',
      reportingEntity: '',
      parentCompany: '',
      asOfDate: '',
      submittedDate: '',
      cbDate:'',
      requirementNameReport: '',
      reportingEntityReport: '',
      asOfDateReport: '',
      submittedDateReport: '',

      dealName: '',
      dealId:'',
      status: '',
      syndicationFundName: '',
      following: '',
    };
    if (sessionStorage.getItem('userrole') === 'employee' && !JSON.parse(sessionStorage.getItem('remote_user'))) {
      dashboardDataTemp.statusValue = 'Submitted';
      activeFiltersTemp.status = 'Submitted';
      dashboardDataTemp.followValue = 'Follow';
      activeFiltersTemp.following = 'Follow';
    }
    if (
      sessionStorage.getItem('userrole') === 'portaladmin' ||
      sessionStorage.getItem('userrole') === 'customer' ||
      JSON.parse(sessionStorage.getItem('remote_user'))
    ) {
      dashboardDataTemp.statusValue = 'Not Submitted';
      activeFiltersTemp.status = 'Not Submitted';
    }
    dashboardDataTemp.requirementNameValue = 'Select';
    activeFiltersTemp.requirementName = '';
    sessionStorage.setItem('sessionFilters', JSON.stringify(activeFiltersTemp));
    setDashboardData(dashboardDataTemp);
    setActiveFilters(activeFiltersTemp);
    setSortByValue('dateAsc');
    setSearchValue('');
    if(assignedUser=="cb"){
      getCBAPIResponse(assignedUser,'')
    } else {
      if(JSON.parse(sessionStorage.getItem('remote_user'))){
        getAPIResponse(
          JSON.parse(sessionStorage.getItem('remote_user')).userID,
          '',
          true
        );
      } else {
        getAPIResponse(assignedUser, '');
      }
    
    }
  };

  const clearFilterForReports = (assignedUser) => {
    // reportResultsFull, dashboardData, setDashboardData, setActiveFilters, searchValue, setSortByValue, setReportResults, setTotalResults, setTotalPages, loadFilterS, setSearchValue, getAPIResponse
    const dashboardDataTempReport = {
      reportingEntityValueReport: '',
      asOfDateValueReport: '',
      submittedDateValueReport: '',
      requirementNameValueReport: '',
    };
    const activeFiltersTempReport = {
    // requirementName :'',

      requirementNameReport: '',
      reportingEntityReport: '',
      asOfDateReport: '',
      submittedDateReport: '',
    };

    setDashboardData({ ...dashboardData, ...dashboardDataTempReport });
    setActiveFilters({ ...activeFilters, ...activeFiltersTempReport });

    sessionStorage.setItem(
      'sessionFilters',
      JSON.stringify({ ...activeFilters, ...activeFiltersTempReport })
    );
    setSortByValue('dateAsc');
    setSearchValue('');
    getAPIResponse(assignedUser, '');
  };

  useEffect(() => {
    if (
      sessionStorage.getItem('tok') !== null &&
      sessionStorage.getItem('type') === 'employee_signon'
    ) {
      loadEmployeeNames(sessionStorage.getItem('loggedUser'));
    }
    const dashboardDataTemp = dashboardData;
    const activeFiltersTemp = activeFilters;

    if (JSON.parse(sessionStorage.getItem('sessionFilters')) !== null) {
      const sessionFilters = JSON.parse(
        sessionStorage.getItem('sessionFilters')
      );
      dashboardDataTemp.requirementNameValue = sessionFilters.requirementName;
      dashboardDataTemp.reportingEntityValue = sessionFilters.reportingEntity;
      dashboardDataTemp.parentCompanyValue = sessionFilters.parentCompany;
      dashboardDataTemp.asOfDateValue = sessionFilters.asOfDate;
      dashboardDataTemp.cbDateValue = sessionFilters.cbDate;
      dashboardDataTemp.submittedDateValue = sessionFilters.submittedDate;

      dashboardDataTemp.requirementNameValueReport =
        sessionFilters.requirementNameReport;
      dashboardDataTemp.reportingEntityValueReport =
        sessionFilters.reportingEntityReport;
      dashboardDataTemp.asOfDateValueReport = sessionFilters.asOfDateReport;
      dashboardDataTemp.submittedDateValueReport =
        sessionFilters.submittedDateReport;

      dashboardDataTemp.dealNameValue = sessionFilters.dealName;
      dashboardDataTemp.dealIdValue = sessionFilters.dealId;
      dashboardDataTemp.syndicationfundValue =
        sessionFilters.syndicationFundName;
      dashboardDataTemp.statusValue = sessionFilters.status;

      dashboardDataTemp.followingValue = sessionFilters.following;
      activeFiltersTemp.requirementName = sessionFilters.requirementName;
      activeFiltersTemp.reportingEntity = sessionFilters.reportingEntity;
      activeFiltersTemp.parentCompany = sessionFilters.parentCompany;
      activeFiltersTemp.asOfDate = sessionFilters.asOfDate;
      activeFiltersTemp.submittedDate = sessionFilters.submittedDate;

      activeFiltersTemp.requirementNameReport =
        sessionFilters.requirementNameReport;
      activeFiltersTemp.reportingEntityReport =
        sessionFilters.reportingEntityReport;
      activeFiltersTemp.asOfDateReport = sessionFilters.asOfDateReport;
      activeFiltersTemp.submittedDateReport =
        sessionFilters.submittedDateReport;

      activeFiltersTemp.dealName = sessionFilters.dealName;
      activeFiltersTemp.dealId = sessionFilters.dealId;
      activeFiltersTemp.syndicationFundName =
        sessionFilters.syndicationFundName;
      activeFiltersTemp.status = sessionFilters.status;
      activeFiltersTemp.following = sessionFilters.following;
    } else {
      if (sessionStorage.getItem('userrole') === 'employee') {
        dashboardDataTemp.statusValue = 'Submitted';
        activeFiltersTemp.status = 'Submitted';
      }
      if (
        sessionStorage.getItem('userrole') === 'portaladmin' ||
        sessionStorage.getItem('userrole') === 'customer'
      ) {
        dashboardDataTemp.statusValue = 'Not Submitted';
        activeFiltersTemp.status = 'Not Submitted';
      }
    }
    setDashboardData(dashboardDataTemp);
    setActiveFilters(activeFiltersTemp);
  }, []);

  return (
    // eslint-disable-next-line react/jsx-filename-extension
    <DashboardContext.Provider
      value={{
        employeeName,
        setEmployeeName,
        reportResults,
        setReportResults,
        reportResultsFull,
        reportData,
        reportingEntity,
        dealName,
        dealId,
        requirementName,
        statusDrop,
        parentCompany,
        asOfDate,
        submittedDate,
        reportingEntityReport,
        requirementNameReport,
        asOfDateReport,
        submittedDateReport,
        loading,
        setLoading,
        dashboardData,
        setDashboardData, // statusValue, setStatusValue,
        syndicationFund,
        employeeNameFull,
        sortBy,
        searchData,
        totalPages,
        setTotalPages,
        totalResults,
        setTotalResults,
        activeFilters,
        setActiveFilters,
        loadFilterS,
        getAPIResponse,
        getCBAPIResponse,
        getReportAPIResponse,
        emptyFilters,
        sortByValue,
        setSortByValue,
        searchValue,
        setSearchValue,
        clearFilter,
        clearFilterForReports,
        showSyndication,
        followCheck,
        parentCompanyCpy,
        reportingEntityCpy,
        dealNameCpy,
        dealIdCpy,
        syndicationFundCpy,
      }}
    >
      {props.children}
    </DashboardContext.Provider>
  );
};

export default DashboardContextProvider;
