import React from 'react';
import {
  USBProgressIndicator,
  USBRadio,
  USBDropdown,
  USBButton,
  USBNotification,
} from '@usb-shield/components-react/dist/cjs';
import componentConfig from '../../config/component-config.json';
import { connect } from 'react-redux';
import { UserRoleConfig } from '../../config/user-role-config';
import GraphQlQuery from '../../utilities/graphQL-query';
import { getSvcServiceURL } from '../../config/connections';
import axios from 'axios';
import NavigateBack from '../../components/shared/navigateBack';
import LoadingHelper from '../../utilities/loading-helper';

class UserType extends React.Component {
  fullVendorListDetails = null;
  constructor(props) {
    super(props);
    this.onCancelClick = this.onCancelClick.bind(this);
    this.onContinue = this.onContinue.bind(this);
    this.radioBtnChange = this.radioBtnChange.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.onJumpLinkClick = this.onJumpLinkClick.bind(this);
    this.handleRadioFocusEvent = this.handleRadioFocusEvent.bind(this);
    this.state = {
      isEmpRadioBtnChecked: false,
      isVendorRadioBtnChecked: false,
      vendorList: null,
      isUserTypeSelected: false,
      isUserTypeSelectedError: false,
      isVendorNameSelectedError: false,
      getVendorListApiError: false,
      noProfileListError: false,
    };
  }

  loadVendorList() {
    const graphURL = getSvcServiceURL({ service: 'graph' });
    const query = GraphQlQuery.getVendorList();
    const specialVendorQuery = GraphQlQuery.getSpecialVendorList();
    LoadingHelper.inFlight();

    axios
      .all([
        axios.post(graphURL, query, { cdcType: 'servicing' }),
        axios.post(graphURL, specialVendorQuery, { cdcType: 'servicing' }),
      ])
      .then((res) => {
        LoadingHelper.outFlight();
        if (res[0].data) {
          const userRole =
            this.props.currentUser.custom_data.role.toLowerCase();
          // let frequentVendorList = res[0].data.data.getBusinessProfileList.profiles.sort((a, b) => {
          //     if (a.parentCompanyName > b.parentCompanyName) {
          //         return 1;
          //     }
          //     if (a.parentCompanyName < b.parentCompanyName) {
          //         return -1;
          //     }
          //     return 0;
          // })
          // let prntcmm=this.props.parentCompany.businessProfileType;
          // if (userRole.includes(UserRoleConfig.customer)) {
          //     frequentVendorList=  frequentVendorList.concat(res[1].data.data.getBusinessProfileList.profiles);
          //     if  (this.props.parentCompany &&  this.props.parentCompany?.businessProfileType!==UserRoleConfig.specialVendor) {
          //         this.fullVendorListDetails = [...frequentVendorList, { parentCompanyName: 'Not Listed' }];
          //     }else{
          //         this.fullVendorListDetails = frequentVendorList;
          //     }
          // }else  if  (userRole.includes(UserRoleConfig.superAdmin)) {
          //     frequentVendorList=  frequentVendorList.concat(res[1].data.data.getBusinessProfileList.profiles);
          //     this.fullVendorListDetails = frequentVendorList;
          // }

          // optimized the above code and provided fix for CSWP-3680
          let frequentVendorList =
            res[0].data.data.getBusinessProfileList.profiles.concat(
              res[1].data.data.getBusinessProfileList.profiles
            );
          this.fullVendorListDetails = frequentVendorList.sort((a, b) => {
            if (a.parentCompanyName > b.parentCompanyName) {
              return 1;
            }
            if (a.parentCompanyName < b.parentCompanyName) {
              return -1;
            }
            return 0;
          });
          let prntcmm = this.props.parentCompany.businessProfileType;
          if (
            userRole.includes(UserRoleConfig.customer) &&
            this.props.parentCompany &&
            this.props.parentCompany?.businessProfileType !==
            UserRoleConfig.specialVendor
          ) {
            this.fullVendorListDetails = [
              ...frequentVendorList,
              { parentCompanyName: 'Not Listed' },
            ];
          }
          // end
          this.setState({
            vendorList: this.fullVendorListDetails.map(
              (li) => li.parentCompanyName
            ),
          });
        }
      })
      .catch((error) => {
        LoadingHelper.outFlight();
        console.log('response===', this.state.profileList);
        console.log('There is an error in API call.' + error);
        this.setState({
          getVendorListApiError: true,
        });
        //    this.autoResetNotification();
      });
  }
  onCancelClick = (e) => {
    let path = '/ManageUser/';
    this.props.history.push(path);
  };

  onContinue = (e) => {
    let pSelection = this.props.parentCompanySelectionHasProfile;
    if (pSelection === undefined) {
      pSelection = false;
    }

    if (this.state.isUserTypeSelected) {
      let userSubType = null;
      if (this.fullVendorListDetails) {
        if (this.vendorSubType !== undefined) {
          if (this.vendorSubType.includes('Not Listed')) {
            this.vendorSubType = 'Not Listed';
          }
        }
        userSubType = this.fullVendorListDetails.find(
          (li) => li.parentCompanyName === this.vendorSubType
        );
      }
      let userType = {
        type: this.state.isEmpRadioBtnChecked ? 'Employee' : 'Vendor',
        subType: this.state.isEmpRadioBtnChecked ? null : userSubType,
      };
      this.props.dispatch({
        type: 'USER_TYPE_SELECTION',
        payload: userType,
      });
      if (userType.type === 'Employee') {
        this.props.history.push('/ManageUser/emp-reg');
      } else if (userType.type === 'Vendor' && userType.subType) {
        if (pSelection === true) {
          this.setState({
            noProfileListError: true,
          });
        } else {
          if (userType.subType.parentCompanyName === 'Not Listed') {
            this.props.history.push('/ManageUser/emp-reg');
          } else {
            this.props.history.push('/ManageUser/permissions');
          }
        }
      } else if (userType.type === 'Vendor' && !userType.subType) {
        if (
          this.vendorSubType === undefined ||
          this.vendorSubType === 'Not Listed'
        ) {
          this.setState({
            isVendorNameSelectedError: true,
          });
        }
        //   this.autoResetNotification();
      }
    } else {
      this.setState({
        isUserTypeSelectedError: true,
      });
      //  this.autoResetNotification();
    }
  };

  errorMessage() { }

  radioBtnChange = (e) => {
    if (e.target.value === 'Employee') {
      this.setState({
        isEmpRadioBtnChecked: true,
        isVendorRadioBtnChecked: false,
        isUserTypeSelected: true,
        isUserTypeSelectedError: false,
        isVendorNameSelectedError: false,
      });
      this.vendorSubType = undefined;
    } else if (e.target.value === 'Vendor') {
      if (!this.fullVendorListDetails) {
        this.loadVendorList();
      }
      this.setState({
        isEmpRadioBtnChecked: false,
        isVendorRadioBtnChecked: true,
        isUserTypeSelected: true,
        isUserTypeSelectedError: false,
      });
    }
  };

  handleSelectChange = (e) => {
    this.setState({
      isVendorNameSelectedError: false,
    });
    this.vendorSubType =
      e.target.value === undefined ? e.target.innerText : e.target.value;
  };

  empRadioBtnProps = {
    [componentConfig.USBRadio.formGroupID]: 'radio',
    [componentConfig.USBRadio.labelText]: 'Employee',
    [componentConfig.USBRadio.value]: 'Employee',
    [componentConfig.USBRadio.stacked]: true,
    [componentConfig.USBRadio.handleChange]: this.radioBtnChange,
    [componentConfig.USBRadio.handleFocus]: this.handleRadioFocusEvent,
    [componentConfig.USBRadio.isChecked]: this.isEmpRadioBtnChecked,
  };

  vendorRadioBtnProps = {
    [componentConfig.USBRadio.formGroupID]: 'radio',
    [componentConfig.USBRadio.labelText]: 'Vendor',
    [componentConfig.USBRadio.value]: 'Vendor',
    [componentConfig.USBRadio.stacked]: false,
    [componentConfig.USBRadio.handleChange]: this.radioBtnChange,
    [componentConfig.USBRadio.isChecked]: this.isVendorRadioBtnChecked,
  };

  continueBtnProps = {
    [componentConfig.USBButton.variant]: 'primary',
    [componentConfig.USBButton.addClasses]: 'sp-button-spacing',
    [componentConfig.USBButton.handleClick]: this.onContinue,
  };

  cancelBtnProps = {
    [componentConfig.USBButton.variant]: 'secondary',
    [componentConfig.USBButton.addClasses]: 'sp-button-spacing',
    [componentConfig.USBButton.handleClick]: this.onCancelClick,
  };

  getBackNavigationUrl = () => {
    /*if (this.props.parentCompany.businessProfileType.toLowerCase() === 'customer') {
            return '/ManageUser/parent-company';
        } else {*/
    return '/ManageUser/';
    //}
  };

  onJumpLinkClick = () => {
    setTimeout(() => {
      let str = window.location.hash;
      this.handleFieldFocus(str);
    }, 300);
  };

  handleRadioFocusEvent = (e) => {
    console.log('Handle Focus Called');
  };

  handleFieldFocus = (type: string): void => {
    switch (type) {
      case '#employee':
        document.getElementById('employeeRadio').focus();
        break;
      case '#select-vendor':
        document
          .getElementById('select-vendor')
          .getElementsByTagName('button')[0]
          .focus();
        break;
      default:
        break;
    }
  };

  resetNotification = () => {
    this.setState({
      isUserTypeSelected: false,
      isUserTypeSelectedError: false,
      isVendorNameSelectedError: false,
      getVendorListApiError: false,
    });
  };

  autoResetNotification = () => {
    setTimeout(() => {
      this.resetNotification();
    }, 5000);
  };

  render() {
    return (
      <React.Fragment>
        <div className="sp-progress-bar-container sp-mr-b-40">
          <div className="sp-progress-bar">
            <USBProgressIndicator
              id={'userTypeStep'}
              percentSuffixLabel="% Complete"
              PERCENTAGE="percentage"
              ariaProgressLabelFirst="Your Progress: Step"
              ariaProgressLabelMid="of"
              ariaProgressLabelLast="Completed"
              currentStep={1}
              variant="percentage"
            >
              <USBProgressIndicator.Step>
                <span>Step1</span>
              </USBProgressIndicator.Step>
              <USBProgressIndicator.Step>
                <span></span>
              </USBProgressIndicator.Step>
              <USBProgressIndicator.Step>
                <span></span>
              </USBProgressIndicator.Step>
              <USBProgressIndicator.Step>
                <span></span>
              </USBProgressIndicator.Step>
              <USBProgressIndicator.Step>
                <span>Step2</span>
              </USBProgressIndicator.Step>
            </USBProgressIndicator>
          </div>
        </div>
        <div
          className="sp-container-fluid sp-progress-content-space"
          id="sp-user-type"
        >
          <div className="sp-row">
            <div className="sp-col-6 sp-offset-left-3">
              <div>
                <NavigateBack url={this.getBackNavigationUrl()} />

                <h1 className="sp-page-title sp-mr-b-16">Add a user</h1>
                {this.state.isUserTypeSelectedError ? (
                  <div onClick={this.onJumpLinkClick}>
                    <USBNotification addClasses="sp-mr-b-12" variant="error">
                      Select a <a href="#employee"> user type.</a>
                    </USBNotification>
                  </div>
                ) : null}
                {this.state.isVendorNameSelectedError ? (
                  <div onClick={this.onJumpLinkClick}>
                    <USBNotification addClasses="sp-mr-b-12" variant="error">
                      Select a <a href="#select-vendor"> vendor.</a>
                    </USBNotification>
                  </div>
                ) : null}
                {this.state.getVendorListApiError ? (
                  <div onClick={this.onJumpLinkClick}>
                    <USBNotification
                      addClasses="sp-mr-b-12"
                      variant="error"
                      roleType="alert"
                      handleClose={this.resetNotification}
                      notificationData={[
                        {
                          text: 'There was a problem processing your request. Please try again.',
                        },
                      ]}
                    />
                  </div>
                ) : null}
                {this.state.noProfileListError ? (
                  <div>
                    <USBNotification addClasses="sp-mr-b-12" variant="error">
                      It looks like the company you’re trying to add a vendor to
                      doesn’t have access yet. Please try again later.
                    </USBNotification>
                  </div>
                ) : null}
                <div className="sp-mr-b-48 ">
                  <h4 className="sp-mr-b-12">
                    Choose the type of user you want to add.
                  </h4>
                  <div className="sp-mr-b-24">
                    <div className="sp-selection-radio">
                      {this.state.isUserTypeSelectedError ? (
                        <div style={{ color: 'rgb(222,22,43)' }}>
                          User type is required.
                        </div>
                      ) : null}
                      <USBRadio
                        id={'employee'}
                        radioID="employeeRadio"
                        {...this.empRadioBtnProps}
                        isError={this.state.isUserTypeSelectedError}
                      />
                      {this.props.currentUser.custom_data.role
                        .toLowerCase()
                        .includes(UserRoleConfig.superAdmin) ? (
                        <div className="sp-caption sp-caption-margin sp-mr-b-12">
                          This is anyone who works for the organization.{' '}
                        </div>
                      ) : (
                        <div className="sp-caption sp-caption-margin sp-mr-b-12">
                          This is anyone who works in your organization.{' '}
                        </div>
                      )}
                      {/* This will be an explanation of what an Employee is. */}
                    </div>
                    <div className="sp-selection-radio">
                      <USBRadio
                        id={'vendor'}
                        radioID="vendorRadio"
                        {...this.vendorRadioBtnProps}
                        isError={this.state.isUserTypeSelectedError}
                      />
                      {this.props.currentUser.custom_data.role
                        .toLowerCase()
                        .includes(UserRoleConfig.superAdmin) ? (
                        <div className="sp-caption sp-caption-margin">
                          {' '}
                          This is a third-party company or person that assists
                          with the reporting requirements.{' '}
                        </div>
                      ) : (
                        <div className="sp-caption sp-caption-margin">
                          {' '}
                          This is a third-party company or person that assists
                          with your reporting requirements.{' '}
                        </div>
                      )}

                      {/* This will be an explanation of what a Vendor is. */}
                    </div>
                  </div>

                  {this.state.isVendorRadioBtnChecked &&
                    this.state.vendorList ? (
                    <div id="sp-vendor-list">
                      {this.props.currentUser.custom_data.role
                        .toLowerCase()
                        .includes(UserRoleConfig.superAdmin) ? (
                        <div className="sp-body-medium sp-form-label-margin sp-mr-b-12">
                          Please select the vendor.
                        </div>
                      ) : (
                        <div className="sp-body-medium sp-form-label-margin sp-mr-b-12">
                          Please select your vendor.
                        </div>
                      )}
                      <USBDropdown
                        isScrolling={true}
                        id={'select-vendor'}
                        handleChange={(e) => this.handleSelectChange(e)}
                        label="Vendor"
                        errorText="Choose your vendor from the list to continue."
                        isError={this.state.isVendorNameSelectedError}
                        addClasses="sp-au-dropdown"
                        items={this.state.vendorList}
                      />{' '}
                    </div>
                  ) : null}
                </div>
                <div className="sp-action-button-wrapper">
                  <USBButton id={'usrTypCancelBtn'} {...this.cancelBtnProps}>
                    Cancel
                  </USBButton>
                  <USBButton
                    id={'usrTypContinueBtn'}
                    {...this.continueBtnProps}
                  >
                    Continue
                  </USBButton>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    currentUser: state.currentUser.user,
    parentCompany: state.parentCompanyInformation.parentCompany,
    parentCompanySelectionHasProfile:
      state.parentCompanyInformation.parentCompanySelectionHasProfile,
  };
};
export default connect(mapStateToProps)(UserType);
