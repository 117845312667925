import React from 'react';

const EntitlementReadOnlyItem = (props) => {
    return (
        <div>
            <div className="sp-mr-b-12 sp-my-profile-entitlement-heading">
                {props.title}
            </div>

            <div className="sp-my-profile-categories">
                {props.allSelected === "true" ? 'All' :
                    props.entities.map(entity => (
                        <div key={entity}>{entity}</div>
                    ))
                }

            </div>
        </div>
    )
}

export default EntitlementReadOnlyItem;