// React modules
import React, { useState, useEffect,useContext } from 'react';

// CSS
import './featureflags.css'

import { PermissionRoleContext } from '../../components/Hooks/usePermissionRoles';
import { USBGrid, USBColumn, USBButton, USBInput, USBIconDelete, USBIconSuccess ,
    USBModal,
    USBModalHeader,
    USBModalFooter,
    USBModalCloseIcon } from '@usb-shield/components-react';
import Loader from '../../components/utilities/Loader';
import USBBreadcrumb from '@usb-shield/components-react/dist/cjs/breadcrumb';
import Messages from '../../components/organisms/Messages';
import callApi from '../../components/utilities/callApi';
import { endpoints } from '../../../config/connections';
import Toggle from './Toggle/toggle';



const FeatureFlags = () => {
    
  const {permissionDetails}=useContext(PermissionRoleContext);
    const [errorModal, setErrorModal] = useState(false);
    const [errorModalTitle, setErrorModalTitle] = useState('');
    const [documentId, setDocumentId] = useState('');
    const [loading, setLoading] = useState(false); // to manage loader
    const [featureFlagsFullData, setFeatureflagsFullData] = useState([]) // to store full report data
    const [addNewRowFlag, setAddNewRowFlag] = useState(false) // to show and hide new row
    const [newFeatureflagsData, setNewFeatureFlagsData] = useState({
        FeatureFlags: '',
        Description: '',
        enable: false,
    }) // to store the values of new feature flags; initializing with default values
    const [FeatureFlagsError, setFeatureFlagsError] = useState(false)
    const [DescriptionError, setDescriptionError] = useState(false)
    const [featuresFileCategoryError, setCbFileCategoryError] = useState(false)
    const [successFlag, setSuccessFlag] = useState(false)
    const [failedFlag, setFailedFlag] = useState(false)
    const [messageContent, setMessageContent] = useState('')

    useEffect(() => {
        fetchFeatureflagsData()
    }, [])

    const fetchFeatureflagsData = () => {
        const fetchURL = `${endpoints.apigee}/document-management/coreservice/user-management/v1/get-all-features`
        callApi.
            getApiData(fetchURL).
            then(response => {
                setFeatureflagsFullData(response.data)
                setFailedFlag(false)
            }).catch(error => {
                setFailedFlag(true)
                setMessageContent("There is some issue fetching FeatureFlags data")
            })
    }

    const updateApi = (updatedDataObj) => {
        const updateURL = `${endpoints.apigee}/document-management/coreservice/user-management/v1/update-feature`
        callApi.
            postApi(updateURL, updatedDataObj).
            then(response => {
                fetchFeatureflagsData()
            }).catch(error => {
                setFailedFlag(true)
                setMessageContent("There is some issue updating FeatureFlags")
            })
    }

    const createApi = (newFeatureflagsDataObj) => {
        const createURL = `${endpoints.envUrl}/document-management/closing-binders/v1/FeatureFlagsAdd`
        callApi.
            postApi(createURL, newFeatureflagsDataObj).
            then(response => {
                fetchFeatureflagsData()
                setSuccessFlag(true)
                setMessageContent(`Added ${newFeatureflagsDataObj.FeatureFlags} FeatureFlags`)
                setTimeout(() => {
                    setSuccessFlag(false)
                }, 5000);
                deleteNewRow()
            }).catch(error => {
                setFailedFlag(true)
                setMessageContent("There is some issue adding new FeatureFlags")
            })
    }

    const updateExistingRow = (toggleValue, optionalData) => {
        updateApi(setToggleData(toggleValue, optionalData))
    }

    const setToggleValuesForNewRow = (toggleValue, optionalData) => {
        setNewFeatureFlagsData(setToggleData(toggleValue, optionalData))
    }

    const setToggleData = (toggleValue, optionalData) => {
        let updatedDataObj;
        if (optionalData.column == "enable") {
            updatedDataObj = { ...optionalData.data, enable: toggleValue }
        } 
        return updatedDataObj;
    }

    const closeMessage = () => {
        setFailedFlag(false);
        setSuccessFlag(false);
    };

    // -------------------- Adding new Featureflags ---------------------

    const addNewRow = () => {
        setAddNewRowFlag(true)
        setSuccessFlag(false)
        setFailedFlag(false)
    }

    const deleteNewRow = () => {
        setNewFeatureFlagsData({
            FeatureFlags: '',
            Description: '',
            enable: false
        })
        setFeatureFlagsError(false)
        setDescriptionError(false)
        setCbFileCategoryError(false)
        setFailedFlag(false)
        setAddNewRowFlag(false)
    }
    const deleteFile = (id) =>{
        
        const updateURL = `${endpoints.envUrl}/document-management/closing-binders/v1/FeatureFlagsDelete`
        callApi.
        postApi(updateURL,id,{ headers: {
            'Content-Type':'text/plain'}}).
            then(response => {
                setSuccessFlag(true)
                setMessageContent("FeatureFlags deleted successfully")
                fetchFeatureflagsData()
                setErrorModal(false)
                setTimeout(() => {
                    setSuccessFlag(false)
                }, 5000);
            }).catch(error => {
                setFailedFlag(true)
                setMessageContent("There is some issue deleting FeatureFlags")
                setErrorModal(false)
            })
    }
    const deleteExistingRow =(id) => {
        setErrorModal(true)
        setErrorModalTitle("Are you sure you want to delete feature flags");
        setDocumentId(id)
        
    }
    const submitNewRow = () => {
        if (!FeatureFlagsError && !DescriptionError && !featuresFileCategoryError && newFeatureflagsData.FeatureFlags !== "" && newFeatureflagsData.Description !== "") {
            createApi(newFeatureflagsData)
            setFailedFlag(false)
        } else {
            setFailedFlag(true);
            setMessageContent("Please provide valid inputs")
        }
    }
     const closeModal = (setErrorModal) => {
        setErrorModal(false);
        // setLegalHold(false)
      };

    const onInputChange = (event) => {
        const inputValue = event.target.value.trim();
        const fieldName = event.target.name
        let isError = !(/^[A-Za-z0-9()/ ]{2,}$/.test(inputValue));
        if (fieldName == "FeatureFlags") {
            setNewFeatureFlagsData({ ...newFeatureflagsData, FeatureFlags: inputValue })
            setFeatureFlagsError(isError)
        } else if (fieldName == "Description") {
            setNewFeatureFlagsData({ ...newFeatureflagsData, Description: inputValue })
            setDescriptionError(isError)
        } 
    }

    return (
        <>
      
            {loading ? <Loader /> : null}
            <USBModal className="delete-modal" isOpen={errorModal}>
            <USBModalCloseIcon
              handleModalClose={() => closeModal( setErrorModal)}
              className="errormodalclosebtn"
            />
            <USBModalHeader modalTitle={errorModalTitle} />
            <USBModalFooter>
                <div className="deletebtnmodalframe">
                  <span className="cancel-btn">
                    <USBButton
                    id="cancelButton"
                      handleClick={() => closeModal(setErrorModal)}
                    >
                      Cancel
                    </USBButton>
                  </span>
                  <USBButton
                    variant="primary"
                    handleClick={() =>
                      deleteFile(
                        documentId
                      )
                    }
                  >
                    Delete
                  </USBButton>
                </div>
            </USBModalFooter>
            </USBModal>
            <USBGrid addClasses="content-frame">
                <USBColumn largeSpan={8}>
                    <USBBreadcrumb
                        id="FeatureFlags"
                        text="FeatureFlags"
                        breadcrumbs={[
                            { id: 1, text: 'Tools', href: '/FeatureFlags' },
                            { id: 2, text: 'Feature Flags', href: '/FeatureFlags' },
                        ]}
                    />
                </USBColumn>
            </USBGrid>
            {successFlag || failedFlag ? (
                <div
                    className="message-content"
                    data-testid="messageContent"
                    role="alert"
                >
                    <Messages
                        success={successFlag}
                        closeMessage={closeMessage}
                        singleUploadFail={failedFlag}
                        messageContent={messageContent}
                        setFailedFlag={setFailedFlag}
                        setSuccessFlag={setSuccessFlag}
                        severity={'high'}
                    />
                </div>
            ) : (
                ''
            )}

{ !permissionDetails.includes("tools") ? (<>Access Denied</>): 
            (<USBGrid addClasses="content-frame">
                <USBColumn largeSpan={16} m>
                    <div className='featureflags_grid'>
                        {/* {!addNewRowFlag &&
                            <div className='action_buttons_add' >
                                <USBButton
                                    variaCBnt="primary"
                                    type="button"
                                    size="small"
                                    handleClick={addNewRow}
                                >Add</USBButton>
                            </div>
                        } */}
                        <div className='featureflags_header_container'>
                            <div className='featureflags grid_font'>FEATURE NAME</div>
                            <div className='featureflags_desc grid_font'>DESCRIPTION</div>
                            <div className='features_construction grid_font'>ENABLE</div>
                        </div>
                        {addNewRowFlag &&
                            <div className='featureflags_body_container'>
                                <div className='featureflags grid_font'>
                                    <USBInput
                                        id={'FeatureFlagsInput'}
                                        addClasses={'add_doc_input'}
                                        errorText={newFeatureflagsData.FeatureFlags == '' ? 'FEATURES Featureflags is required.' : newFeatureflagsData.FeatureFlags.length < 2 ? 'This field requires at least two characters' : 'Invalid FEATURES FeatureFlags'}
                                        isError={FeatureFlagsError}
                                        initValue={''}
                                        helperText={"Required"}
                                        handleChange={onInputChange}
                                        labelText=""
                                        name="FeatureFlags"
                                        value={newFeatureflagsData.FeatureFlags}
                                    />
                                </div>
                                <div className='featureflags_desc grid_font'>
                                    <USBInput
                                        id={'DescriptionInput'}
                                        addClasses={'add_doc_input'}
                                        errorText={newFeatureflagsData.Description == '' ? 'FEATURES Featureflags Desc is required.' : newFeatureflagsData.Description.length < 2 ? 'This field requires at least two characters' : 'Invalid FEATURES FeatureFlags Desc'}
                                        isError={DescriptionError}
                                        initValue={''}
                                        helperText={"Required"}
                                        handleChange={onInputChange}
                                        labelText=""
                                        name="Description"
                                        value={newFeatureflagsData.Description}
                                    />
                                </div>
                              
                               
                                <div className='features_construction grid_font'>
                                    <Toggle id={`newConstruction`} toggleTextOn={true} toggleTextOff={false} value={newFeatureflagsData.enable} onToggleChange={setToggleValuesForNewRow} optionalData={{ data: newFeatureflagsData, column: 'enable' }} />
                                </div>
                               
                                <div className='action_icons'>
                                    {addNewRowFlag &&
                                        <div className='action_buttons submitBtn' onClick={submitNewRow}>
                                            <USBIconSuccess title="Submit"></USBIconSuccess>
                                        </div>
                                    }
                                    {/* {addNewRowFlag &&
                                        <div className='action_buttons deleteBtn' onClick={deleteNewRow}>
                                            <USBIconDelete title="Delete"></USBIconDelete>
                                        </div>
                                    } */}
                                </div>
                            </div>
                        }
                        {!loading && featureFlagsFullData && featureFlagsFullData.map((configItem, index) => {
                            return <div className='featureflags_body_container' key={index}>
                                <div className='featureflags grid_font'>
                                    {configItem.feature != null ? `${configItem.feature}` : '-'}
                                </div>
                                <div className='featureflags_desc grid_font'>
                                    {configItem.description != null ? `${configItem.description}` : '-'}
                                </div>
                              
                               
                                <div className='features_construction grid_font'>
                                    <Toggle id={`${index}construction`} toggleTextOn={true} toggleTextOff={false} value={configItem.enable} onToggleChange={updateExistingRow} optionalData={{ data: configItem, column: 'enable' }} />
                                </div>
                                
                                {/* <div className='action_icons'>
                                    <div className='action_buttons deleteBtn' onClick={()=>{deleteExistingRow(configItem.id)}}>
                                        <USBIconDelete title="Delete"></USBIconDelete>
                                    </div>
                                </div> */}
                            </div>
                        })}
                    </div>
                </USBColumn>
            </USBGrid>)
          }
        </>
    )

}

export default FeatureFlags;