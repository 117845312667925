// @flow
import React, { useState, useEffect, forwardRef } from 'react';
import Axios from 'axios';
import { useSelector, useDispatch } from 'react-redux';
import { getDocumentmgmtServiceURL, getDocumentmgmtServiceURLuser } from '../../config/connections';
import LoadingHelper from '../../utilities/loading-helper';
import EntitlementReadOnlyItem from './EntitlementReadOnlyItem';
import { USBGrid, USBColumn } from '@usb-shield/components-react'

type Props = {
    isProfilePermissionsApiError: Boolean,
    profilePermissionsApiErrorHandler: (isProfilePermissionsApiError: false) => void
};

const UserPermissions = forwardRef((props, ref) => {
    const currentLoggedInUserDetails = useSelector(state => state.currentUser.user);
    const serverProfileData = useSelector(state => state.userProfileInformation.profileServerData);
    const [isSiteAdmin, setIsSiteAdmin] = useState(false);
    const [reportingEntities, setReportingEntities] = useState(null);
    const [deals, setDeals] = useState(null);
    const [documents, setDocuments] = useState(null);
    const [isAllSelected, setIsAllSelected] = useState({
        legalAllSelected: false,
        dealAllSelected: false,
        categoriesAllSelected: false
    })

    useEffect(() => {
        if (serverProfileData && serverProfileData.profileRole) {
            if (serverProfileData.profileRole.toLowerCase() === 'site administrator') {
                setIsSiteAdmin(true);
            } else {
                setIsSiteAdmin(false);
                getAssignedPermission();
            }
        }
    }, [serverProfileData]);

    const getAssignedPermission = () => {
        const config = {
            cdcType: "docmanagement",
            headers:{
                'jwt':`${sessionStorage.getItem('tok')}`,
                'user-id':currentLoggedInUserDetails.sub
            }
        }
        let currentAsignedUrl = getDocumentmgmtServiceURLuser({
            service: 'getProfilePermissionsforUser',
            userid: currentLoggedInUserDetails.sub
        });
        currentAsignedUrl = currentAsignedUrl + "?role=" + serverProfileData.profileRole;
        LoadingHelper.inFlight();
        Axios.get(currentAsignedUrl,config).then(response => {
            LoadingHelper.outFlight();
            let reportingEntities = [];
            let dealEntities = [];
            let documentsList = [];
            let allSelected = {
                legalAllSelected: false,
                dealAllSelected: false,
                categoriesAllSelected: false
            }
            if (response.data.length > 1) {
                response.data.forEach(entry => {
                    allSelected.legalAllSelected = entry.allReportingEntitysFlag;
                    allSelected.dealAllSelected = entry.allDealsFlag;
                    allSelected.categoriesAllSelected = entry.allCategoriesFlag;
                    if (entry.reportingEntitys && entry.reportingEntitys.length > 0) {
                        entry.reportingEntitys.forEach(rep => {
                            reportingEntities.push(rep.value + '|' + entry.name);
                        })
                    }
                    if (entry.deals && entry.deals.length > 0) {
                        entry.deals.forEach(rep => {
                            dealEntities.push(rep.value + '|' + entry.name)
                        })
                    }
                    if (entry.categories && entry.categories.length > 0) {
                        entry.categories.forEach(rep => {
                            documentsList.push(rep.value);
                        })

                    }
                });

            } else {
                response.data.forEach(entry => {
                    allSelected.legalAllSelected = entry.allReportingEntitysFlag;
                    allSelected.dealAllSelected = entry.allDealsFlag;
                    allSelected.categoriesAllSelected = entry.allCategoriesFlag;
                    if (entry.reportingEntitys && entry.reportingEntitys.length > 0) {
                        entry.reportingEntitys.forEach(rep => {
                            reportingEntities.push(rep.value)
                        });
                    }
                    if (entry.deals && entry.deals.length > 0) {
                        entry.deals.forEach(rep => {
                            dealEntities.push(rep.value)
                        })
                    }
                    if (entry.categories && entry.categories.length > 0) {
                        entry.categories.forEach(rep => {
                            documentsList.push(rep.value);
                        })

                    }
                });
            }
            setIsAllSelected(allSelected)
            setReportingEntities(reportingEntities);
            setDeals(dealEntities);
            console.log(documentsList);
            setDocuments(documentsList);
        }).catch(error => {
            LoadingHelper.outFlight();
            console.error("There is an error while fetching User Permissions.", error);
            props.profilePermissionsApiErrorHandler(true);
        })
    }

    const getColumnCount = () => {
        const count = (reportingEntities && reportingEntities.length > 0) && (deals && deals.length > 0) ? 3 : 2;
        return count;
    }

    const getColumnSpan = () => {
        return getColumnCount() === 3 ? 5 : 8;
    }

    return (
        <div>
            {isSiteAdmin ?
                <div>
                    <div className="sp-mr-b-32 " >
                        <h2><strong>My permissions</strong></h2>
                    </div>
                    <div>
                        You have access to all current and future reporting requirements for your organization.
                    </div>
                </div>
                :
                <>
                    <USBGrid gridGap="large">
                        {
                            reportingEntities && reportingEntities.length > 0 ?
                                <USBColumn largeSpan={getColumnSpan()}
                                    addClasses="sp-mr-b-12"
                                >
                                    <EntitlementReadOnlyItem title="Legal entity" entities={reportingEntities} allSelected={isAllSelected.legalAllSelected}/>
                                </USBColumn> : null
                        }
                        {
                            deals && deals.length > 0 ?
                                <USBColumn largeSpan={getColumnSpan()}
                                    addClasses="sp-mr-b-12"
                                >
                                    <EntitlementReadOnlyItem title="Deals" entities={deals} allSelected={isAllSelected.dealAllSelected}/>
                                </USBColumn> : null
                        }

                        {
                            documents && documents.length > 0 ?
                                <USBColumn largeSpan={getColumnSpan()}
                                    addClasses="sp-mr-b-12"
                                >
                                    <EntitlementReadOnlyItem title="Documents" entities={documents} allSelected={isAllSelected.categoriesAllSelected}/>
                                </USBColumn> : null
                        }
                    </USBGrid>
                </>
            }
        </div>
    )
});

export default UserPermissions;