/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import PropTypes from 'prop-types';
import './Messages.css';
import errorIcon from '../../../../assets/img/dateAlert.png';
import successIcon from '../../../../assets/img/green-tick.svg';

const Messages = ({
  success,
  singleUploadFail,
  closeMessage,
  messageContent,
  setFailedFlag,
  setSuccessFlag,
  errorContent
}) => {
  return (
    <div>
      {success ? (
        <div className="message-panel">
          <span>
            <img width='25px' height='25px' src={successIcon} />
          </span>
          <span className="success-text">{messageContent}</span>
          <span
            className="close-button"
            onClick={() => closeMessage(setFailedFlag, setSuccessFlag)}
          >
            x
          </span>
        </div>
      ) : (
        ''
      )}
      {singleUploadFail ? (
        <div className="warning-panel">
          <span>
            <img width='25px' height='25px' src={errorIcon} />
          </span>
          <span className="warning-text">{errorContent?errorContent:messageContent}</span>
          <span
            className="close-button"
            onClick={() => closeMessage(setFailedFlag, setSuccessFlag)}
          >
            x
          </span>
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

Messages.propTypes = {
  success: PropTypes.bool.isRequired,
  singleUploadFail: PropTypes.bool.isRequired,
  closeMessage: PropTypes.func.isRequired,
  messageContent: PropTypes.string.isRequired,
  setFailedFlag: PropTypes.func.isRequired,
  setSuccessFlag: PropTypes.func.isRequired,
};

export default Messages;
