// @flow
import React, { useState, useEffect } from 'react';
import './sub-menu.css'

type SubMenuProps = {
    label: ReactNode,
    menuItems: SubMenuItemProps[],
    onItemSelected: () => void,
    optionalData: any
}

type SubMenuItemProps = {
    label: string;
    icon: any // ReactNode,

}

const SubMenu = (props) => {
    const [open, setOpen] = useState(false);
    const array = new Uint32Array(1);
    const toggleDropdown = () => {
        setOpen(!open);
    }

    const handleItemClick = (item) => {
        setOpen(false);
        props.onItemSelected(item.label, props.optionalData);
    }

    const targetNotDrodpown = (e) => {        
        // when the target element is not the current element close the popover
        if (e.target.classList.contains('dropdown__list-item-btn')
            || e.target.classList.contains('sp-sub-menu-wrapper') 
            || e.target.classList.contains(props.optionalData?.userID)  ) {
           return ;

        }
        setOpen(false);
    }
    useEffect(() => {
        if (open) {
            document.addEventListener('mousedown', targetNotDrodpown)
            return function cleanup() {
                document.removeEventListener('mousedown', targetNotDrodpown);
            };
        }
    }, [open])
    return (
        <div className="sp-sub-menu-wrapper usb-dropdown">
            <button id={ `sub-menu-btn${ Math.round(  window.crypto.getRandomValues(array) * 10000 ) }` } title="Sub Menu Btn" type="button" className={`sp-sub-menu-btn ${props.optionalData?.userID}`} onClick={toggleDropdown}>{props.label}</button>

            {
                open &&
                <ul
                    className="sp-sub-menu-dropdown dropdown__list expanded"
                    tabIndex={-1}
                    role='listbox'
                    aria-labelledby="dropdown_item_list">
                    {props.menuItems.map((item, index) => (
                        <li className='dropdown__list-item' key={index}>
                            <div
                                tabIndex={0}
                                role='option'
                                className="dropdown__list-item-btn" style={{'white-space': 'nowrap'}}
                                onClick={() => handleItemClick(item)}
                                onKeyPress={() => handleItemClick(item)}
                            >
                                {item.icon} {item.label}
                            </div></li>
                    ))}

                </ul>
            }


        </div>
    )
}

export default SubMenu;