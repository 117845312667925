// @flow
// import React from 'react';
import {
    USBModal, USBModalCloseIcon, USBModalHeader, USBModalBody, USBModalFooter, USBButtonGroup, USBButton, USBSelect
} from '@usb-shield/components-react/dist/cjs';
import UserListTable from './UserListTable';
  import React, { useState, useEffect, useContext, createContext } from 'react';
  import axios from 'axios';
  import rateLimit from 'axios-rate-limit';
  import { endpoints } from '../../../config/connections';
  import {
    getInboxMetadata,
    getInboxFiles,
    getValueByNestedKey,
    updateInboxItemStatus,
    isEmployee
  } from './utils';
const UserListBox = ({open, id, toggle}) => {
   const [openBox , setOpenBox] = useState(false);
   const [userListAll,setUserListAll] = useState('');
   const https = rateLimit(
    axios.create({
        headers: {
            Accept: 'application/json',
            'Correlation-ID': `${sessionStorage.getItem(
                'loggedUser'
            )} ${sessionStorage.getItem('correlationId')}`,
            'Client-Application': 'CDC',
            Authorization: `Bearer ${sessionStorage.getItem('at')}`,
            'jwt': `${sessionStorage.getItem('tok')}`,
            'user-id':'shibiit601@cdc',
            // 'user-id': sessionStorage.getItem('loggedUser') ? sessionStorage.getItem('loggedUser') : `${sessionStorage.getItem('assignedUser')}`
        },
    }),
    { maxRequests: 7, perMilliseconds: 1000, maxRPS: 7 }
);
   const initialUserData = async()=>{
    const userData = JSON.parse(sessionStorage.getItem('userData'));
    const  url = `${endpoints.apigee}/document-management/cdc/requirements/v1/inbox/users?requirement-id=${id}`;
    if(!isEmployee()){
        const readUrlPut = `${endpoints.apigee}/document-management/cdc/requirements/v1/inbox/users?requirement-id=${id}&firstName=${userData.firstName}&lastName=${userData.lastName}&emailId=${userData.emailId}`
        const readUpdateResponse =  https.post(readUrlPut);
        if(readUpdateResponse){
          console.log("updated successfully");
        }
      }
   const response =  await https.get(url)
    if (response?.data?.inboxUserDetails.length > 0) {
        setUserListAll(response.data.inboxUserDetails)
    }
    // updateInboxItemStatus(id)
   }
   useEffect(()=>{
    setOpenBox(open)
    if(openBox){    
    initialUserData();
    }
   })

return  (
    <>
   
    {/* using router because we dont have handle change in icon show */}
    <USBModal
            isOpen={openBox}
            backdrop={'static'}
        >
            <USBModalCloseIcon
                handleModalClose={toggle}
                ariaLabel='close modal'
            />
            <USBModalHeader
                modalTitle={"User Details"}
            >
            </USBModalHeader>

            <USBModalBody>
            
                { userListAll ? <UserListTable fullTableData={userListAll} /> : <div className="span-bold">Oops,Something went wrong please try again </div>}
         
            </USBModalBody>
            <USBModalFooter>
                
            </USBModalFooter>
        </USBModal>
    </>
)
}
 
export default UserListBox;