import React, { useState, useEffect, useContext } from 'react';
import { USBGrid, USBColumn, USBSelect } from '@usb-shield/components-react';
import USBButton from '@usb-inner-src/react-button';
import { endpoints } from '../../../../config/connections';
import USBBreadcrumb from '@usb-shield/components-react/dist/cjs/breadcrumb';
import Loader from '../../../components/utilities/Loader';
import '@usb-shield/react-forms-base/dist/library/styles/index.css';
import '../Reports';
import './lookup-report.css';
import useUserRole from '../../../components/Hooks/use-UserRole';
import _noaccess from '../../../pages/_noaccess';
import UsbTableData from '../../../components/utilities/UsbTable';
import callApi from '../../../components/utilities/callApi';

const LookupSearch = () => {
  const [fullData, setFullData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchEntityList, setSearchEntityList] = useState([]);
  const [searchInput, setSearchInput] = useState('');
  const disableButton = searchInput ? false : true;

  const [catogory, setCatagory] = useState('');

  const getFilterData = async () => {
    setLoading(true);
    const url = `${endpoints.apigee}/document-management/cdc/requirements/v1/getSearchCategories`;
    try{ 
      const response = await callApi.getApiData(url);
      if (response) {
        setSearchEntityList(formatFilterData(response?.data, 0));
        setLoading(false);
      }
    } catch(e){
      //error
      setLoading(false);
    }
  };

  const formatFilterData = (list, indicator) => {
    const newData = [{ content: 'Select', value: '' }];
    if (indicator === 0) {
      list.forEach((element) => {
        let splitvalue = element.split('-');
        newData.push({ content: splitvalue[0], value: splitvalue[1] });
      });
    }
    return newData;
  };
  const clearSerchValue = () => {
    setLoading(true);
    setSearchInput('');
    setSearchEntityList([]);
    getFilterData();
    setFullData([]);
    setLoading(false);
  };

  const SearchValueBYEntity = async () => {
    const url = `${endpoints.apigee}/document-management/cdc/requirements/v1/getSearchDetails?category=${catogory}&value=${searchInput}`;
    try {
      const response = await callApi.getApiData(url);
      if (response) {
        setFullData(response?.data);
        setLoading(false);
    }} catch(e){
      setLoading(false)
    }
  };

  useEffect(() => {
    getFilterData();
  }, []);

  const tempUserRole = useUserRole();

  const columns = [
    {
      headerName: 'Parent Company',
      key: 'parentCompanyName',
      dataType: 'string',
      type: 'info',
      fieldFor: 'first',
    },
    {
      headerName: 'Deal Name',
      key: 'dealName',
      dataType: 'string',
      type: 'info',
      fieldFor: 'second',
    },
    {
      headerName: 'Deal Number',
      key: 'dealNumber',
      dataType: 'string',
      type: 'info',
      fieldFor: 'third',
    },
    {
      headerName: 'Fund Name',
      key: 'syndicationFundName',
      dataType: 'string',
      type: 'info',
      fieldFor: 'fourth',
    },
    {
      headerName: 'Legal Entity',
      key: 'reportingEntity',
      dataType: 'string',
      type: 'info',
      fieldFor: 'fifth',
    },

    {
      headerName: 'PSA Leads',
      key: 'psalead',
      dataType: 'string',
      type: 'info',
      fieldFor: 'sixth',
    },
    {
      headerName: 'Fund Manager',
      key: 'fundManager',
      dataType: 'string',
      type: 'info',
      fieldFor: 'seventh',
    },
  ];

  return tempUserRole && tempUserRole != 'employee' ? (
    <_noaccess />
  ) : (
    <>
      <USBGrid addClasses="content-frame">
        <USBColumn largeSpan={8}>
          <USBBreadcrumb
            id="reports"
            data-testid="breadcrumb"
            text="Reports"
            breadcrumbs={[
              { id: 2, text: 'Reports', href: '' },
              { id: 3, text: 'Lookup Report', href: '' },
            ]}
          />
        </USBColumn>
        <USBColumn largeSpan={8}></USBColumn>
      </USBGrid>
      <USBGrid  data-testid="main" addClasses="content-frame content-bg">
        {loading ? <Loader /> : null}
        <USBColumn largeSpan={16}>

        {/* <h1 className="heading-h1"  data-testid="heading">Lookup Report</h1> */}
         
          <div className="sorting-inputs">
            <div className="filter-dropdowns leftlabel SearchBy">
              {searchEntityList.length > 0 ? (
                <USBSelect
                  name="globalSearch"
                  id="globalSearch"
                  data-testid="dropdown" 
                  inputName="GlobalSearch"
                  labelText="Search By"
                  optionsArray={searchEntityList}
                  handleChange={(event) => {
                    setCatagory(event?.target?.value);
                  }}
                  errorMessages={{ 224: 'Please select an option' }}
                />
              ) : null}
            </div>
            <div className="filter-dropdowns-lookup searchInputValue">
              <label>
                <input
                  type="text"
                  data-testid="main" 
                  name="name"
                  id="searchInput"
                  size={30}
                  value={searchInput}
                  onChange={(e) => {
                    setSearchInput(e.target.value);
                  }}
                />
              </label>
            </div>
            <div className="filter-dropdowns-lookup searchButton">
              <USBButton
                type="button"
                variant="primary"
                size="small"
                id="searchButton"
                name="searchButton"
                disabled={disableButton}
                handleClick={() => {
                  SearchValueBYEntity();
                }}
              >
                Search
              </USBButton>
            </div>
            <div className="filter-dropdowns-lookup clear">
              {searchEntityList.length > 0 ? (
                <USBButton
                  type="button"
                  variant="text"
                  size="small"
                  id="clearButton"
                  data-testid="clearButton"
                  name="clearButton"
                  handleClick={() => {
                    clearSerchValue();
                  }}
                >
                  Clear Filter
                </USBButton>
              ) : null}
            </div>
          </div>
          <div className="sorting-inputs"></div>

          {fullData.length > 0 ? (
            <div className="page-content">
              <UsbTableData
                data={fullData}
                columns={columns}
              />
            </div>
          ) : null}
        </USBColumn>
      </USBGrid>
    </>
  );
};

export default LookupSearch;