import axios from 'axios';
import { parseJwt } from '../utilities/Utils';

axios.interceptors.request.use(
  (config) => {
    config.headers['Accept'] = 'application/json';
    config.headers['Correlation-ID'] = `${sessionStorage.getItem(
      'loggedUser'
    )} ${sessionStorage.getItem('correlationId')}`;
    config.headers['Authorization'] = `Bearer ${sessionStorage.getItem('at')}`;
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

const callApi = {

  getApiDataRequirement: (
    url,
    arg = '',
    options = { headers: { 'Client-Application': 'CDC',
    'jwt':`${sessionStorage.getItem('tok')}`,
    'user-id':`${sessionStorage.getItem('remote_user') ? JSON.parse(sessionStorage.getItem('remote_user')).userID : parseJwt(sessionStorage.getItem('tok')).custom_data.role.toUpperCase() === 'APP_8083_CDCWEB_SUPER_ADMINS' ? sessionStorage.getItem('assignedUser') : parseJwt(sessionStorage.getItem('tok')).sub}`
   } }
  ) => {
    return axios.get(url + arg, { ...options }).then((response) => {
      return response;
    });
  },
  getApiDataCBdownload: (
    url,
    id,
    arg = '',
    options = { headers: { 'Client-Application': 'CDC',
    'fileIds':id,
    'jwt':`${sessionStorage.getItem('tok')}`,
    'user-id':`${parseJwt(sessionStorage.getItem('tok')).custom_data.role.toUpperCase() === 'APP_8083_CDCWEB_SUPER_ADMINS' ? sessionStorage.getItem('assignedUser') : parseJwt(sessionStorage.getItem('tok')).sub}`

   } }
  ) => {
    return axios.get(url + arg, { ...options }).then((response) => {
      return response;
    });
  },
  getApiDataCBpreview: (
    url,
    id,
    arg = '',
    options = { headers: { 'Client-Application': 'CDC',
    'fileId':id,
    'jwtToken':`${sessionStorage.getItem('tok')}`,
    'user-id':`${parseJwt(sessionStorage.getItem('tok')).custom_data.role.toUpperCase() === 'APP_8083_CDCWEB_SUPER_ADMINS' ? sessionStorage.getItem('assignedUser') : parseJwt(sessionStorage.getItem('tok')).sub}`

   } }
  ) => {
    return axios.get(url + arg, { ...options }).then((response) => {
      return response;
    });
  },
  getApiData: (
    url,
    arg = '',
    options = { headers: { 'Client-Application': 'CDC',
    'jwt':`${sessionStorage.getItem('tok')}`,
    'user-id':`${parseJwt(sessionStorage.getItem('tok')).custom_data.role.toUpperCase() === 'APP_8083_CDCWEB_SUPER_ADMINS' ? sessionStorage.getItem('assignedUser') : parseJwt(sessionStorage.getItem('tok')).sub}`

   } }
  ) => {
    return axios.get(url + arg, { ...options }).then((response) => {
      return response;
    });
  },

  getApiDataLoggedUser: (
    url,
    arg = '',
    options = { headers: { 'Client-Application': 'CDC',
    'jwt':`${sessionStorage.getItem('tok')}`,
    'user-id':sessionStorage.getItem('tok') ? `${parseJwt(sessionStorage.getItem('tok')).sub}` : ''
   } }
  ) => {
    return axios.get(url + arg, { ...options }).then((response) => {
      return response;
    }).catch((error)=>{
      return error;
    });
  },
  postApiUpload: (
    url,
    data,
    options = { headers: { 'Client-Application': 'CDC',
    'jwt':`${sessionStorage.getItem('tok')}`,
    'user-id':`${parseJwt(sessionStorage.getItem('tok')).custom_data.role.toUpperCase() === 'APP_8083_CDCWEB_SUPER_ADMINS' ? sessionStorage.getItem('loggedUser') : parseJwt(sessionStorage.getItem('tok')).sub}` } }
  ) => axios.post(url, data, { ...options }),

  postApi: (
    url,
    data,
    options={}) => {
    const updatedOptions={
      ...options,
      headers: { 'Client-Application': 'CDC',
    'jwt':`${sessionStorage.getItem('tok')}`,
    'user-id':`${parseJwt(sessionStorage.getItem('tok')).custom_data.role.toUpperCase() === 'APP_8083_CDCWEB_SUPER_ADMINS' ? sessionStorage.getItem('assignedUser') : parseJwt(sessionStorage.getItem('tok')).sub}`

    ,...(options?.headers || {})
  }
 }

 

    return axios.post(url, data, { ...updatedOptions })

 },


  postApiLoggedUser: (
    url,
    data,
    options = { headers: { 'Client-Application': 'CDC',
    'jwt':`${sessionStorage.getItem('tok')}`,
    'user-id': sessionStorage.getItem('loggedUser') ? sessionStorage.getItem('loggedUser') : `${sessionStorage.getItem('assignedUser')}`
  }}
    ) => axios.post(url, data, { ...options }),


  patchApi: (
    url,
    data,
    options = { headers: { 'Client-Application': 'CDC',
    'jwt':`${sessionStorage.getItem('tok')}`,
    'user-id':`${parseJwt(sessionStorage.getItem('tok')).custom_data.role.toUpperCase() === 'APP_8083_CDCWEB_SUPER_ADMINS' ? sessionStorage.getItem('assignedUser') : parseJwt(sessionStorage.getItem('tok')).sub}` } }
  ) => axios.patch(url, data, { ...options }),
 

  putApi: (url, data, options = { headers: { 'Client-Application': 'CDC',
  'jwt':`${sessionStorage.getItem('tok')}`,
  'user-id':`${parseJwt(sessionStorage.getItem('tok')).custom_data.role.toUpperCase() === 'APP_8083_CDCWEB_SUPER_ADMINS' ? sessionStorage.getItem('assignedUser') : parseJwt(sessionStorage.getItem('tok')).sub}` } }) =>
    axios.put(url, data, { ...options }),

  deleteApi: (url, options = { headers: { 'Client-Application': 'CDC',
  'jwt':`${sessionStorage.getItem('tok')}`,
  'user-id':`${parseJwt(sessionStorage.getItem('tok')).custom_data.role.toUpperCase() === 'APP_8083_CDCWEB_SUPER_ADMINS' ? sessionStorage.getItem('assignedUser') : parseJwt(sessionStorage.getItem('tok')).sub}` }  }) => {
    return axios.delete(url, { ...options }).then((response) => {
      return response;
    });
  },
};

export default callApi;
