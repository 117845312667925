import React from 'react';
import axios from "axios";
import { USBLink, USBButton, USBProgressIndicator, USBNotification, USBCheckbox } from '@usb-shield/components-react/dist/cjs';
import componentConfig from '../../config/component-config.json';
import { connect } from "react-redux";
import '../../App.css';
import { getSvcServiceURL, getDocumentmgmtServiceURL, getAuthServiceURL } from '../../config/connections';
import { UserRoleConfig } from '../../config/user-role-config';
import NavigateBack from '../../components/shared/navigateBack';
import LoadingHelper from '../../utilities/loading-helper';
import { EntitlementDetails } from './get-entitlement-details';
import ProgressIndicator from '../../components/shared/ProgressIndicator';
import GraphQlQuery from '../../utilities/graphQL-query';
import { transmitIframeMessage } from '../../components/utility/transmit-message';
import Loader from '../../components/utilities/Loader';
import {FeatureFlagsContext} from '../../components/Hooks/useFeatureFlags';

class ProfileEditReview extends React.Component {
    maxEntityLength = 12;
    entitlement = {

    };

    companyDetails = null;
    constructor(props) {
        super(props);
        this.getEntitlement = new EntitlementDetails();
        this.onContinue = this.onContinue.bind(this);
        this.afterDispatchRedirectToEmpRegistration = this.afterDispatchRedirectToEmpRegistration.bind(this);
        this.afterDispatchRedirectToAssignPermissions = this.afterDispatchRedirectToAssignPermissions.bind(this);
        this.state = {
            profile: "",
            isProfileSaveError: false,
            isUnlistedProfileAssociationSaveError: false,
            maxLimitExeededError: false,
            loading : false
        }
        this.firstName = '';
        this.middleName = '';
        this.lastName = '';
        this.role = '';
        this.email = '';
        this.mobilePhone = '';
        this.businessPhone = '';
        this.apt = '';
        this.street = '';
        this.city = '';
        this.zipCode = '';
        this.selectedLeagalEntityList = [];
        this.selectedLeagalEntityDocList = [];
        this.selectedDealList = [];
        this.selectedDealDocList = [];
        this.userState = '';
        this.country = '';
        this.profileId = null;
        this.setProfileData();

    }

    setProfileData() {
        if (this.props.userDetails && Object.keys(this.props.userDetails).length > 0) {
            
            if (this.props.userDetails.profileId)
                this.profileId = this.props.userDetails.profileId;
            if (this.props.userDetails.firstName)
                this.firstName = this.props.userDetails.firstName;
            if (this.props.userDetails.middleName)
                this.middleName = this.props.userDetails.middleName;
            if (this.props.userDetails.lastName)
                this.lastName = this.props.userDetails.lastName;
            if (this.props.userDetails.profileRole)
                this.role = this.props.userDetails.profileRole;
            if (this.props.userDetails.email)
                this.email = this.props.userDetails.email;
            if (this.props.userDetails.phoneNumbers && this.props.userDetails.phoneNumbers.mobilePhone)
                this.mobilePhone = this.props.userDetails.phoneNumbers.mobilePhone;
            if (this.props.userDetails.phoneNumbers && this.props.userDetails.phoneNumbers.businessPhone)
                this.businessPhone = this.props.userDetails.phoneNumbers.businessPhone;
            if (this.props.userDetails.address && this.props.userDetails.address.addressLine2)
                this.apt = this.props.userDetails.address.addressLine2;
            if (this.props.userDetails.address && this.props.userDetails.address.addressLine1)
                this.street = this.props.userDetails.address.addressLine1;
            if (this.props.userDetails.address && this.props.userDetails.address.city)
                this.city = this.props.userDetails.address.city;
            if (this.props.userDetails.address && this.props.userDetails.address.zipCode)
                this.zipCode = this.props.userDetails.address.zipCode;
            if (this.props.userDetails.address && this.props.userDetails.address.country && this.props.countryList) {
                const selectedCountry = this.props.countryList.find(item => item.value === this.props.userDetails.address.country);
                if (selectedCountry) {
                    this.country = selectedCountry.content;
                } else {
                    this.country = null;
                }
            }
            if (this.props.userDetails.address && this.props.userDetails.address.state) {
                this.userState = this.props.userDetails.address.state;
            }
            if (this.props.userDetails.selectedLeagalEntityList)
                this.selectedLeagalEntityList = this.props.userDetails.selectedLeagalEntityList;
            if (this.props.userDetails.selectedLeagalEntityDocList)
                this.selectedLeagalEntityDocList = this.props.userDetails.selectedLeagalEntityDocList;
            if (this.props.userDetails.selectedDealList)
                this.selectedDealList = this.props.userDetails.selectedDealList;
            if (this.props.userDetails.selectedDealDocList)
                this.selectedDealDocList = this.props.userDetails.selectedDealDocList;
        }
    }

    afterDispatchRedirectToEmpRegistration = (e) => {
        this.props.dispatch({
            type: "PROFILE_EDIT_INFO_CLICK",
            payload: true
        });
        this.props.history.push('/ManageUser/emp-reg');
    }

    afterDispatchRedirectToAssignPermissions = (e) => {
        if (this.props.userDetails?.profileRole?.toLowerCase() === "site administrator") {
            this.props.dispatch({
                type: "PROFILE_EDIT_INFO_CLICK",
                payload: false
            });
            this.props.history.push('/ManageUser/emp-reg');
        } else {
            this.props.history.push('/ManageUser/permissions');
        }
    }

    onContinue = (e,flags) => {
        this.setState({
            loading : true,
        })
        if(!this.state.loading){
            this.saveProfileDetails(flags);
        }
        
    }

    onCancelClick = (e) => {
        let path = '/ManageUser/';
        this.props.history.push(path);
    }

    vendorDetails() {
        if (this.props.userType && this.props.userType.type === "Vendor") {
            return {
                "businessProfileID": this.props.userType.subType.businessProfileID,
                "businessProfileType": this.props.userType.subType.businessProfileType,
                "ein": this.props.userType.subType.ein
            }
        }
    }

    getParentCompanyDetails() {
        if (this.props.userType && this.props.userType.type === "Vendor" && this.props.userType.subType.parentCompanyName === "Not Listed") {

            return {
                "parentEIN": "Not Listed",
                "parentCompanyName": (this.props.userDetails.parentCompanyName !== undefined) ? this.props.userDetails.parentCompanyName : 'Not Listed',
                "parentCompanyLegalEntityID": "Not Listed"
            }
        } else {
            return {
                "parentEIN": (this.props.parentCompany !== undefined) ? this.props.parentCompany.ein : '',
                "parentCompanyName": (this.props.parentCompany !== undefined) ? this.props.parentCompany.parentCompanyName : '',
                "parentCompanyLegalEntityID": (this.props.parentCompany !== undefined) ? this.props.parentCompany.parentCompanyLegalEntityID : ''
            }
        }
    }

    associateFreqVendor(frqVenAssociation) {
        axios.post(getSvcServiceURL({ service: 'update' }), frqVenAssociation, { cdcType: "servicing_Update" }).then((res) => {
            LoadingHelper.outFlight();
            let path = '/ManageUser/confirm-user';
            this.props.history.push(path);
        }).catch((error) => {
            LoadingHelper.outFlight();
            alert("There is an error with creating Vendor.");
            console.log("There is an error in API call." + error);
        });
    }

    saveProfileDetails(flags) {
        this.setState((state) => {
            return {
                isProfileSaveError: false
            }
        });
        if (this.props.initialProfileData && this.props.initialProfileData.userID) {
            let bodyForProfileEdit = this.getEditQueryBody();
            let entitlementsUpdate = {
                url: '',
                body: null
            };
            let parentEntitys = null;
            if (this.props.isFrequentVendor || (this.props.parentCompany?.businessProfileType?.toLowerCase() === "vendor" || this.props.parentCompany?.businessProfileType?.toLowerCase() === "specialvendor")) {
                if (this.props.userDetails?.profileRole?.toLowerCase() === 'contributor') {
                    parentEntitys = this.getEntitlement.getVendorEntitlementBodyForApi();
                } else {
                    parentEntitys = []
                }
            } else if (this.isVendorSiteAdmin()) {
                parentEntitys = [];
            } else if (this.props.userDetails && this.props.userDetails.profileRole
                && this.props.userDetails.profileRole.toLowerCase() === 'site administrator') {
                parentEntitys = [{
                    name: this.props.parentCompany.parentCompanyName,
                    number: this.props.parentCompany.ein,
                    parentLegalEntityID: this.props.parentCompany.parentCompanyLegalEntityID,
                    role: this.props.userDetails.profileRole
                }];
            } else if (this.props.isAssignAll) {
                parentEntitys = [this.props.entitlement.entitlementServerResponse];
            } else {
                parentEntitys = [this.entitlement]
            }
            entitlementsUpdate.url = getDocumentmgmtServiceURL({
                service: 'updateProfilePermission',
                userid: `${this.props.initialProfileData.userID}`
            });
            entitlementsUpdate.body = {
                "profileId": this.props.initialProfileData.profileID,
                "source": "CDC",
                "userId": this.props.initialProfileData.userID,
                "emailId": this.email,
                "firstName": this.firstName,
                "lastName": this.lastName,
                "middleName": this.middleName,
                "mobileNumber": [
                    this.mobilePhone,
                    this.businessPhone
                ],
                "vendorRelationship": this.getFrequentVendorRelationship(),
                "permissions": [
                    {
                        "name": "CDC",
                        "parentEntitys": parentEntitys
                    }
                ]
            }


            if (this.props.userDetails.profileRole !== this.props.initialProfileData.profileRole) {
                bodyForProfileEdit.profileRoleChanged = true;
                bodyForProfileEdit.previousProfileRole = this.props.initialProfileData.profileRole;
            }
            else {
                bodyForProfileEdit.profileRoleChanged = false;
            }
            LoadingHelper.inFlight();
            axios.post(getSvcServiceURL({ service: 'update' }), bodyForProfileEdit, { cdcType: "servicing_Update" }).then((res) => {
                console.log('response=======', res.data);
                let path = '/ManageUser/confirm-user';
                axios.put(entitlementsUpdate.url, entitlementsUpdate.body, { cdcType: "docmanagement" })
                    .then((res) => {
                        LoadingHelper.outFlight();
                        this.setState({
                            loading : false,
                        })
                        console.log('response=======', res.data);
                        this.props.history.push(path);

                    }).catch((error) => {
                        LoadingHelper.outFlight();
                        alert("There is an error updating your permissions. Please try again.")
                        console.log("There is an error in API call." + error);
                    });

            }).catch((error) => {
                LoadingHelper.outFlight();
                this.setState((state) => {
                    return {
                        isProfileSaveError: true,
                        loading : false
                    }
                });
            });
        } else {
            if (this.props.userType && this.props.userType.type === "Vendor" && this.props.userType.subType &&
                this.props.userType.subType.parentCompanyName && this.props.userType.subType.parentCompanyName !== "Not Listed") {
                // FREQUENT VENDOR ASSOCIATION (NO ACTUAL PROFILE CREATED via AUTH, SO Just call CORE API and PROFILE UPDATE API)
                let baseUrL = getDocumentmgmtServiceURL({
                    service: 'vendor_association_and_deassociation',
                    userid: this.props.userType ? this.props.userType.subType.ein : ' '
                })

                let frqVenAssociation = this.getCreateFreqVendorAssociationBody();

                const graphURL = getSvcServiceURL({ service: 'graph' });
                const query = GraphQlQuery.isFreqVendorAssociationExists("ASSOCIATED_EIN_" + this.props.userType.subType.ein, "FRQ_" + this.props.parentCompany.ein);



                LoadingHelper.inFlight();
                axios.put(baseUrL, this.getAssociateFeqVendorReqBody(), { cdcType: "docmanagement" }).then((res) => {

                    //-------------------------------                   
                    axios.post(graphURL, query, { cdcType: "servicing" })
                        .then((res) => {
                            console.log(res);
                            const data = res.data.data;
                            if (data && data.getProfileList.profiles[0]) {
                                if (data.getProfileList.profiles[0].parentEIN !== "FRQ_" + this.props.parentCompany.ein && data.getProfileList.profiles[0].userID !== "ASSOCIATED_EIN_" + this.props.userType.subType.ein) {
                                    this.associateFreqVendor(frqVenAssociation);
                                }
                            }
                            LoadingHelper.outFlight();
                            let path = '/ManageUser/confirm-user';
                            this.props.history.push(path);
                        }).catch(error => { // ERROR means the record 
                            // LoadingHelper.outFlight();
                            //---------------------
                            this.associateFreqVendor(frqVenAssociation);

                            //------------------
                        });

                    //-------------------------------

                }).catch((error) => {
                    LoadingHelper.outFlight();
                    this.setState((state) => {
                        return {
                            isProfileSaveError: true
                        }
                    });
                });
            } else { // NOT LISTED VENDOR Creation
                let baseUrL = getAuthServiceURL({ service: 'createProspect' });
                try {

                    LoadingHelper.inFlight();
                    let data = this.getCreateQueryBody();
                    if (this.props.userType && this.props.userType.subType) {
                        if (this.props.userType.subType.parentCompanyName === "Not Listed" && this.props.userType.type === "Vendor") {
                            data.userDetail['hostCompanyThatCreatesUnlistedVendor'] = {
                                "hostEIN": this.props.parentCompany.ein,
                                "hostCompanyName": this.props.parentCompany.parentCompanyName,
                                "hostCompanyLegalEntityID": this.props.parentCompany.parentCompanyLegalEntityID
                            };
                            data.entitlement.vendorRelationship = [
                                {
                                    "vendorName": "Non Listed",
                                    "vendorNumber": "Non Listed",
                                    "vendorLegalEntityID": "Not Listed",
                                    "vendorRole": "Site Admin"
                                }
                            ];
                        }
                    }

                    if (data.userDetail.intent === 'CUST_SITEADMIN_ADDS_UNLISTED_VENDOR' && data.userDetail.parentEIN === 'Not Listed' &&
                        data.userDetail.parentCompanyName === '') {
                        data.userDetail.parentCompanyName = 'Not Listed';
                    }

                    const query = GraphQlQuery.getProfileListWithEmail(data.userDetail.email);
                    const graphURL = getSvcServiceURL({ service: 'graph' });

                    let associateUnlistedVendorCoreUrL = getDocumentmgmtServiceURL({
                        service: 'vendor_association_and_deassociation',
                        userid: this.props?.userType?.subType?.ein || ' '
                    })
                    this.setState((state) => {
                        return {
                            maxLimitExeededError: false
                        }
                    });
                    axios.post(graphURL, query, { cdcType: "servicing" })
                        .then((res) => {
                            console.log('TempList Parent Company ::', res.data);
                            //bodyForProfileEdit.data.intent = "CUST_SITEADMIN_ASSOCIATES_UNLISTED_VENDOR";
                            if (res.data.data.getProfileList.profiles.length > 0) {
                                if (res.data.data.getProfileList.profiles[0].parentEIN === "Not Listed") {
                                    this.setState((state) => {
                                        return {
                                            isUnlistedProfileAssociationSaveError: false
                                        }
                                    });
                                    let reqVendorAssociation = this.getEditQueryBodyForUnlistedVendor();
                                    reqVendorAssociation.data.profileID = res.data.data.getProfileList.profiles[0].profileID;
                                    //if (res.data.getProfileList.profiles[0].parentEIN !== "FRQ_" + this.props.parentCompany.ein && res.data.getProfileList.profiles[0].userID !== "ASSOCIATED_EIN_" + this.props.userType.subType.ein) {
                                    axios.post(getSvcServiceURL({ service: 'update' }), reqVendorAssociation, { cdcType: "servicing_Update" })
                                        .then((res) => {
                                            console.log('RESPONSE STATUS:', res.status)
                                            console.log('RESPONSE Text:', res.statusText)
                                            let assocaiteUnlistedVendorBody = this.getAssociateNonListedVendorReqBody();
                                            assocaiteUnlistedVendorBody.vendorProfileId = reqVendorAssociation.data.profileID;
                                            //------ASSOCIATE UNLISTED VENDOR WITH CORE 
                                            axios.put(associateUnlistedVendorCoreUrL, assocaiteUnlistedVendorBody, { cdcType: "docmanagement" }).then((res) => {

                                                LoadingHelper.outFlight();
                                                let path = '/ManageUser/confirm-user';
                                                this.props.history.push(path);

                                            }).catch((error) => {
                                                LoadingHelper.outFlight();
                                                this.setState((state) => {
                                                    return {
                                                        isProfileSaveError: true
                                                    }
                                                });
                                            });

                                            //------ASSOCIATED UNLISTED VENDOR WITH CORE                          

                                            LoadingHelper.outFlight();
                                            let path = '/ManageUser/confirm-user';
                                            this.props.history.push(path);

                                        }).catch((error) => {
                                            LoadingHelper.outFlight();
                                            this.setState((state) => {
                                                return {
                                                    isProfileSaveError: true
                                                }
                                            });
                                        });
                                } else {//SKK-UNLISTED
                                    this.setState((state) => {
                                        return {
                                            isUnlistedProfileAssociationSaveError: true
                                        }
                                    });
                                    transmitIframeMessage.scrollUp();
                                }

                            }
                            LoadingHelper.outFlight();
                            if (!this.state.isUnlistedProfileAssociationSaveError) {
                                let path = '/ManageUser/confirm-user';
                                this.props.history.push(path);
                            }
                        }).catch((error) => {

                            axios.post(baseUrL, data, { cdcType: "auth" }).then((res) => {
                                let response=res;
                                // Karthik If feature_app_access is enabled
                                if(flags.includes("feature_app_access") && response.status===201 && this.props.userDetails.profileRole.toLowerCase() === 'contributor'){
                                LoadingHelper.outFlight();
                                this.props.history.push({ 
                                    pathname: '/ManageUser/internal-permission',
                                    state: data,
                                    userData:response
                                   });
                                }
                                else{
                                LoadingHelper.outFlight();
                                let path = '/ManageUser/confirm-user';
                                this.props.history.push(path);
                                }
                            }).catch((error) => {
                                LoadingHelper.outFlight();
                                let sizeExeeded = false;
                                for (let i = 0; i < data.entitlement.permissions[0].parentEntitys.length; i++) {
                                    try {
                                        if (data.entitlement.permissions[0].parentEntitys[i].deals !== undefined) {
                                            if (data.entitlement.permissions[0].parentEntitys[i].deals.length > 50) {
                                                sizeExeeded = true;
                                                break;
                                            }
                                        }
                                        if (data.entitlement.permissions[0].parentEntitys[i].reportingEntitys !== undefined) {
                                            if (data.entitlement.permissions[0].parentEntitys[i].reportingEntitys.length > 50) {
                                                sizeExeeded = true;
                                                break;
                                            }
                                        }

                                    } catch (error) {
                                        //Limit
                                    }
                                }
                                if (error.response) {
                                    if (error.response.status && error.response.status === 409) {
                                        alert('Email Address Already Exists');
                                    } else {
                                        if (sizeExeeded) {
                                            this.setState((state) => {
                                                return {
                                                    maxLimitExeededError: true
                                                }
                                            });
                                        } else {
                                            this.setState((state) => {
                                                return {
                                                    isProfileSaveError: true
                                                }
                                            });
                                        }
                                    }
                                } else {
                                    if (sizeExeeded) {
                                        this.setState((state) => {
                                            return {
                                                maxLimitExeededError: true
                                            }
                                        });
                                    } else {
                                        this.setState((state) => {
                                            return {
                                                isProfileSaveError: true
                                            }
                                        });
                                    }
                                }
                                transmitIframeMessage.scrollUp();
                            });
                        });
                } catch (error) {
                    console.log(error)
                }

            }
        }

    }

    resetUnlistedNotification = () => {
        this.setState({
            isUnlistedProfileAssociationSaveError: false
        })
    }
    resetMaxLimitError = () => {
        this.setState({
            maxLimitExeededError: false
        })
    }

    getCreateQueryBody() {
        let entitlements = [this.entitlement];
        if (this.props.isFrequentVendor || (this.props.parentCompany?.businessProfileType?.toLowerCase() === "vendor" || this.props.parentCompany?.businessProfileType?.toLowerCase() === "specialvendor")) {
            if (this.props.userDetails && this.props.userDetails.profileRole.toLowerCase() === 'contributor') {
                entitlements = this.getEntitlement.getVendorEntitlementBodyForApi();
            }
            else {
                entitlements = [];

            }
        } else if (this.props.isAssignAll) {
            const fullServerEntitlementResponse = this.props.entitlement.entitlementServerResponse;
            fullServerEntitlementResponse.allDealsFlag = "true";
            fullServerEntitlementResponse.allReportingEntitysFlag = "true";
            fullServerEntitlementResponse.allCategoriesFlag = "true";
            this.props.entitlement.entitlementServerResponse.role = this.props.userDetails.profileRole;
            entitlements = [this.props.entitlement.entitlementServerResponse]
        } else if ((this.props.parentCompany?.businessProfileType?.toLowerCase() === "vendor"
            || this.props.parentCompany?.businessProfileType?.toLowerCase() === "specialvendor") &&
            this.props.userDetails.profileRole.toLowerCase() === 'site administrator') {
            entitlements = [];

        }
        let allPermissonSelected = false;
        if (this.isVendorContributor() && this.props.isAssignAll) {
            allPermissonSelected = true;
        }
        if (this.isVendorSiteAdmin()) {
            allPermissonSelected = true;
        }
        return {
            "adminDetail": {
                "adminID": this.props.currentUser.sub,
                "adminRole": this.props.currentUser.custom_data.role
            },
            "userDetail": {
                "intent": this.getIntent(),
                "firstName": this.firstName,
                "lastName": this.lastName,
                "middleName": this.middleName,
                "email": this.email,
                "phoneNumbers": {
                    "mobilePhone": this.mobilePhone,
                    "businessPhone": this.businessPhone
                },
                "mnoBypass": this.props.userDetails.mnoBypass,
                "address": {
                    "addressLine1": this.street,
                    "addressLine2": this.apt,
                    "city": this.city,
                    "state": this.props.userDetails.address.state,
                    "country": this.props.userDetails.address.country,
                    "zipCode": this.zipCode
                },
                ...this.getParentCompanyDetails(),
                "profileRole": this.props.userDetails.profileRole,
                "status": "Pending",
                "usbRelationship": this.getUSBRelationship(),
                "vendorSelection": this.vendorDetails(),
                "createdBy": this.props.currentUser.sub,
                "lastEditedBy": this.props.currentUser.sub,
                "sourceType": "CDC"
            },
            "entitlement": {
                "vendorRelationship": this.getFrequentVendorRelationship(),
                "source": "CDC",
                "permissions": [
                    {
                        "name": "CDC",
                        "parentEntitys": allPermissonSelected ? [] : entitlements
                    }
                ]
            }
        }
    }

    getEditQueryBodyForUnlistedVendor() {
        return {
            "requestType": {
                "serviceType": "CDC_PROF_MAINT",
                "serviceSubType": "EDIT_PROFILE"
            },
            "data": {
                "intent": "CUST_SITEADMIN_ASSOCIATES_UNLISTED_VENDOR",
                "firstName": this.firstName,
                "lastName": this.lastName,
                "middleName": this.middleName,
                "email": this.email,
                "phoneNumbers": {
                    "mobilePhone": this.mobilePhone,
                    "businessPhone": this.businessPhone
                },
                "address": {
                    "addressLine1": this.street,
                    "addressLine2": this.apt,
                    "city": this.city,
                    "state": this.props.userDetails.address.state,
                    "country": this.props.userDetails.address.country,
                    "zipCode": this.zipCode
                },
                ...this.getParentCompanyDetails(),
                "profileRole": this.props.userDetails.profileRole,
                "status": "Pending",
                "usbRelationship": this.getUSBRelationship(),
                "vendorSelection": this.vendorDetails(),
                "createdBy": this.props.currentUser.sub,
                "lastEditedBy": this.props.currentUser.sub,
                "sourceType": "CDC",
                "hostCompanyThatCreatesUnlistedVendor": {
                    "hostEIN": this.props.parentCompany.ein,
                    "hostCompanyName": this.props.parentCompany.parentCompanyName,
                    "hostCompanyLegalEntityID": this.props.parentCompany.parentCompanyLegalEntityID
                }
            }
        }
    }

    getAssociateFeqVendorReqBody() {
        /*return {
            "name": this.props.parentCompany.parentCompanyName,
            "number": this.props.parentCompany.ein,
            "parentLegalEntityID": this.props.parentCompany.parentCompanyLegalEntityID,
            "role": '', //TODO : Need to ask rahul what role should be passed here. 
            "categories": this.entitlement.categories,
            "deals": this.entitlement.deals,
            "reportingEntitys": this.entitlement.reportingEntitys
        }*/

        let entitlements = [this.entitlement];
        // if (this.props.isAssignAll) {
        //     entitlements = [];
        // }

        if (this.props.userType.type === 'Vendor' && this.props.parentCompany.businessProfileType === 'SpecialVendor' && this.props.isAssignAll) {
            entitlements = this.entitlement;
            //PLEASE DONT REMOVE BELOW CODE>> THIS NEEDS TO BE ENABLED IF Twain assingn all
            /*for(let i=0;i<entitlements.length;i++){   
                let item=entitlements[0];
                for(let j=0;j<item.length;j++){
                    delete entitlements[i][j].deals;
                    delete entitlements[i][j].reportingEntitys;
                    delete entitlements[i][j].categories;
                    if(entitlements[i][j].number==='' && entitlements[i][j].parentLegalEntityID===''){
                        delete entitlements[i][j];
                    }
                }
            }*/

        }
        //SKKSKKSKKSKK

        return {

            "vendorNumber": this.props.userType.subType.ein,
            "vendorName": this.props.userType.subType.parentCompanyName,
            "source": "CDC",
            "activation": true,
            "permissions": [
                {
                    "name": "CDC",
                    "parentEntitys": entitlements
                }
            ]
        }
    }

    getAssociateNonListedVendorReqBody() {
        let entitlements = [this.entitlement];
        if (this.props.isFrequentVendor || (this.props.parentCompany?.businessProfileType?.toLowerCase() === "vendor" || this.props.parentCompany?.businessProfileType?.toLowerCase() === "specialvendor")) {
            if (this.props.userDetails && this.props.userDetails.profileRole.toLowerCase() === 'contributor') {
                entitlements = this.getEntitlement.getVendorEntitlementBodyForApi();
            }
            else {
                entitlements = [];

            }
        } else if (this.props.isAssignAll) {
            entitlements = [this.props.entitlement.entitlementServerResponse]
        } else if ((this.props.parentCompany?.businessProfileType?.toLowerCase() === "vendor" || this.props.parentCompany?.businessProfileType?.toLowerCase() === "specialvendor") && this.props.userDetails.profileRole.toLowerCase() === 'site administrator') {
            entitlements = [];

        }
        return {
            "vendorName": "Non Listed",
            "vendorNumber": "Non Listed",
            "vendorLegalEntityID": "Not Listed",
            "vendorRole": "Not Listed",
            "activation": true,
            "source": "CDC",
            "permissions": [
                {
                    "name": "CDC",
                    "parentEntitys": this.isVendorSiteAdmin() ? [] : entitlements
                }
            ]
        }
    }

    isVendorSiteAdmin() {
        return this.props.parentCompany && (this.props.parentCompany.businessProfileType?.toLowerCase() === 'vendor'
            || this.props.parentCompany.businessProfileType?.toLowerCase() === "specialvendor") && this.props.userDetails
            && this.props.userDetails.profileRole && this.props.userDetails.profileRole.toLowerCase() !== 'contributor';
    }

    isVendorContributor() {
        return this.props.parentCompany && (this.props.parentCompany.businessProfileType?.toLowerCase() === 'vendor'
            || this.props.parentCompany.businessProfileType?.toLowerCase() === "specialvendor") && this.props.userDetails
            && this.props.userDetails.profileRole && this.props.userDetails.profileRole.toLowerCase() === 'contributor';
    }

    getFrequentVendorRelationship() {
        if (this.props.parentCompany && (this.props.parentCompany.businessProfileType?.toLowerCase() === 'vendor' || this.props.parentCompany.businessProfileType?.toLowerCase() === "specialvendor")) {
            return [{
                "vendorName": this.props.parentCompany.parentCompanyName,
                "vendorNumber": this.props.parentCompany.ein,
                "vendorRole": this.props.userDetails.profileRole,
                "vendorLegalEntityID": this.props.parentCompany.parentCompanyLegalEntityID
            }]
        }
        return null;
    }

    getEditQueryBody() {
        return {
            "requestType": {
                "serviceType": "CDC_PROF_MAINT",
                "serviceSubType": "EDIT_PROFILE"
            },
            "data": {
                "intent": "EDIT_PROFILE",
                "profileID": this.props.initialProfileData.profileID,
                "userID": this.props.initialProfileData.userID,
                "firstName": this.firstName,
                "lastName": this.lastName,
                "middleName": this.middleName,
                "email": this.email,
                "phoneNumbers": {
                    "mobilePhone": this.mobilePhone,
                    "businessPhone": this.businessPhone
                },
                "address": {
                    "addressLine1": this.street,
                    "addressLine2": this.apt,
                    "city": this.city,
                    "state": this.props.userDetails.address.state,
                    "country": this.props.userDetails.address.country,
                    "zipCode": this.zipCode
                },
                "parentEIN": this.props.parentCompany.ein,
                "parentCompanyName": this.props.parentCompany.parentCompanyName,
                "parentCompanyLegalEntityID": this.props.parentCompany.parentCompanyLegalEntityID,
                "profileRole": this.props.userDetails.profileRole,
                //"status": "Active", cant hard code //TODO:// Need to put a logic for chaging the status 
                "usbRelationship": this.getUSBRelationship(),
                "lastEditedBy": this.props.currentUser.sub,
                "sourceType": "CDC"
            }
        }
    }

    getCreateFreqVendorAssociationBody() {
        return {
            "requestType": {
                "serviceType": "CDC_PROF_MAINT",
                "serviceSubType": "CREATE_PROFILE"
            },
            "data": {
                "intent": "CUST_SITEADMIN_ADDS_FREQ_VENDOR",
                "firstName": "",
                "lastName": this.props.userType.subType.parentCompanyName,
                "userID": "ASSOCIATED_EIN_" + this.props.userType.subType.ein, // HACK TO QUERY THIS LATER FOR CHECKING IF THIS RELATIONSHIP ALREADY EXISTING 
                "middleName": "",
                "email": "",
                "phoneNumbers": {
                    "mobilePhone": this.mobilePhone,
                    "businessPhone": this.businessPhone
                },
                "address": {
                    "addressLine1": this.street,
                    "addressLine2": this.apt,
                    "city": this.city,
                    "state": "",
                    "country": "",
                    "zipCode": ""
                },
                "parentEIN": "FRQ_" + this.props.parentCompany.ein, // ADDED FRQ becuse this profile should not be shown along with other regular profile
                "parentCompanyName": this.props.parentCompany.parentCompanyName,
                "parentCompanyLegalEntityID": this.props.parentCompany.parentCompanyLegalEntityID,
                "profileRole": "Contributor (Vendor)", // Changed by Sridevi : changed the role from "FrequentVendorAssociation" to "Contributor (Vendor)" as the vendor association default role should be "Contributor (Vendor)"
                "status": "Active",   // THis is not actual User Profile.. THis is dummy Profile which doesnt need enrollment. So it is created as Active
                "usbRelationship": this.getUSBRelationship(),
                "lastEditedBy": this.props.currentUser.sub,
                "sourceType": "CDC",
                "createdBy": this.props.currentUser.sub,
                "vendorSelection": {
                    "businessProfileID": this.props.userType.subType.businessProfileID,
                    "businessProfileType": this.props.userType.subType.businessProfileType,
                    "ein": this.props.userType.subType.ein
                }
            }
        }
    };

    validateEmail(email) {
        if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
            return (true)
        }
        alert("You have entered an invalid email address!")
        return (false)
    }

    getIntent() {
        let intent = "";
        if (this.props.currentUser.custom_data.role.toLowerCase().includes(UserRoleConfig.superAdmin)) {
            intent = intent + "SUPERADMIN_ADDS_";
        } else if (this.props.isFrequentVendor) {
            intent = intent + "FREQ_VENDOR_SITEADMIN_ADDS_"
        } else {
            intent = intent + "CUST_SITEADMIN_ADDS_";
        }
        if (this.props.userDetails?.profileRole?.toLowerCase() === "site administrator") {
            intent = intent + "SITEADMIN";
        } else if (this.props.userDetails?.profileRole?.toLowerCase() === "contributor") {
            intent = intent + "CONTRIBUTOR";
        } else if (this.props.parentCompany.businessProfileType?.toLowerCase() === 'vendor' || this.props.parentCompany.businessProfileType?.toLowerCase() === "specialvendor") {
            if (this.props.userDetails?.profileRole?.toLowerCase() === "site administrator") {
                intent = intent + "FREQ_VENDOR";
            } else {
                intent = intent + "FREQ_VENDOR_CONTRIBUTOR";
            }
        } else if (this.props.userDetails?.profileRole?.toLowerCase() === 'contributor (vendor)') {
            intent = intent + "UNLISTED_VENDOR";
        } else if (this.props.userType?.type?.toLowerCase() === 'vendor') {
            intent = intent + "FREQ_VENDOR";
        }
        return intent;
    }

    getUSBRelationship() {
        //SKK
        if (this.props.currentUser.custom_data.role.toLowerCase().includes(UserRoleConfig.superAdmin)) {
            if (this.props.parentCompany && this.props.parentCompany.businessProfileType === "Customer") {
                if (this.props.userDetails !== undefined) {
                    return 'Company_' + this.props.userDetails.profileRole.replace(/ /g, "_").replace("(Vendor)", "") + "__Contact";
                } else {
                    return 'Frequent_Vendor_Contributor__Association';
                }
            } else if (this.props.parentCompany && (this.props.parentCompany.businessProfileType?.toLowerCase() === 'vendor' || this.props.parentCompany.businessProfileType?.toLowerCase() === "specialvendor")) {
                return 'Frequent_Vendor_' + this.props.userDetails.profileRole.replace(/ /g, "_").replace("(Vendor)", "") + "__Contact";
            }
        } else if (this.props.currentUser.custom_data.role.toLowerCase().includes(UserRoleConfig.customer) && !this.props.isFrequentVendor) {

            if (this.props.userType && this.props.userType.type === 'Employee') {
                return 'Company_' + this.props.userDetails.profileRole.replace(/ /g, "_").replace("(Vendor)", "") + "__Contact";
            } else if (this.props.userType && this.props.userType.type === 'Vendor') {
                if (this.props.userType.subType.parentCompanyName.toLowerCase().includes("not listed")) {
                    return 'Unlisted_Vendor_Contributor__Contact';
                } else {//  THE DUMMY PROFILE for freq vendor Association 
                    return 'Frequent_Vendor_Contributor__Association'; // PLEASE NOTE THAT THE LAST WORD IS "ASSOCIATION" WHICH DENOTES THAT THIS IS NOT AN ACTUAL PROFILE
                }
            }
        } else if (this.props.currentUser.custom_data.role.toLowerCase().includes(UserRoleConfig.frequentVendor) || this.props.isFrequentVendor) {
            if (this.props.parentCompany && this.props.parentCompany.businessProfileType?.toLowerCase() === "specialvendor") {
                if (this.props.userType && this.props.userType.type === 'Employee') {
                    return 'Company_' + this.props.userDetails.profileRole.replace(/ /g, "_").replace("(Vendor)", "") + "__Contact";
                } else if (this.props.userType && this.props.userType.type === 'Vendor') {
                    if (this.props.userType.subType.parentCompanyName.toLowerCase().includes("not listed")) {
                        return 'Unlisted_Vendor_Contributor__Contact';
                    } else {//  THE DUMMY PROFILE for freq vendor Association 
                        return 'Frequent_Vendor_Contributor__Association'; // PLEASE NOTE THAT THE LAST WORD IS "ASSOCIATION" WHICH DENOTES THAT THIS IS NOT AN ACTUAL PROFILE
                    }
                }
            } else {
                return 'Frequent_Vendor_' + this.props.userDetails.profileRole.replace(/ /g, "_").replace("(Vendor)", "") + "__Contact";
            }
        }
    }

    componentDidMount() {
        this.companyDetails = this.props.parentCompany ? this.props.parentCompany : this.props.vendorCompany;
        if (this.props.userType && this.props.userType.type === 'Vendor' && this.props.entitlement.assignAll) {
            this.entitlement = this.props.entitlement.entitlementServerResponse;
        } else if (this.props.userDetails && this.props.userDetails.profileRole &&
            (this.props.userDetails.profileRole.toLowerCase() === 'site administrator')) {
            this.getAllEntitmentsForEin();
        } else {
            if (this.props.entitlement.entitlement) {
                if (this.props.entitlement.legalEntity) {
                    this.entitlement.allReportingEntitysFlag = this.props.entitlement.isLegalAllSelected.toString();
                    this.entitlement.allDealsFlag = "false";
                    this.entitlement.reportingEntitys = this.props.entitlement.entitlement.selectedLegalEntityList.map(d => {
                        return {
                            key: d.key,
                            value: d.value
                        }
                    })

                } else if (this.props.entitlement.dealEntity) {
                    this.entitlement.allDealsFlag = this.props.entitlement.isDealAllSelected.toString();
                    this.entitlement.allReportingEntitysFlag = "false"
                    this.entitlement.deals = this.props.entitlement.entitlement.selectedDealEntityList.map(d => {
                        return {
                            key: d.key,
                            value: d.value
                        }
                    }
                    )
                }
                this.entitlement.allCategoriesFlag = this.props.entitlement.isAllCategoriesSelected.toString();
                if (this.props.entitlement.legalEntity && this.props.entitlement.entitlement.selectedEntiyDocumentList !== undefined) {
                    this.entitlement.categories = this.props.entitlement.entitlement.selectedEntiyDocumentList.map(d => {
                        return {
                            key: d.key,
                            value: d.value
                        }
                    }
                    )
                }
                if (this.props.entitlement.dealEntity && this.props.entitlement.entitlement.selectedDealDocumentList !== undefined) {
                    this.entitlement.categories = this.props.entitlement.entitlement.selectedDealDocumentList.map(d => {
                        return {
                            key: d.key,
                            value: d.value
                        }
                    }
                    )
                }
                this.entitlement.name = (this.companyDetails !== undefined) && (!this.companyDetails.businessProfileType || this.companyDetails.businessProfileType === 'Customer') ? this.companyDetails.parentCompanyName : '';
                this.entitlement.number = (this.companyDetails !== undefined) && (!this.companyDetails.businessProfileType || this.companyDetails.businessProfileType === 'Customer') ? this.companyDetails.ein : '';
                this.entitlement.parentLegalEntityID = (this.companyDetails !== undefined && this.companyDetails.parentCompanyLegalEntityID && (!this.companyDetails.businessProfileType || this.companyDetails.businessProfileType === 'Customer')) ? this.companyDetails.parentCompanyLegalEntityID : '';
                this.entitlement.role = (this.companyDetails !== undefined && (!this.companyDetails.businessProfileType || this.companyDetails.businessProfileType === 'Customer')) ? this.props.userDetails ? this.props.userDetails.profileRole : '' : '';
            }
        }
        console.log("Assign Permission data in Review and Submit:", this.props.userDetails);
        console.log("Assign permission selected all permission:", this.props.entitlement)
    }


    getAllEntitmentsForEin() {

        this.entitlement.name = this.companyDetails.parentCompanyName;
        this.entitlement.number = this.companyDetails.ein;
        this.entitlement.parentLegalEntityID = this.companyDetails.parentCompanyLegalEntityID ?
            this.companyDetails.parentCompanyLegalEntityID : this.companyDetails.parentCompanyLegalEntityID
        this.entitlement.role = this.props.userDetails.profileRole;

    }

    displayCompanyInformation() {
        return this.street || this.apt || this.userState || this.zipCode || this.country || this.city
    }

    displayMessage() {
        if (this.props.userDetails && Object.keys(this.props.userDetails).length > 0) {
            return this.firstName + " " + this.lastName;
        } else if (this.props.userType.type === "Vendor") {
            return this.props.userType.subType.parentCompanyName;
        }
    }

    getBackNavigationUrl = () => {
        this.props.dispatch({
            type: "PROFILE_EDIT_INFO_CLICK",
            payload: true
        });
        if (this.props.currentUser.custom_data.role.toLowerCase().includes(UserRoleConfig.superAdmin)) {
            if (this.props.userDetails && this.props.userDetails.profileRole === 'Contributor') {
                return '/ManageUser/permissions';
            }
            return '/ManageUser/emp-reg';
        } else if (this.props.currentUser.custom_data.role.toLowerCase().includes(UserRoleConfig.customer)) {
            if (this.props.userType && this.props.userType.type === "Vendor" && this.props.userType.subType.parentCompanyName !== "Not Listed") {
                return '/ManageUser/permissions';
            } else {
                if (this.props.userDetails && (this.props.userDetails.profileRole === 'Contributor' || this.props.userDetails.profileRole === "Contributor (Vendor)")) {
                    return '/ManageUser/permissions';
                }
                return '/ManageUser/emp-reg';
            }
        }
    }

    toSentenceCase = (str) => {
        let val = str.toLowerCase();
        return val.replace(val.charAt(0), val.charAt(0).toUpperCase());
    }

    render() {


        const continueBtnProps = {
            [componentConfig.USBButton.variant]: "primary",
            [componentConfig.USBButton.addClasses]: "sp-button-spacing",
            // [componentConfig.USBButton.handleClick]: this.onContinue
        };

        const cancelBtnProps = {
            [componentConfig.USBButton.variant]: "secondary",
            [componentConfig.USBButton.addClasses]: "sp-button-spacing",
            [componentConfig.USBButton.handleClick]: this.onCancelClick
        };

        return (

            <FeatureFlagsContext.Consumer>
            {(flags)=>

            <React.Fragment>
                {this.state.loading ? <Loader /> : null}
                <ProgressIndicator currentStep={4} />
                <div className="sp-container-fluid sp-progress-content-space">
                    <div className="sp-row">
                        <div className="sp-col-6 sp-offset-left-3">
                            <NavigateBack url={this.getBackNavigationUrl()} />

                            <div>
                                <h1 className="sp-page-title sp-mr-b-16">Review and submit</h1>
                                {
                                    this.state.isProfileSaveError ?
                                        <USBNotification
                                            addClasses="sp-width-100 sp-mr-b-16"
                                            variant={"error"}
                                            handleClose={true} >
                                            There was a problem processing your request. Please try again.
                                        </USBNotification> : null}
                                {
                                    this.state.maxLimitExeededError ?
                                        <USBNotification
                                            addClasses="sp-width-100 sp-mr-b-16"
                                            variant={"error"}
                                            handleClose={this.resetMaxLimitError} >
                                            Please edit the <strong>Permissions</strong> and select <strong>Assign to all</strong>  to add this user.
                                        </USBNotification> : null
                                }
                                {
                                    this.state.isUnlistedProfileAssociationSaveError ? // SOORAJ
                                        <USBNotification
                                            addClasses="sp-width-100 sp-mr-b-16"
                                            variant={"error"}
                                            handleClose={this.resetUnlistedNotification} >
                                            This email address is already associated with another user. Please enter a different email.
                                        </USBNotification> : null
                                }

                                <div className="sp-body-medium body-medium-margin sp-mr-b-24">
                                    Does everything look correct? Take a minute to review {this.displayMessage()}'s user information and permissions.
                                </div>

                            </div>
                            {this.props.userDetails && Object.keys(this.props.userDetails).length > 0 ?
                                <div className="sp-mr-b-32">
                                    <div className="sp-flex sp-justify-content sp-mr-b-12">

                                        <h2><strong>User information </strong></h2>

                                        <div className=" sp-action-button-wrapper">
                                            <div id="usrProfileEditLink" onClick={this.afterDispatchRedirectToEmpRegistration}>
                                                <USBLink href="#" linkType="basic">Edit</USBLink>
                                            </div></div>

                                    </div>

                                    <div>

                                        <h3 className="sp-superhead sp-mr-b-8"><strong>Name and role</strong></h3>

                                    </div>
                                    <div className="sp-row sp-justify-content sp-mr-b-12">

                                        <div className="sp-col-4">
                                            <label className="sp-caption">First name</label>
                                            <div className="sp-body-medium">{this.firstName}</div>
                                        </div>
                                        {this.middleName ?
                                            <div className="sp-col-4">
                                                <label className="sp-caption">Middle name</label>
                                                <div className="sp-body-medium">{this.middleName}</div>
                                            </div> : null
                                        }
                                        <div className="sp-col-4">
                                            <label className="sp-caption">Last name</label>
                                            <div className="sp-body-medium">{this.lastName}</div>
                                        </div>

                                    </div>
                                    <div>

                                        <div >
                                            <label className="sp-caption">Role</label>
                                            <div className="sp-body-medium sp-mr-b-32">{this.role}</div>
                                        </div>

                                    </div>
                                    {this.email || this.mobilePhone ?

                                        <div>
                                            <h3 className="sp-superhead sp-mr-b-8"><strong>Email and phone</strong></h3>

                                            {this.email ?
                                                <div className=" sp-mr-b-12">
                                                    <label className="sp-caption">Email address</label>
                                                    <div className="sp-body-medium">{this.email}</div>
                                                </div> : null
                                            }

                                            {this.mobilePhone ?
                                                <div className=" sp-mr-b-12">
                                                    <label className="sp-caption">Mobile Phone number</label>
                                                    <div className="sp-body-medium">{this.mobilePhone}</div>


                                                </div> : null
                                            }

                                        </div> : null}
                                    {this.displayCompanyInformation() ?
                                        <div>
                                            <div >
                                                <h3 className="sp-superhead sp-mr-b-8"><strong>Company information</strong></h3>
                                            </div>

                                            {this.props.userDetails && this.props.userDetails?.parentCompanyName ?
                                                <div className="sp-mr-b-12">
                                                    <label className="sp-caption">Company name</label>
                                                    <div className="sp-body-medium">{this.props?.userDetails?.parentCompanyName}</div>
                                                </div> : null
                                            }

                                            {this.businessPhone ?
                                                <div className=" sp-mr-b-12">
                                                    <label className="sp-caption">Business phone number</label>
                                                    <div className="sp-body-medium">{this.businessPhone}</div>
                                                </div> : null
                                            }
                                            {this.street ?
                                                <div className=" sp-mr-b-12">
                                                    <label className="sp-caption">Street address</label>
                                                    <div className="sp-body-medium">{this.street}</div>
                                                </div> : null
                                            }
                                            {this.apt ?
                                                <div className=" sp-mr-b-12">
                                                    <label className="sp-caption">Apt./suite/unit</label>
                                                    <div className="sp-body-medium">{this.apt}</div>
                                                </div> : null
                                            }

                                            <div >
                                                {this.city ?
                                                    <div className="sp-mr-b-12">
                                                        <label className="sp-caption">City</label>
                                                        <div className="sp-body-medium">{this.city}</div>
                                                    </div> : null
                                                }
                                                {this.userState ?
                                                    <div className="sp-mr-b-12">
                                                        <label className="sp-caption">State</label>
                                                        <div className="sp-body-medium">{this.userState}</div>
                                                    </div> : null
                                                }
                                                {this.zipCode ?
                                                    <div className="sp-mr-b-12">
                                                        <label className="sp-caption">ZIP</label>
                                                        <div className="sp-body-medium">{this.zipCode}</div>
                                                    </div> : null
                                                }
                                            </div>
                                            {this.country ?
                                                <div className=" sp-mr-b-12">
                                                    <label className="sp-caption">Country</label>
                                                    <div className="sp-body-medium">{this.country}</div>
                                                </div> : null
                                            }
                                        </div> : null}
                                </div> : null
                            }
                            <div className="sp-flex sp-justify-content sp-mr-b-16">
                                <h3 > <strong>Permissions</strong></h3>

                                <div className=" sp-action-button-wrapper">
                                    <div id="usrPermissionEditLink" onClick={this.afterDispatchRedirectToAssignPermissions}>
                                        <USBLink href="#" linkType="basic">Edit</USBLink>
                                    </div></div>

                            </div>

                            {(this.props.userDetails && this.props.userDetails.profileRole && this.props.userDetails.profileRole.toLowerCase() === 'site administrator') || this.props.entitlement.assignAll
                                ?
                                <div>You've given {this.firstName} {this.lastName} permission to access all documents and reporting information.
                                </div> :
                                <div>
                                    {this.props.entitlement.legalEntity ?
                                        <div className="sp-mr-b-32 sp-pt-8">
                                            <div className="sp-superhead sp-mr-b-12 "><h3>Legal entity</h3></div>
                                            <div className="sp-body-medium">
                                                {
                                                    this.props.entitlement.isLegalAllSelected && 'All'
                                                }
                                                <div className={!this.props.entitlement.isLegalAllSelected && this.props.entitlement.entitlement.selectedLegalEntityList && this.props.entitlement.entitlement.selectedLegalEntityList.length > this.maxEntityLength ? "select-entities-wrapper sp-body-medium sp-my-profile-permission-scroll" : "sp-body-medium"}>
                                                    {!this.props.entitlement.isLegalAllSelected && this.props.entitlement.entitlement.selectedLegalEntityList.map(ll => {
                                                        return <div> {ll.value} </div>
                                                    })}
                                                </div>
                                                {!this.props.entitlement.isLegalAllSelected && this.props.entitlement.entitlement.selectedLegalEntityList && this.props.entitlement.entitlement.selectedLegalEntityList.length > this.maxEntityLength ?
                                                    <div className="sp-caption">{this.props.entitlement.entitlement.selectedLegalEntityList.length} Legal entity </div> : ''}
                                            </div>
                                        </div> : null
                                    }

                                    {this.props.entitlement.dealEntity ?
                                        <div className="sp-mr-b-32">
                                            <div className="sp-superhead sp-mr-b-12"> <h3>Deal</h3> </div>
                                            <div className="sp-body-medium">
                                                {
                                                    this.props.entitlement.isDealAllSelected && 'All'
                                                }
                                                <div className={!this.props.entitlement.isDealAllSelected && this.props.entitlement.entitlement.selectedDealEntityList && this.props.entitlement.entitlement.selectedDealEntityList.length > this.maxEntityLength ? "select-entities-wrapper sp-body-medium sp-my-profile-permission-scroll" : "sp-body-medium"}>
                                                    {!this.props.entitlement.isDealAllSelected && this.props.entitlement.entitlement.selectedDealEntityList.map(dl => {
                                                        return <div> {dl.value} </div>
                                                    })}
                                                </div>
                                                {!this.props.entitlement.isDealAllSelected && this.props.entitlement.entitlement.selectedDealEntityList && this.props.entitlement.entitlement.selectedDealEntityList.length > this.maxEntityLength ?
                                                    <div className="sp-caption">{this.props.entitlement.entitlement.selectedDealEntityList.length} Deals </div> : ''}
                                            </div>
                                        </div> : null
                                    }
                                    {
                                        this.props.entitlement.entitlement ?
                                            <div className="sp-mr-b-32">
                                                <div className="sp-superhead sp-mr-b-12"><h3>Document type</h3></div>
                                                {
                                                    this.props.entitlement.isAllCategoriesSelected ? 'All' :
                                                        <>
                                                            <div className={(this.props.entitlement.entitlement.selectedEntiyDocumentList && this.props.entitlement.entitlement.selectedEntiyDocumentList.length > this.maxEntityLength) ||
                                                                (this.props.entitlement.entitlement.selectedDealDocumentList && this.props.entitlement.entitlement.selectedDealDocumentList.length > this.maxEntityLength) ?
                                                                "select-entities-wrapper sp-body-medium sp-my-profile-permission-scroll" : "sp-body-medium"} >
                                                                {this.props.entitlement.entitlement.selectedEntiyDocumentList ? this.props.entitlement.entitlement.selectedEntiyDocumentList.map(dl => {
                                                                    if(dl.key && (dl.key+"").includes("-")){
                                                                        return <div>{`${this.toSentenceCase(dl.key)}`}</div>
                                                                    } else if(dl.key && dl.value && dl.key!=dl.value){
                                                                        return <div> {`${this.toSentenceCase(dl.key)} - ${this.toSentenceCase(dl.value)}`} </div>
                                                                    } else {
                                                                        return <div>{`${this.toSentenceCase(dl.key)}`}</div>
                                                                    }
                                                                }) : null}

                                                                {this.props.entitlement.entitlement.selectedDealDocumentList ? this.props.entitlement.entitlement.selectedDealDocumentList.map(dl => {
                                                                    if(dl.key && (dl.key+"").includes("-")){
                                                                        return <div>{`${this.toSentenceCase(dl.key)}`}</div>
                                                                    } else if(dl.key && dl.value && dl.key!=dl.value){
                                                                        return <div> {`${this.toSentenceCase(dl.key)} - ${this.toSentenceCase(dl.value)}`} </div>
                                                                    } else {
                                                                        return <div>{`${this.toSentenceCase(dl.key)}`}</div>
                                                                    }
                                                                }) : null}
                                                            </div>
                                                            {(this.props.entitlement.entitlement.selectedEntiyDocumentList && this.props.entitlement.entitlement.selectedEntiyDocumentList.length > this.maxEntityLength) ||
                                                                (this.props.entitlement.entitlement.selectedDealDocumentList && this.props.entitlement.entitlement.selectedDealDocumentList.length > this.maxEntityLength) ?
                                                                <div className="sp-caption">{(this.props.entitlement.entitlement.selectedEntiyDocumentList && this.props.entitlement.entitlement.selectedEntiyDocumentList.length) || (this.props.entitlement.entitlement.selectedDealDocumentList && this.props.entitlement.entitlement.selectedDealDocumentList.length)} Documents </div> : ''}
                                                        </>
                                                }
                                            </div> : null
                                    }
                                </div>
                            }

                            <hr></hr>
                            <div className="sp-action-button-wrapper sp-mr-t-32 ">
                                <USBButton id={'prfEdtRevCancelBtn'}  {...cancelBtnProps}>Cancel</USBButton>
                                <USBButton id={'prfEdtRevContinueBtn'} {...continueBtnProps} handleClick={(e)=>this.onContinue(e,flags.featureDetails)}>Submit</USBButton>
                            </div>
                        </div>
                    </div>
                </div>

            </React.Fragment>
           }
              </FeatureFlagsContext.Consumer>
        );
    }



}

const mapStateToProps = state => {
    return {
        userDetails: state.userProfileInformation.profileData,
        currentUser: state.currentUser.user,
        parentCompany: state.parentCompanyInformation.parentCompany,
        entitlement: state.entitlement,
        initialProfileData: state.userProfileInformation.profileServerData,
        userType: state.userProfileInformation.userType,
        countryList: state.userProfileInformation.countryList,
        vendorCompany: state.parentCompanyInformation.vendorCompany,
        isFrequentVendor: state.currentUser.isFrequentVendor,
        isLegalSelected: state.entitlement.legalEntity,
        isDealSelected: state.entitlement.dealEntity,
        isAssignAll: state.entitlement.assignAll
    };

};

export default connect(mapStateToProps)(ProfileEditReview);


