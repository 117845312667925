//Please dont change anything before confirming with sooraj.

import { store } from '../shared/store';

export const  enableClosingBinders=true;
export const  enableCDCServicing=true;

const defaultEndpoints = {
  apiGee: 'it-api.usbank.com',
  document : 'it-api.usbank.com',
  auth: 'it-api.usbank.com',
  servicing_cdc:'it-api.usbank.com',
  servicing : 'it-api.usbank.com',
  transmit: 'https://it-transec.usbank.com',
  routingKey: 'it1',
  trasmitRoutingKey: 'it1',
  trasmitAK: 'zV2JDobreS0n3GBzU7fhgbN6toHArF6v',
  configAPIURLTrasmit: 'https://it-api.usbank.com/authentication/customer-auth/app-config/v1/config'
}

const isOnEnv = env => {
  const domain = window.location.origin
  return domain.split('.')[0].indexOf(env) !== -1
}

const formEndPointsByConvention = () => {
  if (isOnEnv('localhost') || isOnEnv('dev')) {
    console.log("INSIDE isOnEnv('localhost') || isOnEnv('dev')")
    return defaultEndpoints
  }

  if (isOnEnv('preprod-digitalbanking')) {
    console.log("INSIDE isOnEnv('PREPROD')")
    return {
      apiGee: 'api.usbank.com',
      document: 'api.usbank.com',
      auth: 'api.usbank.com',   
      transmit: 'https://transec.usbank.com',  
      routingKey: 'preprod',
      trasmitAK:'gNShJPNcOlv0A4bbQ3EqJbf52ukCM6lV',
      configAPIURLTrasmit:'https://api.usbank.com/authentication/customer-auth/app-config/v1/config'
    }
  }

  if (isOnEnv('it')) {
    console.log("INSIDE isOnEnv('it')")
    return {
      apiGee: 'it-api.usbank.com',
      document: 'it-api.usbank.com',
      servicing : 'it-api.usbank.com',
      auth: 'it-api.usbank.com',
      transmit: 'https://it-transec.usbank.com',
      routingKey: 'it1',
      trasmitRoutingKey: 'it1',
      trasmitAK: 'zV2JDobreS0n3GBzU7fhgbN6toHArF6v',
      configAPIURLTrasmit: 'https://it-api.usbank.com/authentication/customer-auth/app-config/v1/config'
    }
  }

  if (isOnEnv('uat')) {
    console.log("INSIDE isOnEnv('uat')")
    return {
      apiGee: 'alpha-api.usbank.com', // doc management
      document: 'alpha-api.usbank.com',
      servicing : 'alpha-api.usbank.com', // servicing - update & graph
      auth: 'alpha-api.usbank.com',    
      transmit : 'https://uat1-transec.usbank.com',  
      routingKey: 'uat1',
      trasmitRoutingKey:'uat1',
      trasmitAK:'G6Fl3fdtwBEJXTF8WBFmyLF3xTJO3NiU',
      configAPIURLTrasmit:'https://alpha-api.usbank.com/authentication/customer-auth/app-config/v1/config'
    }
  }
  
  if (isOnEnv('onlinebanking2')) {
    console.log("INSIDE isOnEnv('PREPROD')")
    return {
      apiGee: 'api.usbank.com',
      document: 'api.usbank.com',
      servicing: 'api.usbank.com',
      auth: 'api.usbank.com',   
      transmit: 'https://transec.usbank.com',  
      routingKey: 'preprod',
      trasmitAK:'gNShJPNcOlv0A4bbQ3EqJbf52ukCM6lV',
      configAPIURLTrasmit:'https://api.usbank.com/authentication/customer-auth/app-config/v1/config'
    }
  }

  if (isOnEnv('emp-onlinebanking')) {
    console.log("INSIDE isOnEnv('EMP')")
    return {
      apiGee: 'api.usbank.com',
      document: 'api.usbank.com',
      servicing: 'api.usbank.com',
      auth: 'api.usbank.com',   
      transmit: 'https://transec.usbank.com',     
      routingKey: 'emp',
      trasmitAK:'gNShJPNcOlv0A4bbQ3EqJbf52ukCM6lV',
      configAPIURLTrasmit:'https://api.usbank.com/authentication/customer-auth/app-config/v1/config'
    }
  }
  
  console.log("INSIDE isOnEnv=> PROD")
  return {
    apiGee: 'api.usbank.com',
    document: 'api.usbank.com',    
    servicing: 'api.usbank.com',    
    auth: 'api.usbank.com',
    transmit: 'https://transec.usbank.com',  
    routingKey: 'prod',
    trasmitAK:'gNShJPNcOlv0A4bbQ3EqJbf52ukCM6lV',
    configAPIURLTrasmit:'https://api.usbank.com/authentication/customer-auth/app-config/v1/config'
  }
}

export const isLocal = process.env.NODE_ENV === 'development'


export const endpoints =
  typeof window !== 'undefined' ? formEndPointsByConvention() : defaultEndpoints

export const apiGeeDomain = `https://${endpoints.apiGee}`
export const cdcServicingDomain = `https://${endpoints.servicing}`
export const cdcServicingDomainOld = `https://${endpoints.servicing}`
export const documentMgmtDomain = `https://${endpoints.document}`

export const documentDomain = () => {
  const api = enableCDCServicing==false ? `https://${endpoints.document}` : `https://${endpoints.servicing}`;
  return api;
}

export const authDomain = `${endpoints.auth}`;

const configs = JSON.parse(sessionStorage.getItem("configs"));

export const servicingDomain = ``;
const API_CONST = {
  servicing: {
    update: enableCDCServicing==false ? '/services/customer-management/servicing/v1' : '/document-management/coreservice/user-management/v1/cdcservice' ,
    graph: enableCDCServicing==false ? '/customer-management/graphql/v1' : '/document-management/coreservice/user-management/v1/cdcservice/graphql' ,
  },
  auth: {
    createProspect: configs?.createProspect,
    reactivate: configs?.reactivate,
    deactivate: configs?.deactivate,
    resendInvitation: configs?.resendInvitation,
    unlock: configs?.unlock,
    changePassword : configs?.changePassword,
    updateRole : configs?.updateRole,
    userdetails:  configs?.userdetails,
  },
  documentmgmtApigee: {
    getAllParentCompanies: configs?.getAllParentCompanies,
    getProfilePermissionsforParentEIN: configs?.getProfilePermissionsforParentEIN,
    getProfilePermissionsforUser: configs?.getProfilePermissionsforUser,
    updateProfilePermission: configs?.updateProfilePermission, //to update exisitng user permission
    updatePhoneNumber: configs?.updatePhoneNumber, 
    vendor_association_and_deassociation: configs?.vendor_association_and_deassociation,
    getVendorEntitlements: configs?.getVendorEntitlements,
    getVendorAssignedPermissions: configs?.getVendorAssignedPermissions,
    addFrequenvetOrNonListedVendor: configs?.addFrequenvetOrNonListedVendor,
    associateFrequenvetOrNonListedVendor: configs?.associateFrequenvetOrNonListedVendor,
    
  },
}

export const getTransmitArtifacts = ({item}) => {
  switch (item) {
    case 'trasmitRoutingkey':
      return `${endpoints.trasmitRoutingKey}`;     
    case 'configApi':
      return `${endpoints.configAPIURLTrasmit}`;   
    case 'transmitURL':
      return `${endpoints.transmit}`; 
    case 'transmitAK':
      return `${endpoints.trasmitAK}`;       
    default:
      console.log(`Sorry, we are out of ${item}.`);
  }
  
}

export const getTransmitURL = () => {
  return `${endpoints.transmit}`
}

export const getServicingDomain = ({ service }) => {
  return `${endpoints.servicing}${API_CONST.servicing[service]}`
}

export const getSvcServiceURL = ({ service }) => {
  let tempUrl="";
  if(enableCDCServicing==false){
    tempUrl=`${cdcServicingDomainOld}${API_CONST.servicing[service]}`
  }else
  {
    tempUrl=`${cdcServicingDomain}${API_CONST.servicing[service]}`
  }
  return tempUrl;
}

export const getSvcOldServiceURL = ({ service }) => {
  return `${cdcServicingDomainOld}${API_CONST.servicing[service]}`
}

export const getAuthServiceURL = ({ service }) => {
  return `${apiGeeDomain}${API_CONST.auth[service]}` // APIGEE endpoint
}

export const getDocumentmgmtServiceURL = ({ service, userid }) => {
  const serviceEndpoint = API_CONST.documentmgmtApigee[service]
  const updateUserid = serviceEndpoint && serviceEndpoint.replace('varuserid', userid)
  return `https://${endpoints.apiGee}${updateUserid}`
} 
export const getDocumentmgmtServiceURLuser = ({ service, userid }) => {
  const serviceEndpoint = API_CONST.documentmgmtApigee[service]
  // const updateUserid = serviceEndpoint && serviceEndpoint.replace('{varuserid}', userid)
  return `https://${endpoints.apiGee}${serviceEndpoint}`;
} 

export const getCorrelationId = () => {
 
  let correlationID=store.getState().currentUser.user.custom_data.cid;
  if(correlationID===null || correlationID ==="" || correlationID=== undefined)
     correlationID=store.getState().currentUser.user.dsid;
  return `${store.getState().currentUser.user.sub} ${correlationID}`
}

export const getRoutingkey =() => {
  return  `${endpoints.routingKey}`
}
export const getTrasmitRoutingkey =() => {
  return  `${endpoints.trasmitRoutingKey}`
}

export const getHeaders = ({ serviceType }) => {
  if (serviceType === 'servicing') {
    return enableCDCServicing==false ? {
      "Content-Type": "application/json",
      "routingKey": `${endpoints.routingKey}`,
      "Correlation-ID": getCorrelationId(),
      "Accept": "application/json",
      "Client-ID": "CDC",
      "Service-Version": "2" ,
      "aid":"cdc_web"    
    } : {
      "Content-Type": "application/json",
      "Correlation-ID": getCorrelationId(),
      "Accept": "application/json",      
      "origin": window.location.href,
      "Access-Control-Allow-Origin": "*" 
    }; 
  }
  else if (serviceType === 'servicing_Update') {
    return enableCDCServicing==false ? {
      "Content-Type": "application/json",
      "routingKey": `${endpoints.routingKey}`,
      "Correlation-ID": getCorrelationId(),
      "Accept": "application/json",
      "Client-ID": "CDC",
      "Service-Version": "2",
      "aid":"cdc_web"    
    } : {
      "Content-Type": "application/json",
      "Correlation-ID": getCorrelationId(),
      "Accept": "application/json",      
      "origin": window.location.href,
      "Access-Control-Allow-Origin": "*"   
    };
  }
  else if (serviceType === 'auth') {
    return {
      "Content-Type": "application/json",
      "Routing-Key": `${endpoints.routingKey}`,
      "Correlation-ID": getCorrelationId(),
      "Accept": "application/json",
      "Transmit-ID": "jwtToken",
    };
  }
  else if (serviceType === 'auth_non_cdc_user') {
    return {
      "Content-Type": "application/json",
      // "Routing-Key": `${endpoints.routingKey}`,
      "Correlation-ID": getCorrelationId(),
      "Accept": "application/json",
      "Transmit-ID": "jwtToken",
      "App-ID": "CDC", 
      "Tenant-ID": "USB", 
      "App-Version": "1", 
      "Channel-ID": "web"
    };
  }
  else if (serviceType === 'docmanagement') {
    return {
      "Content-Type": "application/json",
      "Correlation-ID": getCorrelationId(),
      "Accept": "application/json",      
      "origin": window.location.href,
      "Access-Control-Allow-Origin": "*"
    };
  }

}

const authJWTDetails = () => {
  return { role: "SuperAdmin" }
}

export const authJWTExtract = () => {
  return `${authJWTDetails().role}`
}


export const getConstants = (varName) => {
    if (varName === 'ROLE_Company_Contributor') {
      return 'Company_Contributor__Contact';
    }
    else if (varName === 'ROLE_Frequent_Vendor_Contributor') {
      return 'Frequent_Vendor_Contributor__Contact';
    }
}