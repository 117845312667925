const KeyCode = {
  BACKSPACE: 8,
  TAB: 9,
  RETURN: 13,
  ESC: 27,
  SPACE: 32,
  PAGE_UP: 33,
  PAGE_DOWN: 34,
  END: 35,
  HOME: 36,
  LEFT: 37,
  UP: 38,
  RIGHT: 39,
  DOWN: 40,
  DELETE: 46
};

const Utils = Utils || {};

// Polyfill src https://developer.mozilla.org/en-US/docs/Web/API/Element/matches
Utils.matches = function (element, selector) {
  if (!Element.prototype.matches) {
    Element.prototype.matches =
      Element.prototype.matchesSelector ||
      Element.prototype.mozMatchesSelector ||
      Element.prototype.msMatchesSelector ||
      Element.prototype.oMatchesSelector ||
      Element.prototype.webkitMatchesSelector ||
      function (s) {
        var matches = element.parentNode.querySelectorAll(s);
        var i = matches.length;
        while (--i >= 0 && matches.item(i) !== this) {}
        return i > -1;
      };
  }

  return element.matches(selector);
};

Utils.remove = function (item) {
  if (item.remove && typeof item.remove === 'function') {
    return item.remove();
  }
  if (item.parentNode &&
      item.parentNode.removeChild &&
      typeof item.parentNode.removeChild === 'function') {
    return item.parentNode.removeChild(item);
  }
  return false;
};

Utils.isFocusable = function (element) {
  if (element.tabIndex > 0 || (element.tabIndex === 0 && element.getAttribute('tabIndex') !== null)) {
    return true;
  }

  if (element.disabled) {
    return false;
  }

  switch (element.nodeName) {
    case 'A':
      return !!element.href && element.rel != 'ignore';
    case 'INPUT':
      return element.type != 'hidden' && element.type != 'file';
    case 'BUTTON':
    case 'SELECT':
    case 'TEXTAREA':
      return true;
    default:
      return false;
  }
};

Utils.getAncestorBySelector = function (element, selector) {
  if (!Utils.matches(element, selector + ' ' + element.tagName)) {
    // Element is not inside an element that matches selector
    return null;
  }

  // Move up the DOM tree until a parent matching the selector is found
  var currentNode = element;
  var ancestor = null;
  while (ancestor === null) {
    if (Utils.matches(currentNode.parentNode, selector)) {
      ancestor = currentNode.parentNode;
    }
    else {
      currentNode = currentNode.parentNode;
    }
  }

  return ancestor;
};

Utils.hasClass = function (element, className) {
  return (new RegExp('(\\s|^)' + className + '(\\s|$)')).test(element.className);
};

Utils.addClass = function (element, className) {
  if (!Utils.hasClass(element, className)) {
    element.className += ' ' + className;
  }
};

Utils.removeClass = function (element, className) {
  var classRegex = new RegExp('(\\s|^)' + className + '(\\s|$)');
  element.className = element.className.replace(classRegex, ' ').trim();
};

Utils.bindMethods = function (object /* , ...methodNames */) {
  var methodNames = Array.prototype.slice.call(arguments, 1);
  methodNames.forEach(function (method) {
    object[method] = object[method].bind(object);
  });
};
 export const ListboxCombobox = function (
  comboboxNode,
  input,
  listbox,
  searchFn,
  shouldAutoSelect,
  onShow,
  onHide,
  getSelectedItem,
  comboboxarrow
) {
  this.combobox = comboboxNode;
  this.input = input;
  this.listbox = listbox;
  this.searchFn = searchFn;
  this.shouldAutoSelect = shouldAutoSelect;
  this.onShow = onShow || function () {};
  this.onHide = onHide || function () {};
  this.getSelectedItem = getSelectedItem || function() {};
  this.comboboxarrow = comboboxarrow;
  this.activeIndex = -1;
  this.resultsCount = 0;
  this.shown = false;
  this.hasInlineAutocomplete =
    input.getAttribute('aria-autocomplete') === 'both';

  this.setupEvents();
};

ListboxCombobox.prototype.setupEvents = function () {
  document.body.addEventListener('click', this.checkHide.bind(this));
  this.input.addEventListener('keyup', this.checkKey.bind(this));
  this.input.addEventListener('keydown', this.setActiveItem.bind(this));
  this.input.addEventListener('focus', this.checkShow.bind(this));
  this.input.addEventListener('blur', this.checkSelection.bind(this));
  this.listbox.addEventListener('click', this.clickItem.bind(this));
  this.comboboxarrow.addEventListener('click', this.checkArrow.bind(this));
};
ListboxCombobox.prototype.checkArrow = function (evt) {
   evt.preventDefault();
    if (this.shown) {
        this.input.focus();
        this.hideListbox();
    }
    else {
        this.input.focus();
        this.updateResults(true);
    }
}
ListboxCombobox.prototype.checkKey = function (evt) {
  var key = evt.which || evt.keyCode;

  switch (key) {
    case KeyCode.UP:
    case KeyCode.DOWN:
    case KeyCode.ESC:
    case KeyCode.RETURN:
      evt.preventDefault();
      return;
    default:
      this.updateResults(false);
  }

  if (this.hasInlineAutocomplete) {
    switch (key) {
      case KeyCode.BACKSPACE:
        return;
      default:
        this.autocompleteItem();
    }
  }
};

ListboxCombobox.prototype.updateResults = function (shouldShowAll) {
  var searchString = this.input.value;
  var results = this.searchFn(searchString);

  this.hideListbox();

  if (!shouldShowAll && !searchString) {
    results = [];
  }

  if (results.length) {
    for (var i = 0; i < results.length; i++) {
      var resultItem = document.createElement('li');
      resultItem.className = 'result';
      resultItem.setAttribute('role', 'option');
      resultItem.setAttribute('id', 'result-item-' + i);
      resultItem.innerText = results[i];
      if (this.shouldAutoSelect && i === 0) {
        resultItem.setAttribute('aria-selected', 'true');
        Utils.addClass(resultItem, 'focused');
        this.activeIndex = 0;
      }
      this.listbox.appendChild(resultItem);
    }
    Utils.removeClass(this.listbox, 'hidden');
    this.combobox.setAttribute('aria-expanded', 'true');
    this.resultsCount = results.length;
    this.shown = true;
    this.onShow();
  }
};

ListboxCombobox.prototype.setActiveItem = function (evt) {
  var key = evt.which || evt.keyCode;
  var activeIndex = this.activeIndex;

  if (key === KeyCode.ESC) {
    this.hideListbox();
    setTimeout((function () {
      // On Firefox, input does not get cleared here unless wrapped in
      // a setTimeout
      this.input.value = '';
    }).bind(this), 1);
    return;
  }

  if (this.resultsCount < 1) {
    if (this.hasInlineAutocomplete && (key === KeyCode.DOWN || key === KeyCode.UP ||  key === KeyCode.RETURN)) {
      this.updateResults(true);
    }
    else {
      return;
    }
  }

  var prevActive = this.getItemAt(activeIndex);
  var activeItem;

  switch (key) {
    case KeyCode.UP:
      if (activeIndex <= 0) {
        activeIndex = this.resultsCount - 1;
      }
      else {
        activeIndex--;
      }
      break;
    case KeyCode.DOWN:
      if (activeIndex === -1 || activeIndex >= this.resultsCount - 1) {
        activeIndex = 0;
      }
      else {
        activeIndex++;
      }
      break;
    case KeyCode.RETURN:
      if(activeIndex === -1){
        activeIndex = 0;
        break;
      } else {
        activeItem = this.getItemAt(activeIndex);
        this.selectItem(activeItem);
        return;
      }
      
    case KeyCode.TAB:
      this.checkSelection();
      this.hideListbox();
      return;
    default:
      return;
  }

  evt.preventDefault();

  activeItem = this.getItemAt(activeIndex);
  this.activeIndex = activeIndex;

  if (prevActive) {
    Utils.removeClass(prevActive, 'focused');
    prevActive.setAttribute('aria-selected', 'false');
  }

  if (activeItem) {
    this.input.setAttribute(
      'aria-activedescendant',
      'result-item-' + activeIndex
    );
    Utils.addClass(activeItem, 'focused');
    activeItem.setAttribute('aria-selected', 'true');
    //changes for manuallu scrolling the listitems -start
    var container = document.getElementsByClassName("listbox")[document.getElementsByClassName("listbox").length-1]
    if (activeItem.offsetTop < container.scrollTop) {
      container.scrollTop = activeItem.offsetTop;
    } else {
      const offsetBottom = activeItem.offsetTop + activeItem.offsetHeight;
      const scrollBottom = container.scrollTop + container.offsetHeight;
      if (offsetBottom > scrollBottom) {
        container.scrollTop = offsetBottom - container.offsetHeight;
      }
    }
    //changes for manuallu scrolling the listitems - end
    if (this.hasInlineAutocomplete) {
      this.input.value = activeItem.innerText;
    }
  }
  else {
    this.input.setAttribute(
      'aria-activedescendant',
      ''
    );
  }
};

ListboxCombobox.prototype.getItemAt = function (index) {
  return document.getElementById('result-item-' + index);
};

ListboxCombobox.prototype.clickItem = function (evt) {
  if (evt.target && evt.target.nodeName == 'LI') {
    this.selectItem(evt.target);
  }
};

ListboxCombobox.prototype.selectItem = function (item) {
  if (item) {
    this.input.value = item.innerText;
    if(item.innerText.length >= 20){
      this.input.style.width = item.innerText.length *10+ "px";
    } else {
      this.input.style.width = "100%"
    }
    
    this.getSelectedItem(item.innerText)
    this.hideListbox();
  }
};

ListboxCombobox.prototype.checkShow = function (evt) {
  this.updateResults(false);
};

ListboxCombobox.prototype.checkHide = function (evt) {
  if (evt.target === this.input || this.combobox.contains(evt.target)) {
    return;
  }
  this.hideListbox();
};

ListboxCombobox.prototype.hideListbox = function () {
  this.shown = false;
  this.activeIndex = -1;
  this.listbox.innerHTML = '';
  Utils.addClass(this.listbox, 'hidden');
  this.combobox.setAttribute('aria-expanded', 'false');
  this.resultsCount = 0;
  this.input.setAttribute(
    'aria-activedescendant',
    ''
  );
  this.onHide();
};

ListboxCombobox.prototype.checkSelection = function () {
  if (this.activeIndex < 0) {
    return;
  }
  var activeItem = this.getItemAt(this.activeIndex);
  this.selectItem(activeItem);
};

ListboxCombobox.prototype.autocompleteItem = function () {
  var autocompletedItem = this.listbox.querySelector('.focused');
  var inputText = this.input.value;

  if (!autocompletedItem || !inputText) {
    return;
  }

  var autocomplete = autocompletedItem.innerText;
  if (inputText !== autocomplete) {
    this.input.value = autocomplete;
    this.input.setSelectionRange(inputText.length, autocomplete.length);
  }
};
/**
 * @namespace aria
 */

var aria = aria || {};

/**
 * @desc
 *  Key code constants
 */


export default ListboxCombobox;