import React from 'react';
// import Head from 'next/head'
import { USBGrid, USBColumn } from '@usb-shield/components-react';

const FooterTOU = () => {
  return (
    <>
      {/* <Head> */}
      <title>Terms of Use</title>
      <meta property="og:title" content="Terms of Use" key="title" />
      {/* </Head> */}
      <USBGrid addClasses="whitebg">
        <USBColumn largeSpan={2} />
        <USBColumn largeSpan={12}>
          <h1 className="heading-h1 margintop40 marginbot16">
          Impact Finance Portal Terms of Use
          </h1>
          <div className="text-page">
            <h2 className="sub-heading-h2 marginbot16">Welcome</h2>
            <p className="marginbot16">
              These Terms of Use (“
              <strong>TOU</strong>
              ”) govern your use of our secure web portal (“
              <strong>Portal</strong>
              ”) that is designed to make it easy for you to manage your
              organization’s reporting requirements. Through the Portal you can view and filter reporting requirements, manage digital documentation and communicate via messaging with your U.S. Bancorp Impact Finance (“Impact Finance”) counterparties.
               By continuing to access the Portal, you agree to
              be bound by this TOU. If you do not agree to this TOU, please do
              not use the Portal.
            </p>
            <p className="marginbot80">
              <strong>
                This TOU is revised periodically, and it may include changes
                from earlier versions. By using the Portal, you agree to the
                most recent version of this TOU.
              </strong>
            </p>

            <h2 className="sub-heading-h2 marginbot16">Definitions</h2>
            <strong>The following definitions apply in this TOU:</strong>
            <ul className="marginbot80">
              <li>
                The words
                <strong> “we,” “our” </strong>
                and
                <strong> “us” </strong>
                mean U.S. Bank,
                <strong>  U.S. Bancorp Community Development Corporation, d/b/a U.S. Bancorp Impact Finance </strong>
                and respective affiliates, successors and assigns.
              </li>
              <li>
                The words
                <strong> “you” </strong>
                and
                <strong> “your” </strong>
                mean the person that has been provided credentials to access the
                Portal and use the services covered by this TOU.
              </li>
              <li>
                <strong> “Individual User(s)” </strong>
                are provided access to the Portal by the Site Administrator in
                accordance with a defined access role.
              </li>
              <li>
                <strong> “Log-in Credentials” </strong>
                means your username and password used to access the Portal.
              </li>
              <li>
                <strong> “Site Administrator” </strong>
                is appointed by your organization to manage access to the Portal
                for your employees and Vendors to whom you provide access.
              </li>
              <li>
                <strong> “Vendor” </strong>
                is a third-party company or individual, external to your
                organization, that can be given access to the Portal by a Site
                Administrator.
              </li>
            </ul>
            <h2 className="sub-heading-h2 marginbot16">Privacy & security</h2>
            <p className="marginbot16">
              Protecting your privacy is important to us. Please visit our
              online&nbsp;
              <a
                href="https://emp.usbank.com/about-us-bank/privacy.html"
                target="_blank"
                rel="noopener noreferrer"
              >
                Privacy
              </a>
              &nbsp;page for more information on our commitment to privacy and
              online security.
            </p>
            <h3 className="super-heading marginbot20">
              PROTECTING YOUR LOGIN-IN CREDENTIALS
            </h3>
            <p className="marginbot48">
              Prevent unauthorized access by keeping your Log-in Credentials
              confidential and ensure that you log out of the Portal when you
              are finished. If you give another person or business access to the
              Portal by sharing your Log-in Credentials, you agree that each
              such third-party will be authorized to act on your behalf and will
              be bound by this TOU (and any separate agreement governing your
              account). We are not responsible for managing the authority of
              your third-party relationships, or the use of the Portal by an
              authorized third-party using your Log-in Credentials.
              <strong>
                &nbsp;Any activity performed using your Log-in Credentials,
                <em>
                  <strong>
                    &nbsp;even if not specifically intended by you,{' '}
                  </strong>
                </em>
                will be presumed to be authorized by you.
              </strong>
              &nbsp;If you have shared your Log-in Credentials with a
              third-party, and wish to revoke such access, you must contact us
              to block access to the Portal until new Log-in Credentials are
              established. If you are the Site Administrator for your
              organization, you can establish Individual User profiles
              (described in more detail below) that allow you to delegate and
              manage access without sharing Log-in Credentials.
            </p>
            <h3 className="super-heading marginbot20">
              RECEIVING TEXT MESSAGES AND OTHER COMMUNICATIONS
            </h3>
            <p className="marginbot20">
              <strong>
                By providing us with a telephone number for a mobile device,
                including a number that you later convert to a mobile device
                number, you are expressly consenting to receiving communications
                — including but not limited to prerecorded or artificial voice
                message calls, text messages and calls made by an automatic
                telephone dialing system — from us and our affiliates and agents
                at that number. This express consent applies to each such
                telephone number that you provide to us now or in the future and
                permits such calls for&nbsp;
                <u>non-marketing</u>
                &nbsp;purposes. Calls and messages may incur fees from your
                mobile services provider.
              </strong>
            </p>
            <p className="marginbot20">
              We use text messaging services to send you one-time passcodes.
              Message frequency depends on your account settings and how often
              you use the services that utilize such functionality. If you need
              assistance, contact us at&nbsp;
              <a
                href="mailto:ImpactFinancedocumentportal@usbank.com"
                title="ImpactFinancedocumentportal@usbank.com"
              >
                ImpactFinancedocumentportal@usbank.com
              </a>
              ,&nbsp;or reply to the text message with the word “HELP.” To stop
              receiving text messages on your mobile phone, reply to the text
              message with the word “STOP.”
            </p>
            <p className="marginbot48">
              <strong>
                Text messaging is available from all major wireless operators
                based in the United States of America (AT&amp;T, Verizon
                Wireless, T-Mobile®, Sprint, Metro PCS, U.S. Cellular® and
                Cincinnati Bell). Text messages may be delayed (or not
                delivered) if your mobile device is not in range of a
                transmission site, placed in airplane mode or if sufficient
                network capacity is not available at a particular time. Even
                within a coverage area, factors beyond the control of your
                wireless operator may interfere with message delivery, including
                your equipment, terrain, proximity to buildings, foliage and
                weather. You acknowledge that urgent messages may not be timely
                received, that your wireless operator does not guarantee that
                messages will be delivered, and that we and the wireless
                operators are not liable for delayed or undelivered messages.
              </strong>
            </p>
            <h3 className="super-heading marginbot20">
              MONITORING AND RECORDING COMMUNICATIONS
            </h3>
            <p className="marginbot80">
              We may monitor and/or record any communications between you and us
              for quality control and other permitted business purposes. This
              monitoring or recording may be done without any further notice to
              you or anyone acting on your behalf.
            </p>
            <h2 className="sub-heading-h2 marginbot16">
              Managing Reporting Requirements
            </h2>
            <p className="marginbot48">
              The Portal is designed to facilitate communication and provide a
              secure method of sharing electronic documentation. The Portal will
              allow you to upload/download documents and track actions taken
              within the Portal in connection with your reporting obligations,
              but it is not an official system of record.
            </p>
            <h3 className="super-heading marginbot20">NOTIFICATIONS</h3>
            <p className="marginbot48">
              Use of the Portal incorporates selective messaging via email to
              communicate to you about important, time-sensitive updates.
              Depending on the access you’ve been provided in the Portal, you
              may receive emails when the status updates occur.
            </p>
            <h3 className="super-heading marginbot20">ELECTRONIC DOCUMENTS</h3>
            <p className="marginbot80">
              Electronic documentation may be deleted from the Portal after a
              period of time. You are responsible for downloading and saving
              your electronic documents for your own purposes. If you terminate
              your access to the Portal, you will no longer have access to the
              associated electronic documents.
            </p>
            <h2 className="sub-heading-h2 marginbot16">
              Managing authorized users
            </h2>
            <p className="marginbot48">
              The Site Administrator is responsible for controlling access to
              the Portal and for managing the permissions provided to the
              Individual User. We have no duty or responsibility to monitor the
              acts of the Individual User or ensure that the acts of the
              Individual User comply with the policies or instructions of your
              organization. Any separate agreement, or required duty to act, is
              solely between your organization and the Individual User and we
              are not responsible for managing or enforcing any suchagreement
              between you and the appointed Individual User.
            </p>
            <h3 className="super-heading marginbot20">INDIVIDUAL USER ROLES</h3>
            <p>
              The Site Administrator may assign the Individual User to one of
              the following roles:
            </p>
            <ul className="marginbot20">
              <li>
                Employee Site Administrator: Can add/manage Individual Users and
                has access to all of the reporting requirements, electronic
                documents and messaging associated with your organization.
              </li>
              <li>
                Employee Contributor: Has access to the reporting requirements,
                electronic documents and messaging of the respective
                organization as assigned by the Site Administrator.
              </li>
              <li>
                Vendor: A third-party company or individual that has access to
                the reporting requirements, electronic documents and messaging
                of the respective organization as assigned by the Site
                Administrator.
              </li>
            </ul>
            <h3 className="super-heading marginbot20">
              INDIVIDUAL USER PERMISSIONS
            </h3>
            <p className="marginbot20">
              The Site Administrator delegates access to reporting requirements
              by assigning permissions to the Individual User and such
              permissions can be filtered by the following:
            </p>
            <p className="marginbot20">
              Limited Access by Deal, Legal Entity or Reporting Requirement:
            </p>
            <ul className="marginbot48">
              <li>
                Legal Entity: A legal entity is a specific company or individual
                for whom the reporting requirement is a direct obligation as
                described in the transaction documents of a Deal.
              </li>
              <li>
                Deal: A deal is the broader, multi-party legal transaction
                involving your organization (or affiliate legal entities) for a
                described investment period during which reporting is required.
              </li>
              <li>
                Reporting Requirements: Specific reporting obligations and their
                associated documents.
              </li>
              <li>
                Vendors can be given access to your customer organization’s
                reporting requirements, and permissions can be restricted by the
                Site Administrator.
              </li>
            </ul>
            <p>Unlimited Access</p>
            <ul className="marginbot20">
              <li>
                Assign to all: Allows the Individual User to see and take action
                upon all reporting requirements for your organization, for all
                Deals, or for all Legal Entities and/or for all Reporting
                Requirements.
              </li>
            </ul>
            <p className="marginbot48">
              Any additional limitations placed on the Individual User, even if
              we have express written notice of those limitations, are
              exclusively between the Site Administrator/organization and the
              Individual User.
            </p>
            <h3 className="super-heading marginbot20">CONFIDENTIALITY</h3>
            <p className="marginbot80">
              While the Portal is a secured environment, Site Administrators and
              Individual Users will be able to see all communications,
              electronic documents and reporting requirements as outlined by the
              permission granted. If you or we require additional levels of
              confidentiality for some communications, these may be conducted
              outside of the Portal as requested by either party. Individual
              Users in the Vendor role may have access to the information of
              multiple organizations if given access by other Site
              Administrators. Any limitations you impose on the access and the
              use of your organization’s information is strictly between you and
              your appointed Individual User or the Vendor company to whom
              access is provided.
            </p>
            <h2 className="sub-heading-h2 marginbot16">Additional terms</h2>
            <h3 className="super-heading marginbot20">
              INTELLECTUAL PROPERTY RIGHTS
            </h3>
            <p className="marginbot16">
              All content connected with the Portal is the exclusive
              intellectual property of us, our licensors and/or service
              providers and it is protected by copyrights and other intellectual
              property rights. You are permitted to use content delivered to you
              through the Portal only for your personal use. You may not copy,
              reproduce, distribute or create derivative works from this
              content. Further, you agree not to reverse-engineer or
              reverse-compile any technology, including any software or other
              content associated with the Portal.
            </p>
            <p className="marginbot16">
              The trademarks, logos and service marks displayed in connection
              with the Portal are the registered and unregistered trademarks of
              us and/or our service providers. Under no circumstances may you
              use, copy, imitate, alter, modify or change these trademarks.
              Nothing contained on, in or otherwise connected with the Portal
              should be construed as granting (by implication or otherwise) any
              license or right to use any trademark without the express written
              permission of us or the third party, which has rights to such
              trademark.
            </p>
            <p className="marginbot48">
              All messages, suggestions, ideas, notes, concepts, know-how,
              techniques, data, applications, mail and other information you may
              send to us through or regarding the Portal shall be considered an
              uncompensated contribution of intellectual property to us and
              shall become our exclusive intellectual property. By submitting
              any such materials to us, you automatically grant (or warrant that
              the owner of such materials has expressly granted) to us a
              perpetual, royalty-free, irrevocable, non-exclusive right and
              license to use, reproduce, modify, adapt, publish, translate,
              publicly perform and display, create derivative works from and
              distribute such materials or incorporate such materials into any
              form, medium or technology now known or later developed. You
              warrant that all so-called “moral rights” in those materials have
              been waived, and you warrant that you have the right to make these
              warranties and transfers of rights.
            </p>
            <h3 className="super-heading marginbot20">
              LINKS TO OTHER INTERNET SITES AND THIRD-PARTY SERVICES
            </h3>
            <p className="marginbot16">
              The Portal may contain links to other websites, merchandise and
              services provided, owned or operated by third parties; these links
              do not imply our endorsement or approval of material on any
              third-party website. The linked websites are not under our control
              and we are not responsible for the availability, content,
              products, services, advertising or other materials available on
              the third-party websites. The privacy policies of third-party
              websites may provide less security than our Portal, so we strongly
              encourage you to read the third party&#39;s privacy policy before
              sharing any information with that third party.
            </p>
            <p className="marginbot48">
              All matters concerning third-party websites, merchandise and
              services provided or operated by third parties are solely between
              you and the third party. We make no warranties or representations
              whatsoever with regard to any third-party website, merchandise or
              service and we are not responsible or liable to you for any
              damages, losses or injuries of any kind arising out of your use of
              any third-party website.
            </p>
            <h3 className="super-heading marginbot20">EQUIPMENT</h3>
            <p className="marginbot48">
              You are responsible for and must provide all mobile devices,
              computers, and/or other equipment, software (other than any
              software provided by us), and services necessary to access the
              Portal. You may need additional software that is capable of
              opening PDF files (such as Adobe Acrobat) to view, print, and/or
              save electronic versions of your documents.
            </p>
            <h3 className="super-heading marginbot20">INTENDED USE</h3>
            <p className="marginbot16">
              The Portal, and the products and services described, are intended
              for people that are at least 18 years old or otherwise able to
              lawfully enter into contracts under applicable law. Nothing on the
              Portal is intended for persons under the age of 13.
            </p>
            <p className="marginbot48">
              Use of the Portal is subject to all applicable federal, state and
              local laws and regulations. You agree not to use the Portal to
              conduct any business or activity or solicit the performance of any
              activity prohibited by law or any contractual provision by which
              you are bound.
            </p>
            <h3 className="super-heading marginbot20">
              DELAY OR SUSPENSION OF SERVICE
            </h3>
            <p className="marginbot48">
              Without limiting any other provision of this TOU, if we or any
              other service provider reasonably believes that your conduct in
              using the Portal constitutes a&nbsp;
              <strong>“Threatening Condition”</strong>
              &nbsp;(including, but not limited to, violation of this TOU;
              violation of any applicable laws, rules, regulations or industry
              standards; or otherwise poses a threat to any system, equipment,
              process, intellectual property or the reputation of us or any
              service provider), we or any such service provider may provide you
              with a notice to cease the Threatening Condition. If, in the
              reasonable and good faith determination of us or any service
              provider, the Threatening Condition poses an imminent or actual
              threat (including regulatory investigation, inquiry or penalty) to
              us or any service provider or its systems, equipment, processes or
              intellectual property, you agree that we or any other service
              provider may suspend any and all use of the Portal without notice.
            </p>
            <h3 className="super-heading marginbot20">TERM AND TERMINATION</h3>
            <p className="marginbot16">
              We reserve the right to add or eliminate features to the Portal,
              and we may terminate your use of any or all features of the Portal
              for any reason and at any time with or without prior notice as the
              law requires. You agree that you will immediately stop using the
              Portal upon our request.
            </p>
            <p className="marginbot16">
            You may voluntarily terminate your access to the Portal and withdraw your consent to this TOU by contacting your U.S. Bank or Impact Finance representative or emailing us
             at&nbsp;
              <a
                href="mailto:ImpactFinancedocumentportal@usbank.com."
                title="ImpactFinancedocumentportal@usbank.com."
              >
                ImpactFinancedocumentportal@usbank.com
              </a>
              . If you terminate your access and/or withdraw your consent to
              this TOU, you will no longer have access to any of the services
              offered through the Portal.
            </p>
            <p className="marginbot48">
              All applicable provisions of this TOU shall survive termination by
              either you or us, including, without limitation, provisions
              related to your liability, intellectual property, warranty
              disclaimers, limitations of liability and indemnification.
            </p>
            <h3 className="super-heading marginbot20">NO WARRANTIES</h3>
            <p className="marginbot16">
              WE DO NOT REPRESENT OR WARRANT THE ACCURACY, ADEQUACY,
              COMPLETENESS OR TIMELINESS OF THE PORTAL, OR THE ERROR FREE USE OF
              THE PORTAL. THE PORTAL IS PROVIDED “AS IS” AND “AS AVAILABLE,”
              WITHOUT WARRANTY OF ANY KIND, EITHER EXPRESS OR IMPLIED,
              INCLUDING, WITHOUT LIMITATION, THE WARRANTIES OF MERCHANTABILITY,
              FITNESS FOR A PARTICULAR PURPOSE, NON-INFRINGEMENT AND FREEDOM
              FROM A COMPUTER VIRUS.
            </p>
            <p className="marginbot48">
              IN THE EVENT OF A SYSTEM FAILURE OR INTERRUPTION, YOUR DATA MAY BE
              LOST OR DESTROYED. YOU ASSUME THE RISK OF LOSS OF YOUR DATA DURING
              ANY SYSTEM FAILURE OR INTERRUPTION.
            </p>
            <h3 className="super-heading marginbot20">
              LIMITATION OF LIABILITY; INDEMNIFICATION
            </h3>
            <p className="marginbot16">
              IN NO EVENT WILL WE BE LIABLE UNDER ANY CONTRACT, TORT,
              NEGLIGENCE, STRICT LIABILITY OR OTHER CLAIM FOR ANY DIRECT,
              INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL OR EXEMPLARY DAMAGES,
              INCLUDING, WITHOUT LIMITATION, DAMAGES TO EQUIPMENT, FOR LOSS OF
              PROFITS, GOODWILL, USE, DATA, OR OTHER INTANGIBLE LOSSES (EVEN IF
              ADVISED OF THE POSSIBILITY OF SUCH DAMAGES), WHETHER CAUSED BY OR
              RESULTING FROM (1) THE USE OR THE INABILITY TO USE THE PORTAL; (2)
              ANY FAILURE OF PERFORMANCE, ERROR, OMISSION, INTERRUPTION, DELAY
              IN OPERATION OR TRANSMISSION, COMPUTER VIRUS, LOSS OF DATA, THEFT,
              DESTRUCTION, OR UNAUTHORIZED ACCESS TO YOUR INFORMATION; (3)
              ERRORS, INACCURACIES, OMISSIONS, OR OTHER DEFECTS IN INFORMATION
              OR CONTENT PROVIDED BY, CONTAINED WITHIN, OR OBTAINED THROUGH THE
              PORTAL, OR (4) ANY OTHER FAILURE, ACTION, OR OMISSION.
            </p>
            <p className="marginbot48">
              YOU AGREE TO INDEMNIFY, DEFEND, AND HOLD US HARMLESS FROM ANY AND
              ALL THIRD-PARTY CLAIMS, ACTIONS, LIABILITY, DAMAGES AND/OR COSTS
              (INCLUDING BUT NOT LIMITED TO REASONABLE ATTORNEY’S FEES) ARISING
              FROM (1) A THIRD-PARTY CLAIM, ACTION OR ALLEGATION OF
              INFRINGEMENT, MISUSE OR MISAPPROPRIATION BASED ON INFORMATION,
              DATA, FILES OR OTHER MATERIALS SUBMITTED BY YOU TO OR THROUGH OUR
              PORTAL; (2) ANY FRAUD, MANIPULATION OR OTHER BREACH OF THIS TOU BY
              YOU; (3) ANY THIRD-PARTY CLAIM, ACTION OR ALLEGATIONS BROUGHT
              AGAINST US ARISING OUT OF OR RELATING TO A DISPUTE WITH YOU OVER
              THE TERMS AND CONDITIONS OF AN AGREEMENT OR RELATED TO THE
              PURCHASE OF SALE OF ANY GOODS OR SERVICES; (4) YOUR VIOLATION OF
              ANY LAW OR RIGHTS OF A THIRD PARTY; OR (5) USE OF THE PORTAL BY
              ANY THIRD PARTY. WE RESERVE THE RIGHT, AT OUR OWN EXPENSE, TO
              ASSUME THE EXCLUSIVE DEFENSE AND CONTROL OF ANY MATTER OTHERWISE
              SUBJECT TO INDEMNIFICATION BY YOU, IN WHICH EVENT YOU WILL
              COOPERATE WITH US IN ASSERTING ANY AVAILABLE DEFENSES. YOU WILL
              NOT SETTLE ANY ACTION OR CLAIMS ON OUR BEHALF WITHOUT OUR PRIOR
              WRITTEN CONSENT.
            </p>
            <h3 className="super-heading marginbot20">EXPORT CONTROL</h3>
            <p className="marginbot48">
              You acknowledge that your use of the Portal is subject to the
              United States government export control laws and regulations,
              which may restrict or prohibit the use, export, re-export or
              transfer of the content within the Portal and any associated
              software. You agree that you will not directly or indirectly use,
              export, re-export or transfer any part of the Portal except in
              compliance with applicable U.S. export laws and regulations.
              Without limitation, you agree that you will not use the Portal in
              any embargoed or sanctioned country.
            </p>
            <h3 className="super-heading marginbot20">
              USING THE PORTAL FROM OUTSIDE THE UNITED STATES
            </h3>
            <p className="marginbot48">
              You understand that the described products and services are
              intended for users located in the United States and U.S.
              Territories; all information and products referenced assume the
              use of U.S. currency. You also understand that we are based in the
              United States, and your use of the Portal may subject you to
              United States law (including laws that govern the use and
              collection of personal data).
            </p>
            <h3 className="super-heading marginbot20">WAIVER</h3>
            <p className="marginbot48">
              We will not be deemed to have waived any of our rights or remedies
              with regard to this TOU unless our waiver is in writing and signed
              by our authorized officer. No delay or omission on our part in
              exercising any rights or remedies will operate as a waiver. A
              waiver on one occasion will not be construed as a bar or waiver of
              any rights or remedies on future occasions.
            </p>
            <h3 className="super-heading marginbot20">
              SEVERABILITY AND GOVERNING LAW
            </h3>
            <p className="marginbot16">
              If any provision of this TOU is held to be void or unenforceable
              in any jurisdiction, such ineffectiveness or unenforceability
              shall not affect the validity or enforceability of such provision
              in any other jurisdiction or the validity or enforceability of any
              other provision in that or any other jurisdiction.
            </p>
            <p className="marginbot48">
              This TOU is governed by all applicable Federal laws of the United
              States of America and the laws of the State of Minnesota (without
              regard to any choice of law provisions thereof).
            </p>
            <h3 className="super-heading marginbot20">ASSIGNMENT</h3>
            <p className="marginbot48">
              You may not assign your obligations under this TOU to any other
              party and we will not honor any such assignment. We may assign
              this TOU and you agree that we have the right to delegate to
              service providers all of the rights and performance obligations
              that we have under this TOU, and that the service providers will
              be third-party beneficiaries of this TOU and will be entitled to
              all the rights and protections that this TOU provides to us.
            </p>
            <h3 className="super-heading marginbot20">COMPLETE AGREEMENT</h3>
            <p className="marginbot48">
              This TOU represents the sole and exclusive agreement between you
              and us regarding the Portal. It merges and supersedes all previous
              and contemporaneous written or oral agreements and understandings
              regarding the subject matter hereof.
            </p>
          </div>
        </USBColumn>
        <USBColumn largeSpan={2} />
      </USBGrid>
    </>
  );
};

export default FooterTOU;
