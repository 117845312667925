import { endpoints } from '../../../config/connections';
import callApi from './callApi';
import moment from 'moment';
import { ExportToExcel } from '../utilities/ExportToExcel';

export const saveAsFile = (binary, fileName) => {
  if (window.navigator.msSaveOrOpenBlob) {
    // for IE and Edge
    const binaryString = window.atob(binary);
    const len = binaryString.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; i += 1) {
      bytes[i] = binaryString.charCodeAt(i);
    }
    const blob = new Blob([bytes], { type: { type: 'application/pdf' } });
    window.navigator.msSaveBlob(blob, fileName);
  } else {
    const obj = document.createElement('object');
    obj.style.width = '100%';
    obj.style.height = '842pt';
    obj.type = 'application/pdf';
    obj.data = `data:application/pdf;base64,${binary}`;
    const link = document.createElement('a');
    const hrefValue = `data:application/octet-stream;base64,${binary}`;
    link.innerHTML = 'Download PDF file';
    link.download = fileName;
    link.href = hrefValue;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
};
export const previewFile = (binary, fileName) => {
  const obj = document.createElement('object');
  obj.style.width = '100%';
  obj.style.height = '842pt';
  (obj.type = 'application/pdf'),
    (obj.data = `data:application/pdf;base64,${binary}`);
  const link = document.createElement('a');
  const hrefValue = `data:application/octet-stream;base64,${binary}`;
  link.innerHTML = 'Download PDF file';
  link.download = fileName;
  link.href = hrefValue;

  var binar_string = window.atob(binary);
  var len = binary.length;
  if (len) {
    var bytes = new Uint8Array(len);
    for (var i = 0; i < len; i++) {
      bytes[i] = binar_string.charCodeAt(i);
    }
  }
  let file = new Blob([bytes.buffer], { type: 'application/pdf' });
  //  var fileURL = URL.createObjectURL(file);
  return file;
};
export function helpAndFaq(setLoading) {
  setLoading(true);
  const apiURL = `${endpoints.envUrl}/document-management/file-download/v1/documents/DocumentTemplates/files-filter?category=Guide&subType=NA`;
  // const apiURL = `${endpoints.envUrl}/document-management/file-download/v1/documents/DocumentTemplate/files-filter?category=Guide&subType=NA`
  // const listUrl = `${endpoints.envUrl}/document-management/file-download/v1/documents/DocumentTemplates/files/HelpAndFAQ`
  callApi
    .getApiData(apiURL)
    .then((response) => {
      if (response.status === 200) {
        const fileName = response.data.fileName.replace(
          'Content-Disposition: attachment; filename=',
          ''
        );
        saveAsFile(response.data.binary, fileName);
        setLoading(false);
        /*
        const apiURL = `${endpoints.api}/document-management/file-download/v1/files/${response.data.filenetId}`
        callApi
          .getApiData(apiURL)
          .then(result => {
            const fileName = result.data.fileName.replace('Content-Disposition: attachment; filename=', '')
            saveAsFile(result.data.binary, fileName)
            setLoading(false)
          }) */
      }
    })
    .catch(() => {
      setLoading(false);
      // console.error(error)
    });
}

export function getQS(param) {
  let qsParam = '';
  if (typeof window !== 'undefined') {
    const searchResult = window.location.search;
    const params = new URLSearchParams(searchResult);
    qsParam = params.get(param);
  }
  return qsParam;
}

export function getLastPathParam(url) {
  return url.substring(url.lastIndexOf('/') + 1);
}

export function parseJwt(token) {
  const base64Url = token?.split('.')[1];
  const base64 = decodeURIComponent(
    atob(base64Url)
      .split('')
      .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
      .join('')
  );
  return JSON.parse(base64);
}

export const setUserRole = () => {
  let userRole = '';
  if (sessionStorage.getItem('role')) {
    if (
      sessionStorage.getItem('role').toUpperCase() ===
      'APP_8083_CDCWEB_SUPER_ADMINS'
    ) {
      userRole = 'employee';
    } else if (
      sessionStorage.getItem('role').toUpperCase() === 'CDC_DOC_PORTAL_ADMIN'
    ) {
      userRole = 'portaladmin';
    } else if (
      sessionStorage.getItem('role').toUpperCase() ===
      'CDC_DOC_PORTAL_CONTRIBUTOR'
    ) {
      userRole = 'customer';
    } else {
      userRole = 'customer';
    }
  }
  sessionStorage.setItem('userrole', userRole);
};

export const setServicingUserRole = () => {
  let servRole = '';
  if (sessionStorage.getItem('servicingrole')) {
    if (
      sessionStorage.getItem('servicingrole').toUppercase() ===
      'SITE ADMINISTRATOR'
    ) {
      servRole = 'siteadmin';
    } else if (
      sessionStorage.getItem('servicingrole').toUppercase() === 'CONTRIBUTOR'
    ) {
      servRole = 'contributor';
    } else if (
      sessionStorage.getItem('servicingrole').toUppercase() ===
      'SITE ADMINISTRATOR (VENDOR)'
    ) {
      servRole = 'vendorsiteadmin';
    } else if (
      sessionStorage.getItem('servicingrole').toUppercase() ===
      'CONTRIBUTOR (VENDOR)'
    ) {
      servRole = 'vendorcontributor';
    } else {
      servRole = 'customer';
    }
  }
  sessionStorage.setItem('servrole', servRole);
};

export function formatByBytes(bytes) {
  switch (true) {
    case bytes >= 1073741824:
      return (bytes / 1073741824).toFixed(2) + ' GB';

    case bytes >= 1048576:
      return (bytes / 1048576).toFixed(2) + ' MB';

    case bytes >= 1024:
      return (bytes / 1024).toFixed(2) + ' KB';

    case bytes > 1:
      return bytes + ' bytes';

    case bytes == 1:
      return bytes + ' byte';

    default:
      return '0 bytes';
  }

}
//download reusable code
export const downloadReport = (reportAccessList, filterSelections, columnName, columns, reportName) => {
  const excelData = [];
  if (reportAccessList) {
    reportAccessList.map((item, index) => {
      if (index == 0) {
        excelData.push(
          {
            ' ': filterSelections,
          },
          { ' ': ' ' },
          columnName,
          { '': '' },
        );
      }
      const str = ' ';
      const maxCharLength = 32767;
      let singleObj = {};
      let tempObj = {};
      columns.map((acc, index) => {
        if(item[acc]?.length < maxCharLength) {
          singleObj[str.padStart(1 + index, ' ')] = item[acc];
        } else { 
          singleObj[str.padStart(1 + index, ' ')] = item[acc]?.substring(0,maxCharLength);
          tempObj[str.padStart(1 + index, ' ')] = item[acc]?.substring(maxCharLength)
        }
      })
      if(Object.entries(tempObj).length!=0){
        Object.entries(singleObj).forEach(([key,value])=>{
          if(tempObj[key] == undefined){
            tempObj[key] = ''
          }
        })
        excelData.push(singleObj)
        excelData.push(tempObj)
      } else {
        excelData.push(singleObj);
      }
    });
  }
  
  // if (excelData.length > 0) {
  //   let finalExcelData = []
  //   excelData.forEach((row) => {
  //     let tempObj = {}
  //     let newRow = {}
  //     const maxLength = 32767;
  //     Object.entries(row).forEach(([key, value]) => {
  //       if (value.length < maxLength) {
  //         tempObj[key] = value;
  //       } else {
  //         tempObj[key] = value.substring(0, maxLength);
  //         newRow[key] = value.substring(maxLength)
  //       }
  //     })
  //     if(Object.entries(newRow).length!=0){
  //       Object.entries(row).forEach(([key,value])=>{
  //         if(newRow[key] == undefined){
  //           newRow[key] = ''
  //         }
  //       })
  //       finalExcelData.push(tempObj)
  //       finalExcelData.push(newRow)
  //     } 
  //   })
  // }
  ExportToExcel(
      Object.values(excelData),
      reportName +
      moment().format('MMM DD, YYYY'),
      () => { },
      () => { }
    );
  }
