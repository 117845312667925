import React, { useState, useEffect } from 'react';
import { ListboxCombobox } from "./AutoCompleteUtility";
import { USBIconChevronDown } from '@usb-shield/components-react/dist/cjs';
import "./AutoCompleteDropdown.css";

const AutoCompleteDropdown = ({listItems,onItemSelect,comboboxId,autocompleteInputId,listboxID,arrowId}) => {
    useEffect(() => {
        var Combobox = new ListboxCombobox(
            document.getElementById(comboboxId),
            document.getElementById(autocompleteInputId),
            document.getElementById(listboxID),
            searchItems,
            false,
            function () {
                // on show
                document.getElementById(arrowId)
                    .setAttribute('aria-label', 'Hide parent company options');
            },
            function () {
                // on hide
                if(document.getElementById(arrowId)){
                    document.getElementById(arrowId)
                    .setAttribute('aria-label', 'Show parent company options');
                }               
            },
            function(parentCompanyName) {
                let filtereOobj = listItems.filter(item => item.parentCompanyName == parentCompanyName)[0]
                console.log(filtereOobj)
                onItemSelect(filtereOobj)
            },
            document.getElementById(arrowId)
        );

        
    }, [])
    const searchItems = (searchString) => {
        var results = [];
        for (var i = 0; i < listItems.length; i++) {
            var item = (listItems[i].parentCompanyName).toLowerCase();
            if (item.indexOf(searchString.toLowerCase()) === 0) {
                results.push(listItems[i].parentCompanyName);
            }
        }

        return results;
    }
    return (
        <div class="services">
            <div role="combobox"
                aria-expanded="false"
                aria-owns={listboxID}
                aria-haspopup={listboxID}
                id={comboboxId}
                className="combobox">
                <input type="text"
                    aria-autocomplete="both"
                    aria-controls={listboxID}
                    aria-labelledby="ex3-label"
                    id={autocompleteInputId}
                    placeholder="Parent company" />
                <div class="combobox-dropdown"
                    id={arrowId}
                    tabindex="-1"
                    role="button"
                    aria-label="Show parent company options">
                        <USBIconChevronDown/>
                </div>
            </div>
            <ul aria-labelledby="ex3-label"
                role="listbox"
                id={listboxID}
                class="listbox hidden">
            </ul>
        </div>
    )
}
export default AutoCompleteDropdown;