import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import useUserRole from '../../Hooks/use-UserRole'
const TopLink = ({ href, title, text, notification }) => {
  let history = useHistory();

  const handleNotificationClick = (href) => {

    if (
      (sessionStorage.getItem('loggedUser') !== '' &&
      sessionStorage.getItem('loggedUser') !== null && 
      sessionStorage.getItem('assignedUser') !== '' &&
      sessionStorage.getItem('assignedUser') !== null && 
      sessionStorage.getItem('assignedUser') == sessionStorage.getItem('loggedUser'))
      &&
      (!sessionStorage.getItem('remote_user'))){
      window.location.href=href;
    }
    else
    {
    if (confirm("Are you sure you want to navigate to Notification page? This will reset view to your profile if you were impersonating any user/viewing another user's profile.") == true) {
      sessionStorage.setItem('assignedUser',sessionStorage.getItem('loggedUser'));
      sessionStorage.removeItem('remote_user');
      window.location.href=href;
    } 
  }
  };
  const tempUserRole = useUserRole();

  const getCurrentPath = window.location.pathname;
  const currentPath =
    getCurrentPath === '/Notifications' && text === 'Notifications'
      ? 'notification-strong'
      : '';

  let notificationicon;
  if (notification > 0) {
    notificationicon = <span className="notification">{notification}</span>;
  } else {
    notificationicon = '';
  }

  return (
    <>{(tempUserRole === 'employee') ?
      (
        <a href="javascript:void(0);" onClick={()=>handleNotificationClick(href)} title={title} tabIndex="0">
          <span className={currentPath}>{text}</span>
          {notificationicon}
        </a>
      )
      :
      (
        <a href={href} title={title} tabIndex="0">
          <span className={currentPath}>{text}</span>
          {notificationicon}
        </a>
      )}



    </>
  );
};

TopLink.propTypes = {
  href: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  notification: PropTypes.string,
};

TopLink.defaultProps = {
  notification: undefined,
};

export default TopLink;
