import React, { useState, useEffect, useContext, Suspense } from 'react';
import Loader from '../../components/utilities/Loader';
import { FeatureFlagsContext } from '../../components/Hooks/useFeatureFlags';
import { PermissionRoleContext } from '../../components/Hooks/usePermissionRoles';


const RedirectLandingPage = () => {

    const {featureDetails}=useContext(FeatureFlagsContext);
    const { permissionDetails } = useContext(PermissionRoleContext);

    useEffect(()=>{
        if(featureDetails && featureDetails.length>0){
            if(["feature_landing_page_visible"].some(cb => featureDetails?.includes(cb))){
                window.location.href = '/Welcome'
            } else if(permissionDetails && permissionDetails.length > 0) {
                if(permissionDetails?.includes("inbox") && !permissionDetails?.includes("portfolio")){
                    window.location.href = '/Inbox'
                } else if(!permissionDetails?.includes("inbox") && permissionDetails?.includes("portfolio")){
                    window.location.href = '/ReportingDashboard'
                } else if(permissionDetails?.includes("portfolio")) {
                    window.location.href = '/ReportingDashboard'
                }else {
                    window.location.href = '/Error'
                }
            }    
        }
    },[featureDetails,permissionDetails])

    return (
        <>
            {/* <Loader></Loader> */}
        </>
    )
}

export default RedirectLandingPage

