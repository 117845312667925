import React, { useState } from 'react';
import USBCheckbox from '@usb-shield/react-forms-checkbox';
import USBSearchInput from '@usb-shield/react-search-input';
import Spinner from '../../components/shared/Spinner';
import { getValueByNestedKey, isEmployee } from './utils';

export default function InboxTable({
  data,
  headers,
  status,
  setStatus,
  searchProperties,
  tableActions,
}) {
  const [selectedRows, setSelectedRows] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [sortColumn, setSortColumn] = useState(null);
  const [sortAscending, setSortAscending] = useState(true);
  const [hoverIndex, setHoverIndex] = useState(-1);

  const selectAllRef = React.createRef();

  const { loading } = status;

  const handleRowSelection =
    (rowId) =>
    ({ event }) => {
      if (event === 'onChange') {
        const selectedRow = selectedRows.includes(rowId);
        if (selectedRow) {
          setSelectedRows(selectedRows.filter((each) => each !== rowId));
        } else {
          setSelectedRows([...selectedRows, rowId]);
        }
      }
    };
  const handleSelectAll = ({ event, inputRef }) => {
    if (event === 'onChange') {
      if (selectedRows.length === data.length) {
        setSelectedRows([]);
      } else {
        setSelectedRows(
          data.map((row) => {
            return getValueByNestedKey(row, tableActions.selectionKey);
          })
        );
      }
    }
  };
  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };
  const handleSort = (accessor) => () => {
    setSortColumn(accessor);
    setSortAscending(!sortAscending);
  };

  function onHeaderMouseEnter(index) {
    return () => setHoverIndex(index);
  }
  function onHeaderMouseLeave() {
    setHoverIndex(null);
  }
  // Function to filter the data based on the search term
  const filteredData =
    searchProperties.enable && searchTerm?.toLowerCase()
      ? data.filter((row) => {
          return searchProperties.keys.some((eachKey) => {
            return getValueByNestedKey(row, eachKey)
              ?.toLowerCase()
              ?.includes(searchTerm?.toLowerCase());
          });
        })
      : data;

  // Function to sort the data based on the selected column
  const sortedData =
    sortColumn !== null
      ? [...filteredData].sort((a, b) => {
          const valueA = getValueByNestedKey(a, sortColumn);
          const valueB = getValueByNestedKey(b, sortColumn);
          if (valueA < valueB) {
            return sortAscending ? -1 : 1;
          } else if (valueA > valueB) {
            return sortAscending ? 1 : -1;
          }
          return 0;
        })
      : filteredData;

  const isEmptyResults = sortedData?.length === 0 && !!searchTerm;
  const TableActionComponent = tableActions?.Component
    ? tableActions.Component
    : null;

  return (
    <div className="closing-binder-table ">
      <div className="table-container">
        <section className="table-actions-container">
          <section className="table-actions-left">
            {searchProperties.enable ? (
              <div className="search-bar">
                <USBSearchInput
                  id="cb-table-search"
                  name="test-name"
                  addClasses="cb-table-search"
                  type="search"
                  isError={false}
                  ariaProps={{
                    label: 'Search',
                  }}
                  placeholder="Search"
                  handleChange={handleSearchChange}
                />
              </div>
            ) : null}
          </section>
          {tableActions?.Component ? (
            <section className="table-actions-right">
              <p className="p-r-lg"> Selected {selectedRows.length} files</p>
              <TableActionComponent
                selectedRows={selectedRows}
                data={data}
              ></TableActionComponent>
            </section>
          ) : null}
        </section>
        <section className="cb-list-table-container">
          <table role="table">
            <thead>
              <tr>
                {tableActions?.enable ? (
                  <th scope="col" style={{ width: '5%' }}>
                    <USBCheckbox
                      inputName="CB-documents-select-all"
                      statusUpdateCallback={handleSelectAll}
                      addClasses="cb-checkbox"
                      ref={selectAllRef}
                      dynamicValue={selectedRows.length === data?.length}
                    />
                  </th>
                ) : null}
                {headers.map((header, index) => {
                  if (header.sortable) {
                    return (
                      <th
                        key={index}
                        scope="col"
                        role="button"
                        onClick={handleSort(header.accessor)}
                        style={header.style || {}}
                        onMouseEnter={onHeaderMouseEnter(index)}
                        onMouseLeave={onHeaderMouseLeave}
                        className="pointer"
                      >
                        {header.label}
                        {sortColumn === header.accessor ? (
                          <span>{sortAscending ? ' ▲' : ' ▼'}</span>
                        ) : hoverIndex === index ? (
                          <span> ▼</span>
                        ) : null}
                      </th>
                    );
                  }

                  return (
                    <th key={index} scope="col" style={header.style || {}}>
                      {header.label}
                    </th>
                  );
                })}
              </tr>
            </thead>

            <tbody>
              {sortedData.map((row, rowIndex) => (
                <tr key={rowIndex} role="row">
                  {tableActions?.enable ? (
                    <td role="cell">
                      <USBCheckbox
                        inputName={`CB-documents-select-row-${row.id}`}
                        statusUpdateCallback={handleRowSelection(
                          getValueByNestedKey(row, tableActions.selectionKey)
                        )}
                        addClasses="cb-checkbox"
                        dynamicValue={selectedRows.includes(
                          getValueByNestedKey(row, tableActions.selectionKey)
                        )}
                      />
                    </td>
                  ) : null}
                  {headers.map((header, cellIndex) => (
                    <td key={cellIndex} role="cell" style={header.style || {}}>
                      {header.cell
                        ? header.cell(row)
                        : getValueByNestedKey(row, header.accessor)}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
          {isEmptyResults ? (
            <div>
              <h4 style={{ width: '100%', textAlign: 'center' }}>
                No results found.
              </h4>
            </div>
          ) : null}
        </section>
      </div>
    </div>
  );
}

InboxTable.defaultValues = {
  searchProperties: {
    enable: false,
    keys: [],
  },
  tableActions: {
    enable: false,
    selectionKey: 'id',
  },
};
