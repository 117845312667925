// @flow
type EntitlementReducerModel = {
  entitlement: Object,
  entitlementServerResponse: Object,
  legalEntity: boolean,
  dealEntity: boolean,
  assignAll: boolean,
  isLegalAllSelected: boolean,
  isDealAllSelected: boolean,
  isAllCategoriesSelected: boolean
}

const initialState: EntitlementReducerModel = {
  entitlement: null,
  entitlementServerResponse: null,
  legalEntity: false,
  dealEntity: false,
  assignAll: false,
  isDealAllSelected: false,
  isLegalAllSelected: false,
  isAllCategoriesSelected: false
}

const EntitlementReducer = (state: EntitlementReducerModel = initialState, action) => {
  switch (action.type) {
    case "SET_ENTITLEMENT":
      return {
        ...state,
        entitlement: action.payload
      };
    case "SET_ENTITY":
      return {
        ...state,
        legalEntity: action.payload.isLegalEntitySelected,
        dealEntity: action.payload.isDealSelected,
        assignAll: action.payload.isAssignToAllSelected
      };
    case "SET_ALL_ENTITY_SELECTED":
      return {
        ...state,
        isDealAllSelected: action.payload.isDealAllSelected,
        isLegalAllSelected: action.payload.isLegalAllSelected,
        isAllCategoriesSelected: action.payload.isCategoryAllSelected

      }
    case "SET_ENTITLEMENT_SERVER_RESPONSE":
      return {
        ...state,
        entitlementServerResponse: action.payload
      }
    case "RESET_ENTITLEMENTS":
      return {}
    default:
      return state;
  }
};

export default EntitlementReducer;
