import React from 'react';
import USBTable from '@usb-inner-src/react-table';

const defaultRow = [
  {
    first: '-',
    second: <div></div>,
    third: <div></div>,
    fourth: <div></div>,
    fifth: <div></div>,
    sixth: <div></div>,
    seventh: <div></div>,
    eight: <div></div>,
    nineth: <div></div>,
  },
];

export default function UsbTableData({ data, columns }) {
  const tableData =
    data?.map((each) => {
      return columns.reduce((acc, current) => {
        return { ...acc, [current.fieldFor]: current.element ? current.element(each) : each[current.key] || '' };
      }, {});
    }) || defaultRow;
  return (
    <>
      <USBTable
        columnData={columns || {}}
        tableData={tableData}
        displayVariant="auto"
      />
    </>
  );
}