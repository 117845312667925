// @flow
import React from 'react';
import {
    USBModal, USBModalCloseIcon, USBModalHeader, USBModalBody, USBModalFooter, USBButtonGroup, USBButton
} from '@usb-shield/components-react/dist/cjs';


type ConfirmationDialogProps = {
    open: boolean,
    title: String,
    toggle: () => void,
    body: String,
    multiLineBody : Array,
    primaryButtonText: String,
    primaryClickHandler: () => void
}
const ConfirmationDialog = (props) => {
    const fulldetails = props.multiLineBody ? props.multiLineBody.map((item)=>{
    let temp = item.split(":")
    return <div><span style={{fontWeight:'bolder'}}>{temp[0]} : </span><span>{temp[1]}</span></div>}) : '';
    return (
        <USBModal
            isOpen={props.open}
            backdrop={'static'}
        >
            <USBModalCloseIcon
                handleModalClose={props.toggle}
                ariaLabel='close modal'
            />
            <USBModalHeader
                modalTitle={props.title}
            >

            </USBModalHeader>

            <USBModalBody>
            
                <div>{props.body}</div>
                <br></br>
                { props?.multiLineBody?.length > 0 ? <div>{fulldetails}</div> : '' }
                
            </USBModalBody>
            <USBModalFooter>
                <USBButtonGroup>
                    <USBButton
                        variant='secondary'
                        // handleClick={props.cancelHandler}
                        handleClick = {props.toggle}
                        ariaLabel='close modal'
                    >
                        Cancel
                    </USBButton>
                    <USBButton
                        variant='primary'
                        handleClick={props.primaryClickHandler}
                        ariaLabel={props.primaryButtonText}
                    >
                        {props.primaryButtonText}
                    </USBButton>
                </USBButtonGroup>
            </USBModalFooter>
        </USBModal>
    )
}

export default ConfirmationDialog;