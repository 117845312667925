import React, { createContext, useState, useEffect } from 'react';
import { endpoints } from '../../../config/connections';
import callApi from '../utilities/callApi';
import useUserRole from './use-UserRole'
export const FeatureFlagsContext = createContext();

const FeatureFlagsContextProvider = (props) => {
  const [featureDetails, setFeatureDetails] = useState([]);
  const [featureFlagResponseBanner, setFeatureFlagResponse]  = useState([]);
  const [loading , setLaoding] = useState(true)
  const userCheck = useUserRole();
  const featureURL = `${endpoints.apigee}/document-management/coreservice/user-management/v1/get-all-features`;

  useEffect(() => {
    if (featureDetails.length === 0) {
      callApi.getApiDataLoggedUser(featureURL).then((response) => {
        
        let flags = [];
        response?.data?.forEach((flag) => {
          if(flag.feature.includes('feature_banner_') &&  flag?.enable){
            setFeatureFlagResponse(flag)
          }
          if (
            (flag.users.includes(sessionStorage.getItem('loggedUser')) ||
              flag.users.includes('*')) &&
            flag?.enable
          ) {
            flags.push(flag.feature);
          }
        });

        setFeatureDetails(flags);
        if(response?.name=="AxiosError"){
          setFeatureDetails([])
          // window.alert("Session Expired. Please login again.")
          // if(response?.response?.status==401){
          //  setTimeout(() => {
          //   sessionStorage.removeItem("remote_user")
          //   sessionStorage.removeItem("portfolioFilters")
          //   if (userCheck !== 'employee') {
          //       window.location.href = '/';
          //       sessionStorage.clear();
          //   } else {
          //       sessionStorage.clear();
          //       window.location.href = '/EmployeeRedirect?tp=lg';
          //   }
          //  }, (10000));
          // }
        }
      })
    }
  }, []);

  return (
    <FeatureFlagsContext.Provider
      value={{
        featureDetails,
        featureFlagResponseBanner,
        loading
      }}
    >
      {props.children}
    </FeatureFlagsContext.Provider>
  );
};

export default FeatureFlagsContextProvider;
