export const transmitIframeMessage = {
    previousWrapperHeight: 0,
    sendMessage: (wrapperHeight, iframeHeight) => {
        transmitIframeMessage.previousWrapperHeight = wrapperHeight;
        const height = {
            iframeWrapperHeight: wrapperHeight + 'px',
            iframeheight: iframeHeight+30 + 'px'
        }
        window.parent.postMessage(height, "*");
    },
    sendOverLayMessage: (open) => {
        const height = {
            iframeWrapperHeight: transmitIframeMessage.previousWrapperHeight + 'px',
            iframeheight: (transmitIframeMessage.previousWrapperHeight + (open ? 200 : 0 ))+ 'px'
        }
        window.parent.postMessage(height, "*");
    },
    sendUserActiveMessage: (time) => {
        const userActivity = {
            servicingUserActive: true,
            servicingUserIdleTime : time
        }
        window.parent.postMessage(userActivity, "*");

    },
    scrollUp: () => {
        window.parent.postMessage({
            scrollTop : true
        }, "*");
        window.scrollTo(0,0);

    },
    scrollToPosition : () => {
        window.parent.postMessage({
            scrollToPosition : true,
            positionToScroll: window.innerHeight/2
        }, "*");
        window.scrollTo(0,window.innerHeight/2);

    },
    scrollToMiddleLow : () => {
        console.log('Challenge Module Height-------->'+(window.innerHeight/2));
        console.log('Challenge Module Height-------->'+(window.innerHeight/8));
        console.log('Overall window position-------->'+window.innerHeight);
        window.parent.postMessage({
            scrollToPosition : true,
            positionToScroll: (window.innerHeight/2)
        }, "*");    

    },
    adjustSafariStyle : () => {        
        var ua = navigator.userAgent.toLowerCase(); 
        if (ua.indexOf('safari') != -1) { 
            if (ua.indexOf('chrome') > -1) {            

            } else {
                console.log("SAFAI FIX INVOKED");
                var position= document.body.style.position
                document.body.style.position="absolute";
                setTimeout(function(){ document.body.style.position=position;},0)
            }
        }
    }
}