import { getSvcServiceURL,getDocumentmgmtServiceURL } from '../../config/connections';
import Axios from 'axios';
import GraphQlQuery from '../../utilities/graphQL-query';

export const userProfileThunk = {
    getEditQueryBody: (originalData, modifiedData) => {
        return {
            "requestType": {
                "serviceType": "CDC_PROF_MAINT",
                "serviceSubType": "EDIT_PROFILE"
            },
            "data": {
                "intent": "EDIT_PROFILE",
                "profileID": originalData.profileID,
                "userID": originalData.userID,
                "firstName": modifiedData.firstName,
                "lastName": modifiedData.lastName,
                "middleName": modifiedData.middleName,
                "email": modifiedData.email,
                "phoneNumbers": {
                    "mobilePhone": modifiedData.phoneNumbers.mobilePhone,
                    "businessPhone": modifiedData.phoneNumbers.businessPhone
                },
                "address": {
                    "addressLine1": modifiedData.address.addressLine1,
                    "addressLine2": modifiedData.address.addressLine2,
                    "city": modifiedData.address.city,
                    "state": modifiedData.address.state,
                    "country": modifiedData.address.country,
                    "zipCode": modifiedData.address.zipCode
                },
                "parentEIN": originalData.parentEIN,
                "parentCompanyName": originalData.parentCompanyName,
                "parentCompanyLegalEntityID": originalData.parentCompanyLegalEntityID,
                "profileRole": modifiedData.profileRole,
                "status": "Active",
                "usbRelationship": originalData.usbRelationship,
                "sourceType": "CDC",
            }
        }
    },
    submitUserProfileEdit: (modifiedFormData) => {
        return async function (dispatch, getState) {
            const serverProfileData = getState().userProfileInformation.profileServerData;

            let bodyForProfileEdit = userProfileThunk.getEditQueryBody(serverProfileData, modifiedFormData);

            if (modifiedFormData.profileRole !== serverProfileData.profileRole) {
                bodyForProfileEdit.profileRoleChanged = true;
                bodyForProfileEdit.previousProfileRole = serverProfileData.profileRole;
            } else {
                bodyForProfileEdit.profileRoleChanged = false;
            }
            const bodyForDocManagement = {
                "profileId": serverProfileData.profileID,
                "source": "CDC",
                "userId": serverProfileData.userID,
                "emailId": modifiedFormData.email,
                "firstName": modifiedFormData.firstName,
                "lastName": modifiedFormData.lastName,
                "middleName": modifiedFormData.middleName,
                "mobileNumber": [
                    modifiedFormData.phoneNumbers.mobilePhone
                ]
            }
            try {
                const response = await Axios.post(getSvcServiceURL({ service: 'update' }), bodyForProfileEdit, { cdcType: "servicing_Update" });
                const permission = await Axios.put(getDocumentmgmtServiceURL({ service: 'updateProfilePermission' }), bodyForDocManagement, { cdcType: "docmanagement" });
                Promise.resolve(true);
            } catch (error) {
                Promise.reject(error);
            }
        };
    },
    getUserProfileData: (id) => {
        return function (dispatch, getState) {

        }
    }
}