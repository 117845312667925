import React from 'react';
import { Route, Switch, BrowserRouter as Router } from 'react-router-dom';
import ManageUsers from '../pages/manage-user/ManageUsers';
import ProfileEditReview from '../pages/profile-edit-review/ProfileEditReview';
import EmployeeRegistration from '../pages/employee-registration/EmployeeRegistration';
import ConfirmUser from '../pages/confirm-user/ConfirmUser';
import AssignPermissions from '../pages/assign-permission/AssignPermissions';
import AssignPermissionsCDC from '../pages/assign-permission/AssignPermissionsCDC';
import UserType from '../pages/user-type/UserType';
import history from './history';
import ParentCompany from '../pages/parent-company/ParentCompany';
import EditUserProfile from '../pages/edit-user-flow/EditUserProfile';
import MyProfile from '../pages/my-profile/MyProfile2';
import PermissionRoleContextProvider from '../components/Hooks/usePermissionRoles';
import FeatureFlagsContextProvider from '../components/Hooks/useFeatureFlags';
import ConfigConstantsContextProvider from '../components/Hooks/useConfigConstants';

const ProfileManageUserExternal = () => {
  return (
    <>
      <ConfigConstantsContextProvider>
        <FeatureFlagsContextProvider>
          <PermissionRoleContextProvider>
            <Router history={history}>
              <Switch>
                <Route
                  exact
                  path="/ManageUser"
                  render={(props) => <ManageUsers {...props} />}
                ></Route>
                <Route
                  path="/ManageUser/parent-company"
                  render={(props) => <ParentCompany {...props} />}
                ></Route>
                <Route
                  path="/ManageUser/user-type"
                  render={(props) => <UserType {...props} />}
                ></Route>
                <Route
                  path="/ManageUser/emp-reg"
                  render={(props) => <EmployeeRegistration {...props} />}
                ></Route>
                <Route
                  path="/ManageUser/edit-profile"
                  render={(props) => <EditUserProfile {...props} />}
                ></Route>
                <Route
                  path="/ManageUser/permissions"
                  render={(props) => (
                    <AssignPermissions {...props} createView={true} />
                  )}
                ></Route>
                <Route
                  path="/ManageUser/profile-review"
                  render={(props) => <ProfileEditReview {...props} />}
                ></Route>
                <Route
                  path="/ManageUser/confirm-user"
                  render={(props) => <ConfirmUser {...props} />}
                ></Route>

                <Route
                  path="/ManageUser/internal-permission"
                  render={(props) => <AssignPermissionsCDC edit={false} {...props} />}
                ></Route>
                <Route
                  path="/ManageUser/my-profile"
                  render={(props) => <MyProfile {...props} />}
                ></Route>
              </Switch>
            </Router>
          </PermissionRoleContextProvider>
        </FeatureFlagsContextProvider>
      </ConfigConstantsContextProvider>
    </>
  );
};

export default ProfileManageUserExternal;
