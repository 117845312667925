import { store } from '../../shared/store';
import AssignPermissionHelper from '../assign-permission/assign-permission-helper';

export class EntitlementDetails {
    currentState = null;
    isFrequentVendor = false;
    constructor() {
        this.currentState = store.getState();
        this.isFrequentVendor = this.currentState.currentUser.isFrequentVendor

    }

    getEntitlementState() {
        return this.currentState.entitlement ? this.currentState.entitlement.entitlement : undefined;
    }

    getUserDetails() {
        return this.currentState.userProfileInformation.profileData;
    }

    getVendorContributorEntities() {
        let parentEntityList = {};
        let finalParentEntityList = []
        if (this.currentState.entitlement.legalEntity) {
            parentEntityList = this.currentState.entitlement.entitlement.selectedLegalEntityList.reduce((acc, cur) => {
                const parentCompanyName = cur.value.split(' | ')[1];
                const value = cur.value.split(' | ')[0];
                if (acc[parentCompanyName] && acc[parentCompanyName].reportingEntitys) {
                    acc[parentCompanyName].reportingEntitys = [
                        ...acc[parentCompanyName].reportingEntitys,
                        {
                            key: cur.key,
                            value: value
                        }
                    ];
                } else {
                    acc[parentCompanyName] = {
                        ...acc[parentCompanyName],
                        reportingEntitys: [{
                            key: cur.key,
                            value: value
                        }]
                    }
                }
                return acc;
            }, parentEntityList);
        }
        if (this.currentState.entitlement.dealEntity) {
            parentEntityList = this.currentState.entitlement.entitlement.selectedDealEntityList.reduce((acc, cur) => {
                const parentCompanyName = cur.value.split(' | ')[1];
                const value = cur.value.split(' | ')[0];
                if (acc[parentCompanyName] && acc[parentCompanyName].deals) {
                    acc[parentCompanyName].deals = [...acc[parentCompanyName].deals, {
                        key: cur.key,
                        value: value
                    }];
                } else {
                    acc[parentCompanyName] = {
                        ...acc[parentCompanyName],
                        deals: [{
                            key: cur.key,
                            value: value
                        }]
                    }
                }
                return acc;
            }, parentEntityList);
            // parentEntityList.allDealsFlag = this.currentState.entitlement.isDealAllSelected.toString();
        }
        let selectedCategoryList = [];
        if (this.currentState.entitlement.entitlement.selectedEntiyDocumentList) {
            selectedCategoryList = this.currentState.entitlement.entitlement.selectedEntiyDocumentList;
        } else if (this.currentState.entitlement.entitlement.selectedDealDocumentList) {
            selectedCategoryList = this.currentState.entitlement.entitlement.selectedDealDocumentList;
        }
        if (selectedCategoryList.length > 0 && this.currentState.entitlement.entitlementServerResponse) {
            parentEntityList = selectedCategoryList.reduce((acc, cur) => {
                this.currentState.entitlement.entitlementServerResponse.forEach(entity => {
                    const categoryExist = entity.categories?.find(c => c.key === cur.key);
                    if (categoryExist) {
                        if (acc[entity.name] && acc[entity.name].categories) {
                            if(cur.key?.includes("-")){
                                acc[entity.name].categories = [...acc[entity.name].categories, {
                                    key: cur.key,
                                    value: cur.key
                                }]
                            } else if(cur.value==""){
                                acc[entity.name].categories = [...acc[entity.name].categories, {
                                    key: cur.key,
                                    value: cur.key
                                }]
                            } else if(cur.key == cur.value){
                                acc[entity.name].categories = [...acc[entity.name].categories, {
                                    key: cur.key,
                                    value: cur.value
                                }]
                            } else {
                                acc[entity.name].categories = [...acc[entity.name].categories, {
                                    key: cur.key + " - " + cur.value,
                                    value: cur.key + " - " + cur.value
                                }]
                            }
                        } else {
                            if(cur.key?.includes("-")){
                                acc[entity.name] = {
                                    ...acc[entity.name],
                                    categories: [{
                                        key: cur.key,
                                        value: cur.key
                                    }]
                                }
                            } else if(cur.value==""){
                                acc[entity.name] = {
                                    ...acc[entity.name],
                                    categories: [{
                                        key: cur.key,
                                        value: cur.key
                                    }]
                                }
                            } else if(cur.value==cur.key){
                                acc[entity.name] = {
                                    ...acc[entity.name],
                                    categories: [{
                                        key: cur.key,
                                        value: cur.key
                                    }]
                                }
                            } else {
                                acc[entity.name] = {
                                    ...acc[entity.name],
                                    categories: [{
                                        key: cur.key + " - " + cur.value,
                                        value: cur.key + " - " + cur.value
                                    }]
                                }
                            }
                        }
                    }
                });
                return acc;
            }, parentEntityList)

        }

        finalParentEntityList = Object.keys(parentEntityList).map(parentCompanyName => {
            const companyDetails = this.currentState.entitlement.entitlementServerResponse.find(par => par.name === parentCompanyName);
            if (companyDetails) {
                return {
                    number: companyDetails.number,
                    name: companyDetails.name,
                    parentLegalEntityID: companyDetails.parentLegalEntityID,
                    role: this.currentState.userProfileInformation.profileData.profileRole,
                    reportingEntitys: parentEntityList[parentCompanyName].reportingEntitys,
                    deals: parentEntityList[parentCompanyName].deals,
                    categories: parentEntityList[parentCompanyName].categories,
                    allReportingEntitysFlag:this.currentState.entitlement.isLegalAllSelected.toString(),
                    allDealsFlag: this.currentState.entitlement.isDealAllSelected.toString(),
                    allCategoriesFlag: this.currentState.entitlement.isAllCategoriesSelected.toString()
                }
            }

        })
            .filter(list => list.deals || list.reportingEntitys)
            .map(ent => {
                return {
                    ...ent,
                    allReportingEntitysFlag: this.currentState.entitlement.isLegalAllSelected.toString(),
                    allDealsFlag: this.currentState.entitlement.isDealAllSelected.toString(),
                    allCategoriesFlag: this.currentState.entitlement.isAllCategoriesSelected && ent.categories ? 'true' : 'false'
                }
            });
        return finalParentEntityList;
    }
    isProfileRoleSiteAdmin() {
        return this.currentState.userProfileInformation.profileData && this.currentState.userProfileInformation.profileData.profileRole && this.currentState.userProfileInformation.profileData.profileRole.toLowerCase() === "site administrator"
    }

    getVendorEntitlementBodyForApi() {
        const entitlements = this.getEntitlementState();
        if (entitlements &&
            AssignPermissionHelper.isFrequentVendor(this.currentState.currentUser.isFrequentVendor,
                this.currentState.parentCompanyInformation.parentCompany, this.currentState.userProfileInformation.profileServerData)) {
            if (this.isProfileRoleSiteAdmin() || this.currentState.entitlement.assignAll) {
                return this.currentState.entitlement.entitlementServerResponse.map(entity => {
                    entity.role = this.isProfileRoleSiteAdmin() ? 'Site administrator' : 'Contributor';
                    entity.allReportingEntitysFlag =  entity.reportingEntitys ? 'true' : 'false';
                    entity.allDealsFlag =  entity.deals ? 'true' : 'false';
                    entity.allCategoriesFlag =  entity.categories ? 'true' : 'false';
                    return entity;
                });
            } else {
                return this.getVendorContributorEntities();
            }
        }
    }
}