// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/img/search.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".page-content-vendor-access .table.auto tr th{font-size:1rem;font-weight:600;line-height:1;vertical-align:top;max-width:250px;white-space:normal;word-wrap:normal;text-align:center;border-color:transparent transparent #cdcdd3 #cdcdd3;border-style:none none solid solid;border-width:0 0 1px 1px;padding:1.25rem .5rem 1rem;white-space:nowrap}.page-content-vendor-access .table.auto tr td.wrapped{white-space:pre-wrap !important}.page-content-vendor-access .table.auto tr td{max-width:150px !important;text-align:left;overflow:hidden;word-wrap:break-word;text-overflow:ellipsis;white-space:pre-wrap;border-left:1px solid #cdcdd3;padding:1rem 1.5rem}.content-bg-vendor-access{background-color:#f7f7fa}.page-details-wrap-vendor-access{float:left;width:100%}.page-details-container-vendor-access{margin-top:24px;font-size:14px;width:53%;float:left;text-align:justify;text-justify:inter-word}.sorting-inputs-vendor-access{width:100%;padding:15px 0px 10px;display:inline-flex;margin-bottom:24px}.filter-dropdowns-vendor-access{float:left;width:25%;margin-right:15px}.input-search{width:60%;margin:20px 0 0;border-radius:0 3px 3px 0 !important;padding:6px 4px 6px 30px;background-position:5px center;background-size:24px;background-repeat:no-repeat;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");height:40px !important;background-color:#fff;font-size:16px;border:1px solid #6e6e6e}#clearFilterVendorAccess{margin-top:21px !important}#clearFilterVendorAccess:hover{color:#fff !important}.page-content-vendor-access{width:100%;margin-bottom:24px}", ""]);
// Exports
module.exports = exports;
