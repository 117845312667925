import React, { createContext, useState, useEffect } from 'react';
import { endpoints } from '../../../config/connections';
import callApi from '../utilities/callApi';
import { saveAsFile, getQS } from '../../components/utilities/Utils';
import { parseJwt } from '../utilities/Utils';

export const IBDownloadsContext = createContext({ filesList: { original: [], filtered: [] }, setFileList: (list) => { }});
// export const IBDownloadsContext1 = createContext({downloadType:'', setDownloadType:()=>null})
const IBDownloadsContextProvider = (props) => {
    const requirementId = getQS('id');
    const [filesList, setFileList] = useState({ original: [], filtered: [] });
    const [downloadType, setDownloadType]= useState(null)
    const userID = sessionStorage.getItem('tok') != undefined
    ? `${parseJwt(sessionStorage.getItem('tok')).custom_data.role.toUpperCase() === 'APP_8083_CDCWEB_SUPER_ADMINS' ? sessionStorage.getItem('assignedUser') : parseJwt(sessionStorage.getItem('tok')).sub}`
    : ''
    const initiateDonwload = async (list) => {
        let updatedList = [];
        if (list?.length > 0) {
            for (let j = 0; j < list.length; j++) {
                if (list[j].downloadStatus != "Completed") {
                    try {
                        const getApiURL = `${endpoints.envUrl}/document-management/file-download/v1/inbox/${requirementId}/files/${list[j].id}`;
                        // let response = await callApi.getApiData(getApiURL);
                        let response = await fetch(getApiURL, {
                            method: 'GET',
                            headers: {
                                'Content-Type': 'application/pdf',
                                'Client-Application':'CDC',
                                'Correlation-ID': userID,
                                'jwtToken': `${sessionStorage.getItem('tok')}`

                            },
                        })
                        if (response.status == 200) {
                            let blob = await response.blob();
                            const url = window.URL.createObjectURL(new Blob([blob]));

                            const link = document.createElement('a');
                            link.href = url;
                            link.download = list[j].name;

                            document.body.appendChild(link);

                            link.click();

                            link.parentNode.removeChild(link);
                            updatedList.push({ name: list[j].name, id: list[j].id, downloadStatus: "Completed", retryCount: list[j].retryCount })

                            window.URL.revokeObjectURL(url)
                            // console.log(list, "list");
                        } else if (response.status != 200) {
                            list[j].retryCount += 1;
                            list[j].downloadStatus = "Failed"
                            updatedList.push({ name: list[j].name, id: list[j].id, downloadStatus: "Failed", retryCount: list[j].retryCount + 1 })
                            // console.log(list, "list failed ", j);
                        }
                    } catch (error) {
                        // console.log(error)
                    }
                } else {
                    updatedList.push(list[j])
                }
            }
            if (updatedList.length == list.length) {
                return updatedList;
            }
        }
    }

    const updateList = async (list) => {
        let updatedList = await initiateDonwload(list)
        if (updatedList?.length > 0) {
            setFileList({ original: updatedList, filtered: updatedList })
            sessionStorage.setItem("downloadFiles",JSON.stringify({ original: updatedList, filtered: updatedList }))
        }
        if (updatedList?.length > 0 && updatedList.filter((file) => file.downloadStatus == "Failed" && file.retryCount < 4).length > 0) {
            // console.log("list update called");
            updateList(updatedList)
        }

    }

    useEffect(() => {
        if (sessionStorage.getItem("downloadFiles")) {
            setFileList(JSON.parse(sessionStorage.getItem("downloadFiles")))
        }
    }, []);

    useEffect(() => {
        // console.log("list changed", filesList);
        sessionStorage.setItem("downloadFiles", JSON.stringify(filesList))
        if (filesList.original.filter((file) => file.downloadStatus == 'Pending').length == filesList.original.length || filesList.original.filter((file) => file.downloadStatus == "Pending" && file.retryCount == 0).length > 0) {
            updateList(filesList.original)
        }
    }, [filesList])

    return (

        <IBDownloadsContext.Provider value={{ filesList, setFileList }}>
            {props.children}
        </IBDownloadsContext.Provider>
    );

};

export default IBDownloadsContextProvider;