// @flow
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  USBButton,
  USBTabs,
  USBNotification,
} from '@usb-shield/components-react/dist/cjs';
import {  getDocumentmgmtServiceURL } from '../../config/connections';
import { USBGrid, USBColumn } from '@usb-shield/components-react';
import LoginInfo from './LoginInfo';
import { getSvcServiceURL, getAuthServiceURL } from '../../config/connections';
import LoadingHelper from '../../utilities/loading-helper';
import getCountryListThunk from '../../shared/thunk/get-country-list';
import GraphQlQuery from '../../utilities/graphQL-query';
import { userProfileThunk } from '../edit-user-flow/user-profile.thunk';
import Axios from 'axios';
import UserInformationForm from '../employee-registration/UserInformationForm';
import UserPermissions from './UserPermissions';
import { FormValidationHelpers } from '../../utilities/form-validation-helpers';
import { transmitIframeMessage } from '../../components/utility/transmit-message';
// import OtpDialog from '../../components/shared/otp-modal-wrapper';
import { connect } from 'react-redux';
import ConfirmationDialog from '../../components/shared/modal-wrapper';

const MyProfile2 = (props) => {
  const userProfileRef = React.useRef();

  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState('1');
  const [isSuccess, setIsSuccess] = useState(false);
  const [isGlobalError, setIsGlobalError] = useState(false);
  const [globalNotificationMessage, setGlobalNotificationMessage] = useState(
    []
  );
  const [isFormInvalidError, setIsFormInvalidError] = useState(false);
  const [isBusinessPhoneTopLevelError, setIsBusinessPhoneTopLevelError] =
    useState(false);
    const [isMobilePhoneChanged, setIsMobilePhoneChanged] =
    useState(false);
    
    const [isMobilePhoneChangedResponse, setIsMobilePhoneChangedResponse] = useState('');

  const [isMobilePhoneTopLevelError, setIsMobilePhoneTopLevelError] =
    useState(false);
  const [user, setUser] = useState(new Object());
  const [otpModal, setOtpModal] = useState({ otpModalObj: {} });

  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
  const [userClickedTabInMemory, setUserClickedTabInMemory] = useState('1');

  const [loginUserModified, setLoginUserModified] = useState(false);
  const [isOtpTransmitFailure, setIsOtpTransmitFailure] = useState(false);
  const [dataChangeConfirmationAccepted, setDataChangeConfirmationAccepted] =
    useState(false);

  const [userProfileDetails, setUserProfileDetails] = useState({
    firstName: '',
    middleName: '',
    lastName: '',
    email: '',
    profileRole: '',
    phoneNumbers: {
      mobilePhone: '',
      businessPhone: '',
    },
    address: {
      addressLine1: '',
      addressLine2: '',
      city: '',
      state: '',
      zipCode: '',
      country: '',
    },
  });
  const [countryList, setCountryList] = useState([]);
  const currentLoggedInUser = useSelector((state) => state.currentUser.user);
  const initialProfileData = useSelector(
    (state) => state.userProfileInformation.profileServerData
  );
  const [mobileNoBeforeUpdate, setMobileNoBeforeUpdate] = useState('');

  useEffect(() => {
    getUserProfileDetails();
    getCountryList();
  }, []);

  const getCountryList = () => {
    LoadingHelper.inFlight();
    dispatch(getCountryListThunk())
      .then((res) => {
        LoadingHelper.outFlight();
        setCountryList(res);
      })
      .catch((error) => {
        LoadingHelper.outFlight();
        console.log(error);
        setIsGlobalError(true);
        setGlobalNotificationMessage([
          {
            text: 'We can’t access your information right now. Check back later.',
          },
        ]);
        transmitIframeMessage.scrollUp();
      });
  };
  const getUserProfileDetails = () => {
    const graphURL = getSvcServiceURL({ service: 'graph' });
    const query = GraphQlQuery.getProfileByUserId(currentLoggedInUser.sub);
    LoadingHelper.inFlight();
    Axios.post(graphURL, query, { cdcType: 'servicing' })
      .then((response) => {
        LoadingHelper.outFlight();
        const data = response.data;
        if (
          data &&
          data.data &&
          data.data.getProfileList &&
          data.data.getProfileList.profiles &&
          data.data.getProfileList.profiles[0]
        ) {
          setFormData(data.data.getProfileList.profiles[0]);
        }
        dispatch({
          type: 'SERVER_PROFILE_DATA',
          payload: data.data.getProfileList.profiles[0],
        });
        console.log(response);
      })
      .catch((error) => {
        LoadingHelper.outFlight();
        console.error(
          'There is an Employee Information Error for Update User.',
          error
        );
        setIsGlobalError(true);
        setGlobalNotificationMessage([
          {
            text: 'We can’t access your information right now. Check back later.',
          },
        ]);
        transmitIframeMessage.scrollUp();
      });
  };

  const setFormData = (data) => {
    setMobileNoBeforeUpdate(data.phoneNumbers.mobilePhone);
    setUserProfileDetails({
      firstName: data.firstName,
      middleName: data.middleName,
      lastName: data.lastName,
      profileRole: data.profileRole,
      email: data.email,
      phoneNumbers: {
        mobilePhone: data.phoneNumbers ? data.phoneNumbers.mobilePhone : '',
        businessPhone: data.phoneNumbers ? data.phoneNumbers.businessPhone : '',
      },
      address: {
        addressLine1: data.address ? data.address.addressLine1 : '',
        addressLine2: data.address ? data.address.addressLine2 : '',
        city: data.address ? data.address.city : '',
        state: data.address ? data.address.state : '',
        zipCode: data.address ? data.address.zipCode : '',
        country: data.address ? data.address.country : '',
      },
      userId: data.userID,
      parentCompanyName: data.parentCompanyName ? data.parentCompanyName : '',
    });
  };

  const resetNotification = () => {
    setIsSuccess(false);
    setIsGlobalError(false);
    setGlobalNotificationMessage([]);
    setIsOtpTransmitFailure(false);
    setIsFormInvalidError(false);
    setIsBusinessPhoneTopLevelError(false);
    setIsMobilePhoneTopLevelError(false);
  };

  const setProfilePermissionsApiError = (
    isProfilePermissionsApiErrorApiError
  ) => {
    LoadingHelper.outFlight();
    setIsGlobalError(true);
    setGlobalNotificationMessage([
      {
        text: 'We can’t access your information right now. Check back later.',
      },
    ]);
    transmitIframeMessage.scrollUp();
  };

  const onContinueClick = (
    userDetails,
    isFormValid,
    isFormInvalidError,
    usrfirstname,
    usrMiddleName,
    usrLastName,
    usremail,
    usrmobilephone,
    usrbusinessphone
  ) => {
    
    resetNotification();
    if (userDetails.firstName !== usrfirstname) {
      userDetails.firstName = usrfirstname;
    }
    if (userDetails.lastName !== usrLastName) {
      userDetails.lastName = usrLastName;
    }
    if (userDetails.middleName !== usrMiddleName) {
      userDetails.middleName = usrMiddleName;
    }
    if (userDetails.phoneNumbers.businessPhone !== usrbusinessphone) {
      userDetails.phoneNumbers.businessPhone = usrbusinessphone;
    }
    if (userDetails.phoneNumbers.mobilePhone !== usrmobilephone) {
      userDetails.phoneNumbers.mobilePhone = usrmobilephone;
    }

    dispatch({
      type: 'PROFILE_VALIDATED_DATA',
      payload: {
        userDetails: userDetails,
        isFormValid: isFormValid,
        isFormInvalidError: isFormInvalidError,
      },
    });
    if (isFormValid) {
      if (!isFormInvalidError) {
        if (mobileNoBeforeUpdate !== userDetails.phoneNumbers.mobilePhone) {
          var element = document
            .getElementById('usrbusinessphone')
            .getElementsByTagName('input')[0];
          if (element) {
            element.focus();
            element.blur();
          }
          updateProfile(userDetails, isFormValid, isFormInvalidError);
          transmitIframeMessage.scrollUp();
          // setTimeout(() => {
          //   openOtpModal(userDetails.phoneNumbers.mobilePhone);
          // }, 500);
        } else {
          updateProfile(userDetails, isFormValid, isFormInvalidError);
          transmitIframeMessage.scrollUp();
        }
      } else {
        setIsFormInvalidError(true);
        //setIsMobilePhoneTopLevelError(true);
        transmitIframeMessage.scrollUp();
      }
    } else {
      setUser(userDetails);
      setIsFormInvalidError(isFormInvalidError);
      let isMobilePhoneTopLevelError =
        FormValidationHelpers.validateMobileNumber(
          userDetails.phoneNumbers.mobilePhone
        );
      setIsMobilePhoneTopLevelError(isMobilePhoneTopLevelError);
      transmitIframeMessage.scrollUp();
    }
  };

  const updateProfile = async (userDetails, isFormValid, isFormInvalidError) => {
    if (isFormValid) {
      if (!isFormInvalidError) {
        LoadingHelper.inFlight();
        if (isMobilePhoneChanged) {                
          let phoneNumberUpdate = {
                  url: '',
                  body: null
              };  
              phoneNumberUpdate.url = getDocumentmgmtServiceURL({
                  service: 'updatePhoneNumber',
                  userid: `${initialProfileData.userID}`
              });
            
              phoneNumberUpdate.body = {
                  "userID": initialProfileData.userID,
                  "email": userDetails.email,
                  "phone": {
                      "phoneNumber": userDetails.phoneNumbers.mobilePhone
                  },
                  "updateProfileDB": false
              }

       const phoneUpdateResp =  await Axios.put(phoneNumberUpdate.url, phoneNumberUpdate.body, { cdcType: "docmanagement" });
       
       if(phoneUpdateResp.status==200){
        setIsMobilePhoneChangedResponse("Phone number udpated successfully");
       }

      }

        dispatch(userProfileThunk.submitUserProfileEdit(userDetails))
          .then((response) => {
            if (userProfileRef.current !== undefined)
              userProfileRef.current.clearFormChange();
            LoadingHelper.outFlight();
            setIsSuccess(true);
            setGlobalNotificationMessage([
              {
                text: `You’ve updated your profile. ${isMobilePhoneChangedResponse}`,
              },
            ]);
            transmitIframeMessage.scrollUp();
          
            setMobileNoBeforeUpdate(userDetails.phoneNumbers.mobilePhone);
            // window.scrollTo(0,0);
          })
          .catch((error) => {
            LoadingHelper.outFlight();
            setIsGlobalError(true);
            setGlobalNotificationMessage([
              {
                text: 'There was a problem saving the changes to your profile. Please try again.',
              },
            ]);
            transmitIframeMessage.scrollUp();
          });
      } else {
        setIsFormInvalidError(true);
        setIsMobilePhoneTopLevelError(true);
      }
      transmitIframeMessage.scrollUp();
    } else {
      setUser(userDetails);
      setIsFormInvalidError(isFormInvalidError);
      let isMobilePhoneTopLevelError =
        FormValidationHelpers.validateMobileNumber(
          userDetails.phoneNumbers.mobilePhone
        );
      setIsMobilePhoneTopLevelError(isMobilePhoneTopLevelError);
    }
    transmitIframeMessage.scrollUp();
  };

  var isModalOpen = false;
  const openOtpModal = (phoneNumber) => {
    isModalOpen = true;
    let modalObj = {
      open: true,
      phoneNumber: phoneNumber,
      title: 'Enter your passcode.',
      message:
        'We sent a six-digit code to your mobile device XXX-XXX-<####>. Your code will expire in 15 minutes.',
      primaryText: 'Continue',
    };

    setOtpModal({
      ...otpModal,
      otpModalObj: {
        ...otpModal.otpModalObj,
        ...modalObj,
      },
    });
    setTimeout(() => {
      transmitIframeMessage.scrollToPosition();
    }, 2000);
  };

  const onPhoneNumberChange = (userDetails: UserProfileFormModel) => {
    let isBusinessPhoneTopLevelError =
      FormValidationHelpers.validateMobileNumber(
        userDetails.phoneNumbers.businessPhone
      )
        ? true
        : false;
    let isMobilePhoneTopLevelError = FormValidationHelpers.validateMobileNumber(
      userDetails.phoneNumbers.mobilePhone
    )
      ? true
      : false;
    setIsFormInvalidError(false);
    setIsBusinessPhoneTopLevelError(isBusinessPhoneTopLevelError);
    setIsMobilePhoneTopLevelError(isMobilePhoneTopLevelError);
    setIsMobilePhoneChanged(true);
  };

  const onTabClick = (e, tabIndex) => {
    if (activeTab == tabIndex || !e.isTrusted) {
      return;
    }
    setIsSuccess(false);
    setIsGlobalError(false);
    setGlobalNotificationMessage([]);
    setIsFormInvalidError(false);
    setIsBusinessPhoneTopLevelError(false);
    setIsMobilePhoneTopLevelError(false);
    setUser(new Object());
    let profileFormChanged = false;
    profileFormChanged = props.isProfileFormChanged;
    if (profileFormChanged === false) {
      profileFormChanged =
        FormValidationHelpers.profileFormDataChanged(userProfileDetails);
    }
    if (dataChangeConfirmationAccepted == true) {
      profileFormChanged = false;
    }
    if (activeTab == '1' && profileFormChanged) {
      e.stopPropagation();
      setOpenConfirmationDialog(true);
      setUserClickedTabInMemory(tabIndex);
    } else if (activeTab == '3' && loginUserModified) {
      e.stopPropagation();
      setOpenConfirmationDialog(true);
      setUserClickedTabInMemory(tabIndex);
    } else {
      setActiveTab(tabIndex);
      setUserClickedTabInMemory(tabIndex);
    }
  };

  const onPassChangeFormSubmit = (
    passwordChangeFormData,
    validationMessages
  ) => {
    let errorMessage = [];
    let formValid = true;
    if (
      !validationMessages.currentPassword.isTouched ||
      validationMessages.currentPassword.required ||
      !validationMessages.password.isTouched ||
      validationMessages.password.required ||
      !validationMessages.confirmPassword.isTouched ||
      validationMessages.confirmPassword.required
    ) {
      if (
        !validationMessages.currentPassword.isTouched ||
        validationMessages.currentPassword.required
      ) {
        errorMessage = [
          {
            text: 'Incorrect current password. Re-enter your current ',
            linkText: 'password.',
            link: '#currentpassword',
          },
        ];
      }
      if (
        !validationMessages.password.isTouched ||
        validationMessages.password.required
      ) {
        errorMessage = [
          ...errorMessage,
          {
            text: 'Enter your new ',
            linkText: 'password.',
            link: '#logininfo',
          },
        ];
      }
      if (
        !validationMessages.confirmPassword.isTouched ||
        validationMessages.confirmPassword.required
      ) {
        errorMessage = [
          ...errorMessage,
          {
            text: 'Confirm your new ',
            linkText: 'password.',
            link: '#login-info-confirm-password',
          },
        ];
      }
      formValid = false;
    }
    if (
      validationMessages.password.isTouched &&
      !validationMessages.password.required &&
      (!validationMessages.password.rules.characterlength ||
        !validationMessages.password.rules.atLeastOneAlphabate ||
        !validationMessages.password.rules.atLeastOneNumeric)
    ) {
      errorMessage = [
        ...errorMessage,
        {
          text: 'Enter your new ',
          linkText: 'password.',
          link: '#logininfo',
        },
      ];
      formValid = false;
    }
    if (
      validationMessages.confirmPassword.isTouched &&
      !validationMessages.confirmPassword.required &&
      !validationMessages.confirmPassword.matched
    ) {
      errorMessage = [
        ...errorMessage,
        {
          text: 'Confirm your new ',
          linkText: 'password.',
          link: '#login-info-confirm-password',
        },
      ];
      formValid = false;
    }
    setGlobalNotificationMessage(errorMessage);
    setIsGlobalError(true);
    transmitIframeMessage.scrollUp();
    // return;
    if (formValid) {
      passwordChange(
        passwordChangeFormData.currentPassword,
        passwordChangeFormData.password
      );
      resetNotification();
    }
  };

  const passwordChange = (currentPassword, password) => {
    let baseUrL = getAuthServiceURL({ service: 'changePassword' });
    LoadingHelper.inFlight();
    Axios.post(
      baseUrL,
      {
        userID: currentLoggedInUser.sub,
        currentPassword: currentPassword,
        newPassword: password,
      },
      { cdcType: 'auth_non_cdc_user' }
    )
      .then((res) => {
        LoadingHelper.outFlight();
        setLoginUserModified(false);
        setIsSuccess(true);
        setGlobalNotificationMessage([
          {
            text: 'You’ve changed your password.',
          },
        ]);
        transmitIframeMessage.scrollUp();
      })
      .catch((error) => {
        LoadingHelper.outFlight();
        if (error.response.status === 409) {
          setIsGlobalError(true);
          setGlobalNotificationMessage([
            {
              text: 'That password has already been used. Enter a new ',
              linkText: 'password.',
              link: '#logininfo',
            },
          ]);
          // window.scrollTo(0,0);
        } else {
          setIsGlobalError(true);
          setGlobalNotificationMessage([
            {
              text: 'There was a problem saving the changes to password. Please try again.',
            },
          ]);
          // window.scrollTo(0,0);
        }
        transmitIframeMessage.scrollUp();
      });
  };

  const onJumpLinkClick = () => {
    setTimeout(() => {
      let str = window.location.hash;
      handleFieldFocus(str);
    }, 300);
  };

  const handleFieldFocus = (type: string): void => {
    switch (type) {
      case '#mobile-number':
        document
          .getElementById('usrmobilephone')
          .getElementsByTagName('input')[0]
          .focus();
        break;
      default:
        const element = document.querySelector(type + ' input');
        if (element) {
          element.focus();
        }
        break;
    }
  };

  const onCancel = () => {
    isModalOpen = false;
    setOtpModal({
      otpModalObj: { open: false },
    });
  };

  const otpTransmitSuccess = () => {
    isModalOpen = false;
    setOtpModal({
      otpModalObj: { open: false },
    });
    updateProfile(
      props.profileValidatedData.userDetails,
      props.profileValidatedData.isFormValid,
      props.profileValidatedData.isFormInvalidError
    );
  };

  const otpTransmitFailure = () => {
    isModalOpen = false;
    setOtpModal({
      otpModalObj: { open: false },
    });
    setIsOtpTransmitFailure(true);
  };

  const dialogContinueForward = () => {
    setOpenConfirmationDialog(false);
    setDataChangeConfirmationAccepted(true);
    setActiveTab(userClickedTabInMemory);
    // moving to permissions tab.
    if (userClickedTabInMemory == '2') {
      setActiveTab(userClickedTabInMemory);
      document.getElementById('tab-permissions').click();
    }
    if (userClickedTabInMemory == '1') {
      setActiveTab(userClickedTabInMemory);
      document.getElementById('tab-personal-info').click();
    }
    if (userClickedTabInMemory == '3') {
      setActiveTab(userClickedTabInMemory);
      document.getElementById('tab-login-info').click();
    }
  };

  const dialogCancel = () => {
    setOpenConfirmationDialog(false);
  };

  return (
    <React.Fragment>
      {/* <OtpDialog open={otpModal.otpModalObj.open} toggle={onCancel} title={otpModal.otpModalObj.title} phoneNumber={otpModal.otpModalObj.phoneNumber} body={otpModal.otpModalObj.message}
                primaryButtonText={otpModal.otpModalObj.primaryText} transmitSucessHandler={otpTransmitSuccess} transmiFailureHandler={otpTransmitFailure}
            /> */}
      <ConfirmationDialog
        open={openConfirmationDialog}
        title={'Continue without saving?'}
        multiLineBody={[]}
        body={
          activeTab == '1'
            ? 'You’ll lose any changes you made to your personal info.'
            : 'You’ll lose any changes you made to your login info.'
        }
        toggle={dialogCancel}
        primaryButtonText={'Continue'}
        primaryClickHandler={dialogContinueForward}
      />

      <div className="sp-mu-title sp-mr-b-16 ">
        <h1 className="sp-page-title">My profile</h1>
      </div>

      <USBGrid>
        <USBColumn largeSpan={16}>
          {isOtpTransmitFailure ? (
            <div className="sp-width-100">
              <USBNotification
                addClasses="sp-mr-b-16"
                variant={'error'}
                roleType="alert"
                handleClose={resetNotification}
                notificationData={[
                  { text: 'Please enter valid Mobile Number' },
                ]}
              />
            </div>
          ) : null}
          {isSuccess || isGlobalError ? (
            <div className="sp-width-100" onClick={onJumpLinkClick}>
              <USBNotification
                addClasses="sp-mr-b-16"
                variant={isSuccess ? 'confirmation' : 'error'}
                roleType="alert"
                handleClose={isSuccess ? resetNotification : null}
                notificationData={globalNotificationMessage}
              />
            </div>
          ) : null}
          {isFormInvalidError && isMobilePhoneTopLevelError ? (
            <div className="sp-width-100" onClick={onJumpLinkClick}>
              <USBNotification
                id="multiple"
                addClasses="sp-mr-b-12"
                variant="error"
                roleType="alert"
                notificationData={[
                  {
                    text: 'Enter a valid',
                    linkText: 'mobile phone number.',
                    link: '#mobile-number',
                  },
                ]}
              />
            </div>
          ) : null}
        </USBColumn>
      </USBGrid>
      <USBGrid>
        <USBColumn largeSpan={9}>
          <div className="sp-mr-b-24">
            Review and update your personal information here.
            <br></br>
            <br></br>
            To view your username or change your password, go to the
            <strong> Login info </strong> tab.
          </div>
        </USBColumn>
      </USBGrid>

      <USBGrid gridGap="large">
        <USBColumn largeSpan={16}>
          <USBTabs
            tabs={[
              <span
                id="tab-personal-info"
                className="sp-my-profile-categories"
                onClick={(e) => onTabClick(e, '1')}
              >
                Personal info
              </span>,

              <span
                id="tab-permissions"
                className="sp-my-profile-categories"
                onClick={(e) => onTabClick(e, '2')}
              >
                Permissions
              </span>,

              <span
                id="tab-login-info"
                className="sp-my-profile-categories"
                onClick={(e) => onTabClick(e, '3')}
              >
                Login info
              </span>,
            ]}
            addClasses="sp-width-100 sp-tabs-mb-spacing"
          >
            <div className="sp-row sp-mr-b-24 sp-col-6">
              <USBColumn largeSpan={8}>
                <UserInformationForm
                  ref={userProfileRef}
                  onSubmitHandler={onContinueClick}
                  handlePhoneNumberChangeHandler={onPhoneNumberChange}
                  countryList={countryList}
                  formData={userProfileDetails}
                  primaryButtonText="Save changes"
                  readOnlyFields={[
                    'firstName',
                    'lastName',
                    'email',
                    'profileRole',
                    'companyName',
                  ]}
                  type="MYPROFILE"
                />
              </USBColumn>
            </div>

            <div>
              <UserPermissions
                profilePermissionsApiErrorHandler={
                  setProfilePermissionsApiError
                }
              />
            </div>

            <div>
              <LoginInfo
                loggedInUserId={currentLoggedInUser.sub}
                onSubmitHandler={onPassChangeFormSubmit}
                formChanged={setLoginUserModified}
              />
            </div>
          </USBTabs>
        </USBColumn>
      </USBGrid>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    profileValidatedData: state.userProfileInformation.profileValidatedData,
    isProfileFormChanged: state.userProfileInformation.isProfileFormChanged,
  };
};
export default connect(mapStateToProps)(MyProfile2);
