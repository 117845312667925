import React, { Component, Suspense } from 'react';
import { USBGrid, USBColumn } from '@usb-shield/components-react';
import { v4 as uuidv4 } from 'uuid';
import { endpoints } from '../../../config/connections';
import AppLoader from '@usb-ui-tools/micro-app-loader/dist/cjs/index.js';

const onTransmitAuthorizationSuccess = () => {
  console.log('onTransmitAuthorizationSuccess');
};
const onTransmitAuthorizationFailure = () => {
  console.log('onTransmitAuthorizationFailure');
};
const getCorrelationID = () => {
  const correlationId = uuidv4();
  typeof sessionStorage !== 'undefined'
    ? sessionStorage.setItem('correlationId', correlationId)
    : '';
  return correlationId;
};

const configHeader = {
  'Tenant-ID': 'USB',
  'App-ID': 'CDC',
  'Channel-ID': 'web',
  'App-Version': 'loginhelp-atg-1.0',
  AK: `${endpoints.configAK}`,
  'Correlation-ID': getCorrelationID(),
};

const configSettings = {
  transmitConfigs: {
    URL: "https://it1-transec.usbank.com",
    APP_ID: 'cdc_web',
    policyIds: {
      FORGOT_ID: 'forgot_uid',
      RESET_PASSWORD: 'forgot_password'
    },
    policyParams: {
      routingKey: endpoints.authRoutingKey,
      appId: 'CDC',
      tenantId: 'USB',
      channelId: 'web',
      appVersion: '1'
    }
  },
  constants: {
    USER_SUFFIX: '@cdc'
  },
  urls: {
    loginAssistance: {
      RETRIVE_ID: '/RetriveId',
      RESET_PASSWORD: '/ResetPassword',
      UPDATE_CREDENTIALS_API: endpoints.updateCredentialsApi
    }
  }
};

class LoginAssistance extends Component {
  constructor() {
    super();
    this.state = {};
  }

  redirectToLoginPortal = () => {
    window.location.href = '/';
  };

  render() {
    return (
      <>
        <USBGrid addClasses="content-frame">
          <USBColumn largeSpan={2} />
          <USBColumn largeSpan={12}>
            <div className="page-content-login">
              <div className="page-content">
                <div id="test-spinner" />

                <Suspense fallback={<div>Loading...</div>}>
                  <USBGrid addClasses="content-frame">
                    <USBColumn largeSpan={2} />
                    <USBColumn largeSpan={12}>
                      <div className="page-content-login">
                        <div className="page-content">
                          <div id="test-spinner" />
                          <AppLoader
                            elementRef={ React.createRef()}
                                  id="auth-loginhelp"
                                  isDomainPrefixedToManifestPath = {false}
                                  manifestPath={endpoints.manifestPathLoginHelp}
                                  namespace="USBAuthLoginHelpModule"
                                  appProps={{
                                      configApiHeaders: configHeader,
                                      configSettings: configSettings,
                                      onAuthorizationSuccess: onTransmitAuthorizationSuccess,
                                      onAuthorizationFailure: onTransmitAuthorizationFailure,
                                      // appNameForSiteCat: appNameForSiteCat,
                                      // uxNameForSiteCat: uxNameForSiteCat,
                                      // clientNameForSiteCat: clientNameForSiteCat,
                                      isOLB: false,
                                      isUMDFormat: false,
                                      isAppDEnabled: true,
                                      isReportingEnabled: true,
                                      isIovationEnabled: true,
                                      isJSLoggerEnabled: true,
                                      configApiURL: endpoints.configApiURL,
                                      // appDKey: appDynamicsKey,
                                      idShieldQuestion: false,
                                      isTux: false,
                                      isHeaderRequired: false,
                                      isFooterRequired: false
                                  }}    
                          /> 
                        </div>
                      </div>
                    </USBColumn>
                    <USBColumn largeSpan={2} />
                  </USBGrid>
                </Suspense>
              </div>
            </div>
          </USBColumn>
          <USBColumn largeSpan={2} />
        </USBGrid>
      </>
    );
  }
}

export default LoginAssistance;
