// React modules
import React, { useState, useEffect } from 'react';

// CSS
import './cbdoctypereport.css'

// Components

import { USBGrid, USBColumn, USBButton, USBInput, USBIconDelete, USBIconSuccess ,
    USBModal,
    USBModalHeader,
    USBModalFooter,
    USBModalCloseIcon } from '@usb-shield/components-react';
import Loader from '../../components/utilities/Loader';
import USBBreadcrumb from '@usb-shield/components-react/dist/cjs/breadcrumb';
import Messages from '../../components/organisms/Messages';
import callApi from '../../components/utilities/callApi';
import { endpoints } from '../../../config/connections';
import Toggle from './Toggle/toggle';



const CBDoctypeReport = () => {
    const [errorModal, setErrorModal] = useState(false);
    const [errorModalTitle, setErrorModalTitle] = useState('');
    const [documentId, setDocumentId] = useState('');
    const [loading, setLoading] = useState(false); // to manage loader
    const [docTypeFullData, setDoctypeFullData] = useState([]) // to store full report data
    const [addNewRowFlag, setAddNewRowFlag] = useState(false) // to show and hide new row
    const [newDoctypeData, setNewDocTypeData] = useState({
        cbDocType: '',
        cbDocTypeDesc: '',
        cbFileCategory: '',
        constructionDraws: false,
        financeReporting: false,
        leasingAndOperating: false,
        loanServicing: false,
        monitoringApproval: false
    }) // to store the values of new cb doctype; initializing with default values
    const [cbDocTypeError, setCbDocTypeError] = useState(false)
    const [cbDocTypeDescError, setCbDocTypeDescError] = useState(false)
    const [cbFileCategoryError, setCbFileCategoryError] = useState(false)
    const [successFlag, setSuccessFlag] = useState(false)
    const [failedFlag, setFailedFlag] = useState(false)
    const [messageContent, setMessageContent] = useState('')

    useEffect(() => {
        fetchDoctypeData()
    }, [])

    const fetchDoctypeData = () => {
        const fetchURL = `${endpoints.envUrl}/document-management/closing-binders/v1/cbDocTypeInfo-all`
        callApi.
            getApiData(fetchURL).
            then(response => {
                setDoctypeFullData(response.data)
                setFailedFlag(false)
            }).catch(error => {
                setFailedFlag(true)
                setMessageContent("There is some issue fetching DocType data")
            })
    }

    const updateApi = (updatedDataObj) => {
        const updateURL = `${endpoints.envUrl}/document-management/closing-binders/v1/cbDocTypeUpdate`
        callApi.
            postApi(updateURL, updatedDataObj).
            then(response => {
                fetchDoctypeData()
            }).catch(error => {
                setFailedFlag(true)
                setMessageContent("There is some issue updating DocType")
            })
    }

    const createApi = (newDoctypeDataObj) => {
        const createURL = `${endpoints.envUrl}/document-management/closing-binders/v1/cbDocTypeAdd`
        callApi.
            postApi(createURL, newDoctypeDataObj).
            then(response => {
                fetchDoctypeData()
                setSuccessFlag(true)
                setMessageContent(`Added ${newDoctypeDataObj.cbDocType} DocType`)
                setTimeout(() => {
                    setSuccessFlag(false)
                }, 5000);
                deleteNewRow()
            }).catch(error => {
                setFailedFlag(true)
                setMessageContent("There is some issue adding new DocType")
            })
    }

    const updateExistingRow = (toggleValue, optionalData) => {
        updateApi(setToggleData(toggleValue, optionalData))
    }

    const setToggleValuesForNewRow = (toggleValue, optionalData) => {
        setNewDocTypeData(setToggleData(toggleValue, optionalData))
    }

    const setToggleData = (toggleValue, optionalData) => {
        let updatedDataObj;
        if (optionalData.column == "constructionDraws") {
            updatedDataObj = { ...optionalData.data, constructionDraws: toggleValue }
        } else if (optionalData.column == "financeReporting") {
            updatedDataObj = { ...optionalData.data, financeReporting: toggleValue }
        } else if (optionalData.column == "leasingAndOperating") {
            updatedDataObj = { ...optionalData.data, leasingAndOperating: toggleValue }
        } else if (optionalData.column == "loanServicing") {
            updatedDataObj = { ...optionalData.data, loanServicing: toggleValue }
        } else if (optionalData.column == "monitoringApproval") {
            updatedDataObj = { ...optionalData.data, monitoringApproval: toggleValue }
        }
        return updatedDataObj;
    }

    const closeMessage = () => {
        setFailedFlag(false);
        setSuccessFlag(false);
    };

    // -------------------- Adding new Doctype ---------------------

    const addNewRow = () => {
        setAddNewRowFlag(true)
        setSuccessFlag(false)
        setFailedFlag(false)
    }

    const deleteNewRow = () => {
        setNewDocTypeData({
            cbDocType: '',
            cbDocTypeDesc: '',
            cbFileCategory: '',
            constructionDraws: false,
            financeReporting: false,
            leasingAndOperating: false,
            loanServicing: false,
            monitoringApproval: false
        })
        setCbDocTypeError(false)
        setCbDocTypeDescError(false)
        setCbFileCategoryError(false)
        setFailedFlag(false)
        setAddNewRowFlag(false)
    }
    const deleteFile = (id) =>{
        
        const updateURL = `${endpoints.envUrl}/document-management/closing-binders/v1/cbDocTypeDelete`
        callApi.
        postApi(updateURL,id,{ headers: {
            'Content-Type':'text/plain'}}).
            then(response => {
                setSuccessFlag(true)
                setMessageContent("DocType deleted successfully")
                fetchDoctypeData()
                setErrorModal(false)
                setTimeout(() => {
                    setSuccessFlag(false)
                }, 5000);
            }).catch(error => {
                setFailedFlag(true)
                setMessageContent("There is some issue deleting DocType")
                setErrorModal(false)
            })
    }
    const deleteExistingRow =(id) => {
        setErrorModal(true)
        setErrorModalTitle("Are you sure you want to delete doctype");
        setDocumentId(id)
        
    }
    const submitNewRow = () => {
        if (!cbDocTypeError && !cbDocTypeDescError && !cbFileCategoryError && newDoctypeData.cbDocType !== "" && newDoctypeData.cbDocTypeDesc !== "" && newDoctypeData.cbFileCategory !== "") {
            createApi(newDoctypeData)
            setFailedFlag(false)
        } else {
            setFailedFlag(true);
            setMessageContent("Please provide valid inputs")
        }
    }
     const closeModal = (setErrorModal) => {
        setErrorModal(false);
        // setLegalHold(false)
      };

    const onInputChange = (event) => {
        const inputValue = event.target.value.trim();
        const fieldName = event.target.name
        let isError = !(/^[A-Za-z0-9()/ ]{2,}$/.test(inputValue));
        if (fieldName == "cbDocType") {
            setNewDocTypeData({ ...newDoctypeData, cbDocType: inputValue })
            setCbDocTypeError(isError)
        } else if (fieldName == "cbDocTypeDesc") {
            setNewDocTypeData({ ...newDoctypeData, cbDocTypeDesc: inputValue })
            setCbDocTypeDescError(isError)
        } else if (fieldName == "cbFileCategory") {
            setNewDocTypeData({ ...newDoctypeData, cbFileCategory: inputValue })
            setCbFileCategoryError(isError)
        }
    }

    return (
        <>
            {loading ? <Loader /> : null}
            <USBModal className="delete-modal" isOpen={errorModal}>
            <USBModalCloseIcon
              handleModalClose={() => closeModal( setErrorModal)}
              className="errormodalclosebtn"
            />
            <USBModalHeader modalTitle={errorModalTitle} />
            <USBModalFooter>
                <div className="deletebtnmodalframe">
                  <span className="cancel-btn">
                    <USBButton
                    id="cancelButton"
                      handleClick={() => closeModal(setErrorModal)}
                    >
                      Cancel
                    </USBButton>
                  </span>
                  <USBButton
                    id="deleteButton"
                    variant="primary"
                    handleClick={() =>
                      deleteFile(
                        documentId
                      )
                    }
                  >
                    Delete
                  </USBButton>
                </div>
            </USBModalFooter>
            </USBModal>
            <USBGrid addClasses="content-frame">
                <USBColumn largeSpan={8}>
                    <USBBreadcrumb
                        id="closingBinders"
                        text="Closing Binders"
                        breadcrumbs={[
                            { id: 1, text: 'CB Portfolio', href: '/ClosingBinders/Portfolio' },
                            { id: 2, text: 'CB DocType', href: '/ClosingBinders/Doctype' },
                        ]}
                    />
                </USBColumn>
            </USBGrid>
            {successFlag || failedFlag ? (
                <div
                    className="message-content"
                    data-testid="messageContent"
                    role="alert"
                >
                    <Messages
                        success={successFlag}
                        closeMessage={closeMessage}
                        singleUploadFail={failedFlag}
                        messageContent={messageContent}
                        setFailedFlag={setFailedFlag}
                        setSuccessFlag={setSuccessFlag}
                        severity={'high'}
                    />
                </div>
            ) : (
                ''
            )}
            <USBGrid addClasses="content-frame">
                <USBColumn largeSpan={16}>
                    <div className='docType_grid'>
                        {!addNewRowFlag &&
                            <div className='action_buttons_add' >
                                <USBButton
                                    id="addDocTypeBtn"
                                    variant="primary"
                                    type="button"
                                    size="small"
                                    handleClick={addNewRow}
                                >Add</USBButton>
                            </div>
                        }
                        <div className='cb_doctype_header_container'>
                            <div className='cb_doctype grid_font'>CB DOCTYPE</div>
                            <div className='cb_doctype_desc grid_font'>CB DOCTYPE DESC</div>
                            <div className='cb_file_category grid_font'>CB FILE CATEGORY</div>
                            <div className='cb_monitoring grid_font'>MONITORING APPROVAL</div>
                            <div className='cb_construction grid_font'>CONSTRUCTION DRAWS</div>
                            <div className='cb_finance grid_font'>FINANCE REPORTING</div>
                            <div className='cb_leasing grid_font'>LEASING & OPERATING</div>
                            <div className='cb_loan grid_font'>LOAN SERVICING</div>
                        </div>
                        {addNewRowFlag &&
                            <div className='cb_doctype_body_container'>
                                <div className='cb_doctype grid_font'>
                                    <USBInput
                                        id={'cbDocTypeInput'}
                                        addClasses={'add_doc_input'}
                                        errorText={newDoctypeData.cbDocType == '' ? 'CB Doctype is required.' : newDoctypeData.cbDocType.length < 2 ? 'This field requires at least two characters' : 'Invalid CB DocType'}
                                        isError={cbDocTypeError}
                                        initValue={''}
                                        helperText={"Required"}
                                        handleChange={onInputChange}
                                        labelText=""
                                        name="cbDocType"
                                        value={newDoctypeData.cbDocType}
                                    />
                                </div>
                                <div className='cb_doctype_desc grid_font'>
                                    <USBInput
                                        id={'cbDocTypeDescInput'}
                                        addClasses={'add_doc_input'}
                                        errorText={newDoctypeData.cbDocTypeDesc == '' ? 'CB Doctype Desc is required.' : newDoctypeData.cbDocTypeDesc.length < 2 ? 'This field requires at least two characters' : 'Invalid CB DocType Desc'}
                                        isError={cbDocTypeDescError}
                                        initValue={''}
                                        helperText={"Required"}
                                        handleChange={onInputChange}
                                        labelText=""
                                        name="cbDocTypeDesc"
                                        value={newDoctypeData.cbDocTypeDesc}
                                    />
                                </div>
                                <div className='cb_file_category grid_font'>
                                    <USBInput
                                        id={'cbFileCategoryInput'}
                                        addClasses={'add_doc_input'}
                                        errorText={newDoctypeData.cbFileCategory == '' ? 'CB File Category is required.' : newDoctypeData.cbFileCategory.length < 2 ? 'This field requires at least two characters' : 'Invalid CB File Category'}
                                        isError={cbFileCategoryError}
                                        initValue={''}
                                        helperText={"Required"}
                                        handleChange={onInputChange}
                                        labelText=""
                                        name="cbFileCategory"
                                        value={newDoctypeData.cbFileCategory}
                                    />
                                </div>
                                <div className='cb_monitoring grid_font'>
                                    <Toggle id={`newMonitoring`} toggleTextOn={true} toggleTextOff={false} value={newDoctypeData.monitoringApproval} onToggleChange={setToggleValuesForNewRow} optionalData={{ data: newDoctypeData, column: 'monitoringApproval' }} />
                                </div>
                                <div className='cb_construction grid_font'>
                                    <Toggle id={`newConstruction`} toggleTextOn={true} toggleTextOff={false} value={newDoctypeData.constructionDraws} onToggleChange={setToggleValuesForNewRow} optionalData={{ data: newDoctypeData, column: 'constructionDraws' }} />
                                </div>
                                <div className='cb_finance grid_font'>
                                    <Toggle id={`newFinance`} toggleTextOn={true} toggleTextOff={false} value={newDoctypeData.financeReporting} onToggleChange={setToggleValuesForNewRow} optionalData={{ data: newDoctypeData, column: 'financeReporting' }} />
                                </div>
                                <div className='cb_leasing grid_font'>
                                    <Toggle id={`newLease`} toggleTextOn={true} toggleTextOff={false} value={newDoctypeData.leasingAndOperating} onToggleChange={setToggleValuesForNewRow} optionalData={{ data: newDoctypeData, column: 'leasingAndOperating' }} />
                                </div>
                                <div className='cb_loan grid_font'>
                                    <Toggle id={`newLoan`} toggleTextOn={true} toggleTextOff={false} value={newDoctypeData.loanServicing} onToggleChange={setToggleValuesForNewRow} optionalData={{ data: newDoctypeData, column: 'loanServicing' }} />
                                </div>
                                <div className='action_icons'>
                                    {addNewRowFlag &&
                                        <div className='action_buttons submitBtn' onClick={submitNewRow}>
                                            <USBIconSuccess title="Submit"></USBIconSuccess>
                                        </div>
                                    }
                                    {addNewRowFlag &&
                                        <div className='action_buttons deleteBtn' onClick={deleteNewRow}>
                                            <USBIconDelete title="Delete"></USBIconDelete>
                                        </div>
                                    }
                                </div>
                            </div>
                        }
                        {!loading && docTypeFullData && docTypeFullData.map((configItem, index) => {
                            return <div className='cb_doctype_body_container'  key={index}>
                                <div className='cb_doctype grid_font'>
                                    {configItem.cbDocType != null ? `${configItem.cbDocType}` : '-'}
                                </div>
                                <div className='cb_doctype_desc grid_font'>
                                    {configItem.cbDocTypeDesc != null ? `${configItem.cbDocTypeDesc}` : '-'}
                                </div>
                                <div className='cb_file_category grid_font'>
                                    {configItem.cbFileCategory != null ? `${configItem.cbFileCategory}` : '-'}
                                </div>
                                <div className='cb_monitoring grid_font'>
                                    <Toggle id={`${index}monitoring`} toggleTextOn={true} toggleTextOff={false} value={configItem.monitoringApproval} onToggleChange={updateExistingRow} optionalData={{ data: configItem, column: 'monitoringApproval' }} />
                                </div>
                                <div className='cb_construction grid_font'>
                                    <Toggle id={`${index}construction`} toggleTextOn={true} toggleTextOff={false} value={configItem.constructionDraws} onToggleChange={updateExistingRow} optionalData={{ data: configItem, column: 'constructionDraws' }} />
                                </div>
                                <div className='cb_finance grid_font'>
                                    <Toggle id={`${index}finance`} toggleTextOn={true} toggleTextOff={false} value={configItem.financeReporting} onToggleChange={updateExistingRow} optionalData={{ data: configItem, column: 'financeReporting' }} />
                                </div>
                                <div className='cb_leasing grid_font'>
                                    <Toggle id={`${index}lease`} toggleTextOn={true} toggleTextOff={false} value={configItem.leasingAndOperating} onToggleChange={updateExistingRow} optionalData={{ data: configItem, column: 'leasingAndOperating' }} />
                                </div>
                                <div className='cb_loan grid_font'>
                                    <Toggle id={`${index}loan`} toggleTextOn={true} toggleTextOff={false} value={configItem.loanServicing} onToggleChange={updateExistingRow} optionalData={{ data: configItem, column: 'loanServicing' }} />
                                </div>
                                <div className='action_icons'>
                                    <div className='action_buttons deleteBtn' onClick={()=>{deleteExistingRow(configItem.id)}}>
                                        <USBIconDelete title="Delete"></USBIconDelete>
                                    </div>
                                </div>
                            </div>
                        })}
                    </div>
                </USBColumn>
            </USBGrid>
          
        </>
    )

}

export default CBDoctypeReport;