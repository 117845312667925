// React modules
import React, { useState, useEffect } from 'react';

// CSS
import './cbfilenettypereport.css'

// Components
import { USBGrid, USBColumn } from '@usb-shield/components-react';
import Loader from '../../components/utilities/Loader';
import USBBreadcrumb from '@usb-shield/components-react/dist/cjs/breadcrumb';
import Messages from '../../components/organisms/Messages';
import callApi from '../../components/utilities/callApi';
import { endpoints } from '../../../config/connections';


const CBFileNettypeReport = () => {
    const [loading, setLoading] = useState(false); // to manage loader
    const [filenetTypeFullData, setfilenettypeFullData] = useState([]) // to store full report data
    const [successFlag, setSuccessFlag] = useState(false)
    const [failedFlag, setFailedFlag] = useState(false)
    const [messageContent, setMessageContent] = useState('')

    useEffect(() => {
        fetchfilenettypeData()
    }, [])

    const fetchfilenettypeData = () => {
        setLoading(true)
        const fetchURL = `${endpoints.envUrl}/document-management/closing-binders/v1/cbFileNetType-all`
        callApi.
            getApiData(fetchURL).
            then(response => {
                setLoading(false)
                setfilenettypeFullData(response.data)
                setFailedFlag(false)
            }).catch(error => {
                setFailedFlag(true)
                setMessageContent("There is some issue fetching FileNetType data")
            })
    }

    return (
        <>
            {loading ? <Loader /> : null}
            <USBGrid addClasses="content-frame">
                <USBColumn largeSpan={8}>
                    <USBBreadcrumb
                        id="closingBinders"
                        text="Closing Binders"
                        breadcrumbs={[
                            { id: 1, text: 'CB Portfolio', href: '/ClosingBinders/Portfolio' },
                            { id: 2, text: 'CB FileNetType', href: '/ClosingBinders/Filenettype' },
                        ]}
                    />
                </USBColumn>
            </USBGrid>
            {successFlag || failedFlag ? (
                <div
                    className="message-content"
                    data-testid="messageContent"
                    role="alert"
                >
                    <Messages
                        success={successFlag}
                        singleUploadFail={failedFlag}
                        messageContent={messageContent}
                        setFailedFlag={setFailedFlag}
                        setSuccessFlag={setSuccessFlag}
                        severity={'high'}
                    />
                </div>
            ) : (
                ''
            )}
            <USBGrid addClasses="content-frame">
                <USBColumn largeSpan={16}>
                    <div className='filenetType_grid'>
                        <div className='cb_filenetType_header_container'>
                            <div className='cb_filenetType grid_font'>NAME</div>
                            <div className='cb_filenetType_desc grid_font'>DESCRIPTION</div>
                        </div>
                        {!loading && filenetTypeFullData && filenetTypeFullData.map((configItem,i) => {
                            return <div className='cb_filenetType_body_container' key={i}>
                                <div className='cb_filenetType grid_font'>
                                    {configItem.name != null ? `${configItem.name}` : '-'}
                                </div>
                                <div className='cb_filenetType_desc grid_font'>
                                    {configItem.description != null ? `${configItem.description}` : '-'}
                                </div>
                            </div>
                        })}
                    </div>
                </USBColumn>
            </USBGrid>
        </>
    )

}

export default CBFileNettypeReport;