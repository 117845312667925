import React, { useState, useEffect } from 'react';
import { default as AppSelect } from 'react-select';
import './Select.scss';

function Select({ label, name, ...props }) {
  const [value, setValue] = useState(null);

  useEffect(() => {
    setValue(props.value || null);
  }, [props.value]);

  return (
    <section className="app-select">
      <label htmlFor={name} className="select-label p-b-xs">
        {label}
      </label>
      <AppSelect
        name={name}
        classNames={{
          control: () => 'control',
          container: () => 'container',
        }}
        {...props}
        value={value}
      ></AppSelect>
    </section>
  );
}

export default Select;
