import React, { createContext, useState } from 'react';

export const CBDownloadsContext = createContext({ filesList: { original: [], filtered: [] }, setFilesList: (list) => { }, areDownloadsInProgress : false, setAreDownloadsInProgress : (isInProgress) => {} });

const CBDownloadsContextProvider = (props) => {

    const [filesList, setFilesList] = useState({ original: [], filtered: [] });
    const [areDownloadsInProgress,setAreDownloadsInProgress] = useState(false);

    return (

        <CBDownloadsContext.Provider value={{ filesList, setFilesList,areDownloadsInProgress, setAreDownloadsInProgress }}>
            {props.children}
        </CBDownloadsContext.Provider>
    );

};

export default CBDownloadsContextProvider;