import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import ProfileManageUserExternal from './ProfileManageUserExternal';
import TokenGenerator from './tokenGenerator';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import Helpers from '../utilities/helper';
import { getHeaders } from '../config/connections';
import { USBGrid, USBColumn } from '@usb-shield/components-react';
import Spinner from '../components/shared/Spinner';
import { UserRoleConfig } from '../config/user-role-config';
import ResizeObserver from 'resize-observer-polyfill';
import { transmitIframeMessage } from '../components/utility/transmit-message';
import USBBreadcrumb from '@usb-shield/components-react/dist/cjs/breadcrumb';
import GlobalHead from '../components/organisms/GlobalHead';
import history from './history';
import {enableCDCServicing} from  '../config/connections';

const AppRouting = () => {
  const [token, setToken] = useState(undefined);
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.currentUser);
  const globalLoadingIcon = useSelector((state) => state.showLoading);
  const [displayRequestToken, setDisplayRequestToken] = useState(true);

  /**
   * Add event listerner for get the message from parent application
   * Core team will send out a message with token.
   */

  const postMsgHandler = function (e) {
    if (e && e.data.type === 'token') {
      updateToken(e.data.data);
      console.log(
        'Index token From servicing SIDE-SETP1 Extend token',
        e.data.data
      );
      console.log('Index token Post Message Event Recived at ', new Date());
      sessionStorage.setItem('tok', e.data.data);
    }
    if (e && e.data.type === 'currentSession') {
      console.log('currentSession token Writing', e.data.data);
      sessionStorage.setItem('currentSession', e.data.data);
    }
  };

  useEffect(() => {
    window.removeEventListener('message', postMsgHandler, false);
    window.addEventListener('message', postMsgHandler, false);
  }, []); // <-- empty dependency array

  const appHeight = document.querySelector('#root');

  let resizeObserver = new ResizeObserver((entries) => {
    console.log('The element was resized');
    for (let entry of entries) {
      transmitIframeMessage.sendMessage(
        entry.contentRect.height,
        entry.contentRect.height
      );
    }
  });

  resizeObserver.observe(appHeight);

  /**
   * Axios interceptors to add all the default headers.
   */
  axios.interceptors.request.use(
    function (config) {
      if (currentUser && currentUser.user) {
        let token = currentUser.user.custom_data.at;
        let jwtTokenFinal = currentUser.svcJWTTokenSaved;
        try {
          const tokenFromSession = Helpers.decodeJWTToken(
            sessionStorage.getItem('tok')
          );
          if (token !== tokenFromSession.custom_data.at) {
            token = tokenFromSession.custom_data.at;
            jwtTokenFinal = sessionStorage.getItem('tok');
            console.log('Overwriting Token from session');
            connect.log('Overwriting Event Happend at ', new Date());
          }
        } catch (e) {}

        if (config.cdcType !== undefined) {
          if (config.cdcType === 'auth') {
            config.headers = {
              ...getHeaders({ serviceType: config.cdcType }),
              ...config.headers,
              Authorization: `Bearer ${token}`,
              'Client-Data': `{"Role-ID": "${currentUser.user.custom_data.role}", "User-ID": "${currentUser.user.sub}", "App-ID": "CDC", "Tenant-ID": "USB", "App-Version": "1", "Channel-ID": "web"}`,
              'Transmit-ID': `${jwtTokenFinal}`,
            };
          } else if (config.cdcType === 'auth_non_cdc_user') {
            config.headers = {
              ...getHeaders({ serviceType: config.cdcType }),
              ...config.headers,
              Authorization: `Bearer ${token}`,
              'Transmit-ID': `${jwtTokenFinal}`,
            };
          } else if (config.cdcType === 'servicing') {
            config.headers = enableCDCServicing==false ? {
              ...getHeaders({ serviceType: config.cdcType }),
              ...config.headers,
              Authorization: `Bearer ${token}`,
              'Client-Data': `{"Role-ID": "${currentUser.user.custom_data.role}", "User-ID": "${currentUser.user.sub}", "App-ID": "CDC", "Tenant-ID": "USB", "App-Version": "1", "Channel-ID": "web"}`,
              SessionToken: `${jwtTokenFinal}`,
            } : {
              ...getHeaders({ serviceType: config.cdcType }),
              ...config.headers,
              Authorization: `Bearer ${token}`,
              'jwt': `${jwtTokenFinal}`,
              'Client-Data': `{"Role-ID": "${currentUser.user.custom_data.role}", "User-ID": "${currentUser.user.sub}", "App-ID": "CDC", "Tenant-ID": "USB", "App-Version": "1", "Channel-ID": "web"}`,
            };
            if (config.headers.routingKey === 'prod') {
              // PLEASE DONT REMOVE THIS. This is needed for Graph to work
              delete config.headers.routingKey;
            } /* else if (config.headers.routingKey === "uat4") {
             // config.headers.routingKey = "it2"
            config.headers.routingKey = "uat2"
          }*/
          } else if (config.cdcType === 'servicing_Update') {
            config.headers = enableCDCServicing==false ? {
              ...getHeaders({ serviceType: config.cdcType }),
              ...config.headers,
              Authorization: `Bearer ${token}`,
              'Client-Data': `{"Role-ID": "${currentUser.user.custom_data.role}", "User-ID": "${currentUser.user.sub}", "App-ID": "CDC", "Tenant-ID": "USB", "App-Version": "1", "Channel-ID": "web"}`,
              SessionToken: `${jwtTokenFinal}`,
            } : {
              ...getHeaders({ serviceType: config.cdcType }),
              ...config.headers,
              Authorization: `Bearer ${token}`,
              'jwt': `${jwtTokenFinal}`,
              'Client-Data': `{"Role-ID": "${currentUser.user.custom_data.role}", "User-ID": "${currentUser.user.sub}", "App-ID": "CDC", "Tenant-ID": "USB", "App-Version": "1", "Channel-ID": "web"}`,
            };
          } else {
            config.headers = {
              ...getHeaders({ serviceType: config.cdcType }),
              ...config.headers,
              Authorization: `Bearer ${token}`,
              'jwt': `${jwtTokenFinal}`,
              'Client-Data': `{"Role-ID": "${currentUser.user.custom_data.role}", "User-ID": "${currentUser.user.sub}", "App-ID": "CDC", "Tenant-ID": "USB", "App-Version": "1", "Channel-ID": "web"}`,
            };
          }
        }
      }
      return config;
    },
    function (error) {
      return Promise.reject(error);
    }
  );

  const updateToken = (value) => {
    if (!value) {
      return;
    }
    //sessionStorage.setItem("currentSession",value);
    dispatch({
      type: 'SET_JWT_TOKEN',
      payload: value,
    });

    const token = Helpers.decodeJWTToken(value);
    console.log('Decode Token from servicing SIDE-SETP2 Extend token', token);
    if (
      token.custom_data.role
        .toLowerCase()
        .includes(UserRoleConfig.customerContributor)
    ) {
      history.push('/ManageUser/my-profile');
      // setDisplayRequestToken(false);
      // alert('As you are customer contributor you dont have access to this portal.');
      // return;
    }
    setDisplayRequestToken(false);
    dispatch({
      type: 'SET_CURRENT_USER',
      payload: Helpers.decodeJWTToken(value),
    });
    setToken(value);
  };

  return (
    <>
      <GlobalHead title="Secure Document Portal" />
      <USBGrid addClasses="content-frame">
        {/* <USBColumn largeSpan={2} /> */}
        <USBColumn largeSpan={12}>
          <USBBreadcrumb
            id="manageusers"
            text="Manage Users"
            breadcrumbs={
              
              window.location.href.indexOf("edit-profile") != -1?
              [
                { id: 2, text: 'Manage users' , href: '/ManageUser' },
                { id: 3, text: 'Edit User' , href: '' }
              
              ]
              :
              [{ id: 2, text: window.location.pathname != '/ManageUser/my-profile' ? 'Manage users' : 'My Profile', href: '' }]
          
          }
          />
        </USBColumn>
      </USBGrid>
      {token ? <ProfileManageUserExternal /> : null}
      {displayRequestToken ? <TokenGenerator update={updateToken} /> : null}
    </>
  );
};

export default connect()(AppRouting);
