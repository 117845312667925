import React, { Component } from 'react';
import { USBLoader } from '@usb-shield/components-react';
import '../../pages/index.css';

class Loader extends Component {
  constructor() {
    super();
    this.state = {};
  }

  render() {
    return (
      // <div className="page-loading">
      //   <div />
      // </div>
      // <div className="loader-div" />
      <div className="loader-container">
        <div className="loader-child">
          <USBLoader determinate={false} variant="simple" />
        </div>
      </div>
    );
  }
}

export default Loader;
