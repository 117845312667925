import React, { useState, useEffect } from 'react';
// @ts-ignore
import { USBButton } from '@usb-shield/components-react';
import { getQS, saveAsFile , previewFile} from '../../components/utilities/Utils';
import { endpoints } from '../../../config/connections';
import callApi from "../../components/utilities/callApi";



export const DOWNLOAD_PATH =
  'M9.47 13.53l-4-4 1.06-1.06 2.72 2.72V2h1.5v9.19l2.72-2.72 1.06 1.06-4 4a.75.75 0 01-1.06 0zm7.78-2.64v6.36H2.75V11h-1.5v7a.76.76 0 00.75.75h16a.76.76 0 00.75-.75v-7.11z';

export default function DownloadMultiple({ selectedRows, data }) {
  var url = window.location.pathname;
  const requirementId = url.substring(url.lastIndexOf('/') + 1);
  const downloadApi = async()=>{
  for(let fileId of selectedRows){
  const getApiURL = `${endpoints.api}/document-management/file-download/v1/inbox/${requirementId}/files/${fileId}`;
  callApi
  .getApiData(getApiURL)
  .then((result) => {
    var values = result.data.fileName;
    const fileurl = saveAsFile(
    result.data.binary,
    values
    );
    var values = result.data.fileName;
  })
  .catch(() => {
    // setLoading(false);
    
  })

    }
  }
  return (
    <USBButton
      type="button"
      variant="secondary"
      size="default"
      ariaLabel="Download"
      id={'cb-list-table-action-download-icon'}
      addClasses={'cb-list-action-button-class'}
      name={'cb-list-table-action-download-icon'}
      handleClick={(e) => {
        console.log(e.target.innerText);
        downloadApi()
      }}
      iconName={'download'}
      iconData={{
        path: {
          d: DOWNLOAD_PATH,
        },
      }}
      iconViewBox={'0 0 20 20'}
      iconAssistiveText={{
        label: 'Download',
      }}
      title={'Download'}
      disabled={selectedRows.length>0?false:true}
    >
      Download
    </USBButton>
  );
}
