import React from 'react';
import { USBIconChevronLeft, USBLink } from '@usb-shield/components-react/dist/cjs';
import { withRouter } from 'react-router-dom';

/**
 * Use this component to navigate back based on the react router histroy.
 * Uses shiled usb link for UI.
 * @param {*} props 
 */

const NavigateBack = (props) => {
    const onBack = () => {
        props.history.push(props.url);
        // props.history.goBack()
    }   

    return (
        <React.Fragment>
            <div className="sp-back-link sp-mr-b-4" onClick={onBack}>
                <USBIconChevronLeft addClasses={'sp-back-icon'} size='10' colorVariant='interaction' ></USBIconChevronLeft>
                <USBLink href="#"
                    onClick={ev => ev.preventDefault()}
                    linkType="basic" id={props.id ? props.id : 'navigate-back'}
                    useReactRouter={true}>Back
                </USBLink>
            </div>
        </React.Fragment>
    );
}

export default withRouter(NavigateBack)