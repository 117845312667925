import { getSvcServiceURL } from '../../config/connections';
import Axios from 'axios';
import GraphQlQuery from '../../utilities/graphQL-query';

export default function getCountryListThunk() {
    return function (dispatch, getState) {
        const countryList = getState().userProfileInformation.countryList;
        if (countryList && countryList.length > 0) {
            return Promise.resolve(countryList);
        }
        const qraphUrl = getSvcServiceURL({ service: 'graph' });
        const qraphQuery = GraphQlQuery.getCountryQuery();
        return Axios.post(qraphUrl, qraphQuery, { cdcType: "servicing" }).then((res) => {
            const sheildFormate = res.data.data.getCountryStateList.map(item => {
                return { value: item.countryInfo.countryCode, content: item.countryInfo.countryName }
            });
            dispatch({
                type: 'ADD_COUNTRY_LIST',
                payload: sheildFormate
            });
            return Promise.resolve(sheildFormate);
        }, (error) => {
            return Promise.reject(error)
        });
    };
}