import React, { useState, useEffect, useContext } from 'react';
import moment from 'moment';
import { endpoints } from '../../../config/connections';
import callApi from '../../components/utilities/callApi';
import USBDatePicker from '@usb-shield/react-date-picker';
import { defaultCalendarSettings } from '@usb-shield/react-calendar';
import {
  USBGrid,
  USBColumn,
  USBButton,
  USBSelect,
} from '@usb-shield/components-react';
import USBTable from '@usb-inner-src/react-table';
import USBBreadcrumb from '@usb-shield/components-react/dist/cjs/breadcrumb';
import Loader from '../../components/utilities/Loader';
import './Reports.css';
import USBAccordion from '@usb-shield/react-accordion';
import '@usb-shield/react-accordion/dist/library/styles/index.css';
import USBCheckboxGroup from '@usb-shield/react-forms-checkbox-group';
import '@usb-shield/react-forms-base/dist/library/styles/index.css';
import {ExportToExcel} from '../../components/utilities/ExportToExcel';
import useUserRole from '../../components/Hooks/use-UserRole';
import _noaccess from '../../pages/_noaccess';
import { MultiSelect } from "react-multi-select-component";

const Reports = () => {

  const [reportData, setReportData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [clearFilterFlag, setClearFilterFlag] = useState(false);
  const [filterType, setFilterType] = useState(null);
  const [selectedValue, setSelectedValue] = useState([]);
  const [selectedValueBu, setSelectedValueBu] = useState([]);
  const businessUnitAll = 'CLD,CRASP,LIHTC,NMTC,RETC,SF';
  const docTypesList = [ 
    {
      label: 'Budget',
      value: 'Budget',
      disabled: false,
    },
    {
      label: 'CDE Compliance',
      value: 'CDE Compliance',
      disabled: false,
    },
    {
      label: 'Compliance Certificate',
      value: 'Compliance Certificate',
      disabled: false,
    },
    {
      label: 'Financial Statement',
      value: 'Financial Statement',
      disabled: false,
    },
    {
      label: 'Guarantor Reporting',
      value: 'Guarantor Reporting',
      disabled: false,
    },
    {
      label: 'Tax Return',
      value:'Tax Return',
      disabled: false,
    },
  ];
  const docTypeAll =
    'Budget,CDE Compliance,Compliance Certificate,Financial Statement,Guarantor Reporting,Tax Return';
  const [selections, setSelections] = useState({
    businessUnits: '',
    documentTypes: '',
    fromDate: '',
    toDate: '',
  });

  const [currentPage, setCurrentPage] = useState(1);

  const loadReportData = (responseArray) => {
    setReportData(responseArray);
    tableData();
  };

  const clearFilter = async() => {
    setClearFilterFlag(true);
    await getReportAPIResponse(1);
    setClearFilterFlag(false)
    window.location = '/Reports/PortalSubmission';
  };

  const getReportAPIResponse = async (indicator) => {
    setLoading(true);
    let categoryTemp = '';
    let doctypeTemp = '';
    let fromAsOfDateTemp = selections.fromDate;
    let toAsOfDateTemp = selections.toDate;
    if(indicator  == 1){
      categoryTemp = '';
      doctypeTemp = '';
      fromAsOfDateTemp ='';
      toAsOfDateTemp = '';

    }
    if (selections.businessUnits == '') {
      categoryTemp = businessUnitAll;
    } else {
      categoryTemp = selections.businessUnits;
    }
    if (selections.documentTypes == '') {
      doctypeTemp = docTypeAll;
    } else {
      doctypeTemp = selections.documentTypes;
    }
    if (
      toAsOfDateTemp == null ||
      toAsOfDateTemp == undefined ||
      toAsOfDateTemp == ''
    ) {
      toAsOfDateTemp = moment().format('YYYY-MM-DD');
    } else {
      toAsOfDateTemp = moment(toAsOfDateTemp).format('YYYY-MM-DD');
    }
    if (
      fromAsOfDateTemp == null ||
      fromAsOfDateTemp == undefined ||
      fromAsOfDateTemp == ''
    ) {
      fromAsOfDateTemp = moment().subtract(10, 'years').format('YYYY-MM-DD');
    } else {
      fromAsOfDateTemp = moment(fromAsOfDateTemp).format('YYYY-MM-DD');
    }

    let apiURLFirst = '';
    apiURLFirst = `${endpoints.apigee}/document-management/cdc/requirements/v1/portalSubmissionStats?doctype=${doctypeTemp}&category=${categoryTemp}&fromAsOfDate=${fromAsOfDateTemp}&toAsOfDate=${toAsOfDateTemp}`;
    
    try{
      const response = await callApi.getApiData(apiURLFirst);
      if(response){
        loadReportData(response.data);
        setLoading(false);
      }
    }catch{
      setLoading(false);
    }
    
  };

  useEffect(() => {
    getReportAPIResponse(1);
  }, []);

  const defaultSettingsDateInput = {
    initValue: undefined,
    isDateOfBirth: true,
    // minimumAge: 0,
    // maximumAge: 10,
    // maxlength: 10,
    showRenderCount: false,
    inputName: 'date-picker_single-date--default',
    isDisabled: false,
    isOptional: false,
    isReadOnly: false,
    dataTestId: null,
  };

  const defaultSettingsCalendar = {
    areBankHolidaysDisabled: defaultSettingsDateInput.isDateOfBirth
      ? false
      : true,
    areWeekendsDisabled: defaultSettingsDateInput.isDateOfBirth ? false : true,
    occurrence: 'both',
    numberOfYearsToShow: '10',
  };

  const defaultContent1 = {
    dateInput: {
      calendarButtonAriaLabel: 'Choose date',
      helperText: 'mm/dd/yyyy',
      helperTextOptional: 'Optional',
      labelText: 'As of Date From',
      errorMessages: {
        default: 'Please enter a valid date.',
        202: 'Date should be in the past.',
        203: 'Date should be in the future.',
        221: 'Enter no more than 10 characters.',
        213: 'Enter numbers only.',
        224: '(Optional field)',
        228: "That date isn't available. Please try again.",
      },
    },
  };

  const defaultContent2 = {
    dateInput: {
      calendarButtonAriaLabel: 'Choose date',
      helperText: 'mm/dd/yyyy',
      helperTextOptional: 'Optional',
      labelText: 'As of Date To',
      errorMessages: {
       default: 'Please enter a valid date.',
        202: 'Date should be in the past.',
        203: 'Date should be in the future.',
        221: 'Enter no more than 10 characters.',
        213: 'Enter numbers only.',
        224: '(Optional field)',
        228: "That date isn't available. Please try again.",
      },
    },
  };

 
  const updateData = async (event, column) => {
    setLoading(true);
    const selectionsTemp = selections;
    let tempSelections = '';
  
    if (column === 'businessUnits' || column === 'documentTypes') {
      event.forEach((item, index) => {
        if (item.checked) {
          tempSelections = `${item.value},${tempSelections}`;
        }
      });
      tempSelections = tempSelections.substring(0, tempSelections.length - 1);
    } else {
      tempSelections = event.inputValue;
    }
    if (column === 'businessUnits') {
      selectionsTemp.businessUnits = tempSelections;
 
    }
    if (column === 'documentTypes') {
      selectionsTemp.documentTypes = tempSelections;
  
    }
    if (column === 'fromDate' && tempSelections?.length == 10) {
      selectionsTemp.fromDate = tempSelections;
    }
    if (column === 'toDate' && tempSelections?.length == 10) {
      selectionsTemp.toDate = tempSelections;
    }
    setSelections(selectionsTemp);
    await getReportAPIResponse(0);
    setLoading(false);
  
  };

  const tableData = () => {
    const tempData = [];
    reportData.forEach((item) => {
      tempData.push({
        first:
          item.category && item.category.length > 0 ? item.category : 'ALL',
        second: item.docType && item.docType.length > 0 ? item.docType : 'ALL',
        third:
          item.submittedCount && item.submittedCount != 0
            ? item.submittedCount
            : '0',
        fourth:
          item.portalSubmittedCount && item.portalSubmittedCount != 0
            ? item.portalSubmittedCount
            : '0',
        fifth:
          item.portalPercentage && item.portalPercentage != 0
            ? item.portalPercentage.toFixed(2) + '%'
            : '0%',
        sixth: '1,919.58',
      });
    });
    return tempData;
  };

   
  const tempUserRole = useUserRole();
 
  const allOptions = [
    { value: "CLD", label: "CLD", disabled:false },
    { value: "CRASP", label: "CRASP", disabled:false },
    { value: "LIHTC", label: "LIHTC", disabled:false },
    { value: "NMTC", label: "NMTC", disabled:false },
    { value: "RETC", label: "RETC", disabled:false },
    { value: "SF", label: "SF", disabled:false },
  ];
  
  return (
    tempUserRole && tempUserRole!="employee" ? <_noaccess/> :
    <>
      <USBGrid addClasses="content-frame">
        <USBColumn largeSpan={8}>
          <USBBreadcrumb
            id="reports"
            text="Reports"
            breadcrumbs={[
              // {
              //   id: 1,
              //   text: 'My portfolio',
              //   href: '/ReportingDashboard',
              // },
              { id: 2, text: 'Reports', href: '' },
              { id: 3, text: 'Portal Submission', href: '' },
            ]}
          />
        </USBColumn>
        <USBColumn largeSpan={8}></USBColumn>
      </USBGrid>
      <USBGrid addClasses="content-frame content-bg-portal">
        {loading ? <Loader /> : null}
        <USBColumn largeSpan={16}>
          <h1 className="heading-h1">Report: Portal Submission</h1>
          <div className="page-details-wrap-portal">
          </div>
          { !clearFilterFlag ?
          <div className="sorting-inputs-portal ">
            <div className="filter-by-portal">Filter by :</div>
            <div className="filter-dropdowns-portal portal-accordions">
            <h1>Business Unit :</h1>
            <MultiSelect 
              options={allOptions}
              value={selectedValueBu}
              onChange={(allOptionsData)=> {
                setSelectedValueBu(allOptionsData)
                if(Array.isArray(allOptionsData)) {
                  const data = allOptionsData.map((item)=> {
                    var temp = Object.assign({}, item);
                    if(!item.disabled){
                      temp.checked = true,
                      temp.value = item.value
                    }
                    return temp;
                  })
                  setFilterType('businessUnits')
                  updateData(data, 'businessUnits');
                  
                }
              }}
              labelledBy="BusinessUnit"
              />
            </div>

            <div className="filter-dropdowns-portal portal-accordions">
               
              <h1>Document Type :</h1>
              <MultiSelect 
              options={docTypesList}
              value={selectedValue}
              onChange={(allOptions)=>{
                setSelectedValue(allOptions)
                if(Array.isArray(allOptions)) {
                  const data = allOptions.map((item)=> {
                    var temp = Object.assign({}, item);
                    if(!item.disabled){
                      temp.checked = true,
                      temp.value = item.value
                    }
                    return temp;
                  })
                  setFilterType('documentTypes')
                  updateData(data, 'documentTypes');
                  
                }
              }}
              labelledBy="Document 
              Type"
              />
            </div>
                <div className="filter-dropdowns-portal portal-From-datepickers">
                <USBDatePicker
                  id="asofdate"
                  inputName="asofdate"
                  labelText2="As of Date From"
                  statusUpdateCallback={(e) => {
                    updateData(e, 'fromDate');
                  }}
                  settings={{
                    dateInput: {
                      ...defaultSettingsDateInput,
                    },
                    calendar: {
                      id: 'datepicker',
                      ...defaultCalendarSettings,
                      ...defaultSettingsCalendar,
  
                      occurrence: 'past',
                      numberOfYearsToShow: '10',
                      labelText: 'As of Date From',
                    },
                  }}
                  content={{
                    ...defaultContent1,
                    labelText: 'Start date',
                    inputName: 'asofdate',
                  }}
                />
              </div>
              <div className="filter-dropdowns-portal portal-to-datepickers">
                <USBDatePicker
                  id="submitteddate"
                  inputName="submitteddate"
                  initValue={moment().format('MMM DD, YYYY')}
                  labelText="As of Date To"
                  statusUpdateCallback={(e) => {
                    updateData(e, 'toDate');
                  }}
                  settings={{
                    dateInput: {
                      ...defaultSettingsDateInput,
                    },
                    calendar: {
                      id: 'datepicker',
                      ...defaultCalendarSettings,
                      ...defaultSettingsCalendar,
                      occurrence: 'past',
                      numberOfYearsToShow: '10',
                      labelText: 'As of Date To',
                    },
                  }}
                  content={{
                    ...defaultContent2,
                    labelText: 'Start date',
                    inputName: 'submitteddate',
                  }}
                />
              </div> 
          </div>
           : null }
          <div className="actionButtons-portal">
            <div>
              <USBButton
                id="clearfilterPortal"
                name="clearfilter"
                handleClick={() => {
                  clearFilter();
                }}
              >
                Clear filters
              </USBButton>
            </div>
            <div>
              <USBButton
                id="downloadReportPortal"
                name="downloadReport"
                variant="primary"
                disabled = {reportData[0]?.docType !== '' && reportData[0]?.category !== '' ? false : true }
                handleClick={() => {
                 const excelData = [];
                  const filterSelections =
                    `Filter selections : Business Units = ${
                      selections.businessUnits == ''
                        ? businessUnitAll
                        : selections.businessUnits
                    } Document Types = ${
                      selections.documentTypes == ''
                        ? docTypeAll
                        : selections.documentTypes
                    } AsOfDateFrom = ${selections.fromDate} AsOfDateTo = ${
                      selections.toDate
                    }` + '';
                  if (reportData) {
                    reportData.forEach((item, index) => {
                      if (index == 0) {
                        excelData.push(
                          {
                            '': filterSelections,
                          },
                          { '': '' },
                          {
                            '': 'Business unit',
                            ' ': 'Document Type',
                            '  ': 'Submitted ',
                            '   ': 'Portal Submitted',
                            '    ': 'Portal Percentage',
                          }
                        );
                      }
                      excelData.push({
                        '': item.category ? item.category : 'ALL',
                        ' ': item.docType ? item.docType : 'ALL',
                        '  ': item.submittedCount,
                        '   ': item.portalSubmittedCount,
                        '    ': item.portalPercentage,
                      });
                    });
                  } else {
                    excelData.push({
                      'Business unit': 'ALL',
                      'Document Type': 'ALL',
                      Submitted: 0,
                      'Portal Submitted': 0,
                      'Portal Percentage': 0,
                      'Filter selections': `Business Units = ${selections.businessUnits} Document Types = ${selections.documentTypes} AsOfDateFrom = ${selections.fromDate} AsOfDateTo = ${selections.toDate}`,
                    });
                  }
                  ExportToExcel(
                    Object.values(excelData),
                    'Portal Submission Statistics ' +
                      moment().format('MMM DD, YYYY'),
                    ()=>{},
                    ()=>{}
                  );
                }}
              >
                Download Report
              </USBButton>
            </div>
          </div>
          {!loading && !clearFilterFlag? (
            <div className="page-content-portal">
              
              {reportData[0]?.docType !== '' && reportData[0]?.category !== '' ? 
              <USBTable
                columnData={[
                  {
                    headerName: 'Business Unit',
                    fieldFor: 'first',
                    dataType: 'string',
                    type: 'info',
                  },
                  {
                    headerName: 'Document Type',
                    fieldFor: 'second',
                    dataType: 'string',
                    type: 'default',
                  },
                  {
                    headerName: 'Submitted',
                    fieldFor: 'third',
                    dataType: 'string',
                    type: 'default',
                  },
                  {
                    headerName: 'Portal Submitted',
                    fieldFor: 'fourth',
                    dataType: 'string',
                    type: 'default',
                  },
                  {
                    headerName: 'Portal Percentage',
                    fieldFor: 'fifth',
                    dataType: 'string',
                    type: 'default',
                  },
                ]}
                tableData={
                  reportData && reportData.length > 0
                    ? tableData()
                    : [
                        {
                          first: 'ALL',
                          second: 'ALL',
                          third: '0',
                          fourth: '0',
                          fifth: '0%',
                          sixth: '0',
                        },
                      ]
                }
                displayVariant="auto"
              /> :<div className='Notification-Report'><h2>No Record Found</h2></div>}
            </div>
          ) : (
            null
          )}
        </USBColumn>
      </USBGrid>
    </>
  );
};

export default Reports;