export default class QraphQlQuery {

    static getAllUnlistedVendor = (parentEIN,metaData,search) => {
        if (search && search.topLeftSearchBy) {
            let query;
            switch (search.topLeftSearchBy) {
                case 'Last name' : 
                query = {"query":`{\n  getUnlistedVendorList(input: {\n    profileRequest: \n    \t{\n        userID: \"\", \n      lastName: "${search.topLeftSearchBoxValue}",\n      parentCompanyName: \"\",\n      email: \"\",\n   profileRole: \"\"\n      \tphoneNumbers: {\n          homePhone: \"\", \n          mobilePhone: \"\", \n          businessPhone: \"\"\n        }\n        parentEIN:"${parentEIN}"\n     status:"${search.statusFilterValue}"\n }, \n    sortBy: {sortKey: \"lastName\", sortValue: \"asc\"}, offset: 0, limit: 12}) {\n      profiles{\n      userID\n      profileID\n      firstName\n      lastName\n      email\n      profileRole\n\t\t\tparentCompanyName\n      parentEIN\n   status\n   parentCompanyLegalEntityID\n      }\n    \n  \t\tpageMeta {\n        total\n      }\t\n    }\n  }`,"variables":null,"operationName":null};
                    break;
                default : 
                query = {"query":`{\n  getUnlistedVendorList(input: {\n    profileRequest: \n    \t{\n        userID: \"\", \n      lastName: \"\",\n      parentCompanyName: \"\",\n      email: "${search.topLeftSearchBoxValue}",\n   profileRole: \"\"\n      \tphoneNumbers: {\n          homePhone: \"\", \n          mobilePhone: \"\", \n          businessPhone: \"\"\n        }\n        parentEIN:"${parentEIN}"\n     status:"${search.statusFilterValue}"\n }, \n    sortBy: {sortKey: \"lastName\", sortValue: \"asc\"}, offset: 0, limit: 12}) {\n      profiles{\n      userID\n      profileID\n      firstName\n      lastName\n      email\n      profileRole\n\t\t\tparentCompanyName\n      parentEIN\n   status\n   parentCompanyLegalEntityID\n      }\n    \n  \t\tpageMeta {\n        total\n      }\t\n    }\n  }`,"variables":null,"operationName":null};
                    break;
            }
            return query;
        }
        return  {"query":`{\n  getUnlistedVendorList(input: {\n    profileRequest: \n    \t{\n        userID: \"\", \n      lastName: \"\",\n      parentCompanyName: \"\",\n      email: \"\",\n   profileRole: \"\"\n      \tphoneNumbers: {\n          homePhone: \"\", \n          mobilePhone: \"\", \n          businessPhone: \"\"\n        }\n        parentEIN:"${parentEIN}"\n     status:"${search.statusFilterValue}"\n }, \n    sortBy: {sortKey: \"lastName\", sortValue: \"asc\"}, offset: 0, limit: 12}) {\n      profiles{\n      userID\n      profileID\n      firstName\n      lastName\n      email\n      profileRole\n\t\t\tparentCompanyName\n      parentEIN\n   status\n   parentCompanyLegalEntityID\n      }\n    \n  \t\tpageMeta {\n        total\n      }\t\n    }\n  }`,"variables":null,"operationName":null};
    };

    static cdcEmployeeViewGetAllProfileListByLastNameOrEmailAddressQuery = (userId, metaData, search) => {
        if (search && search.topLeftSearchBy) {
            let query;
            switch (search.topLeftSearchBy) {
                case 'Last name' : 
                query = {"query":`{\n  getProfileList(input: {\n    profileRequest: \n    \t{\n        userID: \"\", \n      lastName: "${search.topLeftSearchBoxValue}",\n      parentCompanyName: \"\",\n      email: \"\",\n   profileRole: \"\"\n      \tphoneNumbers: {\n          homePhone: \"\", \n          mobilePhone: \"\", \n          businessPhone: \"\"\n        }\n        parentEIN:"${search.parentCompanyFilterValue}"\n      }, \n    sortBy: {sortKey: "${metaData.sortKey}", sortValue: "${metaData.sortValue}"}, offset: ${metaData.offset}, limit: ${metaData.limit}}) {\n      profiles{\n      userID\n      profileID\n      firstName\n      lastName\n      email\n      profileRole\n\t\t\tstatus\n      parentCompanyName\n      parentEIN\n      parentCompanyLegalEntityID\n      }\n    \n  \t\tpageMeta {\n        total\n      }\t\n    }\n  }`,"variables":null,"operationName":null};
                    break;
                default : 
                    break;
            }
            return query;
        } 
        return {"query":`{\n  getProfileList(input: {\n    profileRequest: \n    \t{\n        userID: \"\", \n      firstName: \"\",\n      parentCompanyName: \"\",\n      email: \"\",\n        profileRole: \"\"\n      \tphoneNumbers: {\n          homePhone: \"\", \n          mobilePhone: \"\", \n          businessPhone: \"\"\n        }\n        parentEIN:"${search.parentCompanyFilterValue}"\n      }, \n    sortBy: {sortKey: "${metaData.sortKey}", sortValue: "${metaData.sortValue}"}, offset: ${metaData.offset}, limit: ${metaData.limit}}) {\n      profiles{\n      userID\n      profileID\n      firstName\n      lastName\n      email\n      profileRole\n\t\t\tstatus\n      parentCompanyName\n      parentEIN\n      parentCompanyLegalEntityID\n      }\n    \n  \t\tpageMeta {\n        total\n      }\t\n    }\n  }`,"variables":null,"operationName":null};
    };

    static cdcEmployeeQuery = (userId, metaData, search) => {
        if (search && search.topLeftSearchBy) {
            let query;
            switch (search.topLeftSearchBy) {
                case 'Last name' : 
                if (search && search.userTypeFilterValue==='Frequent Vendor') 
                query = {"query":`{\n  getProfileList(input: {\n    profileRequest: \n    \t{\n        userID: \"\", \n      lastName: "${search.topLeftSearchBoxValue}",\n      parentCompanyName: \"\",\n      email: \"\",\n   profileRole: \"\"\n      \tphoneNumbers: {\n          homePhone: \"\", \n          mobilePhone: \"\", \n          businessPhone: \"\"\n        }\n        parentEIN:"FRQ_${search.parentCompanyFilterValue}"\n     status:"${search.statusFilterValue}"\n  }, \n    sortBy: {sortKey: "${metaData.sortKey}", sortValue: "${metaData.sortValue}"}, offset: ${metaData.offset}, limit: ${metaData.limit}}) {\n      profiles{\n      userID\n      profileID\n      firstName\n      lastName\n  middleName\n     email\n      profileRole\n\t\t\tstatus\n      parentCompanyName\n      parentEIN\n    parentCompanyLegalEntityID\n      }\n    \n  \t\tpageMeta {\n        total\n      }\t\n    }\n  }`,"variables":null,"operationName":null};
                else
                query = {"query":`{\n  getProfileList(input: {\n    profileRequest: \n    \t{\n        userID: \"\", \n      lastName: "${search.topLeftSearchBoxValue}",\n      parentCompanyName: \"\",\n      email: \"\",\n   profileRole: \"\"\n      \tphoneNumbers: {\n          homePhone: \"\", \n          mobilePhone: \"\", \n          businessPhone: \"\"\n        }\n        parentEIN:"${search.parentCompanyFilterValue}"\n     status:"${search.statusFilterValue}"\n  }, \n    sortBy: {sortKey: "${metaData.sortKey}", sortValue: "${metaData.sortValue}"}, offset: ${metaData.offset}, limit: ${metaData.limit}}) {\n      profiles{\n      userID\n      profileID\n      firstName\n      lastName\n  middleName\n     email\n      profileRole\n\t\t\tstatus\n      parentCompanyName\n      parentEIN\n    parentCompanyLegalEntityID\n      }\n    \n  \t\tpageMeta {\n        total\n      }\t\n    }\n  }`,"variables":null,"operationName":null};
                    break;
                case 'Email address' :
                    if (search && search.userTypeFilterValue==='Frequent Vendor') 
                    query = {"query":`{\n  getProfileList(input: {\n    profileRequest: \n    \t{\n        userID: \"\", \n      firstName: \"\",\n      parentCompanyName: \"\",\n      email: "${search.topLeftSearchBoxValue}",\n   profileRole: \"\"\n      \tphoneNumbers: {\n          homePhone: \"\", \n          mobilePhone: \"\", \n          businessPhone: \"\"\n        }\n        parentEIN:"FRQ_${search.parentCompanyFilterValue}"\n      status:"${search.statusFilterValue}"\n }, \n    sortBy: {sortKey: "${metaData.sortKey}", sortValue: "${metaData.sortValue}"}, offset: ${metaData.offset}, limit: ${metaData.limit}}) {\n      profiles{\n      userID\n      profileID\n      firstName\n      lastName\n  middleName\n     email\n      profileRole\n\t\t\tstatus\n      parentCompanyName\n      parentEIN\n   parentCompanyLegalEntityID\n      usbRelationship\n      }\n    \n  \t\tpageMeta {\n        total\n      }\t\n    }\n  }`,"variables":null,"operationName":null};
                    else
                    query = {"query":`{\n  getProfileList(input: {\n    profileRequest: \n    \t{\n        userID: \"\", \n      firstName: \"\",\n      parentCompanyName: \"\",\n      email: "${search.topLeftSearchBoxValue}",\n   profileRole: \"\"\n      \tphoneNumbers: {\n          homePhone: \"\", \n          mobilePhone: \"\", \n          businessPhone: \"\"\n        }\n        parentEIN:"${search.parentCompanyFilterValue}"\n      status:"${search.statusFilterValue}"\n }, \n    sortBy: {sortKey: "${metaData.sortKey}", sortValue: "${metaData.sortValue}"}, offset: ${metaData.offset}, limit: ${metaData.limit}}) {\n      profiles{\n      userID\n      profileID\n      firstName\n      lastName\n  middleName\n     email\n      profileRole\n\t\t\tstatus\n      parentCompanyName\n      parentEIN\n   parentCompanyLegalEntityID\n      usbRelationship\n      }\n    \n  \t\tpageMeta {\n        total\n      }\t\n    }\n  }`,"variables":null,"operationName":null};
                    break;
                default : 
                    break;
            }
            return query;
        } 
        if (search && search.userTypeFilterValue==='Frequent Vendor') {
            return {"query":`{\n  getProfileList(input: {\n    profileRequest: \n    \t{\n        userID: \"\", \n      firstName: \"\",\n      parentCompanyName: \"\",\n      email: \"\",\n        profileRole: \"\"\n      \tphoneNumbers: {\n          homePhone: \"\", \n          mobilePhone: \"\", \n          businessPhone: \"\"\n        }\n        parentEIN:"FRQ_${search.parentCompanyFilterValue}"\n    status:"${search.statusFilterValue}"\n }, \n    sortBy: {sortKey: "${metaData.sortKey}", sortValue: "${metaData.sortValue}"}, offset: ${metaData.offset}, limit: ${metaData.limit}}) {\n      profiles{\n      userID\n      profileID\n      firstName\n      lastName\n      email\n      profileRole\n\t\t\tstatus\n      parentCompanyName\n      parentEIN\n     parentCompanyLegalEntityID\n      }\n    \n  \t\tpageMeta {\n        total\n      }\t\n    }\n  }`,"variables":null,"operationName":null};

        }
        return {"query":`{\n  getProfileList(input: {\n    profileRequest: \n    \t{\n        userID: \"\", \n      firstName: \"\",\n      parentCompanyName: \"\",\n      email: \"\",\n        profileRole: \"\"\n      \tphoneNumbers: {\n          homePhone: \"\", \n          mobilePhone: \"\", \n          businessPhone: \"\"\n        }\n        parentEIN:"${search.parentCompanyFilterValue}"\n    status:"${search.statusFilterValue}"\n  }, \n    sortBy: {sortKey: "${metaData.sortKey}", sortValue: "${metaData.sortValue}"}, offset: ${metaData.offset}, limit: ${metaData.limit}}) {\n      profiles{\n      userID\n      profileID\n      firstName\n      lastName\n      email\n      profileRole\n\t\t\tstatus\n      parentCompanyName\n      parentEIN\n   parentCompanyLegalEntityID\n      }\n    \n  \t\tpageMeta {\n        total\n      }\t\n    }\n  }`,"variables":null,"operationName":null};

    };

    static customerQuery = (userId, metaData, search, parentEin) => {
        if (search && search.topLeftSearchBy) {
            let query;
            switch (search.topLeftSearchBy) {
                case 'Last name' : 
                    query = {"query":`{\n  getProfileList(input: {\n    profileRequest: \n    \t{\n        userID: \"\", \n      lastName: "${search.topLeftSearchBoxValue}",\n      parentCompanyName: \"\",\n      email: \"\",\n   profileRole: \"\"\n      \tphoneNumbers: {\n          homePhone: \"\", \n          mobilePhone: \"\", \n          businessPhone: \"\"\n        }\n        parentEIN:"${parentEin}"\n status:"${search.statusFilterValue}"\n     }, \n    sortBy: {sortKey: "${metaData.sortKey}", sortValue: "${metaData.sortValue}"}, offset: ${metaData.offset}, limit: ${metaData.limit}}) {\n      profiles{\n      userID\n      profileID\n      firstName\n      lastName\n  middleName\n      email\n      profileRole\n\t\t\status\n        parentCompanyName\n      parentEIN\n   parentCompanyLegalEntityID\n      }\n    \n  \t\tpageMeta {\n        total\n      }\t\n    }\n  }`,"variables":null,"operationName":null};
                    break;
                case 'Email address':
                    query = {"query":`{\n  getProfileList(input: {\n    profileRequest: \n    \t{\n        userID: \"\", \n      firstName: \"\",\n      parentCompanyName: \"\",\n      email: "${search.topLeftSearchBoxValue}",\n   profileRole: \"\"\n      \tphoneNumbers: {\n          homePhone: \"\", \n          mobilePhone: \"\", \n          businessPhone: \"\"\n        }\n        parentEIN:"${parentEin}"\n status:"${search.statusFilterValue}"\n      }, \n    sortBy: {sortKey: "${metaData.sortKey}", sortValue: "${metaData.sortValue}"}, offset: ${metaData.offset}, limit: ${metaData.limit}}) {\n      profiles{\n      userID\n      profileID\n      firstName\n      lastName\n  middleName\n      email\n      profileRole\n\t\t\tstatus\n      parentCompanyName\n      parentEIN\n   parentCompanyLegalEntityID\n      }\n    \n  \t\tpageMeta {\n        total\n      }\t\n    }\n  }`,"variables":null,"operationName":null};
                    break;
                default : 
                    break;
            }
            return query;
        } 
        if(userId && search){
            return {"query":`{\n  getProfileList(input: {\n    profileRequest: \n    \t{\n        userID: "${userId}", \n      firstName: \"\",\n      parentCompanyName: \"\",\n      email: \"\",\n        profileRole: \"\"\n      \tphoneNumbers: {\n          homePhone: \"\", \n          mobilePhone: \"\", \n          businessPhone: \"\"\n        }\n        parentEIN:\"\"\n     status:"${search.statusFilterValue}"\n  }, \n    sortBy: {sortKey: "", sortValue: ""}, offset: 0, limit: 1}) {\n      profiles{\n        profileID\n    \t\tuserID\n        firstName\n        lastName\n        middleName\n        email\n        address {\n          addressLine1\n          addressLine2\n          city\n          state\n          country\n          zipCode      \n        }\n        phoneNumbers{\n          homePhone\n          businessPhone\n          mobilePhone      \n        }    \n        parentEIN\n  \t\t\tstatus \n  \t\t\tusbRelationship\n  \t\t\tparentCompanyName\n  \t\t\tparentCompanyLegalEntityID\n  \t\t\tprofileRole\n  \t\t\tstatus\n      }\n    \n  \t\tpageMeta {\n        total\n      }\t\n    }\n  }`,"variables":null,"operationName":null};
        }
        else if(parentEin && search && search.userTypeFilterValue!=='Frequent Vendor'){
            return {"query":`{\n  getProfileList(input: {\n    profileRequest: \n    \t{\n        userID: \"\", \n      firstName: \"\",\n      parentCompanyName: \"\",\n      email: \"\",\n   profileRole: \"\"\n      \tphoneNumbers: {\n          homePhone: \"\", \n          mobilePhone: \"\", \n          businessPhone: \"\"\n        }\n        parentEIN:"${parentEin}"\n     status:"${search.statusFilterValue}"\n  }, \n    sortBy: {sortKey: "${metaData.sortKey}", sortValue: "${metaData.sortValue}"}, offset: ${metaData.offset}, limit: ${metaData.limit}}) {\n      profiles{\n      userID\n      profileID\n      firstName\n      lastName\n      email\n      profileRole\n\t\t\tparentCompanyName\n      parentEIN\n  status\n    parentCompanyLegalEntityID\n      }\n    \n  \t\tpageMeta {\n        total\n      }\t\n    }\n  }`,"variables":null,"operationName":null};

        }else if (parentEin && search && search.userTypeFilterValue==='Frequent Vendor') {
        
            return {"query":`{\n  getProfileList(input: {\n    profileRequest: \n    \t{\n        userID: \"\", \n      firstName: \"\",\n      parentCompanyName: \"\",\n      email: \"\",\n        profileRole: \"\"\n      \tphoneNumbers: {\n          homePhone: \"\", \n          mobilePhone: \"\", \n          businessPhone: \"\"\n        }\n        parentEIN:"FRQ_${parentEin}"\n    status:"${search.statusFilterValue}"\n }, \n    sortBy: {sortKey: "${metaData.sortKey}", sortValue: "${metaData.sortValue}"}, offset: ${metaData.offset}, limit: ${metaData.limit}}) {\n      profiles{\n      userID\n      profileID\n      firstName\n      lastName\n      email\n      profileRole\n\t\t\tstatus\n      \nparentCompanyName\n      parentEIN\n     parentCompanyLegalEntityID\n      }\n    \n  \t\tpageMeta {\n        total\n      }\t\n    }\n  }`,"variables":null,"operationName":null};

        }
       else if(userId){
            return {"query":`{\n  getProfileList(input: {\n    profileRequest: \n    \t{\n        userID: "${userId}", \n      firstName: \"\",\n      parentCompanyName: \"\",\n      email: \"\",\n        profileRole: \"\"\n      \tphoneNumbers: {\n          homePhone: \"\", \n          mobilePhone: \"\", \n          businessPhone: \"\"\n        }\n        parentEIN:\"\"\n }, \n    sortBy: {sortKey: "", sortValue: ""}, offset: 0, limit: 1}) {\n      profiles{\n        profileID\n    \t\tuserID\n        firstName\n        lastName\n        middleName\n        email\n        address {\n          addressLine1\n          addressLine2\n          city\n          state\n          country\n          zipCode      \n        }\n        phoneNumbers{\n          homePhone\n          businessPhone\n          mobilePhone      \n        }    \n        parentEIN\n  \t\t\tstatus \n  \t\t\tusbRelationship\n  \t\t\tparentCompanyName\n  \t\t\tparentCompanyLegalEntityID\n  \t\t\tprofileRole\n      }\n    \n  \t\tpageMeta {\n        total\n      }\t\n    }\n  }`,"variables":null,"operationName":null};
        }
      else if(parentEin){
            return {"query":`{\n  getProfileList(input: {\n    profileRequest: \n    \t{\n        userID: \"\", \n      firstName: \"\",\n      parentCompanyName: \"\",\n      email: \"\",\n   profileRole: \"\"\n      \tphoneNumbers: {\n          homePhone: \"\", \n          mobilePhone: \"\", \n          businessPhone: \"\"\n        }\n        parentEIN:"${parentEin}"\n }, \n    sortBy: {sortKey: "${metaData.sortKey}", sortValue: "${metaData.sortValue}"}, offset: ${metaData.offset}, limit: ${metaData.limit}}) {\n      profiles{\n      userID\n      profileID\n      firstName\n      lastName\n      email\n      profileRole\n\t\t\tparentCompanyName\n      parentEIN\n  status\n    parentCompanyLegalEntityID\n      }\n    \n  \t\tpageMeta {\n        total\n      }\t\n    }\n  }`,"variables":null,"operationName":null};

        }

    };
  
    static frequentVendorQuery = (userId, metaData, search, parentEin) => {
        if (search && search.topLeftSearchBy) {
            let query;
            switch (search.topLeftSearchBy) {
                case 'Last name' : 
                    query = {"query":`{\n  getProfileList(input: {\n    profileRequest: \n    \t{\n        userID: \"\", \n      firstName: \"\",\n      lastName: "${search.topLeftSearchBoxValue}",\n      parentCompanyName: \"\",\n      email: \"\",\n   profileRole: \"\"\n      \tphoneNumbers: {\n          homePhone: \"\", \n          mobilePhone: \"\", \n          businessPhone: \"\"\n        }\n        parentEIN:"${parentEin}"\n   status:"${search.statusFilterValue}"     }, \n    sortBy: {sortKey: "${metaData.sortKey}", sortValue: "${metaData.sortValue}"}, offset: ${metaData.offset}, limit: ${metaData.limit}}) {\n      profiles{\n      userID\n      profileID\n      firstName\n      lastName\n middleName\n      email\n       status\n      profileRole\n\n      }\n    \n  \t\tpageMeta {\n        total\n      }\t\n    }\n  }`,"variables":null,"operationName":null};
                    break;
                case 'Email address' : 
                    query = {"query":`{\n  getProfileList(input: {\n    profileRequest: \n    \t{\n        userID: \"\", \n      firstName: \"\",\n      lastName: \"\",\n      parentCompanyName: \"\",\n      email: "${search.topLeftSearchBoxValue}",\n   profileRole: \"\"\n      \tphoneNumbers: {\n          homePhone: \"\", \n          mobilePhone: \"\", \n          businessPhone: \"\"\n        }\n        parentEIN:"${parentEin}"\n   status:"${search.statusFilterValue}"    }, \n    sortBy: {sortKey: "${metaData.sortKey}", sortValue: "${metaData.sortValue}"}, offset: ${metaData.offset}, limit: ${metaData.limit}}) {\n      profiles{\n      userID\n      profileID\n      firstName\n      lastName\n  middleName\n     email\n   parentEIN\n   status\n      profileRole\n\n      }\n    \n  \t\tpageMeta {\n        total\n      }\t\n    }\n  }`,"variables":null,"operationName":null};
                    break;
                default : 
                    break;
            }
            return query;
        }else if (search && search.userTypeFilterValue==='Frequent Vendor') {
         return {"query":`{\n  getProfileList(input: {\n    profileRequest: \n    \t{\n        userID: \"\", \n      firstName: \"\",\n      parentCompanyName: \"\",\n      email: \"\",\n        profileRole: \"\"\n      \tphoneNumbers: {\n          homePhone: \"\", \n          mobilePhone: \"\", \n          businessPhone: \"\"\n        }\n        parentEIN:"FRQ_${parentEin}"\n    status:"${search.statusFilterValue}"\n }, \n    sortBy: {sortKey: "${metaData.sortKey}", sortValue: "${metaData.sortValue}"}, offset: ${metaData.offset}, limit: ${metaData.limit}}) {\n      profiles{\n      userID\n      profileID\n      firstName\n      lastName\n      email\n      profileRole\n\t\t\tstatus\n      \nparentCompanyName\n      parentEIN\n     parentCompanyLegalEntityID\n      }\n    \n  \t\tpageMeta {\n        total\n      }\t\n    }\n  }`,"variables":null,"operationName":null};
        }
        return {"query":`{\n  getProfileList(input: {\n    profileRequest: \n    \t{\n        userID: \"\", \n      firstName: \"\",\n      parentCompanyName: \"\",\n      email: \"\",\n        profileRole: \"\"\n      \tphoneNumbers: {\n          homePhone: \"\", \n          mobilePhone: \"\", \n          businessPhone: \"\"\n        }\n        parentEIN:"${parentEin}"\n    status:"${search.statusFilterValue}"\n }, \n    sortBy: {sortKey: "${metaData.sortKey}", sortValue: "${metaData.sortValue}"}, offset: ${metaData.offset}, limit: ${metaData.limit}}) {\n      profiles{\n      userID\n      profileID\n      firstName\n      lastName\n      email\n      profileRole\n\t\t\tparentCompanyName\n      parentEIN\n  status\n     parentCompanyLegalEntityID\n      }\n    \n  \t\tpageMeta {\n        total\n      }\t\n    }\n  }`,"variables":null,"operationName":null};
    };

    static getProfile = (profileId) => {
        return {"query":`{\n      getProfile(profileID:\"${profileId}\") {\n        profileID\n    \t\tuserID\n        firstName\n        lastName\n        middleName\n  mnoBypass\n      email\n        address {\n          addressLine1\n          addressLine2\n          city\n          state\n          country\n          zipCode      \n        }\n        phoneNumbers{\n          homePhone\n          businessPhone\n          mobilePhone      \n        }    \n        parentEIN\n  \t\t\tstatus \n  \t\t\tusbRelationship\n  \t\t\tparentCompanyName\n  \t\t\tparentCompanyLegalEntityID\n  \t\t\tprofileRole\n      }\n    }`,"variables":null,"operationName":null} ;        
    };

    static getProfileByUserId = (userId) => {
        return {"query":`{\n  getProfileList(input: {\n    profileRequest: \n    \t{\n        userID: "${userId}", \n      firstName: \"\",\n      parentCompanyName: \"\",\n      email: \"\",\n        profileRole: \"\"\n      \tphoneNumbers: {\n          homePhone: \"\", \n          mobilePhone: \"\", \n          businessPhone: \"\"\n        }\n        parentEIN:\"\"\n      }, \n    sortBy: {sortKey: "", sortValue: ""}, offset: 0, limit: 1}) {\n      profiles{\n        profileID\n    \t\tuserID\n        firstName\n        lastName\n        middleName\n        email\n        address {\n          addressLine1\n          addressLine2\n          city\n          state\n          country\n          zipCode      \n        }\n        phoneNumbers{\n          homePhone\n          businessPhone\n          mobilePhone      \n        }    \n        parentEIN\n  \t\t\tstatus \n  \t\t\tusbRelationship\n  \t\t\tparentCompanyName\n  \t\t\tparentCompanyLegalEntityID\n  \t\t\tprofileRole\n      }\n    \n  \t\tpageMeta {\n        total\n      }\t\n    }\n  }`,"variables":null,"operationName":null};

    };

    static getCountryAndStateList = (userID) => {
        return {"query":"{\n      getCountryStateList(nullID:\"\") {\n        countryInfo {\n          countryName\n          countryCode\n        }\n\t\tcountryStateSelection {\n\t\t  stateCode\n\t\t  stateName\n\t\t  }\n      }\n    }","variables":null,"operationName":null};
    };

    static getCurrentUserRole = (userId) => {
        return {"query":`{\n      getProfileWithParentComType(userID:\"${userId}\") {\n        firstName\n        lastName\n        middleName\n        email\n        businessProfileID\n        profileID\n     \t\tuserID\n    \t\tparentCompanyName\n    \t\tparentCompanyLegalEntityID\n       \tprofileRole\n      \tbusinessProfileID\n    \t\tbusinessProfileType\n    \t\tein\n    \t\ttotal\n        parentEIN\n      }\n    }`,"variables":null,"operationName":null}
    };

    static getCountryQuery = () => {
        return {"query":"{\n      getCountryStateList(nullID:\"\") {\n        countryInfo {\n          countryName\n          countryCode\n        }\n\t\tcountryStateSelection {\n\t\t  stateCode\n\t\t  stateName\n\t\t  }\n      }\n    }","variables":null,"operationName":null}
    }
    static getVendorList = () => {
        return {"query":"{\n    getBusinessProfileList(input: {businessProfileRequest: {\n    businessProfileType: \"Vendor\",\n    },      \n      offset:0,limit:100}) {\n      profiles{\n      businessProfileID\t\n     \tein\t\t\t\t\n     \tbusinessProfileType\n      parentCompanyName\n      parentCompanyLegalEntityID          \n      }\n      pageMeta{\n        total\n      }\n    }\n  }","variables":null,"operationName":null}
    };

    static getSpecialVendorList = () => {
        return {"query":"{\n    getBusinessProfileList(input: {businessProfileRequest: {\n    businessProfileType: \"SpecialVendor\",\n    },      \n      offset:0,limit:100}) {\n      profiles{\n      businessProfileID\t\n     \tein\t\t\t\t\n     \tbusinessProfileType\n      parentCompanyName\n      parentCompanyLegalEntityID          \n      }\n      pageMeta{\n        total\n      }\n    }\n  }","variables":null,"operationName":null}
    };

    static isFreqVendorAssociationExists = (userID,parentEIN) => {
        return {"query":`{\n  getProfileList(input: {\n    profileRequest: \n    \t{\n        userID: "${userID}", \n      firstName: \"\",\n      parentCompanyName: \"\",\n      email: \"\",\n        profileRole: \"\"\n      \tphoneNumbers: {\n          homePhone: \"\", \n          mobilePhone: \"\", \n          businessPhone: \"\"\n        }\n        parentEIN:"${parentEIN}"\n      }, \n    sortBy: {sortKey: "", sortValue: ""}, offset: 0, limit: 1}) {\n      profiles{\n        profileID\n    \t\tuserID\n        firstName\n        lastName\n        middleName\n        email\n        address {\n          addressLine1\n          addressLine2\n          city\n          state\n          country\n          zipCode      \n        }\n        phoneNumbers{\n          homePhone\n          businessPhone\n          mobilePhone      \n        }    \n        parentEIN\n  \t\t\tstatus \n  \t\t\tusbRelationship\n  \t\t\tparentCompanyName\n  \t\t\tparentCompanyLegalEntityID\n  \t\t\tprofileRole\n      }\n    \n  \t\tpageMeta {\n        total\n      }\t\n    }\n  }`,"variables":null,"operationName":null};
    };

    static getProfileListWithEmail = (emailID) => {        
            return {"query":`{\n  getProfileList(input: {\n    profileRequest: \n    \t{\n        userID: \"\", \n      firstName: \"\",\n      parentCompanyName: \"\",\n      email: "${emailID}",\n   profileRole: \"\"\n      \tphoneNumbers: {\n          homePhone: \"\", \n          mobilePhone: \"\", \n          businessPhone: \"\"\n        }\n        parentEIN:""\n      }, \n    sortBy: {sortKey: "lastName", sortValue: "asc"}, offset: 0, limit: 2}) {\n      profiles{\n      userID\n      profileID\n      firstName\n      lastName\n      email\n   \n        phoneNumbers{\n          homePhone\n          businessPhone\n          mobilePhone      \n        }    \n           profileRole\n\t\t\tparentCompanyName\n      parentEIN\n      parentCompanyLegalEntityID\n      }\n    \n  \t\tpageMeta {\n        total\n      }\t\n    }\n  }`,"variables":null,"operationName":null};
    };

    static getUserTncStatus = (userID) => {        
        return {"query":`{\n IsTncAccpeted(input:{userID: "${userID}", sourceType:\"CDC\", productID: \"cdc-termsandconditions\"}) {\t\tTnCAccpetedStatus}\n}`,"variables":null,"operationName":null};
    };

    static getExcelReport = (parentEIN) => {        
        return { "query": `{\n  getManageUserExcelReport(parentEIN:"${parentEIN}")\n  \n}`, "variables": null, "operationName": null };
        
    };
    
  }
  