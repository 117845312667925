import React, { useContext, useState, useEffect } from 'react';
import { endpoints } from '../../../config/connections';
import callApi from '../utilities/callApi';
import { saveAsFile } from '../../components/utilities/Utils';
import { parseJwt } from '../utilities/Utils';
import { CBDownloadsContext } from '../../pages/context/CBDownloadsContext';

export const FileDownloader = () => {
  const { filesList, setFilesList, areDownloadsInProgress, setAreDownloadsInProgress } =
    useContext(CBDownloadsContext);
  
    const getApiURL = `${endpoints.envUrl}/document-management/closing-binders/v1/closingbinders/download`;
  
    const userID = sessionStorage.getItem('tok')
    ? `${
        parseJwt(
          sessionStorage.getItem('tok')
        ).custom_data.role.toUpperCase() === 'APP_8083_CDCWEB_SUPER_ADMINS'
          ? sessionStorage.getItem('assignedUser')
          : parseJwt(sessionStorage.getItem('tok')).sub
      }`
    : '';

  const updateList = async (list) => {
    // console.log("updated LiST")
    setAreDownloadsInProgress(true);
    let updatedList = await initiateDonwload(list);
    if (updatedList?.length > 0) {
      setFilesList({ original: updatedList, filtered: updatedList });
      sessionStorage.setItem(
        'downloadFiles',
        JSON.stringify({ original: updatedList, filtered: updatedList })
      );
    }
    if (
      updatedList?.length > 0 &&
      updatedList.filter(
        (file) => file.downloadStatus == 'Failed' && file.retryCount < 4
      ).length > 0
    ) {
      // console.log("list update called");
      updateList(updatedList);
    }
  };

  useEffect(() => {
    if (sessionStorage.getItem('downloadFiles')) {
      setFilesList(JSON.parse(sessionStorage.getItem('downloadFiles')));
    }
    // console.log("list changed", filesList);
  }, []);

  useEffect(() => {
    sessionStorage.setItem('downloadFiles', JSON.stringify(filesList));
    if (
      filesList.original.filter((file) => file.downloadStatus == 'Pending')
        .length == filesList.original.length ||
      filesList.original.filter(
        (file) => file.downloadStatus == 'Pending' && file.retryCount == 0
      ).length > 0
    ) {
      updateList(filesList.original);
    }
    if (
      filesList.original.filter((file) => file.downloadStatus == 'Pending')
        .length == 0
    ) {
      setAreDownloadsInProgress(false);
    }
  }, [filesList]);

  const initiateDonwload = async (list) => {
    let updatedList = [];
    if (list?.length > 0) {
      for (let j = 0; j < list.length; j++) {
        if (list[j].downloadStatus != 'Completed' && list[j].retryCount < 4) {
          try {
            let response = await fetch(getApiURL, {
              method: 'GET',
              headers: {
                'Content-Type': 'application/pdf',
                'user-id': userID,
                'Correlation-ID': userID,
                jwtToken: `${sessionStorage.getItem('tok')}`,
                fileId: list[j].id,
              },
            });
            if (response.status == 200) {
              let blob = await response.blob();
              const url = window.URL.createObjectURL(new Blob([blob]));

              const link = document.createElement('a');
              link.href = url;
              link.download = list[j].name;

              document.body.appendChild(link);

              link.click();

              link.parentNode.removeChild(link);
              updatedList.push({
                name: list[j].name,
                id: list[j].id,
                downloadStatus: 'Completed',
                retryCount: list[j].retryCount,
              });
              let downloadingLists = JSON.parse(
                sessionStorage.getItem('downloadFiles')
              )?.original?.filter((fileObj) => fileObj?.name != list[j].name);
              sessionStorage.setItem(
                'downloadFiles',
                JSON.stringify({
                  original: downloadingLists,
                  filtered: downloadingLists,
                })
              );

              window.URL.revokeObjectURL(url);
              // console.log(list, "list");
            } else if (response.status != 200) {
              list[j].retryCount += 1;
              list[j].downloadStatus = 'Failed';
              updatedList.push({
                name: list[j].name,
                id: list[j].id,
                downloadStatus: 'Failed',
                retryCount: list[j].retryCount + 1,
              });
              // console.log(list, "list failed ", j);
            }
          } catch (error) {
            // console.log(error)
          }
        } else {
          updatedList.push(list[j]);
        }
      }
      if (updatedList.length == list.length) {
        return updatedList;
      }
    }
  };

  return null;
};
