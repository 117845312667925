/* eslint-disable no-lone-blocks */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import PlatformController from './PlatformController';
import _noaccess from '../../pages/_noaccess'
import axios from 'axios';
import { parseJwt, formatByBytes } from '../../components/utilities/Utils';
import rateLimit from 'axios-rate-limit';
import searchIcon from '../../../assets/img/search.png';
import {
  EDMDocumentUpload,
  DocumentView,
  FileItems,
  DocumentInfo,
  UploadView,
  FolderView,
  defaultContent,
} from '@usb-edm/react-document-upload';
import finalized2 from '../../../assets/img/finalized.png';
import greenTickImg from '../../../assets/img/green-tick.svg';
import dateAlertImg from '../../../assets/img/dateAlert.png';
import reuploadImg from '../../../assets/img/blueupload.png';
import loadingImg from '../../assets/Loading.gif';
import USBTooltip from '@usb-shield/react-tooltip'
import React, {
  useState,
  useEffect,
  useLayoutEffect,
  useContext,
  useRef,
  createContext,
} from 'react';
import dateAlert from '../../../assets/img/dateAlert.png';
import {
  USBGrid,
  USBColumn,
  USBButton,
  USBModal,
  USBModalHeader,
  USBModalBody,
  USBModalFooter,
  USBModalCloseIcon,
  USBSelect
} from '@usb-shield/components-react';
import USBBreadcrumb from '@usb-shield/components-react/dist/cjs/breadcrumb';
import { Dropdown } from 'primereact/dropdown';
import moment from 'moment';
import Dropzone from 'react-dropzone';
import { TabView, TabPanel } from 'primereact/tabview';
import callApi from '../../components/utilities/callApi';
import Loader from '../../components/utilities/Loader';
import { getQS, saveAsFile, saveFileAsExcel } from '../../components/utilities/Utils';
import Messages from '../../components/organisms/Messages';
import { endpoints } from '../../../config/connections';
import ClosingBindersList from './ClosingBindersList';
import {ExportToExcel} from '../../components/utilities/ExportToExcel';
import { DocContext } from '../context/DocContext';
import { PermissionRoleContext } from '../../components/Hooks/usePermissionRoles';
import { FeatureFlagsContext } from '../../components/Hooks/useFeatureFlags';
import  {FileDownloader}  from '../../components/Hooks/useDownloadStatus';
import { CBDownloadsContext } from '../context/CBDownloadsContext';
import './requirementdetails.css';
import './closingBinders.css';
import { USBIconShow, USBIconDownload, USBIconUpload, USBIconDelete, USBIconArrowUp } from '@usb-shield/react-icons';
import useUserRole from '../../components/Hooks/use-UserRole'
import useNetwork from '../../components/Hooks/useNetwork';
import { USBIconSuccess } from '@usb-shield/react-icons';
import { gridHeaders } from './GridHeadersClosingBinders';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useHistory,
  useLocation,
  Redirect
} from 'react-router-dom';
import useWarningMessage from './useWarningMessage';


const platformController = new PlatformController();


const addAriaLabel = () => {
  const searchElement = document.getElementsByClassName('p-checkbox-box');
  const dropElement = document.getElementsByClassName('p-dropdown-trigger');
  for (let i = 0; i < searchElement.length; i += 1) {
    searchElement[i].setAttribute('aria-label', 'Mark as final');
  }
  for (let j = 0; j < dropElement.length; j += 1) {
    dropElement[j].setAttribute('aria-label', 'Click to show more');
  }
};



export const getClosingBindersListingNoAPI = (setReportingDetailsData, newData) => {
  setReportingDetailsData(newData);
  addAriaLabel();
};

export const convert24HoursDate = (d) => {
  var dd;
  if (d.slice(-5) === "PM CT") {
    var hrs = parseInt(d.slice(-11, -8))
    if(hrs!=12){
      hrs+=12;
    }
    var mins = d.slice(-8, -6)
    dd = d.slice(0, 10) + " " + hrs + ":" + mins;
  } else if (d.slice(-5) === "AM CT") {
    var hrs = parseInt(d.slice(-11, -8))
    if(hrs==12){
    hrs=0;
    }
    var mins = d.slice(-8, -6)
    dd = d.slice(0, 10) + " " + hrs + ":" + mins;
  } else {
    console.log("Received Unrecognized Formatted String");
  }
  return (new Date(dd));
}



export const getClosingBindersListing = (setReportingDetailsData, platformController) => {
  const listingId = getQS('id');
  let refreshRequired = false;

  const listUrl = `${endpoints.envUrl}/document-management/closing-binders/v1/closingbinders/${listingId}/files`;
  callApi
    .getApiDataRequirement(listUrl).then((response) => {
      const fullData = []; 
      response.data.sort(function (a, b) {
        
        var dateA = convert24HoursDate(a.createdDate);
        var dateB = convert24HoursDate(b.createdDate);
        return dateA.getTime() < dateB.getTime() ? 1 : -1;
      });
      response.data.forEach((item) => {
        try {
          if (item.edmStatus == 'UPLOADING' || item.edmStatus == 'FINALIZING') {
            refreshRequired = true;
          }
          fullData.push(item);
        } catch (error) {
          console.log(error);
        }
      });
    
      setReportingDetailsData(fullData);

      if (refreshRequired) {
        setTimeout(() => {
          getClosingBindersListing(setReportingDetailsData, platformController);
        }, 15000);
      }
      window.onbeforeunload = () => {
        sessionStorage.removeItem('searchData')
        sessionStorage.removeItem('key')
        sessionStorage.removeItem('direction')
      }

      addAriaLabel();
    })
    .catch((error) => {
      console.log(error);
    });
};

export const getDocDetails = (
  setRequirementResults,
  setLoading,
  setDocTemplateFlag,setStatus
) => {
  setLoading(true);
  const apiURL = `${endpoints.apigee
    }/document-management/cdc/requirements/v1/documents/${getQS('id')}/details`;
  callApi
    .getApiDataRequirement(apiURL).then((result) => {
      setRequirementResults(result.data);
      setStatus(result?.data?.status)
      if (result.data.docTemplateFlg === 'true') {
        setDocTemplateFlag(true);
      } else {
        setDocTemplateFlag(false);
      }
      setLoading(false);
    })
    .catch(() => {
      // console.log('error', error)
      setLoading(false);
    });
};

export const duedateTemplate = (requirementResults) => {
  const docdueDate = moment(requirementResults.dueDate, 'YYYYMMDD').format(
    'YYYYMMDD'
  );
  let dateHtml;
  let dueHtml = '';
  const today = moment(new Date(), 'YYYYMMDD').format('YYYYMMDD');
  if (requirementResults.dueDate != null) {
    const days = moment(docdueDate, 'YYYYMMDD').diff(
      moment(today, 'YYYYMMDD'),
      'days'
    );
    if (days < 0 && requirementResults.status === 'Not Submitted') {
      dueHtml = (
        <span className="font-red date-details">
          <img className="warn-img" src={dateAlert} alt="warning" />
          &nbsp;Past due
        </span>
      );
    }

    dateHtml = (
      <span>
        {moment(docdueDate).format('MMM DD, YYYY')}
        <span>{dueHtml}</span>
      </span>
    );
  } else dateHtml = '';
  return dateHtml;
};

export const deleteFile = (
  setErrorModal,
  setLoading,
  deleteId,
  setReportingDetailsData,
  setActionsDrop,
  setDeleteId
) => {
  setErrorModal(false);
  setLoading(true);
  const deleteURL = `${endpoints.api}/document-management/file-upload/v1/files/${deleteId}`;
  callApi
    .deleteApi(deleteURL)
    .then((result) => {
      getClosingBindersListing(setReportingDetailsData, platformController);
      setLoading(false);
      setActionsDrop(0);
      setDeleteId('');
    })
    .catch(() => {
      // console.log('error', error)
      setLoading(false);
      setActionsDrop(0);
    });
};

export const handleWordCount = (
  event,
  setCommentTxt,
  setCommentCharsLeft,
  setCharCountWarn
) => {
  setCommentCharsLeft(event.target.value.length);
  if (event.target.value.length === 301) {
    setCharCountWarn(
      <span aria-live="assertive" role="alert" className="max300-char-error">
        Your message can&apos;t be more than 300 characters.
      </span>
    );
  } else {
    setCommentTxt(event.target.value);
    setCharCountWarn('');
  }
};

export const getAllMessages = (setLoading, setAllMessages) => {
  setLoading(true);
  const tmZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const getMsgApiURL = `${endpoints.apigee
    }/document-management/cdc/messaging/v1/messages/${getQS(
      'id'
    )}?userTimeZone=${tmZone}`;
  callApi
    .getApiDataRequirement(getMsgApiURL).then((results) => {
      const msgResults = [];
      results.data.forEach((item) => {
        const temp1 = item?.commentsTimeStamp.replace('T', ' ');
        const temp2 = temp1?.split('.');
        const temp = item;
        const today = moment(new Date(), 'YYYYMMDD').format('MM/DD/YYYY');
        const commentdate = moment(item?.commentsTimeStamp).format('MM/DD/YYYY');
        temp.commentsTimeStamp = moment(temp2[0], 'yyyy-mm-dd HH:mm:ss').format(
          'hh:mm A'
        );
        if (commentdate === today) {
          temp.commentsDate = '';
        } else {
          temp.commentsDate = commentdate;
        }
        msgResults.push(temp);
      });
      setAllMessages(msgResults);
      setLoading(false);
    })
    .catch(() => {
      // console.log(error)
      setLoading(false);
    });
};


export const submitMessageFn = (
  commentTxt,
  setCommentTxt,
  setCommentCharsLeft,
  setLoading,
  setAllMessages,
  setCharCountWarn
) => {

  if (commentTxt.length > 0) {
    setLoading(true);
    setCharCountWarn('');
    const data = {
      docId: getQS('id'),
      userId: sessionStorage.getItem('loggedUser'),
      comment: commentTxt,
    };
    const url = `${endpoints.apigee}/document-management/cdc/messaging/v1/documents/message`;
    return callApi
      .postApi(url, data)
      .then((res) => {
        setCommentTxt('');
        setCommentCharsLeft(0);
        getAllMessages(setLoading, setAllMessages);
      })
      .catch(() => {
        // console.log(error)
        history.push('/Error');
      });
  }
};




export const getAllMessagesPSA = (setLoading, setAllMessagesPSA) => {
  setLoading(true);
  const tmZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const getMsgApiURL = `${endpoints.apigee
    }/document-management/cdc/messaging/v1/messages/${getQS(
      'id'
    )}?userTimeZone=${tmZone}&userType=PSA`;
  callApi
    .getApiDataRequirement(getMsgApiURL).then((results) => {
      const msgResults = [];
      results.data.forEach((item) => {
        const temp1 = item?.commentsTimeStamp.replace('T', ' ');
        const temp2 = temp1?.split('.');
        const temp = item;
        const today = moment(new Date(), 'YYYYMMDD').format('MM/DD/YYYY');
        const commentdate = moment(item?.commentsTimeStamp).format('MM/DD/YYYY');
        temp.commentsTimeStamp = moment(temp2[0], 'yyyy-mm-dd HH:mm:ss').format(
          'hh:mm A'
        );
        if (commentdate === today) {
          temp.commentsDate = '';
        } else {
          temp.commentsDate = commentdate;
        }
        msgResults.push(temp);
      });
      setAllMessagesPSA(msgResults);
      setLoading(false);
    })
    .catch(() => {
      // console.log(error)
      setLoading(false);
    });
};
export const submitMessageFnPSA = (
  commentTxtPSA,
  setCommentTxtPSA,
  setCommentCharsLeftPSA,
  setLoading,
  setAllMessagesPSA,
  setCharCountWarnPSA
) => {

  if (commentTxtPSA.length > 0) {
    setLoading(true);
    setCharCountWarnPSA('');
    const data = {
      docId: getQS('id'),
      userId: sessionStorage.getItem('loggedUser'),
      comment: commentTxtPSA,
      userType: "PSA",
    };
    const url = `${endpoints.apigee}/document-management/cdc/messaging/v1/documents/message`;
    return callApi
      .postApi(url, data)
      .then((res) => {
        setCommentTxtPSA('');
        setCommentCharsLeftPSA(0);
        getAllMessagesPSA(setLoading, setAllMessagesPSA);
      })
      .catch(() => {
        history.push('/Error');
      });
  }
};

export const getMessageCount = (user, setMessageCount) => {
  const listingId = getQS('id');
  const listUrl = `${endpoints.apigee}/document-management/cdc/alerts/v1/users/userId/documents/${listingId}/notifications-count`;
  callApi
    .getApiDataRequirement(listUrl).then((response) => {
      setMessageCount(response.data.count);
    })
    .catch(() => {
      // console.log(error)
    });
};

export const getMessageCountPSA = (user, setMessageCountPSA) => {
  const listingId = getQS('id');
  const listUrl = `${endpoints.apigee}/document-management/cdc/alerts/v1/users/userId/documents/${listingId}/notifications-count?userType=PSA`;
  callApi
    .getApiDataRequirement(listUrl).then((response) => {
      setMessageCountPSA(response.data.count);
    })
    .catch(() => {
      // console.log(error)
    });
};

export const activateTabActions = (
  e,
  setactiveTabIndex,
  setMessageCount,
  setMessageCountPSA,
  setLoading,
  setSearchValue
) => {
  setactiveTabIndex(e.index);
  // console.log(e.index)
  if (e.index === 1 || e === '1') {
    setLoading(true)
    setTimeout(() => { setLoading(false) }, 100)
    setSearchValue('')
    setInterval(() => {
      setMessageCount(0);
    }, 3000);
  }

  if (e.index === 2 || e === '2') {
    setInterval(() => {
      setMessageCountPSA(0);
    }, 3000);
  }
};

const uploadMultipleFiles = async (
  files,
  setReportingDetailsData,
  setFailedFlag,
  setMessageContent,
  setLoading,
  setSuccessFlag,
  setUploadedFlag,
  setSelectAll,
  setCount,
  setDownloadEnabled,
  reportingDetailsData,
  showWarning,
  removeWarning,
  isReplace,
  setSizeErrorFiles,
  setErrorContent
) => {
  showWarning();
  const acceptedFileExtentions = [
    'pdf',
    'xls',
    'xlsx',
    'doc',
    'docx',
    'xlsm',
  ];
  let uploaded = true;
  let acceptedFiles = 0;
  let total = 0;
  let extError = 0;
  let sizeError = 0;
  let letterError = 0;
  let formData = new FormData();
  let formDataLastFile = new FormData();
  let firstPushData = new FormData();
  const extErrorFiles = [];
  const sizeErrorFiles = [];
  const letterErrorFiles = [];
  formData.append('reqId', getQS('id'));
  formData.append('areaToStore ', 'Temporary');
  formData.append('user ', sessionStorage.getItem('loggedUser'));

  formData.append('firstName', JSON.parse(sessionStorage.getItem('userData')).firstName);
  formData.append('lastName', JSON.parse(sessionStorage.getItem('userData')).lastName);
  formData.append('emailId', JSON.parse(sessionStorage.getItem('userData')).emailId);

  let maxSizePerFile = 104857600;
  let maxSizePerApi = 52428800;
  let currentApiSize = 0;
  let totalSize = 0;
  let totalFiles = 0;
  let uploadedFiles = 0;
  let uploadSize = 0;
  let uploadSuccess = false;
  let allFiles = files.length;
  const finalSetFiles =  [];
  const sortedFiles = files.sort((file1, file2) => file2.size - file1.size);
  sortedFiles.forEach((element) => {
    total += 1;
    const ext = element.name?.split('.').pop();
    if (acceptedFileExtentions.indexOf(ext.toLowerCase()) < 0) {
      extErrorFiles.push(element.name);
      extError += 1;
    } else if (element.size >= 104857600) {
      sizeErrorFiles.push(element.name);
      sizeError += 1;
    } else if (element.name.length > 70) {
      letterErrorFiles.push(element.name)
      letterError += 1;
    } else {
      finalSetFiles.push(element)
      totalFiles += 1;
      totalSize = totalSize + element.size;
    }
  });

  const uid = sessionStorage.getItem('loggedUser');
  const did = getQS('id');
  const https = rateLimit(
    axios.create({
      headers: {
        Accept: 'application/json',
        'Correlation-ID': `${sessionStorage.getItem(
          'loggedUser'
        )} ${sessionStorage.getItem('correlationId')}`,
        'Client-Application': 'CDC',
        'requirement-id': did,
        'user-id': `${parseJwt(sessionStorage.getItem('tok')).custom_data.role?.toUpperCase() === 'APP_8083_CDCWEB_SUPER_ADMINS' ? sessionStorage.getItem('assignedUser') : parseJwt(sessionStorage.getItem('tok')).sub}`,
        Authorization: `Bearer ${sessionStorage.getItem('at')}`,
      },
    }),
    { maxRequests: 5, perMilliseconds: 1000, maxRPS: 7 }
  );

  if (files.length > 0) {

    let indexNumber = 1;
    for (let file of finalSetFiles) {
        if (file.size >= maxSizePerApi) { // First upload all 50 to 100 MB files
          formData.append('files', file);
          await callApimultiple(formData, setSuccessFlag, setUploadedFlag, removeWarning, isReplace);
          uploadedFiles += 1;
          for (let data of formData) {
            formData.delete('files');
          }
        }
        else  // File size less than 50MB
        {
          currentApiSize = currentApiSize + file.size;
          uploadedFiles += 1;
          if (currentApiSize >= maxSizePerApi || indexNumber == 5) {
            await callApimultiple(formData, setSuccessFlag, setUploadedFlag, removeWarning,isReplace);
            currentApiSize = file.size;
            for (let data of formData) {
              formData.delete('files');
            }
            formData.append('files', file)
            indexNumber = 1;
            if (totalFiles == uploadedFiles) {
              callApimultiple(formData, setSuccessFlag, setUploadedFlag, removeWarning,isReplace);
            }

          }
          else {
            formData.append('files', file);
            indexNumber += 1;
            // uploadedFiles += 1;
            if (totalFiles === uploadedFiles) {
              await callApimultiple(formData, setSuccessFlag, setUploadedFlag, removeWarning,isReplace)
            }

          }
        }
      

    }

    // });
  }

  async function callApimultiple(formData1, setSuccessFlag, setUploadedFlag, removeWarning,isReplace) {
    setLoading(true);
    const getApiURL = `${endpoints.envUrl}/document-management/closing-binders/v1/files/upload?isReplace=${isReplace}`
    await https.post(getApiURL, formData1)
      .then((response) => {
        if (response.status === 200) {
          removeWarning()
          setSuccessFlag(true);
          setUploadedFlag(true);
          setTimeout(() => {
            setSuccessFlag(false)
          }, 5000);
          setMessageContent(`You have successfully uploaded ${uploadedFiles} of ${total} documents.`);
          window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth',
          });
          uploadSuccess = true;
          getClosingBindersListing(setReportingDetailsData, platformController);
        
        } else {
          // console.log('upload failed')
          
        }
        setLoading(false);
      })
      .catch((e) => {
        
        setFailedFlag(true);
        removeWarning()
        setErrorContent(`Cannot upload files currently. Please try again after sometime.`);
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: 'smooth',
        });
        setLoading(false);
      });

  }
  if ((sizeError > 0 && extError > 0 && letterError > 0) ||(sizeError > 0 && extError > 0) || (extError > 0 && letterError > 0) || (sizeError > 0 && letterError > 0)) {
 
    setSizeErrorFiles(
      <>

        {extError ? <div>
          <span>
            Failed {sizeErrorFiles.length} files due to not acceptable format.    </span>
          {extErrorFiles?.map((item) => (
            <div>
              &nbsp;
              {item}
            </div>
          ))}
        </div> : ''}
        {sizeError ? <div>
          <span>
            Failed {sizeErrorFiles.length} files due to size more than 100MB    </span>
          {sizeErrorFiles?.map((item) => (
            <div>
              &nbsp;
              {item}
            </div>
          ))}
        </div> : ''}
        {letterError ? <div>
           <span>
             Failed {letterErrorFiles.length} files due to character length greater than 70.</span>
           {letterErrorFiles?.map((item) => (
             <div>
               &nbsp;
               {item}
             </div>
           ))}
         </div> : ''}
       </>
     );
 
 
     const contentTxt = (
       <div>
         <div className='errorMessagetilte'>
           We were unable to upload the following documents
         </div>
         {sizeError ? <div>
         <div className='errorMessage'>
           Make sure each file is 100 MB or less and try again.
         </div>
         {sizeErrorFiles?.map((item) => (
           <div className='fileNameError'>
             &nbsp;
             {item}
           </div>
         ))}
         </div>:''}
         {extError ? <div>
         <div className='errorMessage'>
          Make sure your files are in a supported format (.pdf, .xls, .xlsx, .doc, .docx, or .xlsm) and try again.
         </div>
         {extErrorFiles?.map((item) => (
           <div className='fileNameError'>
             &nbsp;
             {item}
           </div>
         ))}
         </div>: ''}
         {letterError ? 
         <div>
         <div className='errorMessage'>
          Make sure each file name is less than 70 characters.
         </div>
         {letterErrorFiles?.map((item) => (
           <div className='lenghtyName'>
             &nbsp;
             {item}
           </div>
         ))}
         </div>: ''}
        
       </div>
       
     );
     // setSizeErrorFiles(contentTxt);
     setFailedFlag(true);
     uploaded = false;
     setUploadedFlag(false);
     setErrorContent(contentTxt);
     window.scrollTo({
       top: 0,
       left: 0,
       behavior: 'smooth',
     });
 
   } else if (extError > 0) {
     if (extError === total) {
       if (total === 1) {
         setFailedFlag(true);
         setUploadedFlag(false);
         setErrorContent(
           `We were unable to upload the document. Make sure your file is in a supported format (.pdf, .xls, .xlsx, .doc, .docx or .xlsm) and try again.`
         );
       } else {
         setFailedFlag(true);
         setUploadedFlag(false);
         setErrorContent(
           `We were unable to upload ${total} of ${total} documents. 
           Make sure your files are in a supported format (.pdf, .xls, .xlsx, .doc,
              .docx or .xlsm) and try again.`
         );
 
         setSizeErrorFiles(
           <div>
             <span>
               Failed {sizeErrorFiles.length} files due to not acceptable format.    </span>
             {extErrorFiles?.map((item) => (
               <div>
                 &nbsp;
                 {item}
               </div>
             ))}
           </div>
         );
 
       }
 
     } else {
       const contentTxt = (
         <div>
           <span>
             We were unable to upload the following documents. Make sure your
             files are in a supported format (.pdf, .xls, .xlsx, .doc, .docx or .xlsm) and try again.
           </span>
           {extErrorFiles?.map((item) => (
             <div>
               &nbsp;
               {item}
             </div>
           ))}
         </div>
       );
       setFailedFlag(true);
       setUploadedFlag(false);
       uploaded = false;
       setErrorContent(contentTxt);
 
     }
     window.scrollTo({
       top: 0,
       left: 0,
       behavior: 'smooth',
     });
   } else if (sizeError > 0) {
     if (total === 1) {
       setFailedFlag(true);
       setUploadedFlag(false);
       uploaded = false;
       setErrorContent(
         'We were unable to upload your document. Make sure your file is 100 MB or less and try again.'
       );
 
     } else {
       const contentTxt = (
         <div>
           <span>
             We were unable to upload the following documents. Make sure each
             file is 100 MB or less and try again.
           </span>
           {sizeErrorFiles?.map((item) => (
             <div>
               &nbsp;
               {item}
             </div>
           ))}
         </div>
       );
       setFailedFlag(true);
       setUploadedFlag(false);
       uploaded = false;
       setErrorContent(contentTxt);
 
     }
     window.scrollTo({
       top: 0,
       left: 0,
       behavior: 'smooth',
     });
   } else if (letterError > 0){
     if (total === 1) {
       setFailedFlag(true);
       setUploadedFlag(false);
       uploaded = false;
       setErrorContent(
         'We were unable to upload your document. Make sure your file name is less than 70 characters and try again.'
       );
     } else {
      const contentTxt = (
        <div >
          <span>
            We were unable to upload the following documents. Make sure each
            file name is less than 70 characters.
          </span>
          {letterErrorFiles?.map((item) => (
            <div className='lenghtyName'>
              &nbsp;
              {item}
            </div>
          ))}
        </div>
      );
      setFailedFlag(true);
      setUploadedFlag(false);
      uploaded = false;
      setErrorContent(contentTxt);

    }
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }


  if (acceptedFiles === 1) {
    setLoading(true);
    const getApiURL = `${endpoints.api}/document-management/closing-binders/v1/files/upload?isReplace=${isReplace}`;

    https.post(getApiURL, formData)
      .then((response) => {
        if (response.status === 200) {
          getClosingBindersListing(setReportingDetailsData, platformController);
          setSelectAll(false);
          setCount(0);
          setDownloadEnabled(false);
          if (total === acceptedFiles) {
            // alert("formdataaaaaaaa"+JSON.stringify(formData))

            setSuccessFlag(true);
            uploaded = true;
            setUploadedFlag(true);
            setMessageContent('You have successfully uploaded your document.');
            getClosingBindersListing(setReportingDetailsData, platformController);
            window.scrollTo({
              top: 0,
              left: 0,
              behavior: 'smooth',
            });
            setTimeout(() => {
              setSuccessFlag(false);
            }, 5000);
          }
        } else {
          // console.log('upload failed')
        }
        setLoading(false);
      })
      .catch((e) => {
        setFailedFlag(true);
        setErrorContent(`We are unable to upload the documents currently.Please try after sometime. `);
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: 'smooth',
        });
        setLoading(false);
      });
  }
  if (acceptedFiles > 1) {
    setLoading(true);
    const getApiURL = `${endpoints.api}/document-management/closing-binders/v1/files/upload?isReplace=${isReplace}`;

    https.post(getApiURL, formData)
      .then((response) => {
        if (response.status === 200) {
          getClosingBindersListing(setReportingDetailsData, platformController);
          if (total === acceptedFiles) {
            window.scrollTo({
              top: 0,
              left: 0,
              behavior: 'smooth',
            });
            setSuccessFlag(true);
            setUploadedFlag(true);
            setMessageContent(
              `You have successfully uploaded ${uploadedFiles} of ${total} documents.`
            );
            getClosingBindersListing(setReportingDetailsData, platformController);
            setTimeout(() => {
              setSuccessFlag(false);
            }, 5000);
          }
        } else {
          // console.log('upload failed')
        }
        setLoading(false);
      })
      .catch((e) => {
        setFailedFlag(true);
        setErrorContent(`We are unable to upload the documents currently.Please try after sometime. `);
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: 'smooth',
        });

        // console.log(error)
        setLoading(false);
      });
  }
  if (uploaded) {
    return true;
  } else {
    return null;
  }
};


export const onDrop = (
  files,
  setReportingDetailsData,
  setSuccessFlag,
  setFailedFlag,
  setMessageContent,
  setLoading,
  setUploadedFlag,
  setSelectAll,
  setCount,
  setDownloadEnabled,
  reportingDetailsData,
  setIsUploadConfirmationRequired,
  setSelectedFileList,
  setFilteredFileList,
  showWarning,
  removeWarning,
  setSizeErrorFiles,
  setErrorContent
) => {
  setSuccessFlag(false);
  setFailedFlag(false);

  let filesCpy = files;
  let filesTemp = [];
  filesCpy.forEach((file) => {
    if (reportingDetailsData.find(existingFile => existingFile.filenm == file.name) == undefined) {
      filesTemp.push(file)
    }
  });
  // console.log(files,filesTemp)
  setSelectedFileList(files)
  setFilteredFileList(filesTemp)
  if (files.length > filesTemp.length) {
    setIsUploadConfirmationRequired(true)
  } else {
    uploadMultipleFiles(
      files,
      setReportingDetailsData,
      setFailedFlag,
      setMessageContent,
      setLoading,
      setSuccessFlag,
      setUploadedFlag,
      setSelectAll,
      setCount,
      setDownloadEnabled,
      reportingDetailsData,
      showWarning,
      removeWarning,
      false ,// is replace is false
      setSizeErrorFiles,
      setErrorContent
    );
  }
};

export const handleDrop = (files, event) => {
  // console.log(files, event)
};

export const handleBrowse = (event) => {
  // console.log(event.target.files)
};

export const closeMessage = (setFailedFlag, setSuccessFlag) => {
  setFailedFlag(false);
  setSuccessFlag(false);
};

export const closeModal = (setDeleteId, setErrorModal) => {
  setDeleteId('');
  setErrorModal(false);
  // setLegalHold(false)
};

export const closeRenameModalPopup = (
  setCloseRenameModal,
  setFileRename,
  setFileRenameError
) => {
  setCloseRenameModal(false);
  setFileRename('');
  setFileRenameError(false);
};

export const saveRenamedFile = (
  setLoading,
  setCloseRenameModal,
  fileRename,
  setFileRename,
  currentFilenetId,
  setReportingDetailsData,
  setFileRenameError
) => {
  setLoading(true);
  let editedName = '';
  if (fileRename === '') {
    editedName = 'EditPopUpEmpty';
  } else {
    editedName = fileRename;
  }

  // get storage value
  let fileLists = [];
  if (sessionStorage.getItem('fileNetIdLists')) {
    fileLists = JSON.parse(sessionStorage.getItem('fileNetIdLists'));

    // hit API with list of ids
    const listUrl = `${endpoints.api
      }/document-management/file-upload/v1/files/details?fileIdList=${fileLists.toString()}&markasfinalFlag=true`;
    callApi
      .patchApi(listUrl, '')
      .then((response) => {
        getClosingBindersListing(setReportingDetailsData, platformController);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  const url = `${endpoints.api}/document-management/file-upload/v1/files/fileRename?editedFilename=${editedName}&fileId=${currentFilenetId}`;
  callApi
    .postApi(url, '')
    .then((response) => {
      if (response.status === 200) {
        setCloseRenameModal(false);
        setLoading(false);
        getClosingBindersListing(setReportingDetailsData, platformController);
        setFileRename('');
        setFileRenameError(false);
      }
    })
    .catch(() => {
      setLoading(false);
      // console.log(error)
    });
};

export const handleRenamedTxt = (
  event,
  setFileRename,
  setRenameLength,
  setFileRenameError
) => {
  setFileRename(event.target.value);
  setRenameLength(event.target.value.length);
  if (event.target.value.length >= 15) {
    setFileRenameError(true);
  } else {
    setFileRenameError(false);
  }
};

export const closeAllmodal = (
  setErrorModal,
  setCloseRenameModal,
  setDocumentsModalOpen
) => {
  setErrorModal(false);
  setCloseRenameModal(false);
  setDocumentsModalOpen(false);
};

export const downloadDocTemplate = (setLoading, category, doctype) => {
  // docFilenetId
  let docSubType = '';

  setLoading(true);

  if (doctype === null || doctype === '') {
    docSubType = 'NA';
  } else {
    docSubType = doctype;
  }

  const apiURL = `${endpoints.api}/document-management/file-download/v1/documents/DocumentTemplates/files-filter?category=${category}&subType=${docSubType}`;
  callApi

    .getApiDataRequirement(apiURL).then((result) => {
      const fileName = result.data.fileName.replace(
        'Content-Disposition: attachment; filename=',
        ''
      );
      saveAsFile(result.data.binary, fileName);
      setLoading(false);
    })
    .catch(() => {
      // console.log('error', error)
      setLoading(false);
    });
};


export const uploadMetadata = (e) => {
  // console.log("Uploading real file");
  // console.log(e.target.files);
  const formData = new FormData();
  formData.append("metadataFile", e.target.files[0]);
  formData.append("requirement-id", getQS('id'));

  const getApiURL = `${endpoints.envUrl}/document-management/closing-binders/v1/closingbinders/upload`;
  const https = rateLimit(
    axios.create({
      headers: {
        Accept: 'application/json',
        'Correlation-ID': `${sessionStorage.getItem(
          'loggedUser'
        )}`,
        'Client-Application': 'CDC',
        'requirement-id': getQS('id'),
        'user-id': `${parseJwt(sessionStorage.getItem('tok')).custom_data.role?.toUpperCase() === 'APP_8083_CDCWEB_SUPER_ADMINS' ? sessionStorage.getItem('assignedUser') : parseJwt(sessionStorage.getItem('tok')).sub}`,
        Authorization: `Bearer ${sessionStorage.getItem('at')}`,
        'jwtToken': `${sessionStorage.getItem('tok')}`,
      },
    }),
    { maxRequests: 5, perMilliseconds: 1000, maxRPS: 7 }
  );

  https
    .post(getApiURL, formData)
    .then((response) => {
      if (response.status === 200) {
        console.log('upload success')
      } else {
        console.log('upload failed')
      }
    })
    .catch((e) => {
      console.log(e)
    });

}
export const downloadMetadata = () => {
  // console.log("Downloading");
  const listingId = getQS('id');
  const apiURL = `${endpoints.envUrl}/document-management/closing-binders/v1/closingbinders/${listingId}/download`;
 
  window.location.assign(apiURL);
 
}



const ClosingBinders = () => {

  const ref = useRef()
  const handleUploadClick = (e) => {
    ref?.current?.click()
  }

  const handlePasteClick =()=>{

    const fileIds = sessionStorage.getItem('filesToCopy')
   
    const listingId = getQS('id');

    sessionStorage.setItem("pastedRequirementId",listingId);


    setSuccessFlag(false);
    setFailedFlag(false);
    setLoading(true);
   
    const getApiURL = `${endpoints.envUrl}/document-management/closing-binders/v1/copy/files?fileIds=${fileIds}&firstName=${JSON.parse(sessionStorage.getItem('userData')).firstName}&lastName=${JSON.parse(sessionStorage.getItem('userData')).lastName}&emailId=${JSON.parse(sessionStorage.getItem('userData')).emailId}`;
    const https = rateLimit(
      axios.create({
        headers: {
          Accept: 'application/json',
          'Correlation-ID': `${sessionStorage.getItem(
            'loggedUser'
          )}`,
          'Client-Application': 'CDC',
          'requirement-id': getQS('id'),
          'user-id': `${parseJwt(sessionStorage.getItem('tok')).custom_data.role?.toUpperCase() === 'APP_8083_CDCWEB_SUPER_ADMINS' ? sessionStorage.getItem('assignedUser') : parseJwt(sessionStorage.getItem('tok')).sub}`,
          Authorization: `Bearer ${sessionStorage.getItem('at')}`,
          'jwtToken': `${sessionStorage.getItem('tok')}`,
        },
      }),
      { maxRequests: 5, perMilliseconds: 1000, maxRPS: 7 }
    );
   
    https
      .post(getApiURL)
      .then((response) => {
        if (response.status === 200) {
          getClosingBindersListing(setReportingDetailsData, platformController);
          window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth',
          });
          setTimeout(() => {
            sessionStorage.setItem('pasted',"true"); 
            window.location.reload();
          }, 1000);
          setLoading(false);
        } else {
          setFailedFlag(true);
          setErrorContent(`We are unable to paste documents as of now. Please try after sometime.`);
          window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth',
          });
          setLoading(false);
        }
      })
      .catch((e) => {
        console.log(e);
        setFailedFlag(true);
        setMessageContent(`We are unable to paste documents as of now. Please try after sometime.`);
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: 'smooth',
        });
        setLoading(false);
      });


      


  }

  const handleCopyClick = (fileIds) => {
    const listingId = getQS('id');

    console.log(fileIds);
    sessionStorage.removeItem('pasted');
    sessionStorage.removeItem('pastedRequirementId');
    sessionStorage.setItem("filesToCopy",fileIds);
    sessionStorage.setItem("sourceRequirementId",listingId);

    if(sessionStorage.getItem('role')?.toUpperCase() === 'APP_8083_CDCWEB_SUPER_ADMINS' && !sessionStorage.getItem('remote_user')){
      
      window.location.href = "/ClosingBinders/Portfolio";
    }else{
      window.location.href = "/ReportingDashboard";
    }
   

    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
   
  }


  const handleMarkAsFinalClick = (fileIds) => {
    const listingId = getQS('id');
    setSuccessFlag(false);
    setFailedFlag(false);
    setLoading(true);
    const getApiURL = `${endpoints.envUrl}/document-management/closing-binders/v1/final?fileIds=${fileIds}`;
    const https = rateLimit(
      axios.create({
        headers: {
          Accept: 'application/json',
          'Correlation-ID': `${sessionStorage.getItem(
            'loggedUser'
          )}`,
          'Client-Application': 'CDC',
          'requirement-id': getQS('id'),
          'user-id': `${parseJwt(sessionStorage.getItem('tok')).custom_data.role?.toUpperCase() === 'APP_8083_CDCWEB_SUPER_ADMINS' ? sessionStorage.getItem('assignedUser') : parseJwt(sessionStorage.getItem('tok')).sub}`,
          Authorization: `Bearer ${sessionStorage.getItem('at')}`,
          'jwtToken': `${sessionStorage.getItem('tok')}`,
        },
      }),
      { maxRequests: 5, perMilliseconds: 1000, maxRPS: 7 }
    );

    https
      .post(getApiURL)
      .then((response) => {
        if (response.status === 200) {
          setSuccessFlag(true);
          setMessageContent(`You’ve successfully finalized your document.`);
          getClosingBindersListing(setReportingDetailsData, platformController);
          window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth',
          });
          setTimeout(() => {
            setSuccessFlag(false);
          }, 5000);
          setLoading(false);
        } else {
          setFailedFlag(true);
          setErrorContent(`We are unable to finalize documents as of now. Please try after sometime.`);
          window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth',
          });
          setLoading(false);
        }
      })
      .catch((e) => {
        console.log(e);
        setFailedFlag(true);
        setErrorContent(`We are unable to finalize documents as of now. Please try after sometime.`);
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: 'smooth',
        });
        setLoading(false);
      });


  }

  const handleDownload = (fileIds, totalFailed, showWarning, removeWarning) => {
    setDownloadFilterValue('all')
    setFilesList((prevFiles) => ({ original: [...prevFiles.original, ...fileIds], filtered: [...prevFiles.original, ...fileIds] }))
  }

  const handleDeleteClick = (fileIds, finalizedSelected) => {
    const listingId = getQS('id');
    setSuccessFlag(false);
    setFailedFlag(false);
    setLoading(true);
    const getApiURL = `${endpoints.envUrl}/document-management/closing-binders/v1/files/delete?fileIdList=${fileIds}`;
    const https = rateLimit(
      axios.create({
        headers: {
          Accept: 'application/json',
          'Correlation-ID': `${sessionStorage.getItem(
            'loggedUser'
          )}`,
          'Client-Application': 'CDC',
          'requirement-id': getQS('id'),
          'user-id': `${parseJwt(sessionStorage.getItem('tok')).custom_data.role?.toUpperCase() === 'APP_8083_CDCWEB_SUPER_ADMINS' ? sessionStorage.getItem('assignedUser') : parseJwt(sessionStorage.getItem('tok')).sub}`,
          Authorization: `Bearer ${sessionStorage.getItem('at')}`,
          'jwtToken': `${sessionStorage.getItem('tok')}`,
        },
      }),
      { maxRequests: 5, perMilliseconds: 1000, maxRPS: 7 }
    );

    https
      .post(getApiURL)
      .then((response) => {
        if (response.status === 200) {
          setSuccessFlag(true);
          setMessageContent(`You’ve successfully deleted your documents.`);
          if (finalizedSelected > 0) {
            setFailedFlag(true);
            setErrorContent(`${finalizedSelected} documents with Uploading/Finalizing/Finalized status cannot be deleted.`);
          }
          getClosingBindersListing(setReportingDetailsData, platformController);
          window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth',
          });
          setTimeout(() => {
            setFailedFlag(false);
            setSuccessFlag(false);
          }, 5000);
          setLoading(false);
        } else {
          setFailedFlag(true);
          setErrorContent(`We are unable to delete your documents as of now. Please try after sometime.`);
          window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth',
          });

          setLoading(false);
        }
      })
      .catch((e) => {
        console.log(e);
        setFailedFlag(true);
        setErrorContent(`We are unable to delete documents as of now. Please try after sometime.`);
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: 'smooth',
        });

        setLoading(false);
      });


  }

  const sortByList = [
    { content: 'Select', value: '' },
    { content: 'File Name (A-Z)', value: 'fileName-Asc' },
    { content: 'File Name (Z-A)', value: 'fileName-Desc' },
    { content: 'Document Title (A-Z)', value: 'document-Asc' },
    { content: 'Document Title(Z-A)', value: 'document-Desc' },
    { content: 'Uploaded Recently', value: 'upload-Asc' },
    { content: 'Uploaded Earlier', value: 'upload-Desc' },
    { content: 'Status (A-Z)', value: 'status-Asc' },
    { content: 'Status (Z-A)', value: 'status-Desc' },
    { content: 'Type (A-Z)', value: 'type-Asc' },
    { content: 'Type (Z-A)', value: 'type-Desc' },
]

const statusList = [
  { content: 'Select', value: 'Select' },
  { content: 'Under Review', value: 'Under Review' },
  { content: 'Needs Action', value: 'Needs Action' },
  { content: 'Completed', value: 'Completed' },
  { content: 'Submitted', value: 'Submitted' },
]

const [sortBy,setSortBy] = useState('')

const [status,setStatus] = useState('')


  const [sizeErrorFiles, setSizeErrorFiles] = useState('');
  const [count, setCount] = useState(0);
  const [finalizeMessageBox, setFinalizeMessageBox] = useState("Once marked as final cannot be unmarked. Do you really want to finalize documents?");
  const [loadinger, setLoading] = useState(false);
  const [employee, setEmployee] = useState(false);
  const [vendor, setVendor] = useState(false);
  const [activeTabIndex, setactiveTabIndex] = useState(0);
  const [commentTxt, setCommentTxt] = useState('');
  const [commentTxtPSA, setCommentTxtPSA] = useState('');
  const [commentCharsLeft, setCommentCharsLeft] = useState(0);
  // const [commentError, setCommentError] = useState('')
  const [charCountWarn, setCharCountWarn] = useState('');

  const [commentCharsLeftPSA, setCommentCharsLeftPSA] = useState(0);
  const [charCountWarnPSA, setCharCountWarnPSA] = useState('');
  const [openedAccordion, setOpenedAccordion] = useState(0);
  const [actionsDrop, setActionsDrop] = useState('');
  const [requirementResults, setRequirementResults] = useState({
    metadata: {},
  });
  const [completeData,setCompleteData] = useState([])
  const [reportingDetailsData, setReportingDetailsData] = useState([]);
  const [allMessages, setAllMessages] = useState([]);
  const [messageCount, setMessageCount] = useState(0);
  const [allMessagesPSA, setAllMessagesPSA] = useState([]);
  const [messageCountPSA, setMessageCountPSA] = useState(0);
  const [deleteId, setDeleteId] = useState('');
  const [errorModal, setErrorModal] = useState(false);
  const [errorModalTxt, setErrorModalTxt] = useState('');
  const [errorModalTitle, setErrorModalTitle] = useState('');
  const [successFlag, setSuccessFlag] = useState(false);
  const [isDirty, setIsDirty] = useState(false);
  const [uploadedFlag, setUploadedFlag] = useState(false);
  const [savedFlag, setSavedFlag] = useState(false);
  const [failedFlag, setFailedFlag] = useState(false);
  const [messageContent, setMessageContent] = useState('');
  const [errorContent, setErrorContent] = useState('');
  const [fileRename, setFileRename] = useState('');
  const [fileRenameError, setFileRenameError] = useState(false);
  const [closeRenameModal, setCloseRenameModal] = useState(false);
  const [renameLength, setRenameLength] = useState(0);
  const [renamedTextReceived, setRenamedTextReceived] = useState('');
  const [currentFilenetId, setCurrentFilenetId] = useState('');
  const [hideExcel, setHideExcel] = useState(false);
  const [documentsModalOpen, setDocumentsModalOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [excelData, setExcelData] = useState([]);
  const [downloadEnabled, setDownloadEnabled] = useState(false);
  const [fileType, setFileType] = useState('');
  const [legalHold, setLegalHold] = useState(false);
  const [docTemplateFlag, setDocTemplateFlag] = useState(false);
  // const [docFilenetId, setDocFilenetId] = useState('')
  const [documentReport, setDocumentReport] = useState(false);
  const [viewPortfolioModalOpen, setViewPortfolioModalOpen] = useState(false);
  const [isUploadConfirmationRequired, setIsUploadConfirmationRequired] = useState(false)
  const [selectedFileList, setSelectedFileList] = useState([])
  const [filteredFileList, setFilteredFileList] = useState([])
  const [Prompt, showWarning, removeWarning] = useWarningMessage();

  const { setCurrentMenu } = useContext(DocContext);
  const { permissionDetails } = useContext(PermissionRoleContext);
  const { featureDetails } = useContext(FeatureFlagsContext);
  const { filesList, setFilesList, areDownloadsInProgress } = useContext(CBDownloadsContext)
  const [updatedDownloadList, setUpdatedDownloadList] = useState([]);
  const [locationKeys, setLocationKeys] = useState([]);
  const [fileDownload, setFileDownload] = useState(false);
  const [selectAll, setSelectAll] = useState(false);

  const [searchBy, setSearchBy] = useState("");
  const [searchQuery, setSearchQuery] = useState(null);
  const [showButton, setShowButton] = useState(false);

  const [downloadFilterValue, setDownloadFilterValue] = useState('all')


  const networkState = useNetwork();
  const {
    online,
    effectiveType
  } = networkState;

  const history = useHistory();
  const tempUserRole = useUserRole();


  const initialSearchOptions = {
    from: 1,
    pageSize: 12,
    currentPageFileCount: 0,
    showLoading: true,
    currentDirectory: '/',
    scope: 'PRIMARY',
    sortConfig: { key: 'default', direction: '' },
    sourceTrigger: 'initialize',
    searchCriteria: {},
  };

  const [documentFiles, setDocumentFiles] = useState([]);
  const [searchValue,setSearchValue]=useState("");
 



  useEffect(() => {
    if (sessionStorage.getItem('fileNetIdLists')) {
      sessionStorage.removeItem('fileNetIdLists');
    }

    if (sessionStorage.getItem('fileDownloadIdLists')) {
      sessionStorage.removeItem('fileDownloadIdLists');
    }

    if (!sessionStorage.getItem('tok')) {
      if (getQS('id')) {
        localStorage.setItem('cbredurl', getQS('id'));
        const currentUrl = typeof window !== 'undefined' ? window.location.href : '';
        if (getQS('from') || currentUrl.includes("dns.com")) {
         
          window.location.href = '/Employee';
        } else {
          window.location.href = '/';
        }
      }
    } else {
      setCurrentMenu('portfolio');
      localStorage.removeItem('cbredurl');

      if (sessionStorage.getItem('userrole') === 'employee') {
        setEmployee(true);
      }
      if (
         sessionStorage.getItem('servicingrole') === 'vendorsiteadmin' ||
        sessionStorage.getItem('servicingrole') === 'vendorcontributor' ||
        sessionStorage.getItem('servicingrole') === 'unlistedvendor'
      ) {
        setVendor(true);
      }
      
      getDocDetails(setRequirementResults, setLoading, setDocTemplateFlag,setStatus); // setDocFilenetId
      getClosingBindersListing(setReportingDetailsData, platformController);
      getMessageCount(sessionStorage.getItem('assignedUser'), setMessageCount);
      getMessageCountPSA(sessionStorage.getItem('assignedUser'), setMessageCountPSA);
      const qsParam = getQS('index') ? getQS('index') : 0;
      if (qsParam === '1' || qsParam === '2') {
        activateTabActions(
          qsParam,
          setactiveTabIndex,
          setMessageCount,
          setMessageCountPSA,
          setSearchValue
        );
      }
      setactiveTabIndex(Number(qsParam));
      getAllMessages(setLoading, setAllMessages);
      getAllMessagesPSA(setLoading, setAllMessagesPSA);
    }
    
  }, []);

  useEffect(() => {
    if(reportingDetailsData.length>0)
    // console.log("condition 1 ",featureDetails,sessionStorage.getItem('role')?.toUpperCase() === 'APP_8083_CDCWEB_SUPER_ADMINS')
    setPluginsArray([...defaultPluginArray])
  }, [reportingDetailsData,requirementResults,featureDetails])


  let documentListID = []
  const getTableData = (detailsData) => {
    return detailsData?.map((doc, index) => {
      documentListID.push(doc.id)
      let newDocument;
      newDocument = new DocumentInfo(
        `${doc.id}`,
        `${doc.filenm}`,
        false,
        `10`
      );
      // Indicates failed uploads
      const errorElement =
        <div>
          <img src={dateAlertImg} className='greenTick'></img>
        </div>

      const reuploadElement =
        <div>
          <img src={reuploadImg} className='greenTick'></img>
        </div>



      const uploadingElement =
        <div>
          <img src={loadingImg} className='loadingImage'></img>
        </div>
      // Idicates sucessfull uploads
      const successElement = <div>
        {/* <div className='statusTooltip'>Finalized</div> */}
        {doc.finalized || doc.edmStatus == "FINALIZED" ?
          <img src={finalized2} className='finalized'></img> :
          <img src={greenTickImg} className='greenTick'></img>}

      </div>

      // Data for uploaded column
      const uploadedElement = <div className='uploadedElement'>
        <div>{doc.firstName && doc.firstName != "null" ? doc.firstName : ''} {doc.lastName && doc.lastName != "null" ? doc.lastName : ''}</div>
        <div>{doc.emailId && doc.emailId != "null" ? doc.emailId : ''}</div>
        <div>{doc.createdDate && doc.createdDate != "null" ? doc.createdDate : ''}</div>
      </div>
      const preview = (id) => {
        let type = 'CB'
        window.open(`/document-viewer?url=` + id + `&type=` + type)

      };
      const previewDoc = <div >
      {
        <Router>
          <Link
            to={location.pathname + location.search}
            onClick={() => {
 
              const nameExt = doc.filenm
                ? doc.filenm?.split('.').pop()
                : doc.filenm?.split('.').pop();
              nameExt === 'pdf' && (doc.edmStatus ==="FINALIZED" || doc.edmStatus === "COPIED"|| doc.edmStatus === "UPLOADED" || doc.edmStatus === "FINALIZING")
                ? 
                preview(
                  doc.downloadId ? doc.downloadId : doc.id
                )
                : alert('Only uploaded pdf document can be previewed')
            }}
          >
            <USBIconShow
              colorVariant={
                (doc.filenm
                  ? doc.filenm?.split('.').pop()
                  : doc.filenm?.split('.').pop()) === 'pdf' && (doc.edmStatus ==="FINALIZED" || doc.edmStatus === "COPIED"|| doc.edmStatus === "UPLOADED" || doc.edmStatus === "FINALIZING")
                  ? 'interaction'
                  : 'grey60'
              }
              title="Preview"
              desc="Preview File"
              ariaHidden={true}
              id="add-icon"
              focusable={true}
              tabindex={-1}
            />
          </Link>
        </Router>
      }
    </div>
      // Mark as final & upload status



      const statusElement = <div className='statusElements' >
        <div className='statusElements-icon'>

          <span>
            {doc.edmStatus == "UPLOADED" ||doc.edmStatus == "COPIED" || doc.edmStatus == "FINALIZED" || doc.finalized ? successElement :
              (doc.edmStatus == "RE-UPLOAD" || doc.edmStatus == "FAILED") ? reuploadElement :
                doc.edmStatus == 'UPLOADING' || doc.edmStatus == "FINALIZING" ? uploadingElement : reuploadElement}
          </span>
        </div>

        <div>{doc.edmStatus == "FINALIZED" || doc.finalized ? 'Finalized' :
          doc.edmStatus == "UPLOADED" ? 'Uploaded' :
          doc.edmStatus == "COPIED" ? 'Copied' :
            (doc.edmStatus == "RE-UPLOAD" || doc.edmStatus == "FAILED") ? 'Re-Upload' :
              doc.edmStatus == "FINALIZING" ? 'Finalizing' :
                  doc.edmStatus == 'UPLOADING' ? 'Uploading' :
                    'Re-Upload'}</div>

      </div>

      // Setting column data
      newDocument.metadata = {
        name: newDocument.name,
        fileName:
          <><div id='docTitle'>{newDocument.name ? newDocument.name : newDocument.name}</div>
            <div>{doc.fileSize && doc.fileSize != "null" ? formatByBytes(doc.fileSize) : ''}</div></>,
        docTitle: <div id='docTitle'>{doc.renamedFileName ? doc.renamedFileName : newDocument.name}</div>,
        uploaded: uploadedElement,
        uploadStatus: statusElement,
        fileScan: doc.edmStatus,
        previwDoc: previewDoc
      };
      return newDocument;
    });
  }

  const showDocumentsModal = () => {
    setFileType('');
    setModalTitle('Download document history report.')
    setDocumentsModalOpen(true);
    setDocumentReport(true);
    addAriaLabel();
  };

  const closeDocumentsModal = () => {
    setFileType('');
    setHideExcel(false);
    setDocumentReport(false);
    setDocumentsModalOpen(false);
  };

  const showMessagesModal = () => {
    setFileType('');
    setModalTitle('Download message history report.')
    setDocumentsModalOpen(true);
    setDocumentReport(false);
    addAriaLabel();
  };

  const PluginContext = createContext(null)
  const ctx = { PluginContext }

  const MyPlugin = () => {
    // In your plugin component, get the context
    const pluginContext = useContext(ctx);
    return <div><input type='text' placeholder='Search here' ></input></div>
  }
  const MarkAsFinalPlugin = () => {
    // In your plugin component, get the context
    const pluginContext = useContext(ctx);
    return <div>{finalizeMessageBox}</div>
  }

  const CopyPlugin = () => {
    // In your plugin component, get the context
    const pluginContext = useContext(ctx);
    let displayText="";
    if(sessionStorage.getItem('role')?.toUpperCase() === 'APP_8083_CDCWEB_SUPER_ADMINS' && !sessionStorage.getItem('remote_user')){
      displayText="You will be navigated to Closing Binders Portfolio page and can select the requirement where documents need to be copied. Are you sure you want to copy?";
    }else{
      displayText="You will be navigated to Portfolio page and can select the requirement where documents need to be copied. Are you sure you want to copy?";
    }

    return <div>{displayText}</div>
  }

  const DeletePlugin = () => {
    // In your plugin component, get the context
    const pluginContext = useContext(ctx);
    return <div>Are you sure you want to delete selected documents?</div>
  }

  const DownloadPlugin = () => {
    // In your plugin component, get the context
    const pluginContext = useContext(ctx);
    return <div>Are you sure you want to Download selected documents?</div>
  }

  const defaultPluginArray = [{
    // visible CSWP2-2355,

    visible:(!sessionStorage.getItem('remote_user') && permissionDetails.includes("cbDeleteDocument"))
      ||
       (!["Completed","Submitted","Needs Action","Under Review"].includes(requirementResults?.status)),
  
    location: 'actions',
    plugin: <DeletePlugin />,
    options: {
      labelText: 'Delete',
      actionsEnabled: { itemAction: true, bulkAction: true },
      position: 4,
      iconNode: <USBIconDelete></USBIconDelete>,
      pluginState: useState(false),
      onActiveCheck: (selectType) => {
        if (selectType != 'NONE') {
          return true;
        } else {
          return false;
        }
      },
      onContextAction: ({
        selectedFileItems, addDocumentMessage, clearSelectedFileItems
      }) => {

        let docsArray = [];
        let finalizedSelected = 0;
        for (let document of selectedFileItems.documents) {
          if (!["finalized","uploading","finalizing"].includes(document.metadata.uploadStatus.props.children[1].props.children.toLowerCase())) {
            docsArray.push(document.id)
          } else {
            finalizedSelected++;
          }
        }
        if (docsArray.length > 0) {
          handleDeleteClick(docsArray.toString(), finalizedSelected);
          clearSelectedFileItems();
        }
        else {
          addDocumentMessage(
            'warning',
            'Please select atleast one document that is not in Uploading/Finalizing/Finalized status.',
            null,
            false
          );


        }

      },
      onDisabledClick: ({ addDocumentMessage }) => {
        const withoutIndex = reportingDetailsData.filter(item => item?.edmStatus != "uploading" &&  item?.edmStatus != "finalizing" &&  item?.edmStatus != "finalized").length;
        addDocumentMessage(
          'warning',
          withoutIndex > 0 ?
            `Please select atleast one document that is not in Uploading/Finalizing/Finalized status.`
            :
            `There are no files selected to delete.`,

          null,
          false
        );
      },
    }
  },
  {
    visible: sessionStorage.getItem('role')?.toUpperCase() === 'APP_8083_CDCWEB_SUPER_ADMINS' && !sessionStorage.getItem('remote_user') ? true : false,
    location: 'actions',
    plugin: <>Do you want to download metadata?</>,
    options: {
      labelText: `Download Metadata ${reportingDetailsData.filter(item => (item?.edmStatus == "UPLOADED" || item?.edmStatus == "COPIED" ) && item?.documentTitle?.length == 0)?.length > 0 ? "!" : ""}`,
      position: 1,

      iconNode: <><USBIconDownload></USBIconDownload></>,
      pluginState: useState(false),
      onActiveCheck: () => {
        // Button will always be active
        return reportingDetailsData.length > 0 && reportingDetailsData.filter(item => ["COPIED","UPLOADED","FINALIZING","FINALIZED"].includes(item?.edmStatus)).length > 0 ? true : false;
      },
      onContextAction: ({ pluginContext }) => {
        // console.log("Clicked") 
        downloadMetadata()
      },
      onDisabledClick: ({ addDocumentMessage }) => {
        alert("There are no files to be downloaded.")
      },

    }
  },
  // POC Start
  {
    visible: featureDetails?.includes("feature_cb_files_copy"),
    location: 'actions',
    plugin: <CopyPlugin />,
    options: {
      labelText: 'Copy',
      position: 3,
      iconNode: <USBIconUpload></USBIconUpload>,
      pluginState: useState(false),
      onActiveCheck: (selectType) => {
        const withoutIndex = reportingDetailsData.filter(item => ["COPIED","UPLOADED","FINALIZED"].includes( item?.edmStatus)).length;
        if (withoutIndex > 0 && selectType != 'NONE') {
          // console.log("condition inside if")
          return true;
        } else {
          // console.log("condition inside else")
          return false;
        }
      },
      onContextAction: ({
        selectedFileItems, addDocumentMessage, clearSelectedFileItems
      }) => {

        let docsArray = [];
        for (let document of selectedFileItems.documents) {
          if (["uploaded","copied","finalized"].includes(document.metadata.uploadStatus.props.children[1].props.children.toLowerCase())) {
            docsArray.push(document.id)
          }
        }
        if (docsArray.length > 0) {
          handleCopyClick(docsArray.toString());
          clearSelectedFileItems();
        }
        else {
          addDocumentMessage(
            'warning',
            'Please select atleast one uploaded/finalized document.',
            null,
            false
          );


        }

      },
      onDisabledClick: ({ addDocumentMessage }) => {
        const withoutIndex = reportingDetailsData.filter(item => ["COPIED","UPLOADED","FINALIZED"].includes( item?.edmStatus)).length;
        // if (withoutIndex == 0 && !["Not Submitted","Completed"].includes(requirementResults?.status) && selectType != 'NONE') {
         addDocumentMessage(
          'warning', `Please select atleast one Uploaded/Finalized document.`,
           null,
          false
        );
      },
    }
  },


  // POC End
  {
    visible: sessionStorage.getItem('role')?.toUpperCase() === 'APP_8083_CDCWEB_SUPER_ADMINS' && !sessionStorage.getItem('remote_user')
    && !["B","T"].includes(requirementResults?.closingBinderFileNetType) 
    && !["Not Submitted","Completed"].includes(requirementResults?.status) ,
    location: 'actions',
    plugin: <MarkAsFinalPlugin />,
    options: {
      labelText: 'Mark As Final',
      position: 3,
      iconNode: <USBIconSuccess></USBIconSuccess>,
      pluginState: useState(false),
      onActiveCheck: (selectType) => {
        const withoutIndex = reportingDetailsData.filter(item => item?.documentTitle?.length == 0 && (item?.edmStatus == "UPLOADED"||item?.edmStatus == "COPIED")).length;
        if (withoutIndex == 0 && !["Not Submitted","Completed"].includes(requirementResults?.status) && selectType != 'NONE') {
          return true;
        } else {
          return false;
        }
      },
      onContextAction: ({
        selectedFileItems, addDocumentMessage, clearSelectedFileItems
      }) => {

        let docsArray = [];
        for (let document of selectedFileItems.documents) {
          if (["copied","uploaded"].includes(document.metadata.uploadStatus.props.children[1].props.children.toLowerCase())) {
            docsArray.push(document.id)
          }
        }
        if (docsArray.length > 0) {
          handleMarkAsFinalClick(docsArray.toString());
          clearSelectedFileItems();
        }
        else {
          addDocumentMessage(
            'warning',
            'Please select atleast one uploaded document.',
            null,
            false
          );


        }

      },
      onDisabledClick: ({ addDocumentMessage }) => {
        const withoutIndex = reportingDetailsData.filter(item => item?.documentTitle?.length == 0 && (item?.edmStatus == "UPLOADED"||item?.edmStatus == "COPIED")).length;
        addDocumentMessage(
          'warning',
          ["Not Submitted","Completed"].includes(requirementResults?.status)?
            `Status with Not Submitted/Completed documents cannot be finalized.` :
            withoutIndex > 0 ?
              `Please update metadata before finalizing. Missing Metadata for ${withoutIndex} document${withoutIndex > 1 ? 's' : ''}.`
              :
              `There are no files selected to mark as final.`,

          null,
          false
        );
      },
    }
  },
  {
    visible: true,
    location: 'actions',
    plugin: <DownloadPlugin />,
    options: {
      labelText: 'Download',
      position: 5,
      iconNode: <><USBIconDownload></USBIconDownload></>,
      pluginState: useState(false),
      onActiveCheck: (selectType) => {
        if (selectType != 'NONE') {
          return true;
        } else {
          return false;
        }
      },
      onContextAction: ({
        selectedFileItems, addDocumentMessage, clearSelectedFileItems
      }) => {

        let docsArray = [];
        let totalFailed = 0;
        for (let document of selectedFileItems.documents) {
          if (['Uploaded','Copied','Finalized','Finalizing'].includes(document.metadata.uploadStatus.props.children[1].props.children)) {
            docsArray.push({ id: document.id, name: document.name, downloadStatus: 'Pending', retryCount:0 })
          } else {
            totalFailed++;
          }
        }
        if (docsArray.length > 0) {
          handleDownload(docsArray, totalFailed, showWarning, removeWarning);
          clearSelectedFileItems();
        }
        else {
          addDocumentMessage(
            'warning',
            'Documents in Failed/Reupload/Uploading statuses cannot be downloaded.',
            null,
            false
          );

        }

      },
      onDisabledClick: ({ addDocumentMessage }) => {
        addDocumentMessage(
          'warning',
          `There are no files selected to Download.`,
          null,
          false
        );
      },
    }
  },

  ]
  const [pluginsArray, setPluginsArray] = useState(defaultPluginArray);

  const submitforReview = (
    setViewPortfolioModalOpen,
    reportingDetailsData
  ) => {

    if(reportingDetailsData?.length>0){
      setViewPortfolioModalOpen(true)
    }else{
      setFailedFlag(true);
      setErrorContent(`Please upload files to submit for review. `);

      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });

    }
    // if (reportingDetailsData.filter(item => item?.edmStatus == "UPLOADING" || item?.edmStatus == "RE-UPLOAD" || item?.edmStatus == "FAILED").length >0) {
      // setViewPortfolioModalOpen(true)
    // }
    // else {
    //   submitforReviewOkBtn()
    // }
  };

  const submitforReviewCancelBtn = (setSuccessFlag, setFailedFlag, setLoading, setMessageContent, setActionsDrop, setIsDirty) => {
    const listingId = getQS('id');
    let refreshRequired = false;
    setSuccessFlag(false);
    setFailedFlag(false);
    setLoading(true);
    const url = `${endpoints.envUrl}/document-management/closing-binders/v1/requirementId/documents-all?requirementId=${listingId}`;
    callApi
      .getApiDataRequirement(url).then((response) => {
        if (response.status === 200) {
          setSuccessFlag(true);
          setMessageContent(`You’ve successfully submitted your document for Reupload.`);
          window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth',
          });
          setIsDirty(true);
          setTimeout(() => {
            setSuccessFlag(false);
          }, 5000);

        }
        setLoading(false);
        setActionsDrop('0');
        refreshRequired = true;
      })

      .catch(() => {
        // console.log(error)
        setFailedFlag(true);
        setErrorContent(`We are unable to submit for review currently.Please try after sometime. `);

        window.scrollTo({
          top: 0,
          left: 0,
          behavior: 'smooth',
        });

        setLoading(false);
        setActionsDrop('0');
      });
    setViewPortfolioModalOpen(false);
  }

  const submitforReviewOkBtn = () => {
    setSuccessFlag(false);
    setFailedFlag(false);
    const listingId = getQS('id');
    setLoading(true);
    const url = `${endpoints.envUrl}/document-management/closing-binders/v1/submit`;
    const https = rateLimit(
      axios.create({
        headers: {
          Accept: 'application/json',
          'Correlation-ID': `${sessionStorage.getItem(
            'loggedUser'
          )}`,
          'Client-Application': 'CDC',
          'requirement-id': getQS('id'),
          'user-id': `${parseJwt(sessionStorage.getItem('tok')).custom_data.role?.toUpperCase() === 'APP_8083_CDCWEB_SUPER_ADMINS' ? sessionStorage.getItem('assignedUser') : parseJwt(sessionStorage.getItem('tok')).sub}`,
          Authorization: `Bearer ${sessionStorage.getItem('at')}`,
          'jwtToken': `${sessionStorage.getItem('tok')}`,
        },
      }),
      { maxRequests: 5, perMilliseconds: 1000, maxRPS: 7 }
    );

    https
      .post(url)
      .then((response) => {
        if (response.status === 200) {
          setSuccessFlag(true);
          getDocDetails(setRequirementResults, setLoading, setDocTemplateFlag,setStatus);
          setMessageContent('Successfully submitted documents for review.');
          window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth',
          });
          setTimeout(() => {
            setSuccessFlag(false);
          }, 5000);
        }
        setLoading(false);
      })
      .catch((e) => {
        console.log(e)
        setFailedFlag(true);
        setErrorContent(`We are unable to submit for review currently.Please try after sometime. `);

        window.scrollTo({
          top: 0,
          left: 0,
          behavior: 'smooth',
        });

      });
    setLoading(false);
    setViewPortfolioModalOpen(false);
  }


  const showUploadConfirmationModal = () => {
    setIsUploadConfirmationRequired(true)
  }

  const closeUploadConfirmationModal = () => {
    setIsUploadConfirmationRequired(false)
  }

  const fileTypes = [{ label: 'Microsoft Excel (xlsx)', value: 'xlsx' }];

  const setDownloadFileType = (e, repType) => {
    let tempdata;
    if (repType === 'messages') {
      tempdata = [];
      allMessages.forEach((item) => {
        tempdata.push({
          CLOSING_BINDER_DATE: requirementResults?.closingBinderDate?.length>0 ? moment.utc(requirementResults?.closingBinderDate).format('MMM DD, YYYY'):"",
          DEAL_NAME: requirementResults?.metadata?.dealName,
          DEAL_NUMBER: requirementResults?.metadata?.dealId,
          STATUS: requirementResults.status,
          DUE_DATE: moment(requirementResults.dueDate).format('MMM DD, YYYY'),
          LEGAL_ENTITY: requirementResults?.metadata?.reportingEntity,
          PARENT_COMPANY: requirementResults?.metadata?.parentCompanyName,
          NAME: item?.userName,
          COMMENT: `'${item?.comment}`,
          COMMENTED_AT: item?.commentsTimeStamp,
        });
      });
    } else if (repType === 'documents') {
      tempdata = [];
      reportingDetailsData.forEach((item) => {
        tempdata.push({
          CLOSING_BINDER_DATE: requirementResults?.closingBinderDate?.length>0 ?moment.utc(requirementResults?.closingBinderDate).format('MMM DD, YYYY'):"",
          DEAL_NAME: requirementResults?.metadata?.dealName,
          DEAL_NUMBER: requirementResults?.metadata?.dealId,
          STATUS: requirementResults.status,
          DUE_DATE: moment(requirementResults.dueDate).format('MMM DD, YYYY'),
          LEGAL_ENTITY: requirementResults?.metadata?.reportingEntity,
          PARENT_COMPANY: requirementResults?.metadata?.parentCompanyName,
          NAME: item?.printfilename,
          UPLOADED_DATE: moment(item?.uploadedDate).format('MMM DD, YYYY'),
          UPLOADED_BY: item?.uploadedByName,
          FINAL:
            item?.MarkedasFinalValue === true
              ? 'Marked final'
              : 'Not marked final',
        });
      });
    }
    setFileType(e.target.value);
    setExcelData(tempdata);
    if (e.target.value === 'pdf') {
      setHideExcel(false);
    } else {
      setHideExcel(true);
    }
  };


  /* ============ Report download modal specific ends ========  */

  // Worksheet Tab API Calls

  const uploadWorksheet = (e) => {
    setLoading(true);
    setFailedFlag(false);
    setSuccessFlag(false);
    const formData = new FormData();
    formData.append("metadataFile", e.target.files[0]);
    formData.append("requirement-id", getQS('id'));
    const getApiURL = `${endpoints.envUrl}/document-management/closing-binders/v1/upload/worksheet`;
    const https = rateLimit(
      axios.create({
        headers: {
          Accept: 'application/json',
          'Correlation-ID': `${sessionStorage.getItem(
            'loggedUser'
          )}`,
          'Client-Application': 'CDC',
          'requirement-id': getQS('id'),
          'user-id': `${parseJwt(sessionStorage.getItem('tok')).custom_data.role?.toUpperCase() === 'APP_8083_CDCWEB_SUPER_ADMINS' ? sessionStorage.getItem('assignedUser') : parseJwt(sessionStorage.getItem('tok')).sub}`,
          Authorization: `Bearer ${sessionStorage.getItem('at')}`,
          'jwtToken': `${sessionStorage.getItem('tok')}`,
        },
      }),
      { maxRequests: 5, perMilliseconds: 1000, maxRPS: 7 }
    );

    https
      .post(getApiURL, formData)
      .then((response) => {
        if (response.status === 200) {
          setLoading(false)
          setSuccessFlag(true);
          setFailedFlag(false);
          setTimeout(() => {
            setSuccessFlag(false)
          }, 5000);
          setMessageContent(`You’ve successfully uploaded the worksheet.`);
          window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth',
          });
          getClosingBindersListing(setReportingDetailsData, platformController);
        }
      })
      .catch((e) => {
        setLoading(false)
        setFailedFlag(true);
        setSuccessFlag(false);
        let errorMessage = "Please try after sometime.";
        if (e.response?.data?.message) {
          errorMessage = e.response?.data?.message
        }
        else {
          errorMessage = "";
          var arr = e.response?.data;
          var uniqs = arr.reduce((acc, val) => {
            acc[val] = acc[val] === undefined ? 1 : acc[val] += 1;
            return acc;
          }, {});
          Object.entries(uniqs)?.map(([key, val]) => {
            if (val == "1") {
              errorMessage = errorMessage + `${key}.   `
            } else {
              errorMessage = errorMessage + `${key} - Check ${val} rows.   `
            }
          }
          )
        }
        setErrorContent(errorMessage);
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: 'smooth',
        });
      });

  }

  const downloadWorksheet = () => {
    setFailedFlag(false);
    setSuccessFlag(false);
    const listingId = getQS('id');
    const apiURL = `${endpoints.envUrl}/document-management/closing-binders/v1/download/${listingId}/worksheet`;
    window.location.assign(apiURL)
   
  }

   useEffect(()=>{
    filterDownloadsList(downloadFilterValue)
  },[filesList])

  const changeStatus = (status) => {
    if(status!=="Select"){
    setSuccessFlag(false);
    setFailedFlag(false);
    const listingId = getQS('id');
    setLoading(true);

    const url = `${endpoints.envUrl}/document-management/closing-binders/v1/update/status?requirementId=${listingId}&status=${status}`;
    const https = rateLimit(
      axios.create({
        headers: {
          Accept: 'application/json',
          'Correlation-ID': `${sessionStorage.getItem(
            'loggedUser'
          )}`,
          'Client-Application': 'CDC',
          'user-id': `${parseJwt(sessionStorage.getItem('tok')).custom_data.role?.toUpperCase() === 'APP_8083_CDCWEB_SUPER_ADMINS' ? sessionStorage.getItem('assignedUser') : parseJwt(sessionStorage.getItem('tok')).sub}`,
          Authorization: `Bearer ${sessionStorage.getItem('at')}`,
          'jwtToken': `${sessionStorage.getItem('tok')}`,
        },
      }),
      { maxRequests: 1, perMilliseconds: 1000, maxRPS: 7 }
    );

    https
      .post(url)
      .then((response) => {
        if (response.status === 200) {
          setSuccessFlag(true);
          getDocDetails(setRequirementResults, setLoading, setDocTemplateFlag,setStatus);
          setMessageContent(`Status changed to ${status} successfully`);
          window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth',
          });
          getDocDetails(setRequirementResults, setLoading, setDocTemplateFlag,setStatus);
          setTimeout(() => {
            setSuccessFlag(false);
          }, 5000);
        }
        setLoading(false);
      })
      .catch((e) => {
        console.log(e)
        setFailedFlag(true);
        setErrorContent(`We are unable change the status.Please try after sometime. `);

        window.scrollTo({
          top: 0,
          left: 0,
          behavior: 'smooth',
        });

      });
    setLoading(false);
    }

  }

  const filterDownloadsList = (key) => {
    setDownloadFilterValue(key)
    if(key!="all"){
      let temp = filesList.original.filter((file) => file.downloadStatus.toLocaleLowerCase() == key)
      // setFilesList({ ...filesList, filtered: temp })
      setUpdatedDownloadList(temp)
    } else {
      // setFilesList({...filesList,filtered:filesList.original})
      setUpdatedDownloadList(filesList.original)
    }

  }

  const retryFailedDownloads = () => {
    
    if(filesList.original.filter((file)=>file.downloadStatus.toLocaleLowerCase() == "failed").length>0){
      let temp = filesList.original?.map((file)=> {
        if(file.downloadStatus.toLocaleLowerCase() == "failed"){
          file.retryCount = 0
          file.downloadStatus = "Pending"
        }
        return file;
      })
      setFilesList({original : temp, filtered : temp})
    } else {
      alert("All the files are successfully downloaded")
    }
  }

  const exportDownloadReport = () => {
    const excelData = [];
    excelData.push(
      {
        '': 'File Name',
        ' ': 'Status',
      }
    );
     if (filesList?.original) {
       filesList.original.forEach((item, index) => {
         excelData.push({
           '': item?.name,
           ' ': item?.downloadStatus,
         });
       });
     }
     ExportToExcel(
       Object.values(excelData),
       'Closing Binder Downloads ' +
         moment().format('MMM DD, YYYY hh-mm-ss'),
       ()=>{},
       ()=>{}
     );
   }

   const clearDownloadsList = () => {
      setFilesList({original:[],filtered:[]})
      sessionStorage.setItem("downloadFiles",JSON.stringify({original:[],filtered:[]}))
      setactiveTabIndex(1)
   }

   const sortCBData = (searchedData) => {
    let key = sortBy?.split('-')[0];
    let direction = sortBy?.split('-')[1];
    sessionStorage.setItem("key",key);
    sessionStorage.setItem("direction",direction)
    if(key!="Select" && searchedData?.length > 0){
      let tempList = searchedData;
      switch (key){
        case 'type' : 
        tempList.sort((a, b) => {
          if (direction == "Asc") {
            return (a.filenm?.split('.').pop() + '').localeCompare((b.filenm?.split('.').pop() + ''))
          } else {
            return (b.filenm?.split('.').pop() + '').localeCompare((a.filenm?.split('.').pop() + ''))
          }

        }); break;
        case 'fileName':
          tempList.sort((a, b) => {
            if (direction == "Asc") {
              return (a.filenm + '').localeCompare((b.filenm + ''))
            } else {
              return (b.filenm + '').localeCompare((a.filenm + ''))
            }

          }); break;
        case 'document':
          tempList.sort((a, b) => {

            if (direction == "Asc") {
              return (a.renamedFileName + '').localeCompare((b.renamedFileName + ''))
            } else {
              return (b.renamedFileName + '').localeCompare((a.renamedFileName + ''))
            }

          }); break;
          
       
        case 'upload':
          tempList.sort((a, b) => {
            console.log(a,"sort")
            var dateA = convert24HoursDate(a.createdDate);
            var dateB = convert24HoursDate(b.createdDate);
            if (direction == "Asc") {
              return dateA.getTime() < dateB.getTime() ? 1 : -1
            } else {
              return dateA.getTime() > dateB.getTime() ? 1 : -1
            }

          }); break;
        case 'status':
          tempList.sort((a, b) => {

            if (direction == "Asc") {
              return (a.edmStatus + '').localeCompare((b.edmStatus + ''))
            } else {
              return (b.edmStatus + '').localeCompare((a.edmStatus + ''))
            }

          }); break;
        default:
          break;
      }
      return tempList;
    }
   }

   function globalSearch(){
      return reportingDetailsData?.filter((item) =>
      (item?.filenm?.split('.').pop() + '').toLocaleLowerCase().includes(searchValue.toLocaleLowerCase()) ||
      (item?.filenm + '').toLocaleLowerCase().includes(searchValue.toLocaleLowerCase()) ||
      (item?.renamedFileName + '').toLocaleLowerCase().includes(searchValue.toLocaleLowerCase()) ||
      (item?.firstName + item?.lastName + '').toLocaleLowerCase().includes(searchValue.toLocaleLowerCase()) ||
      (item?.edmStatus + '').toLocaleLowerCase().includes(searchValue.toLocaleLowerCase())
    )
  }

   const searchedData=globalSearch();
   const sortedData=sortCBData(searchedData);
   let timer;

   function triggerSearch(value){
    clearTimeout(timer)
    timer = setTimeout(() => {
      setSearchValue(value)
    }, 200);
   }

  return (
    requirementResults?.metadata?.parentEIN=="" && parseJwt(sessionStorage.getItem('tok')).custom_data.role.toUpperCase() != 'APP_8083_CDCWEB_SUPER_ADMINS'?<_noaccess/> :
    <>
      {Prompt}
      <USBGrid addClasses="content-frame">
        <USBColumn largeSpan={16}>

      
        

        <USBColumn largeSpan={16}>
          {sessionStorage.getItem('filesToCopy') &&
           sessionStorage.getItem('sourceRequirementId')!=getQS('id') &&

         ( ( tempUserRole && tempUserRole == 'employee' && !sessionStorage.getItem('remote_user')
          && requirementResults?.status=="Completed") ||
               
         ( (tempUserRole && tempUserRole != 'employee' || sessionStorage.getItem('remote_user')) &&
           ['Submitted','Under Review','Completed'].includes(requirementResults?.status)) )
           ?(
            <div className="warning-panel-remote">
  
  The status of this requirement is {requirementResults?.status}, hence you cannot paste the documents here,
  To paste, please select a different requirement from&nbsp;
  {sessionStorage.getItem('role')?.toUpperCase() === 'APP_8083_CDCWEB_SUPER_ADMINS' && !sessionStorage.getItem('remote_user')? ( <a href='/ClosingBinders/Portfolio' title="Closing Binders Portfolio">
  Closing Binders Portfolio
            </a>):
  ( <a href='/ReportingDashboard' title="Portfolio">
  Portfolio
            </a>)},

             To cancel copying, click &nbsp; <USBButton
                    size="small"
                    variant='secondary'
                    handleClick={
                      () => { sessionStorage.removeItem('filesToCopy'); 
                      sessionStorage.removeItem('pasted');
                      sessionStorage.removeItem('pastedRequirementId');
                       window.location.reload(); }
                    }
                    ariaLabel='Cancel Copy'
                  >
                    Cancel
                  </USBButton>
            </div>
          ) : (
            ''
          )}
        </USBColumn>




        <USBColumn largeSpan={16}>
          {sessionStorage.getItem('pasted') && 
          sessionStorage.getItem('pastedRequirementId')==getQS('id') &&
          sessionStorage.getItem('sourceRequirementId')!=getQS('id') &&
          requirementResults?.status !== 'Completed'? (
            <div className="success-panel-remote">
            You have successfully pasted selected documents to this requirement. 
            Do you want to paste selected in other requirements?
            &nbsp; 

                <USBButton
                    size="small"
                    variant='primary'
                    handleClick={
                      ()=>{
                        sessionStorage.removeItem('pasted');
                        sessionStorage.removeItem('pastedRequirementId');
                        window.location.href="/ClosingBinders/Portfolio";
                        if(sessionStorage.getItem('role')?.toUpperCase() === 'APP_8083_CDCWEB_SUPER_ADMINS' && !sessionStorage.getItem('remote_user')){
                          window.location.href="/ClosingBinders/Portfolio";
                        }else{
                          window.location.href="/ReportingDashboard";
                        }
                      }
                        
                        }
                    ariaLabel='Cancel Copy'
                  >
                   Yes
                  </USBButton>
                
                 &nbsp; 

                <USBButton
                    size="small"
                    variant='secondary'
                    handleClick={
                      ()=>{sessionStorage.removeItem('pasted');sessionStorage.removeItem('pastedRequirementId');sessionStorage.removeItem('filesToCopy'); window.location.reload();}
                  }
                    ariaLabel='Cancel Copy'
                  >
                   No
                  </USBButton>

            
            </div>
          ) : (
            ''
          )}
        </USBColumn>        

        <USBColumn largeSpan={16}>
          {sessionStorage.getItem('filesToCopy') &&
           sessionStorage.getItem('sourceRequirementId')==getQS('id') &&
           requirementResults?.status !== 'Completed' ? (
            <div className="warning-panel-remote">
  
  You cannot paste the documents to the same requirement. 
  To paste, please select a different requirement from&nbsp;

  {sessionStorage.getItem('role')?.toUpperCase() === 'APP_8083_CDCWEB_SUPER_ADMINS' && !sessionStorage.getItem('remote_user')? ( <a href='/ClosingBinders/Portfolio' title="Closing Binders Portfolio">
  Closing Binders Portfolio
            </a>):
  ( <a href='/ReportingDashboard' title="Portfolio">
  Portfolio
            </a>)}
                
 ,

              To cancel copying, click &nbsp;

              <USBButton
                    size="small"
                    variant='secondary'
                    handleClick={
                      () => { sessionStorage.removeItem('filesToCopy'); 
                      sessionStorage.removeItem('pasted');
                      sessionStorage.removeItem('pastedRequirementId');
                      window.location.reload(); }
                    }
                    ariaLabel='Cancel Copy'
                  >
                    Cancel
                  </USBButton>
            </div>
          ) : (
            ''
          )}
        </USBColumn>


        <USBColumn largeSpan={16}>
          {sessionStorage.getItem('filesToCopy') && 
          (!sessionStorage.getItem('pasted') ||
         
          (sessionStorage.getItem('pasted') &&
          sessionStorage.getItem('pastedRequirementId')!=getQS('id')))  &&
          
          sessionStorage.getItem('sourceRequirementId')!=getQS('id') &&

          (( tempUserRole && tempUserRole == 'employee' && !sessionStorage.getItem('remote_user')
          && requirementResults?.status!="Completed") ||
               
         ( (tempUserRole && tempUserRole != 'employee' || sessionStorage.getItem('remote_user')) &&
          !['Submitted','Under Review','Completed'].includes(requirementResults?.status)) )
          ? (
            <div className="warning-panel-remote">
  
            To paste {(sessionStorage.getItem('filesToCopy')?.split(",")?.length)} selected documents, click
            &nbsp;

                  <USBButton
                    size="small"
                    variant='primary'
                    handleClick={handlePasteClick}
                    ariaLabel='Paste'
                  >
                    Paste
                  </USBButton>
                  &nbsp;


                  To cancel copying, click &nbsp;

                  <USBButton
                    size="small"
                    variant='secondary'
                    handleClick={
                      () => { sessionStorage.removeItem('filesToCopy'); 
                      sessionStorage.removeItem('pasted');
                      sessionStorage.removeItem('pastedRequirementId');
                      window.location.reload(); }
                    }
                    ariaLabel='Cancel Copy'
                  >
                    Cancel
                  </USBButton>
            </div>
          ) : (
            ''
          )}
        </USBColumn>


          {sessionStorage.getItem('remote_user') ? (
            <div className="warning-panel-remote-req-details">
              You are impersonating the user:{' '}
              {JSON.parse(sessionStorage.getItem('remote_user')).firstName}{' '}
              {JSON.parse(sessionStorage.getItem('remote_user')).lastName}. To
              exit, click the 'Exit User' link above.
            </div>
          ) : (
            ''
          )}
        </USBColumn>
        <USBColumn largeSpan={8}>
          {showButton && (
            <div className="showButton">
              <USBButton id="actualButton"
                variant="utility"
                handleClick={() => {
                  window.scrollTo({
                    top: 100,
                    left: 0,
                    behavior: 'smooth',
                  })
                }
                }
              >
                {/* Scroll Top */}
                <USBIconArrowUp />
              </USBButton>
            </div>)
          }
          <USBBreadcrumb
            id="details"
            text="Home"
            breadcrumbs={
              sessionStorage.getItem('role')?.toUpperCase() === 'APP_8083_CDCWEB_SUPER_ADMINS' && !sessionStorage.getItem('remote_user')? [
                {
                  id: 1,
                  text: 'CB portfolio',
                  href: '/ClosingBinders/Portfolio',
                },
                { id: 2, text: 'Closing Binders', href: '' },
              ] : [
                {
                  id: 1,
                  text: 'My Portfolio',
                  href: '/ReportingDashboard',
                },
                { id: 2, text: 'Closing Binders', href: '' },
              ]}
          />
        </USBColumn>
        <USBColumn largeSpan={8} />
      </USBGrid>
      <USBGrid addClasses="content-frame">
        <USBColumn largeSpan={16}>
          {loadinger ? <Loader /> : null}
          <FileDownloader />
          <USBModal className="delete-modal" isOpen={errorModal}>
            <USBModalCloseIcon
              handleModalClose={() => closeModal(setDeleteId, setErrorModal)}
              className="errormodalclosebtn"
            />
            <USBModalHeader modalTitle={errorModalTitle} />
            <USBModalBody>
              <div className="errormodalbody">
                <div className="deletetextframe">{errorModalTxt}</div>
              </div>
            </USBModalBody>
            <USBModalFooter>
              {legalHold ? (
                <div className="deletebtnframe">
                  <USBButton
                    variant="primary"
                    handleClick={() => closeModal(setDeleteId, setErrorModal)}
                  >
                    OK
                  </USBButton>
                </div>
              ) : (
                <div className="deletebtnmodalframe">
                  <span className="cancel-btn">
                    <USBButton
                      id="cancelButton"
                      handleClick={() => closeModal(setDeleteId, setErrorModal)}
                    >
                      Cancel
                    </USBButton>
                  </span>
                  <USBButton
                    variant="primary"
                    handleClick={() =>
                      deleteFile(
                        setErrorModal,
                        setLoading,
                        deleteId,
                        setReportingDetailsData,
                        setActionsDrop,
                        setDeleteId
                      )
                    }
                  >
                    Delete
                  </USBButton>
                </div>
              )}
            </USBModalFooter>
          </USBModal>
          <USBModal className="rename-modal" isOpen={closeRenameModal}>
            <USBModalCloseIcon
              handleModalClose={() =>
                closeRenameModalPopup(
                  setCloseRenameModal,
                  setFileRename,
                  setFileRenameError
                )
              }
              className="errormodalclosebtn"
            />
            <USBModalHeader modalTitle="Rename your document." />
            <USBModalBody>
              <div className="renamemodal-body">
                <p>
                  We’ve automatically renamed your document. If you’d like to
                  add more detail to the file name, enter it in the &nbsp;
                  <strong>Edit file name</strong>
                  &nbsp;field.
                </p>
                <p>
                  When you’re done, select&nbsp;
                  <strong>Save.</strong>
                </p>
                <p>
                  <span className="caption">Document name</span>
                  <br />
                  <span className="black">{renamedTextReceived}</span>
                </p>
                {fileRename.length === 0 ? (
                  <div className="emptycaption height20" />
                ) : (
                  <div className="height20">
                    {fileRename.length < 15 ? (
                      <span className="caption blue">Edit file name</span>
                    ) : (
                      <span className="caption red">Edit file name</span>
                    )}
                  </div>
                )}
                <div className="renametextframe">
                  <div className="renametextframein">
                    <input
                      aria-label="rename"
                      maxLength="15"
                      type="text"
                      name="renametext"
                      placeholder="Edit file name"
                      onChange={(e) =>
                        handleRenamedTxt(
                          e,
                          setFileRename,
                          setRenameLength,
                          setFileRenameError
                        )
                      }
                      value={fileRename}
                    />
                  </div>
                  <div className="renametextframe-left">
                    {fileRenameError ? (
                      <span
                        aria-live="polite"
                        role="alert"
                        className="caption red"
                      >
                        Character limit reached
                      </span>
                    ) : (
                      <span className="caption">Optional</span>
                    )}
                  </div>
                  <div className="renametextframe-right">
                    {fileRenameError ? (
                      <span className="caption red">
                        {renameLength}
                        &nbsp;/ 15
                      </span>
                    ) : (
                      <span className="caption">
                        {renameLength}
                        &nbsp;/ 15
                      </span>
                    )}
                  </div>
                </div>
              </div>
            </USBModalBody>
            <USBModalFooter>
              <div className="deletebtnmodalframe">
                <span className="cancel-btn">
                  <USBButton
                    id="cancelButton"
                    handleClick={() =>
                      closeRenameModalPopup(
                        setCloseRenameModal,
                        setFileRename,
                        setFileRenameError
                      )
                    }
                  >
                    Cancel
                  </USBButton>
                </span>
                <USBButton
                  variant="primary"
                  handleClick={() =>
                    saveRenamedFile(
                      setLoading,
                      setCloseRenameModal,
                      fileRename,
                      setFileRename,
                      currentFilenetId,
                      setReportingDetailsData,
                      setFileRenameError
                    )
                  }
                >
                  Save
                </USBButton>
              </div>
            </USBModalFooter>
          </USBModal>
          <USBModal className="download-modal" isOpen={documentsModalOpen}>
            <USBModalCloseIcon
              handleModalClose={() => closeDocumentsModal()}
              className="errormodalclosebtn"
            />
            <USBModalHeader modalTitle={modalTitle} />
            <USBModalBody>
              <div className="modal-description">
                To download the report, first choose your preferred format from
                the&nbsp;
                <strong>File type</strong>
                &nbsp;menu
              </div>
              <div>
                <div className="download-file-type-dropdown">
                  <Dropdown
                    value={fileType}
                    options={fileTypes}
                    onChange={
                      documentReport
                        ? (e) => setDownloadFileType(e, 'documents')
                        : (e) => setDownloadFileType(e, 'messages')
                    }
                    placeholder="File Type"
                    className="file-type"
                    ariaLabel="file type"
                  />
                </div>
              </div>
            </USBModalBody>
            <USBModalFooter>
              <div className="reportDownloadModal">
                <span className="report-cancel-btn">
                  <USBButton handleClick={() => closeDocumentsModal()}>
                    Cancel
                  </USBButton>
                </span>
                {hideExcel && (
                  <USBButton
                    variant="primary"
                    handleClick={
                      documentReport
                        ? () =>
                          ExportToExcel(
                            Object.values(excelData),
                            'Document_History',
                            setDocumentsModalOpen,
                            setHideExcel
                          )
                        : () =>
                          ExportToExcel(
                            Object.values(excelData),
                            'Messaging_History',
                            setDocumentsModalOpen,
                            setHideExcel
                          )
                    }
                  >
                    Download
                  </USBButton>
                )}
              </div>
            </USBModalFooter>
          </USBModal>
          <USBModal className="download-modal" isOpen={viewPortfolioModalOpen}>
            <USBModalCloseIcon
              handleClick={() => submitforReviewCancelBtn(setSuccessFlag, setFailedFlag, setLoading, setMessageContent, setActionsDrop, setIsDirty)}
              />
            <USBModalHeader modalTitle="Closing Binder details"></USBModalHeader>
            <USBModalBody>
              <div className="modal-description">

             {(reportingDetailsData.filter(item => item?.edmStatus == "UPLOADING" || item?.edmStatus == "RE-UPLOAD" || item?.edmStatus == "FAILED").length >0) ?
             (<>There are some files in Uploading({reportingDetailsData.filter(item => item?.edmStatus == "UPLOADING").length}) / Re-Upload({reportingDetailsData.filter(item => item?.edmStatus == "RE-UPLOAD" || item?.edmStatus == "FAILED").length}) status.</>):''
              }



{((tempUserRole && tempUserRole == 'employee' && !sessionStorage.getItem('remote_user'))) ?
             (<>Once the requirement is submitted external user will not be able to upload new documents.</>):
             (<>Once the requirement is submitted, new documents cannot be uploaded.</>)
              }

Do you still want to submit for review?
                 </div>
            </USBModalBody>
            <USBModalFooter>
              <div className="button-details page-sub-link-nofile">
                <div className="button-panel">
                  <div className="single-buttons">
                    <USBButton
                      variant="primary"
                      handleClick={() => submitforReviewCancelBtn(setSuccessFlag, setFailedFlag, setLoading, setMessageContent, setActionsDrop, setIsDirty)}
                    >
                      Cancel
                    </USBButton>
                  </div>
                  <div className="single-buttons">
                    <USBButton
                      variant="primary"
                      handleClick={() => submitforReviewOkBtn()}
                    >
                      OK
                    </USBButton>
                  </div>
                </div>
              </div>
            </USBModalFooter>
          </USBModal>

          <USBModal className="download-modal" isOpen={isUploadConfirmationRequired}>
            <USBModalCloseIcon
              handleModalClose={() => closeUploadConfirmationModal()}
            />
            <USBModalHeader modalTitle="Document Upload"></USBModalHeader>
            <USBModalBody>
              Do you want to replace the files which are already uploaded?
            </USBModalBody>
            <USBModalFooter>
              <div className="button-details page-sub-link-nofile">
                <div className="button-panel">
                  <div className="single-buttons">
                    <USBButton
                      variant="primary"
                      handleClick={() => {
                        closeUploadConfirmationModal()
                        uploadMultipleFiles(
                          selectedFileList,
                          setReportingDetailsData,
                          setFailedFlag,
                          setMessageContent,
                          setLoading,
                          setSuccessFlag,
                          setUploadedFlag,
                          setSelectAll,
                          setCount,
                          setDownloadEnabled,
                          reportingDetailsData,
                          showWarning,
                          removeWarning,
                          true ,// isReplace
                          setSizeErrorFiles,
                          setErrorContent
                        )
                      }}
                    >
                      Yes
                    </USBButton>
                  </div>
                  <div className="single-buttons">
                    <USBButton
                      variant="primary"
                      handleClick={() => {
                        closeUploadConfirmationModal()
                        uploadMultipleFiles(
                          filteredFileList,
                          setReportingDetailsData,
                          setFailedFlag,
                          setMessageContent,
                          setLoading,
                          setSuccessFlag,
                          setUploadedFlag,
                          setSelectAll,
                          setCount,
                          setDownloadEnabled,
                          reportingDetailsData,
                          showWarning,
                          removeWarning,
                          false, // isReplace
                          setSizeErrorFiles,
                          setErrorContent
                        )
                      }
                      }
                    >
                      No
                    </USBButton>
                  </div>
                </div>
              </div>
            </USBModalFooter>
          </USBModal>
          {successFlag || failedFlag ? (
            <div
              className="message-content"
              data-testid="messageContent"
              role="alert"
            >
              <Messages
                success={successFlag}
                closeMessage={closeMessage}
                singleUploadFail={failedFlag}
                messageContent={messageContent}
                setFailedFlag={setFailedFlag}
                setSuccessFlag={setSuccessFlag}
                severity={'high'}
                errorContent={errorContent}
              />
            </div>
          ) : (
            ''
          )}

          <div className="tab-view-content">
            <div className='closing-binder-title'> Closing Binders</div>
            <div className='closing-binder-requirement'> {requirementResults.requirementName}</div>
           
            <div className="sub-head-small">
            <div className="date-status-details">
                <span className="font-bold light-black">Sequence Number:</span>
                <span className="font-grey">{requirementResults.closingBinderSequenceNumber}</span>
              </div>

              <div className="date-status-details">
                <span className="font-bold light-black">Closing Binder Date:</span>
                {/* <span className="font-grey">{moment(requirementResults.notificationDate).format('MMM DD, YYYY')}</span> */}
                <span className="font-grey">{requirementResults?.closingBinderDate?.length>0 ? moment.utc(requirementResults?.closingBinderDate).format('MMM DD, YYYY'):'-'}</span>
              </div>
              <div className="date-status-details">
                <span className="font-bold light-black">Due date:</span>
                <span className="font-grey">
                  {duedateTemplate(requirementResults)}
                </span>
              </div>
              <div className="date-status-details">
                <span className="font-bold light-black">Status:</span>
                <span
                  className={
                    requirementResults.status === 'Completed'
                      ? 'green-text'
                      : 'font-grey'
                  }
                >
                  {requirementResults.status}
                </span>
              </div>
            </div>
           
              <div className="page-sub-frame">
                <div className="subheading-report-wrap">
                  <div className="sub-heading">
                  {(employee && !sessionStorage.getItem('remote_user')) ||
                      (vendor || sessionStorage.getItem('servicingrole')?.includes('vendorsiteadmin' || 'vendorcontributor' || 'unlistedvendor') ) ? (
                      requirementResults?.metadata?.parentCompanyName === null ||
                        requirementResults?.metadata?.parentCompanyName === '' ? (
                        ''
                      ) : (
                        <div className="date-status-details">
                          <span className="font-bold light-black">
                            Parent company
                          </span>
                          <span className="font-grey">
                            {requirementResults?.metadata?.parentCompanyName}
                          </span>
                        </div>
                      )
                    ) : (
                      ''
                    )}
                    {requirementResults?.metadata?.dealName === null ||
                      requirementResults?.metadata?.dealName === '' ? (
                      ''
                    ) : (
                      <div className="date-status-details">
                        <span className="font-bold light-black">Deal name:</span>
                        <span className="font-grey">
                          {requirementResults?.metadata?.dealName}
                        </span>
                      </div>
                    )}
                    {requirementResults?.metadata?.dealId === null ||
                      requirementResults?.metadata?.dealId === '' ? (
                      ''
                    ) : (
                      <div className="date-status-details">
                        <span className="font-bold light-black">Deal number:</span>
                        <span className="font-grey">
                          {requirementResults?.metadata?.dealId}
                        </span>
                      </div>
                    )}
                    {requirementResults?.metadata?.reportingEntity === null ||
                      requirementResults?.metadata?.reportingEntity === '' ? (
                      ''
                    ) : (
                      <div className="date-status-details">
                        <span className="font-bold light-black">Legal entity:</span>
                        <span className="font-grey">
                          {requirementResults?.metadata?.reportingEntity}
                        </span>
                      </div>
                    )}
                  </div>

                </div>
</div>
                {reportingDetailsData.length === 0 ? (

<div>
  <div className="download-template">
    {/* {reportingDetailsData.length < 1 ?
      <span className="page-sub-empty">
        There are no documents for this reporting
        requirement.&nbsp;


      </span> : ' '} */}

  </div>
</div>
) : (
  <div>
                Total Files - {reportingDetailsData.length} &nbsp;[&nbsp;
                {reportingDetailsData.filter(item => item?.edmStatus == "UPLOADED" && !item?.finalized).length == 0 ? "" : `${reportingDetailsData.filter(item => item?.edmStatus == "UPLOADED" && !item?.finalized).length}-Uploaded `}
                {reportingDetailsData.filter(item => item?.edmStatus == "COPIED" && !item?.finalized).length == 0 ? "" : `${reportingDetailsData.filter(item => item?.edmStatus == "COPIED" && !item?.finalized).length}-Copied `}

                {reportingDetailsData.filter(item => item?.edmStatus == "FINALIZING").length == 0 ? "" : `${reportingDetailsData.filter(item => item?.edmStatus == "FINALIZING").length}-Finalizing `}
                {reportingDetailsData.filter(item => item?.edmStatus == "UPLOADING").length == 0 ? "" : `${reportingDetailsData.filter(item => item?.edmStatus == "UPLOADING").length}-Uploading `}
                {reportingDetailsData.filter(item => item?.edmStatus == "FINALIZED" || item?.finalized).length == 0 ? "" : `${reportingDetailsData.filter(item => item?.edmStatus == "FINALIZED" || item?.finalized).length}-Finalized `}
                {reportingDetailsData.filter(item => item?.edmStatus == "RE-UPLOAD" || item?.edmStatus == "FAILED").length == 0 ? "" : `${reportingDetailsData.filter(item => item?.edmStatus == "RE-UPLOAD" || item?.edmStatus == "FAILED").length}-Re-Upload `}
                {reportingDetailsData.filter(item => item?.edmStatus == "").length == 0 ? "" : `${reportingDetailsData.filter(item => item?.edmStatus == "").length}-Others `}]
              </div>
            )}


            <TabView
              activeIndex={activeTabIndex}
              onTabChange={(e) =>
                activateTabActions(
                  e,
                  setactiveTabIndex,
                  setMessageCount,
                  setMessageCountPSA,
                  setLoading,
                  setSearchValue
                )
              }
            >
              <TabPanel header='Document Upload'>

                {(tempUserRole && tempUserRole == 'employee' && !sessionStorage.getItem('remote_user'))
                     || 
                    (['Not Submitted','Needs Action'].includes(requirementResults?.status))
                    ?
                    (<><h6 className="">
                    {online == true ? (<> Please upload files. Single file should be less than 100 MB.<div
                      className="file-status"
                      data-testid="Upload file"
                    /></>) :
                      (<>You seems to be offline, you can upload only when you are online.<div
                        className="file-status pending"
                        data-testid="Upload file"
                      /> </>)}
                    {(effectiveType == 'slow-2g' || effectiveType == '2g') ? "Your internet connectivity might be slow, ensure you have good bandwidth to upload huge files." : ""}
                    {/* {rtt}, */}
                  </h6>
                   <div id="react-file-drop-space" className="margin-top-bot">
                    <div id="react-file-drop-space" className="margin-top-bot">
                      <Dropzone
                        onDrop={(e) =>
                          onDrop(
                            e,
                            setReportingDetailsData,
                            setSuccessFlag,
                            setFailedFlag,
                            setMessageContent,
                            setLoading,
                            setUploadedFlag,
                            setSelectAll,
                            setCount,
                            setDownloadEnabled,
                            reportingDetailsData,
                            setIsUploadConfirmationRequired,
                            setSelectedFileList,
                            setFilteredFileList,
                            showWarning,
                            removeWarning,
                            setSizeErrorFiles, 
                            setErrorContent
                          )
                        }
                      >
                        {({ getRootProps, getInputProps }) => (
                          <div {...getRootProps()} className="dragndropspace">
                            <input {...getInputProps()} />
                            <div
                              className="upload-big-icon"
                              data-testid="Upload file"
                            />
                            &nbsp;Drag and drop files here or&nbsp;
                            <span>browse</span>
                            &nbsp;to upload
                          </div>
                        )}
                      </Dropzone>
                    </div>
                  </div></>)
                    :
                    (<>
                    You can upload files only for requirements with status as Not Submitted or Needs Action.
                    </>)
                    }
                

              </TabPanel>
              <TabPanel header={"Document View (" + reportingDetailsData.length + " Files)"}>

                <div className="cb-actions">
                  <input
                    id='cbInput'
                    type={"text"}
                    // value={searchValue}
                    placeholder="Search here"
                    style={{ backgroundImage: `url(${searchIcon})` }}
                    onChange={(e) => {
                      triggerSearch(e?.target?.value) 
                    }} >
                  </input>
                  <div className='sortByContainer'>
                  <div className='sortBylabelCB'> Sort By</div>
                  <div className="sortByDropdownCB">
                      <USBSelect
                          id="sortBy"
                          name="sortBy"
                          initValue={sortBy}
                          optionsArray={sortByList}
                          labelText=""
                          handleChange={(e) => setSortBy(e?.target?.value)}
                      />
                  </div>
                  </div>


                
                  

                  <USBButton
                    addClasses="submitReviewCB"
                    variant="primary"

                    // enable if  employee role and not impersonating - removed this logic and kept same for both user
                    // enable if its not submitted and has atlease 1 uplaoded or copied document.
                    
                    disabled={((['Not Submitted','Needs Action'].includes(requirementResults?.status))  &&  (reportingDetailsData.filter(item => item?.edmStatus == "UPLOADED" || item?.edmStatus == "COPIED").length > 0 ))
                    
                    ? false : true}

                    handleClick={() =>
                      submitforReview(
                        setViewPortfolioModalOpen,
                        reportingDetailsData
                      )
                    }
                  >
                 Submit for review
                  </USBButton>

{ 
(requirementResults?.status !== 'Not Submitted' && 
tempUserRole && tempUserRole === 'employee' &&
!sessionStorage.getItem('remote_user') &&
permissionDetails.includes("cbDeleteDocument") &&
!["B","T"].includes(requirementResults?.closingBinderFileNetType)) &&
(

                  <div className='statusContainer'>
                  <div className='statuslabelCB'>Status</div>
                  <div className="statusDropdownCB">
                      <USBSelect
                          id="status"
                          name="status"
                          initValue={status}
                          optionsArray={statusList}
                          labelText=""
                          handleChange={(e) => changeStatus(e?.target?.value)}
                      />
                  </div>
                  </div>

                
)}

</div>
                {sortedData?.length > 0 ?
                  <div id="react-file-drop-space" className="margin-top-bot">
                    <EDMDocumentUpload
                      controller={platformController}
                      content={defaultContent}
                    >
                      <div className={platformController?.fileItems?.documents?.length ? "folder-visible" : "folder-visible"}>
                        <FolderView
                          fileItems={new FileItems(gridHeaders, getTableData(sortedData))}
                          properties={false}
                          move={false}
                          rename={false}
                          notes={false}
                          remove={false}
                          download={false}
                          share={false}
                          preview={false}
                          folders={false}
                          paginationPageSize={25}
                          plugins={pluginsArray.filter(x =>
                            x.visible == true
                          )}
                        />
                      </div>

                    </EDMDocumentUpload>
                  </div>
                  : <span className="page-sub-empty-files">
                    There are no documents for this reporting
                    requirement.&nbsp;
                  </span>}
              </TabPanel>
              <TabPanel id="documentWorksheet" headerClassName={tempUserRole && tempUserRole === 'employee' && permissionDetails.includes("worksheet") && (!sessionStorage.getItem('remote_user'))&& !["B","T"].includes(requirementResults?.closingBinderFileNetType) ? "" : "hide-tabs"} header='Document Worksheet'>
                {reportingDetailsData.length > 0 ?
                  <div className='worksheetBtnContainer'>
                    <div>
                      <USBButton
                        id="uploadWorksheetBtn"
                        variant="primary"
                        handleClick={handleUploadClick}
                      >
                        Upload Worksheet
                      </USBButton>

                    </div>
                    <div>
                      <USBButton
                      id="downloadWorksheetBtn"
                        variant="primary"
                        handleClick={downloadWorksheet}
                      >
                        Download Worksheet
                      </USBButton>
                    </div>
                  </div> : <span className="page-sub-empty-files-documenttab">
                    Please Upload Files.&nbsp;
                  </span>}
                <input ref={ref} accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" type="file" style={{ display: 'none' }} onChange={(event) => uploadWorksheet(event)} onClick={event => event.target.value = null} />
              </TabPanel>

              <TabPanel headerClassName={filesList?.original?.length > 0 ? "" : "hide-tabs"} header='Downloads'>
                { !areDownloadsInProgress ? <div className="filters">
                  <div className='downloadDropdown'>
                    <USBSelect
                      id="downloadsFilter"
                      inputName="downloadsFilter"
                      initValue={downloadFilterValue}
                      labelText="Filter By Status"
                      optionsArray={[{ content: 'All', value: 'all' }, { content: 'Completed', value: 'completed' }, { content: 'Failed', value: 'failed' }, { content: 'Pending', value: 'pending' },]}
                      handleChange={(e) => {
                        filterDownloadsList(e?.target?.value)
                      }}
                    ></USBSelect>
                  </div>
                  <div className="retryFailed">
                    <USBButton
                      id="retryFailedBtn"
                      name="retryFailedBtn"
                      variant="secondary"
                      size="small"
                      disabled={filesList.original.filter((file)=>file?.downloadStatus?.toLocaleLowerCase() == "failed").length > 0 ? false : true}
                      handleClick={retryFailedDownloads}>
                      Retry Failed Downloads
                    </USBButton>
                  </div>
                  <div className="exportExcel">
                    <USBButton
                      id="exportExcelBtn"
                      name="exportExcelBtn"
                      variant="secondary"
                      size="small"
                      handleClick={exportDownloadReport}>
                        Export To Excel
                    </USBButton>
                  </div>
                  {/* <div className="clearDownloads">
                    <USBButton
                      id="clearDownloadBtn"
                      name="clearDownloadBtn"
                      variant="secondary"
                      size="small"
                      handleClick={clearDownloadsList}>
                        Clear Downloads
                    </USBButton>
                  </div> */}
                </div> : <div className='downloadMessage'>Please wait, downloads are in progress...</div>  }
                <div className='fileCard'>
                  <div className='filesContainer'>
                    <div className='fileName fileHeading'>FILE NAME</div>
                    <div className='fileStatus fileHeading'>STATUS</div>
                  </div>
                </div>
                {
                  updatedDownloadList.length > 0 && updatedDownloadList?.map((file) => {
                    return <div className='fileCard'>
                      <div className="filesContainer">
                        <div className='fileName'>{file?.name}</div>
                        <div className='fileStatus'>{file?.downloadStatus}</div>
                      </div>
                    </div>
                  })
                }
              </TabPanel>
            </TabView>
          </div>
        </USBColumn>
      </USBGrid>
    </>
  );
};

export default ClosingBinders;
