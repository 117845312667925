import React, { useState, useEffect, useContext } from 'react';
import { USBGrid, USBColumn, USBSelect } from '@usb-shield/components-react';
import USBButton from '@usb-inner-src/react-button';
import UsbTableData from '../../../components/utilities/UsbTable';
import { endpoints } from '../../../../config/connections';
import USBBreadcrumb from '@usb-shield/components-react/dist/cjs/breadcrumb';
import Loader from '../../../components/utilities/Loader';
import '@usb-shield/react-forms-base/dist/library/styles/index.css';
import '../VendorUserReport/vendor-user.css';
import callApi from '../../../components/utilities/callApi';
import moment from 'moment';
import {ExportToExcel} from '../../../components/utilities/ExportToExcel';
import useUserRole from '../../../components/Hooks/use-UserRole';
import _noaccess from '../../../pages/_noaccess'
import { USBFormsHelperText } from '@usb-shield/react-forms-base';
import {downloadReport} from '../../../components/utilities/Utils';

const VendorUser = () => {
  const [fullData, setFullData] = useState(null);
  const [vendorCompanyNameList, setVendorCompanyNameList] = useState([]);
  const [vendorUserNameList, setVendorUserNameList] = useState([]);
  const [vendorCompanyDataList, setVendorCompanyDataList] = useState([]);
  const [vendorList, setVendorList] = useState([]);
  const [vendorListImmutable, setVendorListImmutable] = useState([]);
  const [loading, setLoading] = useState(false);
  const [responseData, setResponseData] = useState([]);
  const [companySelections, setCompanySelections] = useState("");
  const [keyword, setKeyword] = useState("");
  const [filterSelections,setfilterSelections]=useState({user:'',company:''})
  
  const getFilterData = () => {
    setLoading(true);
    const url = `${endpoints.apigee}/document-management/coreservice/user-management/v1/vendors/details`;
    callApi
      .getApiData(url)
      .then((response) => {
        if (response.status === 200) {
          setResponseData(response?.data);
          loadVendorDropdown(response?.data);
        }
      })
      .catch((e) => {
        setLoading(false);
      });
  };

  const getVendorUserNames = async (selectionsParam) => {
    const url = `${endpoints.apigee}/document-management/coreservice/user-management/v1/vendor/user/report?vendorNumber=${selectionsParam}`;
    try{
       const response =  await callApi.getApiData(url);
       if(response){
        setFullData(response);
          setVendorUserNameList(formatFilterUser(response.data?.users));
          setVendorCompanyDataList(formatFilterCompanyName(response.data?.users));
          setVendorList(response.data?.users);
          setVendorListImmutable(response.data?.users)
          setLoading(false);
       }
    } catch(e){
      setLoading(false);
    }

  };

  const loadVendorDropdown = (response) => {
    const parentCompanyTemp = [];
    response.forEach((element) => {
      parentCompanyTemp.push(element.vendorName);
    });
    setVendorCompanyNameList(formatFilterData(parentCompanyTemp, 0));
    setLoading(false);
  };

  const handleSearch = (value) => {
    setKeyword(value)
    if (value.length > 0) {
      setVendorList(vendorListImmutable.filter(obj =>
         obj.companyName.toLowerCase().includes(value.toLowerCase())||
         obj.emailAddress.toLowerCase().includes(value.toLowerCase())||
         obj.legalEntity?.toLowerCase().includes(value.toLowerCase())||
         obj.deals.toLowerCase().includes(value.toLowerCase())||
         obj.role?.toLowerCase().includes(value.toLowerCase())||
         obj.documentType.toLowerCase().includes(value.toLowerCase())
         ));
    }
    else {
      setVendorList(vendorListImmutable);
    }
  }

  const formatFilterCompanyName = (list) => {
    setKeyword('')
    const companyList = [];
    const uniqueParentCompanyList = list.filter((value, index, self) =>
      index === self.findIndex((item) => (item.companyName === value.companyName))
    )
    uniqueParentCompanyList.forEach((element, index) => {
      companyList.push({ content: element.companyName, value: element.companyName })
      });
    const sortedData = companyList.sort((a, b) =>
      a.content.localeCompare(b.content)
    );
    return [{ value: '', content: 'Select' }, ...sortedData]
  };

// to format data of vendor user dropdown
  const formatFilterUser = (list) => {
    const newData = [];
    const uniqueUserList = list.filter((value, index, self) =>
      index === self.findIndex((item) => (item.firstName + item.lastName === value.firstName + value.lastName))
    )
    uniqueUserList.forEach((element, index) => {
      newData.push({ content: element.firstName + element.lastName, value: element.user });
      });
    const sortedData = newData.sort((a, b) =>
      a.content.localeCompare(b.content)
    );
    return [{ value: '', content: 'Select' }, ...sortedData]
  };

// To format data of vendor dropdown
  const formatFilterData = (list, indicator) => {
    const newData = [];
    list.forEach((element, index) => {
      if (element!==null && element!==undefined) {
        newData.push({ value: index, content: element }); 
      }

    });
    const sortedData = newData.sort((a, b) =>
      a.content.localeCompare(b.content)
    );
    return [{ value: '', content: 'Select' }, ...sortedData]
  };

  const onVendorCompanyChange = (event, filterName) => {
    const eventValue = event?.target?.value;
    setfilterSelections({user:'',company:''})
    setVendorListImmutable([])
    setVendorList([])
    setVendorUserNameList([]);
    setVendorCompanyDataList([]);
    if (event.errorCode != 224) {
      setLoading(true);
      const data = responseData[eventValue]?.vendorNumber;
      const companyname = responseData[eventValue]?.vendorName;
      getVendorUserNames(data);
      setCompanySelections(companyname);
    }
  };

  const downloadVendorUserReport = () => {
    const excelData = [];
    const filterSelectionsTemp =
      `Filter selections : Vendor Company = ${companySelections} Vendor Users = ${filterSelections.user} Company Name = ${filterSelections.company}` + '';
      const columnName = {
        ' ': 'FIRST NAME',
        '  ': 'LAST NAME',
        '   ': 'EMAIL ADDRESS',
        '    ': 'ROLE',
        '     ': 'STATUS',
        '      ': 'COMPANY NAME',
        '       ': 'LEGAL ENTITY',
        '        ': 'DEALS',
        '         ': 'DOCUMENT TYPE'
      };
      const headersFields = [
        'firstName',
        'lastName',
        'emailAddress',
        'role',
        'status',
        'companyName',
        'legalEntity',
        'deals',
        'documentType'
      ]  
    downloadReport(vendorList,filterSelectionsTemp,columnName,headersFields, 'Vendor User')
    // if (vendorList) {
    //   vendorList.forEach((item, index) => {
    //     if (index == 0) {
    //       excelData.push(
    //         {
    //           '': filterSelectionsTemp,
    //         },
    //         { '': '' },
            
    //       );
    //     }
    //     excelData.push({
    //       '': item.firstName,
    //       '  ': item.lastName,
    //       '   ': item.emailAddress,
    //       '    ': item.role,
    //       '     ': item.status,
    //       '      ': item.companyName,
    //       '       ': item.legalEntity.substring(0, 32766),
    //       '        ': item.deals.substring(0, 32766),
    //       '         ': item.documentType
    //     });
    //   });
    // }
    // ExportToExcel(
    //   Object.values(excelData),
    //   'Vendor User ' +
    //   moment().format('MMM DD, YYYY'),
    //   ()=>{},
    //   ()=>{}
    // );
  }

  const filterVendorData = (event,filterName) => {
    setLoading(true)
    const eventValue = event?.target?.value.trim();
    let selection = { user : '', company:''}
    if(filterName==="vendorUser"){
      selection.user = eventValue
      selection.company = filterSelections.company
    } else if(filterName==="vendorCompany"){
      selection.company = eventValue
      selection.user = filterSelections.user
    }
    setfilterSelections(selection)
    let filterFn;
    if(selection.user!=='' && selection.company!==''){
      filterFn = (item) => item.user == selection.user && item.companyName == selection.company
    } else if (selection.user=='' && selection.company!=='') {
      filterFn = (item) => item.companyName == selection.company
    } else if (selection.user!=='' && selection.company=='') {
      filterFn = (item) => item.user == selection.user
    } else{
      filterFn = (item) => item
    }
    let filteredData = vendorListImmutable.filter(filterFn)
    setVendorList(vendorList => {setLoading(false); return filteredData;})
  }


  useEffect(() => {
    getFilterData();
  }, []);
   
  const tempUserRole = useUserRole();
  const columns = [{
    headerName: 'First Name',
    key:'firstName',
    fieldFor: 'first',
    dataType: 'string',
    type: 'info',
  },
  {
    headerName: 'Last Name',
    key:'lastName',
    fieldFor: 'second',
    dataType: 'node',
    type: 'default',
  },
  {
    headerName: 'Email Address',
    key:'emailAddress',
    fieldFor: 'third',
    dataType: 'node',
    type: 'default',
  },
  {
    headerName: 'Role',
    key:'role',
    fieldFor: 'fourth',
    dataType: 'node',
    type: 'default',
  },
  {
    headerName: 'Status',
    key:'status',
    fieldFor: 'fifth',
    dataType: 'node',
    type: 'default',
  },
  {
    headerName: 'Company Name',
    key:'companyName',
    fieldFor: 'sixth',
    dataType: 'node',
    type: 'default',
  },
  {
    headerName: 'Legal Entity',
    key: 'legalEntity',
    fieldFor: 'seventh',
    dataType: 'node',
    type: 'default',
    element : (banker)=>{
      return <>
            <span>
              {banker.legalEntity.split("|").map((item, index) => (
                <>
                <strong>{item.split(":")[0]}</strong>{item.split(":")[1]}
                 
                  {banker.legalEntity.split("|").length > (index + 1) ? (<hr />) : null}
                </>
              ))}
            </span>
          </>

    }
  },
  {
    headerName: 'Deals',
    key:'deals',
    fieldFor: 'eight',
    dataType: 'node',
    type: 'default',
    element: (banker)=>{
      return   <>
            <span>
              {banker.deals.split("|").map((item, index) => (
                <>
                  <strong>{item.split(":")[0]}</strong>{item.split(":")[1]}
                  {banker.deals.split("|").length > (index + 1) ? (<hr />) : null}
                </>
              ))}
            </span>
          </>
    }
  },
  {
    headerName: 'Document Type',
    key:'documentType',
    fieldFor: 'nineth',
    dataType: 'node',
    type: 'default',
    element: (banker)=>{
      return <>
            <span>
              {banker?.documentType?.split("|").map((item, index) => (
                <>
                  {item.length > 1 ? <li>{item}</li> : ''}
                </>
              ))}
            </span>
          </>
    }
  },]

  return (
    tempUserRole && tempUserRole!="employee" ? <_noaccess/> :
    <>
      <USBGrid addClasses="content-frame">
        <USBColumn largeSpan={8}>
          <USBBreadcrumb
            id="reports"
            text="Reports"
            breadcrumbs={[
              // {
              //   id: 1,
              //   text: 'My portfolio',
              //   href: '/ReportingDashboard',
              // },
              { id: 2, text: 'Reports', href: '' },
              { id: 3, text: 'Vendor User', href: '' },
            ]}
          />
        </USBColumn>
        <USBColumn largeSpan={8}></USBColumn>
      </USBGrid>
      <USBGrid addClasses="content-frame content-bg">
        {loading ? <Loader /> : null}
        <USBColumn largeSpan={16}>
          <h1 className="heading-h1">Report: Vendor User</h1>
          <div className="page-details-wrap-vendor-user">
            <div className="page-details-container-vendor-user">Select Filters</div>
          </div>

          <div className="sorting-inputs-vendor-user">
            <div className="filter-dropdowns-vendor-user">
              {vendorCompanyNameList.length > 0 ? (
                <USBSelect
                  id="companyName"
                  inputName="companyName"
                  name="vendorUserCompany"
                  initValue={''}
                  data-testid="your-id"
                  labelText="Vendor Company"
                  optionsArray={vendorCompanyNameList}
                  handleChange={(e) => {
                    onVendorCompanyChange(e, 'vendorNumber');
                  }}
                  errorMessages={{ 224: 'Please select an option' }}
                />
               ) : (
                null
               )
               }
            </div>
            <div className="filter-dropdowns-vendor-user">
              {vendorUserNameList.length > 0 ? (
               <>
                 <USBSelect
                  id="vendorUser"
                  inputName="vendorUser"
                  name="vendorUserParentUser"
                  labelText="Vendor User"
                  initValue={''}
                  optionsArray={vendorUserNameList}
                  handleChange={(e) => {
                    filterVendorData(e, 'vendorUser');
                  }}
                  errorMessages={{ 224: 'Please select an option' }}
                />
                <USBFormsHelperText labelFor="business-structure"/>
                </>
              ) : (
                null
              )}
            </div>
            <div className="filter-dropdowns-vendor-user">
              {vendorCompanyDataList.length > 0 ? (
               <div> <USBSelect
                  id="VendorcompanyName"
                  inputName="companyName"
                  name="vendorUserParentCompany"
                  initValue={''}
                  labelText="Parent Company"
                  optionsArray={vendorCompanyDataList}
                  handleChange={(e) => {
                    filterVendorData(e, 'vendorCompany');
                  }}
                  errorMessages={{ 224: 'Please select an option' }}
                />
                <USBFormsHelperText labelFor="business-structure">
                </USBFormsHelperText>
                </div>
              ) : (
                null
              )}
            </div>
            {vendorUserNameList.length > 0 ? (
              <div>
                <USBButton
                  id="downloadReport"
                  name="downloadReport"
                  variant="primary"
                  handleClick={() => downloadVendorUserReport()}
                >
                  Download Report
                </USBButton>
              </div>) : (null)}
            {vendorUserNameList.length > 0 ? (
              <div>
                <USBButton
                  type="button"
                  
                  id="clearFilterVendor"
                  name="clearfilter"
                  handleClick={() => { 
                    setfilterSelections({user:'',company:''})  
                    setVendorListImmutable([])  
                    setVendorCompanyNameList([]);
                    setVendorUserNameList([]);
                    setVendorCompanyDataList([]);
                    setVendorList([]);
                    getFilterData();
                    setLoading(false);
                  }}
                >
                  Clear filters
                </USBButton>
              </div>
            ) : (
              null
            )}
          </div>

          {vendorListImmutable.length > 0 ? (
           <div className="page-content-vendor-access ">
          <div>
      <input
      type="search"
      className='input-search'
      id='seachData'
      placeholder="Search by company name, legal entities, deals, role or document types."
      value={keyword}
      onChange={(e) => handleSearch(e.target.value)}
    />
    <br /><br />
    {vendorList.length > 0 ? (vendorList.length + " records found.") : "No Records Found"}
    <br />

  </div>

          {vendorList.length > 0 ? (
              <UsbTableData
                data={vendorList}
                columns={columns}
              />             
              ) : (
                null
              )}
            </div>
          ) : (
            null
          )}
        </USBColumn>
      </USBGrid>
    </>
  );
};

export default VendorUser;
