import { USBButton, USBCheckbox } from '@usb-shield/components-react/dist/cjs';
import React, { useState, useEffect } from 'react';
import '../../App.css';
import axios from "axios";
import { getDocumentmgmtServiceURL } from '../../config/connections';
import rateLimit from 'axios-rate-limit';
import { endpoints } from '../../../config/connections';
import Messages from '../../components/organisms/Messages';

const AssignPermissionsCDC = (props) => {

    const [initialProfileData, setInitialProfileData] = useState({ profileID: "", userID: "" });
    // const [data, setData] = useState({
    //     inbox: false,
    //     portfolio: true
    // });

    const [inbox, setInbox] = useState(false);
    const [portfolio, setPortfolio] = useState(true);

    const [successFlag, setSuccessFlag] = useState(false);
    const [failedFlag, setFailedFlag] = useState(false);
    const [messageContent, setMessageContent] = useState('');


    useEffect(() => {
        if (props?.edit) {
            setInitialProfileData(props?.initialProfileData);
            getExistingPermission(props?.initialProfileData?.userID)

        } else {
            setInitialProfileData(props?.location?.userData?.data?.data?.user);
        }
    }, [])


    const closeMessage = (setFailedFlag, setSuccessFlag) => {
        setFailedFlag(false);
        setSuccessFlag(false);
      };


    const getExistingPermission = (userId) => {
        let getCDCPermissionURL = '';
        getCDCPermissionURL = `${endpoints.apigee}/document-management/coreservice/user-management/v1/users/userId`;
        const https = rateLimit(
            axios.create({
                headers: {
                    Accept: 'application/json',
                    'Correlation-ID': `${sessionStorage.getItem(
                        'loggedUser'
                    )} ${sessionStorage.getItem('correlationId')}`,
                    'Client-Application': 'CDC',
                    'user-id': userId,
                    'jwt': `${sessionStorage.getItem('tok')}`,
                    Authorization: `Bearer ${sessionStorage.getItem('at')}`,
                },
            }),
            { maxRequests: 7, perMilliseconds: 1000, maxRPS: 7 }
        );

        axios
            .all([https.get(getCDCPermissionURL)])
            .then(
                axios.spread((...response) => {
                    if (response[0]?.data?.cdcPermissions?.includes("inbox")) {
                        setInbox(true);
                    } else {
                        setInbox(false);
                    }

                    if (response[0]?.data?.cdcPermissions?.includes("portfolio")) {
                        setPortfolio(true);
                    } else {
                        setPortfolio(false);
                    }
                })
            )
            .catch((e) => {
                console.log('DC651:', e);
            });
    }

    const handleCancel = () => {
        window.location.href = "/ManageUser";
    }

    const handleSubmit = (e) => {
        let cdcPermissions = [];
        if (inbox) {
            cdcPermissions.push("inbox");
        }
        if (portfolio) {
            cdcPermissions.push("portfolio");
        }
if(cdcPermissions.length===0){
    setFailedFlag(true);
    setSuccessFlag(false);
    setMessageContent("Please give atleast one access to the user.");
}
else{
        let entitlementsUpdate = {
            url: '',
            body: null
        };
        entitlementsUpdate.url = getDocumentmgmtServiceURL({
            service: 'updateProfilePermission',
            userid: `${initialProfileData.userID}`
        });
        entitlementsUpdate.body = {
            "profileId": initialProfileData.profileID,
            "source": "CDC",
            "userId": initialProfileData.userID,
            "cdcPermissions": cdcPermissions
        }

        axios.put(entitlementsUpdate.url, entitlementsUpdate.body, { cdcType: "docmanagement" })
            .then((res) => {
                if (props?.edit) {
                    setFailedFlag(false);
                    setSuccessFlag(true);
                    setMessageContent("App Access updated successfully");
                    setTimeout(() => {
                        window.location.href = "/ManageUser";
                      }, 1000);
                }
                else {
                    props.history.push("/ManageUser/confirm-user");
                }

            }).catch((error) => {
                console.log("There is an error in API call." + error);
            });
        }

    }

    const handleChange = (e) => {
        const selectedValue = e.target.value;
        if (selectedValue.toLowerCase() === "selectall") {
            // setData({ ...data, inbox: true, portfolio: true })
            // setData(prevState => ({
            //     ...prevState,
            //     inbox: true,
            //     portfolio: true
            // }))
            if (e.target.checked) {
                setInbox(true)
                setPortfolio(true)
            }
            else {
                setInbox(false)
                setPortfolio(false)
            }
        }
        else if (selectedValue.toLowerCase() === "inbox") {
            // setData({ ...data, inbox: !data.inbox })
            setInbox(!inbox)
            // setData(prevState => ({
            //     ...prevState,
            //     inbox: !data.inbox
            // }))
        }
        else if (selectedValue.toLowerCase() === "portfolio") {
            setPortfolio(!portfolio)
            // setData({ ...data, portfolio: !data.portfolio })
            // setData(prevState => ({
            //     ...prevState,
            //     portfolio: !data.portfolio
            // }))
        }

    };

    return (
        <>

{successFlag || failedFlag ? (
            <div
              className="message-content"
              data-testid="messageContent"
              role="alert"
            >
              <Messages
                success={successFlag}
                closeMessage={closeMessage}
                singleUploadFail={failedFlag}
                messageContent={messageContent}
                setFailedFlag={setFailedFlag}
                setSuccessFlag={setSuccessFlag}
              />
            </div>
          ) : (
            ''
          )}



            <h2 className="sp-mr-b-12">Select App Access to assign functionalities.</h2>



            <USBCheckbox checkboxID="all"
                handleChange={e => handleChange(e)}
                labelText="Select all"
                addClasses="sp-mr-b-8"
                initIsChecked={inbox && portfolio} />

            <USBCheckbox checkboxID="inbox"
                handleChange={e => handleChange(e)}
                labelText="Inbox"
                addClasses="sp-mr-b-8"
                initIsChecked={inbox} />

            <USBCheckbox checkboxID="portfolio"
                handleChange={e => handleChange(e)}
                labelText="Portfolio"
                addClasses="sp-mr-b-8"
                initIsChecked={portfolio} />


            <div className="permission-button-wrapper">
                <USBButton id={'assignPerCancelBtn'} addClasses={'sp-button-spacing'} variant='secondary' handleClick={handleCancel}>Cancel</USBButton>
                <USBButton id={'assignPerContinueBtn'} addClasses={'sp-button-spacing'} variant='primary' handleClick={e => handleSubmit(e)}>Save changes</USBButton>
            </div>


        </>
    );
};

export default AssignPermissionsCDC;
