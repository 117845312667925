import { getSvcServiceURL } from '../../config/connections';
import Axios from 'axios';
import GraphQlQuery from '../../utilities/graphQL-query';

export default function verifyEmailAlreadyExist(email: string) {
    return function (dispatch, getState) {

        const qraphUrl = getSvcServiceURL({ service: 'graph' });
        const search = { topLeftSearchBy: 'Email address', topLeftSearchBoxValue: email, parentCompanyFilterValue: '',statusFilterValue:'' };
        const meta = {
            sortKey: "lastName",
            sortValue: "asc",
            offset: 0,
            limit: 1,
        }

        const qraphQuery = GraphQlQuery.cdcEmployeeQuery(null, meta, search);
        return Axios.post(qraphUrl, qraphQuery, { cdcType: "servicing" }).then((res) => {           
            if (res.data.data.getProfileList ?.profiles ?.length > 0 && res.data.data.getProfileList ?.profiles[0].usbRelationship !== 'Unlisted_Vendor_Contributor__Contact') {
                return Promise.reject({
                    emailExist: true,
                    error: ''
                });

            } else {
                return Promise.resolve(true)
            }
        }, (error) => {
            return Promise.reject({
                emailExist: false,
                message: error
            })
        });
    };
}