// @flow
import React from 'react';
import {
    USBModal, USBModalCloseIcon, USBModalHeader, USBModalBody, USBModalFooter, USBButtonGroup, USBButton, USBSelect
} from '@usb-shield/components-react/dist/cjs';


type MoveDialogProps = {
    open: boolean,
    title: String,
    toggle: () => void,
    profile: Object,
    pcList : Array,
    primaryButtonText: String,
    primaryClickHandler: () => void
}
const MoveDialog = (props) => {

    return (
        <USBModal
            isOpen={props.open}
            backdrop={'static'}
        >
            <USBModalCloseIcon
                handleModalClose={props.toggle}
                ariaLabel='close modal'
            />
            <USBModalHeader
                modalTitle={props.title}
            >

            </USBModalHeader>

            <USBModalBody>
                <div>
                    <div style={{fontWeight:'bolder'}}>User Details</div>
                    <div>
                        <span style={{fontWeight:'bolder'}}>Full Name :</span>
                        <span>{` ${props.profile?.user?.firstName}  ${props.profile?.user?.lastName}`}</span> 
                    </div>
                    <div>
                        <span style={{fontWeight:'bolder'}}>Email :</span>
                        <span>{` ${props.profile?.user?.email}`}</span> 
                    </div>
                    <div>
                        <span style={{fontWeight:'bolder'}}>Role :</span>
                        <span>{` ${props.profile?.user?.profileRole}`}</span> 
                    </div>
                    <br></br>
                    <div style={{fontWeight:'bolder'}}>Company Details</div>
                    <div>
                        <span style={{fontWeight:'bolder'}}>Moving From :</span>
                        <span>{` ${props.profile?.user?.parentCompanyName}`}</span> 
                    </div>
                    <div>
                        <span style={{fontWeight:'bolder'}}>Profile Type :</span>
                        <span>{` ${props.profile?.company?.businessProfileType}`}</span> 
                    </div>
                </div>
                <div style={{fontWeight:'bolder'}}>Moving To :</div>
                <div>
                    <USBSelect
                        id={'moveUserPCSelect'}
                        handleChange={props.getPermissionsWithEin}
                        optionsArray={props.pcList}
                    ></USBSelect>
                </div>
                <br/>
                <div>
                    {props.profile?.profileRole == 'Site Administrator' ? 'The user will have access to all current and future reporting requirements' : 'The user will have access to all current and future reporting requirements'}
                </div>
                {/* <div>{JSON.stringify(props?.newParentPermissions)}</div> */}
                
                
            </USBModalBody>
            <USBModalFooter>
                <USBButtonGroup>
                    <USBButton
                        variant='secondary'
                        // handleClick={props.cancelHandler}
                        handleClick = {props.toggle}
                        ariaLabel='close modal'
                    >
                        Cancel
                    </USBButton>
                    <USBButton
                        variant='primary'
                        handleClick={()=>{props.primaryClickHandler(props?.profile,props)}}
                        ariaLabel={props.primaryButtonText}
                    >
                        {props.primaryButtonText}
                    </USBButton>
                </USBButtonGroup>
            </USBModalFooter>
        </USBModal>
    )
}

export default MoveDialog;