// @flow
import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { USBButton, USBInput, USBGrid, USBColumn, USBIconSuccess, USBIconModalClose, USBNotification } from '@usb-shield/components-react/dist/cjs';
import { FormValidationHelpers } from '../../utilities/form-validation-helpers';
import { bool } from 'prop-types';


type LoginInfoProps = {
    loggedInUserId: string,
    formChanged: (modified: boolean) => void,
    onSubmitHandler: (formData: any, validationMessages: object) => void
}

type ILoginPasswordValidationsMessages = {
    globalError: boolean,
    globalSucccess: boolean,
    currentPassword: {
        required: boolean,
        isTouched: boolean
    },
    password: {
        rules: {
            characterlength: boolean,
            atLeastOneNumeric: boolean,
            atLeastOneAlphabate: boolean,
        },
        required: boolean,
        isTouched: boolean
    },
    confirmPassword: {
        matched: boolean,
        required: boolean,
        isTouched: boolean
    }
}

const LoginInfo = (props: LoginInfoProps) => {

    const [changePasswordForm, setChangePasswordForm] = useState({
        currentPassword: null,
        password: null,
        confirmPassword: null
    });
    const [userName, setUserName] = useState('');
    const [validationMessages, setValidationMessages] = useState<ILoginPasswordValidationsMessages>({
        globalError: false,
        globalSucccess: false,
        currentPassword: {
            required: false,
            isTouched: false
        },
        password: {
            rules: {
                characterlength: false,
                atLeastOneNumeric: false,
                atLeastOneAlphabate: false,
            },
            required: false,
            isTouched: false
        },
        confirmPassword: {
            matched: false,
            required: false,
            isTouched: false
        }
    })


    useEffect(() => {
        if (props.loggedInUserId) {
            const userNameList = props.loggedInUserId.split('@');
            setUserName(userNameList[0]);
        }
    }, [props.loggedInUserId])

    useEffect(() => {
        setValidationMessages({
            ...validationMessages,
            currentPassword: {
                required: changePasswordForm.currentPassword?.length <= 0 && validationMessages.currentPassword.isTouched,
                isTouched: !validationMessages.currentPassword.isTouched ? changePasswordForm.currentPassword?.length > 0 ? true : false : true
            },
            password: {
                ...validationMessages.password,
                required: changePasswordForm.password?.length <= 0 && validationMessages.password.isTouched,
                rules: {
                    characterlength: FormValidationHelpers.anyCharacterExceptSpace(changePasswordForm.password) && changePasswordForm.password?.length >= 8 && changePasswordForm.password?.length <= 24,
                    atLeastOneAlphabate: FormValidationHelpers.atLeastOneAlphabate(changePasswordForm.password),
                    atLeastOneNumeric: FormValidationHelpers.atLeastOneNumeric(changePasswordForm.password)
                },
                isTouched: !validationMessages.password.isTouched ? changePasswordForm.password?.length > 0 ? true : false : true

            },
            confirmPassword: {
                matched: (changePasswordForm.confirmPassword?.length > 0 && changePasswordForm.password?.length > 0) && changePasswordForm.confirmPassword === changePasswordForm.password,
                required: changePasswordForm.confirmPassword?.length <= 0 && validationMessages.confirmPassword.isTouched,
                isTouched: !validationMessages.confirmPassword.isTouched ? changePasswordForm.confirmPassword?.length > 0 ? true : false : true,
            }
        });
        Object.keys(changePasswordForm).forEach(form => {
            if(changePasswordForm[form]){
                props.formChanged(true);
                return;
            }
        })
    }, [changePasswordForm])

    useEffect(() => {
        const confirmPasswordElement = document.querySelector('#login-info-confirm-password');
        if (confirmPasswordElement) {
            confirmPasswordElement.addEventListener('paste', (e: any) => {
                e.preventDefault();
            });
        }
        props.formChanged(false);
    }, [])

    const onPasswordChangeHandler = (e) => {
        setChangePasswordForm({
            ...changePasswordForm,
            [e.target.name]: e.target.value
        })
    }

    const onPasswordChangeSubmit = () => {
        let passwordValidation = validationMessages.password;
        let confirmPasswordValidations = validationMessages.confirmPassword;
        let currentPasswordValidation = validationMessages.currentPassword;

        if (!changePasswordForm.currentPassword) {
            currentPasswordValidation = {
                ...currentPasswordValidation,
                isTouched: true,
                required: true
            }
        }
        if (!changePasswordForm.password) {
            passwordValidation = {
                ...validationMessages.password,
                isTouched: true,
                required: true
            };
        }
        if (!changePasswordForm.confirmPassword) {
            confirmPasswordValidations = {
                ...confirmPasswordValidations,
                isTouched: true,
                required: true
            }
        }
        setValidationMessages({
            ...validationMessages,
            currentPassword: currentPasswordValidation,
            password: passwordValidation,
            confirmPassword: confirmPasswordValidations
        })
        props.onSubmitHandler(changePasswordForm, validationMessages);
        // passwordChange();
    }

    return (
        <React.Fragment>
            <USBGrid>
                <USBColumn largeSpan={9}>
                    <h2><strong>Username</strong></h2>
                    <div className="sp-caption sp-mr-b-12"> {userName} </div>
                    <div className="sp-width-100">
                        <h2><strong>Change password</strong></h2>
                        <div className="sp-mr-b-12">
                            <USBInput
                                id={'currentPassword'}
                                labelText={'Current password'}
                                name="currentPassword"
                                value={changePasswordForm.currentPassword}
                                handleChange={onPasswordChangeHandler}
                                isShowHide={true}
                                showBtnLabel="Show"
                                hideBtnLabel="Hide"
                                pattern=".*"
                                errorText={"Enter your current password."}
                                isError={validationMessages.currentPassword.required}
                            >
                            </USBInput>
                        </div>
                        <div className="sp-mr-b-12">
                            <USBInput
                                id={'loginInfo'}
                                labelText={'New password'}
                                name="password"
                                value={changePasswordForm.password}
                                handleChange={onPasswordChangeHandler}
                                isShowHide={true}
                                showBtnLabel="Show"
                                hideBtnLabel="Hide"
                                pattern=".*"
                                // errorText={"This field is required."}
                                isError={validationMessages.password.required}
                            >
                            </USBInput>

                            {
                                validationMessages.password.required || changePasswordForm.password && changePasswordForm.password?.length > 0 ?
                                    <div className="sp-login-info-validation-helpers sp-caption sp-mr-t-4">
                                        <p>
                                            {
                                                validationMessages.password.rules.characterlength ?
                                                    <USBIconSuccess colorVariant="success" /> :
                                                    <USBIconModalClose colorVariant="error" />
                                            }
                                            <span className="sp-mr-l-4"> 8-24 characters. No spaces.</span>
                                        </p>
                                        <p className="sp-mr-t-4">
                                            {
                                                validationMessages.password.rules.atLeastOneNumeric ?
                                                    <USBIconSuccess colorVariant="success" /> :
                                                    <USBIconModalClose colorVariant="error" />
                                            }
                                            <span className="sp-mr-l-4">At least one number.</span></p>
                                        <p className="sp-mr-t-4">
                                            {
                                                validationMessages.password.rules.atLeastOneAlphabate ?
                                                    <USBIconSuccess colorVariant="success" /> :
                                                    <USBIconModalClose colorVariant="error" />
                                            }
                                            <span className="sp-mr-l-4">
                                                At least one letter.</span>
                                        </p>
                                    </div> : null
                            }

                        </div>
                        <div className="sp-mr-b-12">
                            <USBInput
                                id={'login-info-confirm-password'}
                                labelText={'Confirm new password'}
                                name="confirmPassword"
                                value={changePasswordForm.confirmPassword}
                                handleChange={onPasswordChangeHandler}
                                isShowHide={true}
                                showBtnLabel="Show"
                                hideBtnLabel="Hide"
                                pattern=".*"
                                errorText={"Make sure it matches what you already entered."}
                                isError={validationMessages.confirmPassword.required}
                            >
                            </USBInput>
                            {
                                changePasswordForm.confirmPassword && changePasswordForm.confirmPassword?.length > 0 ?

                                    <>
                                        <p className="sp-login-info-validation-helpers sp-caption sp-mr-t-4">
                                            {
                                                validationMessages.confirmPassword.matched ?
                                                    <> <USBIconSuccess colorVariant="success" /> Passwords match.</>
                                                    :
                                                    <><USBIconModalClose colorVariant="error" /> Make sure it matches what you already entered.</>
                                            }
                                        </p>
                                    </>
                                    : null}
                        </div>
                        <div className="sp-action-button-wrapper">
                            <USBButton id={'my-profile-login-submit'}
                                variant={"primary"} addClasses={"sp-button-spacing"}
                                type="button" handleClick={onPasswordChangeSubmit}> Save changes</USBButton>
                        </div>
                    </div>
                </USBColumn>
            </USBGrid>
        </React.Fragment>
    )
}

export default LoginInfo