import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { USBProgressIndicator, USBButton, USBNotification } from '@usb-shield/components-react/dist/cjs';
import componentConfig from '../../config/component-config.json';
import axios from 'axios';
import AutoCompleteDropdown  from '../../components/shared/AutoCompleteDropdown/AutoCompleteDropdown';
import { getDocumentmgmtServiceURL } from '../../config/connections';
import Helpers from '../../utilities/helper.js';
import { connect } from "react-redux";
import NavigateBack from '../../components/shared/navigateBack';
import LoadingHelper from '../../utilities/loading-helper';
import { transmitIframeMessage } from '../../components/utility/transmit-message';
import GraphQlQuery from '../../utilities/graphQL-query';
import { getSvcServiceURL } from '../../config/connections';


const ParentCompany = (props) => {

    const [parentCompanyList, setParentCompanyList] = useState([]);
    const dispatch = useDispatch();
    const [isParentCompanyEnable, setIsParentCompanyEnable] = useState(false);
    const [isParentCompanySelected, setIsParentCompanySelected] = useState(false);
    const [isShowErrorMessage, setIsShowErrorMessage] = useState(false);
    const [isShowApiErrorMessage, setIsShowApiErrorMessage] = useState(false);
    const selectedParentCompany = useSelector(state => state.parentCompanyInformation.parentCompany);

    useEffect(() => {
        LoadingHelper.inFlight();
        getParentCompanyList().then(res => {
            LoadingHelper.outFlight();
            dispatch({
                type: 'SET_PARENT_COMPANY_LIST',
                payload: res.data
            });
            const sortedList = Helpers.sortData([...res.data], 'parentCompanyName');
            setParentCompanyList(sortedList);
           
        }).catch((error) => {
            LoadingHelper.outFlight();
            setIsShowApiErrorMessage(true);
        });
    }, []);


    const getParentCompanyList = async () => {
        // const config = {
        //     cdcType: "docmanagement",
        //     headers:{
        //         'jwt':`${sessionStorage.getItem('tok')}`,
        //         'user-id':`${sessionStorage.getItem('assignedUser')}`
        //     }
        // }
        if (props.parentCompanyList && props.parentCompanyList.length > 0) {
            return Promise.resolve({ data: props.parentCompanyList });
        }
        const url = getDocumentmgmtServiceURL({ service: 'getAllParentCompanies' });
        return axios.get(url,{cdcType: 'docmanagement'});
    }

    const onCancelClick = (e) => {
        let path = '/ManageUser/';
        props.history.push(path);
    }


    const onContinue = async (e) => {
        let val = isParentCompanySelected ? false : true;
        setIsShowErrorMessage(val);

        if (isParentCompanySelected) {
            if (selectedParentCompany.businessProfileType.toLowerCase() === "customer") {
                props.history.push('/ManageUser/user-type');
            } else {
                props.history.push('/ManageUser/emp-reg');
            }
        }
    }



    const onParentCompanyClick = (e) => {
        console.log(e);
        setIsShowErrorMessage(false);
        setIsParentCompanySelected(true);
        setIsParentCompanyEnable(true);
        dispatch({
            type: "SET_PARENT_COMPANY_INFO",
            payload: e
        });

        let metaData = {
            sortKey: "lastName",
            sortValue: "asc",
            offset: 0,
            limit: 12,
        }
        
        let search = {
            searchBy: '',
            searchValue: '',
            topLeftSearchBy: '',
            topLeftSearchBoxValue: '',
            parentCompanyFilterValue: '',
            statusFilterValue: '',
            userTypeFilterValue: '',
            searchIntimated: null,
            isUnlistedVendorCallSuccess: false
        }
            
       let loginRole= props.currentUser.user.pid ;       
       if(loginRole ==='employee_signon'){     
            dispatch({
            type: 'SET_PARENT_COMPANY_SELECTION_HAS_NO_PROFILE',
            payload: false
            });
            search.parentCompanyFilterValue = e.ein;      
            const query = GraphQlQuery.cdcEmployeeQuery(e.ein, metaData, search);
            loadProfilesList(query);
       }        

    }

    const loadProfilesList = (query) => {
        const graphURL = getSvcServiceURL({ service: 'graph' });
        axios.post(graphURL, query, { cdcType: "servicing" }).then((res) => {
          console.log('response=======', res.data);
          const data = res.data.data;
          if(data===undefined || data===null) {
               
            }
        }).catch((error) => {   
            if(error.response.status==400){
                dispatch({
                    type: 'SET_PARENT_COMPANY_SELECTION_HAS_NO_PROFILE',
                    payload: true
                });
            }       
          console.log("There is an error for loadProfilesList in API call." + error);
        });
    }


    const onJumpLinkClick = () => {
        setTimeout(() => {
            let str = window.location.hash;
            handleFieldFocus(str);
        }, 300);

    }


    const handleFieldFocus = (type: string): void => {
        switch (type) {
            case "#parentCompanyUserTypeSearch":
                document.getElementById('parentCompanysearchButton').focus();
                break;
            default:
                break;
        }
    }

    const onDropdownHeightChangeTransmitMessage = (open) => {
        transmitIframeMessage.sendOverLayMessage(open);
    }

    const continueBtnProps = {
        [componentConfig.USBButton.variant]: "primary",
        [componentConfig.USBButton.addClasses]: "sp-button-spacing",
        [componentConfig.USBButton.handleClick]: onContinue,
        [componentConfig.USBButton.id]: "continue-btn"
    };

    const cancelBtnProps = {
        [componentConfig.USBButton.variant]: "secondary",
        [componentConfig.USBButton.addClasses]: "sp-button-spacing",
        [componentConfig.USBButton.handleClick]: onCancelClick,
        [componentConfig.USBButton.id]: "cancel-btn"
    };

    return (
        <>
            <div className="sp-progress-bar-container sp-mr-b-40">
                <div className="sp-progress-bar">
                    <USBProgressIndicator percentSuffixLabel='% Complete'
                        PERCENTAGE='percentage'
                        ariaProgressLabelFirst='Your Progress: Step'
                        ariaProgressLabelMid='of'
                        ariaProgressLabelLast='Completed'
                        currentStep={1}
                        variant='percentage'
                    >
                        <USBProgressIndicator.Step>
                            <span>Step1</span>
                        </USBProgressIndicator.Step>
                        <USBProgressIndicator.Step>
                            <span></span>
                        </USBProgressIndicator.Step>
                        <USBProgressIndicator.Step>
                            <span></span>
                        </USBProgressIndicator.Step>
                        <USBProgressIndicator.Step>
                            <span></span>
                        </USBProgressIndicator.Step>
                        <USBProgressIndicator.Step>
                            <span>Step2</span>
                        </USBProgressIndicator.Step>
                    </USBProgressIndicator>
                </div>
            </div>
            <div className="sp-container-fluid sp-progress-content-space" id="sp-user-type">
                <div className="sp-row">
                    <div className="sp-col-6 sp-offset-left-3">
                        <div className="sp-mr-b-32">
                            <NavigateBack url="/ManageUser/" />
                            <h1 className="sp-page-title sp-mr-b-16">Select a parent company.</h1>
                            {isShowErrorMessage ?  
                             <div onClick={onJumpLinkClick}>          
                            <USBNotification
                             addClasses="sp-mr-b-12"
                             variant="error" >
                           Select a parent company.
                           </USBNotification></div> : null}                           
                           {isShowApiErrorMessage ?  
                             <div>          
                            <USBNotification
                             addClasses="sp-mr-b-12"
                             variant="error" >
                             We’re having trouble accessing this information. Please refresh your browser and try again.
                           </USBNotification></div> : null}

                            <div>
                                <h2 className="sp-mr-b-12">Choose the company the customer or vendor works for.</h2>
                                {parentCompanyList.length > 0 ? 
                                    <AutoCompleteDropdown comboboxId = "combobox"
                                        autocompleteInputId = "autocomplete-input"
                                        listboxID = "listbox" 
                                        arrowId= "combobox-arrow"
                                        onItemSelect={(e) => onParentCompanyClick(e)} listItems = {parentCompanyList}/>: ""
                                }
                            </div>
                        </div>
                        <div className="sp-action-button-wrapper">
                            <USBButton {...cancelBtnProps}>Cancel</USBButton>
                            <USBButton {...continueBtnProps}>Continue</USBButton>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

const mapStateToProps = state => {
    return {
        vendorList: state.vendorList,
        parentCompanyList: state.parentCompanyInformation.parentCompanyList,
        parentCompanySelection: state.parentCompanyInformation.parentCompanySelection,
        parentCompany: state.parentCompanyInformation.parentCompany,
        currentUser: state.currentUser  
    };
};
export default connect(mapStateToProps)(ParentCompany);