import React, { Component, useContext, Suspense , useRef} from 'react';
import { USBGrid, USBColumn } from '@usb-shield/components-react';
import { v4 as uuidv4 } from 'uuid';
import axios from 'axios';
import rateLimit from 'axios-rate-limit';
import { endpoints } from '../../config/connections';
import { parseJwt } from '../components/utilities/Utils';
import AppLoader from '@usb-ui-tools/micro-app-loader/dist/cjs/index.js';
import {enableCDCServicing} from  '../config/connections';
import callApi from '../components/utilities/callApi';
import { withRouter } from "react-router-dom";
// import './App.css';


const getCorrelationID = () => {
  const correlationId = uuidv4();
  typeof sessionStorage !== 'undefined'
    ? sessionStorage.setItem('correlationId', correlationId)
    : '';
  return correlationId;
};

const configHeader = {
  'Tenant-ID': 'USB',
  'App-ID': 'CDC',
  'Channel-ID': 'Web',
  'App-Version': '1',
  AK: `${endpoints.configAK}`,
  'Correlation-ID': getCorrelationID(),
};


const configSettings = {
  transmitConfigs: {
    URL: endpoints.transmit,
    APP_ID: 'cdc_web',
    policyIds: {
      POLICY: 'customer_login',
      CUSTOMER_LOGIN: 'customer_login',
    },
    policyParams: {
      routingKey: endpoints.authRoutingKey,
      appId: 'CDC',
      tenantId: 'USB',
      channelId: 'web',
      appVersion: '1',
    },
  },
  constants: {
    USER_SUFFIX: '@cdc',
  },
  urls: {
    loginAssistance: {
      LOGIN_HELP: '/LoginAssistant',
      RETRIVE_ID: '/LoginAssistant/RetriveId',
      RESET_PASSWORD: '/LoginAssistant/ResetPassword',
    },
  },
  displayFields: {
    REMEMBER_ME: 'hide',
    ENROLLMENT_LINK: 'hide',
    ACCOUNT_TYPE_DROPDOWN: 'hide',
    BACK_BUTTON: 'show',
    CANCEL_BUTTON: 'show',
    COMPANY_ID: 'hide',
    LOGIN_HELP: 'show',
    FORGOT_ID: 'hide',
    FORGOT_PASSWORD: 'hide',
  },
};

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.myRef = React.createRef();
  }

  updateLastLogin = async (redirectUrl) => {
    let apiURLFirst = '';
    apiURLFirst = `${endpoints.apigee}/document-management/cdc/requirements/v1/update/login`;
    const allData = [];
    const https = rateLimit(
      axios.create({
        headers: {
          Accept: 'application/json',
          'Correlation-ID': `${sessionStorage.getItem(
            'loggedUser'
          )} ${sessionStorage.getItem('correlationId')}`,
          'Client-Application': 'CDC',
          'user-id':`${sessionStorage.getItem('loggedUser')}`,
          'jwt':`${sessionStorage.getItem('tok')}`,
          Authorization: `Bearer ${sessionStorage.getItem('at')}`,
        },
      }),
      { maxRequests: 7, perMilliseconds: 1000, maxRPS: 7 }
    );

    axios
      .all([https.get(apiURLFirst)])
      .then(
        axios.spread(async (...response) => {
          window.location.href = await this.getRedirectURL();
        })
      )
      .catch(async (e) => {
        console.log('DC651:', e);
        window.location.href = await this.getRedirectURL();
      });
  }

  onTransmitAuthorizationFailure = () => {};

  onTransmitAuthorizationSuccess = async (response) => {
    sessionStorage.removeItem('userData');
    localStorage.setItem('tok', response.token);
    sessionStorage.setItem('tok', response.token);
    sessionStorage.setItem('loggedUser', parseJwt(response.token).sub);
    sessionStorage.setItem('assignedUser', parseJwt(response.token).sub);
    sessionStorage.setItem('type', parseJwt(response.token).pid);
    sessionStorage.setItem('role', parseJwt(response.token).custom_data.role);
    sessionStorage.setItem('at', parseJwt(response.token).custom_data.at);
    sessionStorage.setItem(
      'currentSession',
      sessionStorage.getItem('currentSession')
    );

    this.isTnCAccepted();

  };

  getRedirectURL = async () => {

    const permissionURL = `${endpoints.apigee}/document-management/coreservice/user-management/v1/allowedPages`;
    let redirectURL=`${endpoints.redirect}/Redirect`;
    let response = await callApi.getApiDataLoggedUser(permissionURL);
    
    if(response.status===200){
      const permissionDetails = response.data;
      if(permissionDetails?.includes("inbox") && !permissionDetails?.includes("portfolio")){
        redirectURL= `${endpoints.redirect}/Inbox`;
        return redirectURL;
      }
      else
      {
      const docIdFromEmail = localStorage.getItem('redurl');
      const docIdFromEmailCB = localStorage.getItem('cbredurl');
      const isManageUser = localStorage.getItem('mu');
      if (docIdFromEmail) {
        redirectURL= `${endpoints.redirect}/RequirementDetails?id=${docIdFromEmail}`;
      } else  if (docIdFromEmailCB) {
        redirectURL= `${endpoints.redirect}/ClosingBinders/Portfolio/Requirements?id=${docIdFromEmailCB}`;
      } else  if (isManageUser) {
        redirectURL= `${endpoints.redirect}/ManageUser`;
      } else {
        redirectURL= `${endpoints.redirect}/Redirect`;
      }
      return redirectURL;
    }
    }

  };

  isTnCAccepted = async () => {
    sessionStorage.removeItem("portfolioFilters")
    const jwtDSID = sessionStorage.getItem('tok');
    const accessToken = sessionStorage.getItem('at');
    const userId = sessionStorage.getItem('loggedUser');
    const roleId = sessionStorage.getItem('role');
    const token = sessionStorage.getItem('tok');
    let lastLoginTime= null;
    
// Code start to check last login so termsofuse can be redirected.
const lastLoginURL = `${endpoints.apigee}/document-management/coreservice/user-management/v1/users/userId`;
 await callApi.getApiData(lastLoginURL)
    .then((userNameResponse) => {
      console.log('--userData printing');
      console.log(userNameResponse.data?.lastLoginTime);
      lastLoginTime=userNameResponse?.data?.lastLoginTime;
    })
    .catch((error) => {
      console.error(error)
    });

// Code end.
    axios({
      // url: endpoints.isTnCAccepted,
      // url: 'https://it-api.usbank.com/customer-management/graphql/v1',
      url: endpoints.servicingAPI,
      method: 'post',
      data: {
        // query: `{\n getProfileWithParentComType(userID:"${userId}") {\t\tprofileRole\n \tbusinessProfileType\n \tusbRelationship}\n}`, "variables": null, "operationName": null
        // query: `{\n IsTncAccpeted(input:{userID: "${userId}"}) {\t\tTnCAccpetedStatus}\n}`, "variables": null, "operationName": null
        query: `{\n IsTncAccpeted(input:{userID: "${userId}", sourceType:"CDC", productID: "cdc-termsandconditions"}) {\t\tTnCAccpetedStatus}\n}`,
        variables: null,
        operationName: null,
      },
      headers:enableCDCServicing == false ? {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
        routingKey: endpoints.routingKey,
        // "Correlation-ID": "44afbcf2-1cd5-4aa1-9b08-f5adeff6ea56"
        'Correlation-ID': `${jwtDSID.sid}`,
        'Client-Data': `{"Role-ID": "${roleId}", "User-ID": "${userId}", "App-ID": "CDC", "Tenant-ID": "USB", "App-Version": "1", "Channel-ID": "web"}`,
        Accept: 'application/json',
        'Client-ID': 'CDC',
        'Service-Version': '2',
        SessionToken: token,
        Aid: 'cdc_web',
      } : {
        "Content-Type": "application/json",
        "Correlation-ID": `${jwtDSID.sid}`,
        "Accept": "application/json",      
        "origin": window.location.href,
        "Access-Control-Allow-Origin": "*" 
      },
    }).then(async (result) => {
      if (result.status === 200) {
        console.log("result");
        console.log(JSON.parse(sessionStorage.getItem("userData")));
        console.log(
          '===tnc-status=====',
          result.data.data.IsTncAccpeted.TnCAccpetedStatus
        );

        console.log( result.data.data.IsTncAccpeted.TnCAccpetedStatus , lastLoginTime)
      
        console.log("Is termsofuse? " + result.data.data.IsTncAccpeted.TnCAccpetedStatus === 'NotAccepted' && lastLoginTime==null)
        
         
        if (
          result.data.data.IsTncAccpeted.TnCAccpetedStatus === 'NotAccepted' && lastLoginTime==null
        ) {
           window.location.href = '/Termsofuse';
        } else {

         await this.updateLastLogin(this.getRedirectURL())
        }
      } else {
        console.log(result)
      }
    }).catch(error => {
      console.log(error);
   });
  };
 



  render() {
    
    return (
      <>
        <USBGrid addClasses="content-frame">
          <USBColumn largeSpan={2} />
          <USBColumn largeSpan={12}>
            <div className="page-content-login">
              <div className="page-content">
                <div id="test-spinner" />
           
                <AppLoader
                            elementRef={this.myRef}
                            id="auth-login"
                            isDomainPrefixedToManifestPath = {false}
                            manifestPath={endpoints.manifestPath}
                            namespace="USBAuthLoginModule"
                            appProps={{
                                configApiHeaders:configHeader,
                                configSettings:configSettings,
                                onAuthorizationSuccess:this.onTransmitAuthorizationSuccess,
                                onAuthorizationFailure:this.onTransmitAuthorizationFailure,
                                // appNameForSiteCat:appNameForSiteCat,
                                // uxNameForSiteCat:uxNameForSiteCat,
                                // clientNameForSiteCat:clientNameForSiteCat,
                                isOLB:false,
                                isAppDEnabled:true,
                                isReportingEnabled:true,
                                isIovationEnabled:true,
                                isJSLoggerEnabled:true,
                                configApiURL:endpoints.configApiURL,
                                // appDKey:appDynamicsKey,
                                isNewLASUrl:true,
                                isShieldMFAEnabled:true,
                                isHeaderRequired: false,
                                isFooterRequired: false
                            }}    
                    />
             
              </div>
            </div>
          </USBColumn>
          <USBColumn largeSpan={2} />
        </USBGrid>
      </>
    );
  }
}

export default withRouter(Login);
