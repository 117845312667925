
import React, { useState, useEffect, useContext, createContext } from 'react';
import { FeatureFlagsContext } from '../../components/Hooks/useFeatureFlags';
import './banner.css';
import { USBIconDownload, USBIconPeople ,USBIconClose} from '@usb-shield/react-icons';

const BannerWarning = () => {
 const [closeIcon, setCloseIcon]= useState(true);
 const { featureDetails, featureFlagResponseBanner , loading} = useContext(FeatureFlagsContext);
 const TodayDate = new Date().toLocaleString().split(',')[0];
 const finalCurentDate = TodayDate.split('/').reverse().join("/");
 const updatedBannerDate = featureFlagResponseBanner?.updatedTime;
 useEffect(()=>{
 if(loading){
  if(sessionStorage.getItem('bannerVisibleFlag') === 'false' && updatedBannerDate < finalCurentDate){
    setCloseIcon(false)
  }else{
    setCloseIcon(true)
  }
 }
 },[featureFlagResponseBanner, loading])
const bannerClose= ()=>{  
  sessionStorage.setItem("bannerVisibleFlag","false")
  setCloseIcon(false)
}
return  (
  <>
  {featureFlagResponseBanner.feature?.includes("feature_banner_") && closeIcon && loading ? <div className='BannerText'>
  <button className="closeButtonForBanner"
          onClick={() => {
            bannerClose()
          }}>
  <USBIconClose></USBIconClose></button> 
  <h1 className='headingForBanner'>{featureFlagResponseBanner.description} </h1> 
  </div> :<></>
  }
  </>
)
}

export default BannerWarning;