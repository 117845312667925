import {parseJwt} from '../utilities/Utils';
import {useState, useEffect} from 'react';

export default function useUserRole() {
    const [userRole, setUserRole] = useState("");
   
    useEffect (() => {
     const savedToken = sessionStorage.getItem('tok');
          
         if(savedToken){
            const roleTemp = parseJwt(savedToken).custom_data.role.toUpperCase();         
                if (
                    roleTemp ===
                  'APP_8083_CDCWEB_SUPER_ADMINS'
                ) {
                    setUserRole('employee');
                } else if (
                    roleTemp === 'CDC_DOC_PORTAL_ADMIN'
                ) {
                    setUserRole('portaladmin');
                } else if (
                    roleTemp ===
                  'CDC_DOC_PORTAL_CONTRIBUTOR'
                ) {
                    setUserRole('customer');
                } else {
                    setUserRole('customer');
                }
               }
        
 })
//  make this line uncommented to change external uer as superadmin
 //return 'employee';
 return userRole; 
}
