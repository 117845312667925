import { 
  FileItems,
FolderInfo,
HeaderInfo,
DocumentInfo,
AsyncDocument,
AsyncOperation,
ASYNC_STATUS,
ASYNC_OPERATION_TYPE,
BaseController,
ASYNC_OPERATION_EVENTS,
INQUIRY_MANAGER_EVENTS,
MODIFICATION_EVENTS,
UPLOAD_QUEUE_EVENTS,
CONTEXT_HANDLER_EVENTS,
// WORKFLOW_HANDLER_EVENTS,
} from '@usb-edm/react-document-upload';
import {USBCheckbox} from '@usb-shield/components-react/dist/cjs';
import '@usb-shield/react-tooltip/dist/library/styles/index.css';
import {gridHeaders} from './GridHeadersClosingBinders';
import { getQS, saveAsFile , previewFile} from '../../components/utilities/Utils';
import callApi from "../../components/utilities/callApi";
import { endpoints } from '../../../config/connections';

// const fileItemsList = fileItems;
class PlatformController extends BaseController {
  constructor() {
    super();
  }


  initialize(
  reportingDetailsData,
  setReportingDetailsData,
  setSuccessFlag,
  setFailedFlag,
  setMessageContent,
  setLoading,
  setUploadedFlag,
  uploadMultipleFiles,
  triggerUploadQueueEvent,
){

    this.reportingDetailsData = reportingDetailsData
    this.setReportingDetailsData = setReportingDetailsData;
    this.setSuccessFlag = setSuccessFlag;
    this.setFailedFlag = setFailedFlag;
    this.setMessageContent = setMessageContent;
    this.setLoading = setLoading;
    this.setUploadedFlag = setUploadedFlag;
    this.uploadMultipleFiles = uploadMultipleFiles;
    this.triggerUploadQueueEvent = triggerUploadQueueEvent;
  }

  fileItemsAPI;

  
  async handleSearchAction(searchOptions,setSearchCriteria){

     // Searching ---------------------------------------
    // condition to search uploaded date and time
    // (item.metadata.uploaded.props.children[2].props.children[0].props.children[0] + '').toLocaleLowerCase().includes((searchOptions.searchCriteria.value).toLocaleLowerCase()) ||
      // (item.metadata.uploaded.props.children[2].props.children[1].props.children + '').toLocaleLowerCase().includes((searchOptions.searchCriteria.value).toLocaleLowerCase()) ||
    if(searchOptions.sourceTrigger=="globalSearch" || sessionStorage.getItem('searchData') || searchOptions.sourceTrigger=="SORT"){
      let searchData = searchOptions.searchCriteria?.value || sessionStorage.getItem('searchData');           
      sessionStorage.setItem('searchData',searchData)
      let filteredData = await this.globalSearch(searchData)
      if(filteredData){
        let fileItems = new FileItems(gridHeaders, filteredData)
        if(fileItems){
          this.triggerInquiryManagerEvent({
            type: INQUIRY_MANAGER_EVENTS.SEARCH_RESULTS,
            fileItems:  fileItems,
            fileCount:  filteredData?.length,
            scope: searchOptions.scope,
          })
        }
    }
    }
    else{
      this.triggerInquiryManagerEvent({
        type: INQUIRY_MANAGER_EVENTS.SEARCH_RESULTS,
        fileItems: this.fileItemsAPI,
        fileCount: this.fileItemsAPI.documents.length,
        scope: searchOptions.scope,
      })
    }
}

  async globalSearch(searchData){
    let list = await this.fileItemsAPI?.documents;
    if(list){
      return list.filter((item) =>
      (item.extension + '').toLocaleLowerCase().includes((searchData).toLocaleLowerCase()) ||
      (item.metadata.fileName + '').toLocaleLowerCase().includes((searchData).toLocaleLowerCase()) ||
      (item.metadata.docTitle.props.children + '').toLocaleLowerCase().includes((searchData).toLocaleLowerCase()) ||
      (item.metadata.uploaded.props.children[0].props.children + '').toLocaleLowerCase().includes((searchData).toLocaleLowerCase()) ||
      (item.metadata.uploaded.props.children[1].props.children + '').toLocaleLowerCase().includes((searchData).toLocaleLowerCase()) ||
      (item.metadata.uploadStatus.props.children[1].props.children + '').toLocaleLowerCase().includes((searchData).toLocaleLowerCase())
      )
    }
  }

  handleInitializeFileItemsAction(options, fileItems){
    // console.log(options,fileItems)
    //  options - {scope : "PRIMARY"}
    this.fileItemsAPI = fileItems
    this.triggerInquiryManagerEvent({
      type: INQUIRY_MANAGER_EVENTS.SEARCH_RESULTS,
      fileItems: fileItems,
      fileCount: fileItems.documents.length,
      scope: options.scope,
    });
  
  }
  handleDownloadAction(fileItems) {
    let docsArray = [];
    let totalfailed = 0;
    for(let items of fileItems.documents){
if(items.metadata.uploadStatus.props.children[1].props.children == 'Uploaded' || items.metadata.uploadStatus.props.children[1].props.children == 'Finalized' || items.metadata.uploadStatus.props.children[1].props.children == 'Finalizing')
{
      docsArray.push(items.id)
}
else 
{
  totalfailed++;
}     
    }
       this.triggerInquiryManagerEvent({
      type: INQUIRY_MANAGER_EVENTS.DOWNLOAD_STARTING,
    });
  
    // ...
    const apiURL = `${endpoints.api}/document-management/closing-binders/v1/closingbinders/download`;
    callApi
      .getApiDataCBdownload(apiURL, docsArray)
      .then((result) => {
                
        let count =0
        const downloadInterval = setInterval(() => {
          try{
            saveAsFile(
              JSON.parse(result.data[count]).binary,
              JSON.parse(result.data[count]).fileName
            )
            count++ 
            this.handleContextAction({  type: CONTEXT_HANDLER_EVENTS.DOCUMENT_CONTEXT,  setSelectedFileItems: () => new FileItems(),  setDocumentNotifications: () => [],})
          }catch(error){
          }
        }, 200);
      
           })
      .catch((error) => {
        // console.log('error', error)
        this.setLoading(false)
      });
    // Make a REST call to retrieve the documents
    // ...
    if(totalfailed > 0){
      this.triggerInquiryManagerEvent({
        type: INQUIRY_MANAGER_EVENTS.DOWNLOAD_ERROR,
        fileItems: fileItems,
        });
      }
    this.triggerInquiryManagerEvent({
      type: INQUIRY_MANAGER_EVENTS.DOWNLOAD_RESULTS,
    });
  
    // Currently, handling the file needs to be done here by creating a hidden link and firing a click event.
  }

  

  async handleStartUploadAction(fileList) {
    this.setSuccessFlag(false);
    this.setFailedFlag(false);

    const response = await this.uploadMultipleFiles(
      fileList,
      this.setReportingDetailsData,
      this.setFailedFlag,
      this.setMessageContent,
      this.setLoading,
      this.setSuccessFlag,
      this.setUploadedFlag
    );

    if (response) {
      this.triggerUploadQueueEvent({
        type: UPLOAD_QUEUE_EVENTS.SUCCESS,
        files: fileList,
      });
    }
  }
}

export default PlatformController;
