export default class Helpers {
  static decodeJWTToken = token => {
    const base64Url = token.split(".")[1];
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    const jsonPayload = decodeURIComponent(
      atob(base64).split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );

    return JSON.parse(jsonPayload);
  };

  static sortData = (data, field) => {
    return data.sort((a, b) => {
      if (a[field].toLowerCase() < b[field].toLowerCase()) {
        return -1;
      }
      if (a[field].toLowerCase() > b[field].toLowerCase()) {
        return 1;
      }
      return 0;
    })
  }
}