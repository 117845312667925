// @flow
type FormValidationHelpersProps = {
    validateEmail: (value: string) => boolean,
    validateMobileNumber: (value: string) => boolean,
    validateName: (type: FormNameFields, value: string) => boolean,
    validateCityName: (value: string) => boolean,
    validateStateName: (value: string) => boolean,
    validateAptUnitStreet: (value: string) => boolean,
    validateZipcode: (value: string) => boolean,
    atLeastOneAlphabate: (value: string) => boolean,
    anyCharacterExceptSpace: (value: string) => boolean,
    atLeastOneNumeric: (value: string) => boolean
}

type FormNameFields = 'firstName' | 'middleName' | 'lastName';

export const FormValidationHelpers: FormValidationHelpersProps = {
    validateEmail: (value: string): boolean => {
        if (/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,6})$/i.test(value)) {
            return (false)
        }
        return (true)
    },
    validateMobileNumber: (value: string): boolean => {
        if (/^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/.test(value) || value === "") {
            return (false)
        }
        return (true)
    },
    validateName: (type: FormNameFields, value: string): boolean => {
        if (type === "firstName" && /^([a-zA-Z-'-\s]{2,50})$/.test(value)) {
            return (false)
        } else if (type === "middleName" && /^([a-zA-Z-'-]{0,50})$/.test(value)) {
            return (false)
        } else if (type === "lastName" && /^([a-zA-Z-'-\s]{2,50})$/.test(value)) {
            return (false)
        }
        return (true)
    },
    validateCityName: (value: string): boolean => {
        if (/^([a-zA-Z.\s]{0,200})$/.test(value) || value === "") {
            return (false)
        }
        return (true)
    },
    validateStateName: (value: string): boolean => {
        if (/^([a-zA-Z]{0,50})$/.test(value)|| value === "") {
            return (false)
        }
        return (true)
    },
    validateAptUnitStreet: (value: string): boolean => {
        if ((/^([A-Za-z0-9]{0,200})+/.test(value) || value === "") && (value.length <= 200)) {
            return (false)
        }
        return (true)
    },
    validateZipcode: (zip: string): boolean => {
        //if (/(^\d{5}$)|(^\d{5}-\d{4}$)/.test(zip) || zip === "") {
        if ((/^([A-Za-z0-9]{0,200})+/.test(zip) || zip === "") && (zip.length <= 200)) {
            return (false)
        }
        return (true)
    },
    anyCharacterExceptSpace: (value: string): boolean => {
        if (/(^\S*$)/.test(value)) {
            return true;
        }
        return false;
    },
    atLeastOneNumeric: (value: string): boolean => {
        if (/\d{1}/.test(value)) {
            return true;
        }
        return false;
    },
    atLeastOneAlphabate : (value: string): boolean => {
        if (/[a-z]/i.test(value)) {
            return true;
        }
        return false;
    },
    profileFormDataChanged :(userProfileDetails: Object): boolean => {
        try {
            if(userProfileDetails.firstName!==document.getElementById('usrfirstname').getElementsByTagName('input')[0].value){
                return true;
            }

            if(userProfileDetails.middleName!==document.getElementById('usrmiddlename').getElementsByTagName('input')[0].value){
                return true;
            }

            if(userProfileDetails.lastName!==document.getElementById('usrlastname').getElementsByTagName('input')[0].value){
                return true;
            }

            if(userProfileDetails.phoneNumbers.mobilePhone!==document.getElementById('usrmobilephone').getElementsByTagName('input')[0].value){
                return true;
            }

            if(userProfileDetails.phoneNumbers.businessPhone!==document.getElementById('usrbusinessphone').getElementsByTagName('input')[0].value){
                return true;
            }

            if(userProfileDetails.email!==document.getElementById('usremail').getElementsByTagName('input')[0].value){
                return true;
            }
        } catch (error) {
            
        }
        return false;
    }
}