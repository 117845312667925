import React, { useEffect, useState } from 'react';
import { USBGrid, USBColumn, USBButton } from '@usb-shield/components-react';
// import Router from 'next/router'
import { getQS } from '../components/utilities/Utils';
import './error.css';
import './index.css';
import { useHistory } from 'react-router-dom';

const login = () => {
  window.location.href = '/Employee';
};

const EmployeeRedirect = () => {
  const [pageId, setPageId] = useState('');

  useEffect(() => {
    setPageId(getQS('tp'));
  }, []);

  return (
    <>
      <USBGrid>
        <USBColumn largeSpan={2} />
        <USBColumn largeSpan={12}>
          <div className="image-section">
            <div
              className={pageId === 'sto' ? 'alert-image' : 'emp-logout-image'}
            />
          </div>
        </USBColumn>
        <USBColumn largeSpan={2} />
      </USBGrid>
      <USBGrid>
        <USBColumn largeSpan={2} />
        <USBColumn largeSpan={12}>
          <div className="main-content">
            <div className="head-content">
              {pageId === 'sto'
                ? `Your session timed out.`
                : `You've logged out.`}
            </div>
          </div>
          <div className="sub-content">
            <p>Please log in to the portal to continue what you were doing.</p>
          </div>
        </USBColumn>
        <USBColumn largeSpan={2} />
      </USBGrid>
      <USBGrid>
        <USBColumn largeSpan={2} />
        <USBColumn largeSpan={12}>
          <div className="button-section">
            <USBButton variant="primary" handleClick={login}>
              Log in
            </USBButton>
          </div>
        </USBColumn>
        <USBColumn largeSpan={2} />
      </USBGrid>
    </>
  );
};

export default EmployeeRedirect;
