// @flow
import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import { connect, useDispatch } from "react-redux";
import { FormValidationHelpers } from '../../utilities/form-validation-helpers';
import { USBButton, USBInput, USBSelect, USBNotification, USBIconInfo, USBTooltip, USBCheckbox } from '@usb-shield/components-react/dist/cjs';
import { UserRoleHelperText, UserRoleConfigKeyMapper } from '../../config/user-role-config';
import type { ContentValueModel } from '../../types/common.types';
import type { UserProfileFormModel } from './types';
import AutoCompleteDropdown from '../../components/shared/AutoCompleteDropdown/AutoCompleteDropdown';


type Props = {
    title?: string,
    formData: UserProfileFormModel,
    roleList?: ContentValueModel[],
    countryList?: ContentValueModel[],
    parentList?: [],
    isAdmin?: [],
    parentListFull?: [],
    primaryButtonText?: string,
    secondaryButtonText?: String,
    isUserTypeNotListedVendor: boolean,
    initialProfileStatus: String,
    readOnlyFields?: string[],
    additionalRequiredFields?: string[],
    type?: 'CREATE' | 'EDIT' | 'MYPROFILE',
    onSubmitHandler: (formData: UserProfileFormModel) => void,
    onCancelHandler: () => void,
    emailAlreadyExist: boolean,
    editableProfileRole: boolean,
    flags:any
};

export type FormDataErrorModel = {
    isFirstNameError: boolean,
    isMiddleNameError: boolean,
    isLastNameError: boolean,
    isEmailError: boolean,
    isMobileNoError: boolean,
    isBusinessPhoneError: boolean,
    isStreetError: boolean,
    isAptError: boolean,
    isCityError: boolean,
    isZipCodeError: boolean,
    isRoleError: Boolean
}

const UserInformationForm = forwardRef < Props, any> ((props: Props, ref: any) => {
    const [userDetails, setUserDetails] = useState < UserProfileFormModel > ({
        firstName: '',
        middleName: '',
        lastName: '',
        email: '',
        profileRole: '',
        phoneNumbers: {
            mobilePhone: '',
            businessPhone: ''
        },
        mnoBypass: false,
        address: {
            addressLine1: '',
            addressLine2: '',
            city: '',
            state: '',
            zipCode: '',
            country: ''
        },
        parentCompanyLegalEntityID: '',
        parentCompanyName: '',
        parentEIN: '',
    });
    const [isFormInvalidError, setIsFormInvalidError] = useState(false);
    const [formErrors, setFormErrors] = useState < FormDataErrorModel > ({});
    const [isMobileNoInvalideForMyProfilePage, setIsMobileNoInvalideForMyProfilePage] = useState(false);
    const [parentCompanyLegalEntityID, setParentCompanyLegalEntityID] = useState('')
    const [parentCompanyName, setParentCompanyName] = useState('')
    const [parentEIN, setParentEIN] = useState('')
    const defaultCountryPlaceholder = {
        content: 'Select country',
        value: ''
    }

    const [countryList, setCountryList] = useState < ContentValueModel[] > ([]);
    const [roleList, setRoleList] = useState < ContentValueModel[] > ([]);
    const [formChanged, setFormChanged] = useState(false);
    const dispatch = useDispatch();
    const [parentList, setParentList] = useState([])
    const [parentListFull, setParentListFull] = useState([])
    const onContinueClick = (): void => {
        props.onSubmitHandler(userDetails);
    }

    useEffect(() => {
        //  console.log("props", props)
        if (props.formData && Object.keys(props.formData).length > 0) {
            setUserDetails(props.formData);
        }
    }, [props.formData]);

    useEffect(() => {
        setParentList(props.parentList ? [props.parentList] : [defaultCountryPlaceholder])
    }, [props.parentList])

    useEffect(() => {
        setParentListFull(props.parentListFull ? [props.parentListFull] : [defaultCountryPlaceholder])
    }, [props.parentListFull])

    useEffect(() => {
        console.log(props.countryList);
        let countryListTemp=props.countryList ? [defaultCountryPlaceholder, ...props.countryList] : [defaultCountryPlaceholder];
        countryListTemp=countryListTemp.sort((a,b)=>a.content.localeCompare(b.content,undefined, { sensitivity: "base" }));
        setCountryList(countryListTemp);
        console.log(countryListTemp);
    }, [props.countryList])

    useEffect(() => {
        setRoleList(props.roleList ? props.roleList : []);
    }, [props.roleList])

    useEffect(() => {
        verifyFormChanged();
    }, [userDetails])

    useEffect(() => {
        setTimeout(() => {
            if (props.isUserTypeNotListedVendor)
                document.getElementById('usrroleunlisteduser').getElementsByTagName('input')[0].readOnly = true;
        }, 1000);
    }, [])



    useImperativeHandle(ref, () => ({
        getUserPofileDetails() {
            return userDetails;
        },
        clearFormChange() {
            setFormChanged(false);
        },
        formChanged
    }))

    const verifyFormChanged = () => {
        if (JSON.stringify(props.formData) === JSON.stringify(userDetails)) {
            setFormChanged(false);
            dispatch({
                type: "PROFILE_FORM_DATA_CHANGED",
                payload: false
            });
        } else {
            setFormChanged(true);
            dispatch({
                type: "PROFILE_FORM_DATA_CHANGED",
                payload: true
            });
        }
    }

    const handleFormValidationErrors = (name: string, value: string): void => {
        const errors: FormDataErrorModel = {};
        switch (name) {
            case "email":
                errors.isEmailError = FormValidationHelpers.validateEmail(value)
                break;
            case "firstName":
                errors.isFirstNameError = FormValidationHelpers.validateName('firstName', value)
                break;
            case "middleName":
                errors.isMiddleNameError = FormValidationHelpers.validateName('middleName', value)
                break;
            case "lastName":
                errors.isLastNameError = FormValidationHelpers.validateName('lastName', value)
                break;
            case "mobilePhone":
                errors.isMobileNoError = value ? FormValidationHelpers.validateMobileNumber(value) : false;
                break;
            case "businessPhone":
                errors.isBusinessPhoneError = value ? FormValidationHelpers.validateMobileNumber(value) : false;
                break;
            case "addressLine1":
                errors.isStreetError = FormValidationHelpers.validateAptUnitStreet(value)
                break;
            case "addressLine2":
                errors.isAptError = FormValidationHelpers.validateAptUnitStreet(value);
                break;
            case "city":
                errors.isCityError = FormValidationHelpers.validateCityName(value);
                break;
            case "state":
                errors.isStateError = FormValidationHelpers.validateStateName(value);
                break;
            case "zipCode":
                errors.isZipCodeError = FormValidationHelpers.validateZipcode(value)
                break;
            case "role":
                errors.isRoleError = value === '' ? true : false;
                break;
            case "companyName":
                errors.isParentCompanyNameError = value === '' ? true : false;
                break;
            default:
                break;
        }
        setFormErrors({
            ...formErrors,
            ...errors
        });
    }

    const isFormValid = (): boolean => {
        let requiredFields = null;
        if (props.isUserTypeNotListedVendor) {
            requiredFields = ['firstName', 'lastName', 'email', 'profileRole', 'parentCompanyName', 'phoneNumbers'].every(field => userDetails[field] !== null && userDetails[field] !== undefined && userDetails[field] !== '' && userDetails[field].mobilePhone !== '');
        } else {
            requiredFields = ['firstName', 'lastName', 'email', 'profileRole', 'phoneNumbers'].every(field => userDetails[field] !== null && userDetails[field] !== undefined && userDetails[field] !== ''&& userDetails[field].mobilePhone !== '');
        }
        if (requiredFields) {
            const touchedFields = Object.keys(formErrors).every(name => !formErrors[name]);
            return touchedFields && requiredFields;
        }
        return requiredFields;
    }

    const onChangeText = (e) => {
        const value = e.target.value.trim();
        handleFormValidationErrors(e.target.name, value);
        let shouldSave = true;
        try {
            if (props.type === 'EDITPROFILE' || props.type === 'MYPROFILE') {
                shouldSave = false
            }
        } catch (error) {
            //do nothing
        }
        if (shouldSave) {
            setUserDetails({
                ...userDetails,
                [e.target.name]: value
            });
        }
        props.onChangeTextHandler && props.onChangeTextHandler(userDetails);
    }

    const handleRoleSelectChange = (e: string): void => {
        //  props.formData.profileRole = ""
        handleFormValidationErrors('role', e);
        setUserDetails({
            ...userDetails,
            profileRole: e
        })
    }

    const handlePhoneNumberChange = (e): void => {
        let value = e.target.value.trim();
        /*value = value.replace(/\D/g,'');
        if(value.length>=10){
        document.getElementById('Mobilephonenumber').blur();
        document.getElementById('Businessphonenumber').blur();
        }*/
        value = value.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3");
        handleFormValidationErrors(e.target.name, value);
        let shouldSave = true;
        try {
            if (props.type === 'EDITPROFILE' || props.type === 'MYPROFILE') {
                shouldSave = false
                if (e.target.name === 'businessPhone') {
                    document.getElementById('usrbusinessphone').getElementsByTagName('input')[0].value = value;
                }
                if (e.target.name === 'mobilePhone') {
                    document.getElementById('usrmobilephone').getElementsByTagName('input')[0].value = value;
                }
                //

            }
        } catch (error) {
            //do nothing
        }
        if (shouldSave) {
            setUserDetails({
                ...userDetails,
                phoneNumbers: {
                    ...userDetails.phoneNumbers,
                    [e.target.name]: value
                }
            });
        }
        props.handlePhoneNumberChangeHandler(userDetails);
    }
    const handleMNOBypass = (e) => {
        //alert(e.target.checked)
        setUserDetails({
            ...userDetails,
            mnoBypass: e.target.checked
        });
    }

    const handleAddressChange = (e) => {
        const value = e.target.value.trim();
        handleFormValidationErrors(e.target.name, value);
        setUserDetails({
            ...userDetails,
            address: {
                ...userDetails.address,
                [e.target.name]: value
            }
        });
        // console.log("address body", userDetails)
    }

    const findCompanyDetails = (ein) => {
        const fullData = props.parentListFull;
        return fullData.find((element) => element.ein == ein)
    }

    const setParentDropdownInitialValue = (parentCompanyName) => {
        // console.log("init", parentCompanyName)
        const fullData = props.parentListFull;
        const initialCompanyDetails = fullData.find((element) => element.parentCompanyName == parentCompanyName);
        // console.log("init", initialCompanyDetails)
        return initialCompanyDetails.ein
    }


    const handleParentCompanyName = (e) => {
        
        if (props.isUserTypeNotListedVendor) {
            // handleFormValidationErrors(e.target.name, details.parentCompanyName); 
            setUserDetails({
                ...userDetails,
                parentCompanyName: e?.target?.value
            });
        } else {
            // console.log(this.props,"props")
            const ein = e?.target?.value;
            const details = findCompanyDetails(ein)
            // console.log("details", details)
            setUserDetails({
                ...userDetails,
                parentCompanyName: details.parentCompanyName,
                parentCompanyLegalEntityID: details.parentCompanyLegalEntityID,
                parentEIN: details.ein
            }); 
        }
    }

    const onCancelClick = () => {
        props.onCancelHandler();
    }
    var isRoleBlank = false;
    const onFormSubmitClick = (e) => {
        setIsMobileNoInvalideForMyProfilePage(false);
        let isFormInvalid = false;
        if (!isFormValid()) {
            formErrors.isFirstNameError = (userDetails.firstName === '' || userDetails.firstName.length < 2 || userDetails.firstName.length > 50) ? true : false;
            formErrors.isLastNameError = (userDetails.lastName === '' || userDetails.lastName.length < 2 || userDetails.lastName.length > 50) ? true : false;
            formErrors.isEmailError = (userDetails.email === '') || formErrors.isEmailError ? true : false;
            formErrors.isRoleError = (userDetails.profileRole === '') ? true : false;
            formErrors.isParentCompanyNameError = (props.isUserTypeNotListedVendor && userDetails.parentCompanyName === '') ? true : false;
            formErrors.isMobileNoError = (userDetails.phoneNumbers.mobilePhone === '') || formErrors.isMobileNoError ? true : false;
            setIsFormInvalidError(true);
            isFormInvalid = true;
        }
        e.preventDefault();
        props.onSubmitHandler(userDetails, isFormValid(), isFormInvalid,
            document.getElementById('usrfirstname').getElementsByTagName('input')[0].value,
            document.getElementById('usrmiddlename').getElementsByTagName('input')[0].value,
            document.getElementById('usrlastname').getElementsByTagName('input')[0].value,
            document.getElementById('usremail').getElementsByTagName('input')[0].value,
            document.getElementById('usrmobilephone').getElementsByTagName('input')[0].value,
            document.getElementById('usrbusinessphone').getElementsByTagName('input')[0].value,
            // document.getElementById('usrParentCompanyName').getElementsByTagName('input')[0].value,
        );
        // if(userDetails.phoneNumbers.mobilePhone==='' && props.type === 'MYPROFILE')
        // setIsMobileNoInvalideForMyProfilePage(true);

    }


    const onJumpLinkClick = () => {
        setTimeout(() => {
            let str = window.location.hash;
            handleFieldFocus(str);
        }, 300);

    }


    const handleFieldFocus = (type: string): void => {
        switch (type) {
            case "#usrRole":
                document.getElementById('role-select-id').focus();
                break;
            default:
                break;
        }
    }



    const getRoleHelperText = (userRole) => {
        if (userRole) {
            const userRoleLowerCase = userRole.toLowerCase();
            const userRoleMatch = Object.entries(UserRoleConfigKeyMapper).find(([key, value]) => value === userRoleLowerCase);
            if (userRoleMatch) {
                return UserRoleHelperText[userRoleMatch[0]]
            }
        }
        return 'Required';
    }
    const isReadOnlyField = (fieldName) => {
        if (props.readOnlyFields && props.readOnlyFields.length > 0) {
            if (fieldName === 'companyName') {
                if (!props.isUserTypeNotListedVendor) {
                    return props.readOnlyFields.some(field => field === fieldName)
                }
                else {
                    if (props.type && props.type === 'EDITPROFILE') {
                        return true;
                    } else {
                        return false;
                    }
                }
            } else {
                return props.readOnlyFields.some(field => field === fieldName)
            }
        }
        return false;
    }

    const isReadOnlyFieldBasedonProfileRole = () => {
        if (props.editableProfileRole !== undefined) {
            return !props.editableProfileRole;
        }
        return false;
    }

    const getPhoneValidationErrorText = () => {
        if (userDetails.phoneNumbers.mobilePhone === '') {
            return 'phone number is required.'
        }
        return 'Use Phone number'
    }
    const getEmailValidationErrorText = () => {
        if (userDetails.email === '') {
            return 'Email address is required.'
        }
        if (props.emailAlreadyExist) {
            return 'This email address is already in the system. Review your current users or try a different email.'
        }
        return 'Use this format: xxxx@xxxx.xxx'
    }
    return (

        <form onSubmit={onFormSubmitClick} noValidate>
            {props.title ?
                <div className="sp-page-title sp-mr-b-16">
                    <h1>
                        {props.title}
                    </h1>
                </div>
                : null
            }
            {props.formData.profileRole === 'Site Administrator' && props.primaryButtonText === undefined && !props.profileEditInfo ?
                <div onClick={onJumpLinkClick} >
                    <USBNotification
                        addClasses="sp-mr-b-12"
                        variant="error"  >
                        To assign specific permissions, <a href="#usrRole"> change the user's role</a> to <strong>Contributor</strong>.
                    </USBNotification>
                </div>
                : null}


            <h2><strong>Name and role</strong></h2>

            <div className="sp-row sp-align-items-start sp-mr-b-16">

                <div className="sp-col-4" id="first-name">
                    <USBInput
                        id={'usrFirstName'}
                        addClasses={props.type === 'MYPROFILE' ? "sp-body-medium" : " "}
                        errorText={userDetails.firstName === '' ? 'First name is required.' : userDetails.firstName.length < 2 ? 'This field requires at least two characters' : userDetails.firstName.length > 50 ? 'You’ve reached the 50-character limit.' : 'Invalid first name'}
                        isError={formErrors.isFirstNameError}
                        initValue={userDetails.firstName}
                        helperText={isReadOnlyField('firstName') ? '' : "Required"}
                        handleChange={onChangeText}
                        labelText="First name"
                        name="firstName"
                        value={userDetails.firstName}
                        isReadOnly={isReadOnlyField('firstName')} />
                </div>

                <div className="sp-col-4">
                    <USBInput id={'usrMiddleName'}
                        labelText='Middle name'
                        errorText={userDetails.middleName.length > 50 ? 'You’ve reached the 50-character limit.' : 'Invalid middle name'}
                        isError={formErrors.isMiddleNameError}
                        initValue={userDetails.middleName}
                        handleChange={onChangeText}
                        name="middleName"
                        value={userDetails.middleName}
                        isReadOnly={isReadOnlyField('middleName')} />
                </div>

                <div className="sp-col-4" id="last-name">
                    <USBInput
                        id={'usrLastName'}
                        errorText={userDetails.lastName === '' ? 'Last name is required.' : userDetails.lastName.length < 2 ? 'This field requires at least two characters' : userDetails.lastName.length > 50 ? 'You’ve reached the 50-character limit.' : 'Invalid last name.'}
                        isError={formErrors.isLastNameError}
                        helperText={isReadOnlyField('lastName') ? '' : "Required"}
                        labelText="Last name"
                        initValue={userDetails.lastName}
                        handleChange={onChangeText}
                        name="lastName"
                        value={userDetails.lastName}
                        isReadOnly={isReadOnlyField('lastName')} />
                </div>
            </div>

            <div className="sp-row">
                {isReadOnlyField('profileRole') ?
                    <div className="sp-col sp-mr-b-16">
                        <USBInput id={'usrRole'}
                            labelText="Role"
                            initValue={userDetails.profileRole}
                            name="profileRole"
                            value={userDetails.profileRole}
                            isReadOnly={isReadOnlyField('profileRole')} />
                    </div>
                    : null}
                {props.isUserTypeNotListedVendor && roleList && roleList.length > 0 ?
                    <div className="sp-col sp-mr-b-16">
                        <div style={{ pointerEvents: 'none' }}>
                            <USBInput id={'usrRoleUnlistedUser'}
                                helperText="Vendors can be contributors only. They can upload, download and comment on documents."
                                isError={false}
                                labelText="Role"
                                value={roleList[0].value}
                                readOnly={true}
                                addClasses={"sp-input-read-only"}
                                uniqueID={'role-select-id'}>
                            </USBInput>
                        </div>
                    </div> :
                    roleList && roleList.length > 0 ?

                        <div className="sp-col sp-mr-b-16" >
                            <USBSelect id={'usrRole'}
                                isError={props.formData.profileRole === 'Site Administrator' && props.primaryButtonText === undefined && !props.profileEditInfo || formErrors.isRoleError ? true : false}
                                errorText={formErrors.isRoleError ? 'Role is required.' : 'Select the Contributor role to assign permissions by legal entity or deal.'}
                                helperText={getRoleHelperText(userDetails.profileRole)}
                                labelText={'Role'}
                                initValue={userDetails.profileRole}
                                optionsArray={roleList}
                                handleChange={e => handleRoleSelectChange(e.target.value)}
                                uniqueID={'role-select-id'}
                                isReadOnly={isReadOnlyField('profileRole')}>
                            </USBSelect>
                        </div>
                        : null}
            </div>

            <h2><strong>Email and phone</strong></h2>

            <div className="sp-row sp-mr-b-16">
                <div className="sp-col " id="email-address">
                    <USBInput id={'usrEmail'}
                        errorText={getEmailValidationErrorText()}
                        isError={formErrors.isEmailError || props.emailAlreadyExist}
                        helperText={isReadOnlyField('email') ? '' : "Required"}
                        labelText="Email address"
                        initValue={userDetails.email}
                        handleChange={onChangeText}
                        name="email"
                        value={userDetails.email}
                        isReadOnly={props.type === 'MYPROFILE' ? isReadOnlyField('email') : isReadOnlyFieldBasedonProfileRole()} />
                </div>
            </div>

            <div className="sp-row sp-mr-b-16">
                <div className="sp-col " id="mobile-number">
                    <USBInput
                        id={'usrMobilePhone'}
                        errorText ={getPhoneValidationErrorText()}
                        isError={formErrors.isMobileNoError}
                        helperText={isReadOnlyField('phoneNumbers') ? '' : "Required"}
                        maxLength={12}
                        ariaErrorRole={null}
                        errorText="Enter 10 characters, numbers only."
                        isError={formErrors.isMobileNoError}
                        labelText="Mobile phone number"
                        initValue={userDetails.phoneNumbers.mobilePhone}
                        handleChange={handlePhoneNumberChange}
                        name="mobilePhone"
                        value={userDetails.phoneNumbers.mobilePhone}
                    />
                    {props.type === 'MYPROFILE' ?
                        <USBTooltip variant="dark"
                            addClasses="sp-info-icon"
                            ariaLabel="tooltip content"
                            toolTipIcon={() => <USBIconInfo toolTipVisible={true} size={19} />}>
                            We use your mobile phone number to identify you. We’ll only call or text if we need to contact you about your account.
                        </USBTooltip>
                        : null}

                </div>
            </div>
            {props.type == "EDITPROFILE" && !isReadOnlyFieldBasedonProfileRole() && props?.flags?.featureDetails?.includes("feature_enable_mnobypass")?
                <div className="sp-row sp-mr-b-16">
                    <div className="sp-col " id="mobile-number">
                        <USBCheckbox
                            checkboxID="testEnabled1"
                            labelText="Mobile number bypass"
                            //initIsDisabled = {isReadOnlyFieldBasedonProfileRole()}
                            initIsChecked={userDetails.mnoBypass}
                            id="test-id"
                            name="test-name"
                            formGroup="checkbox"
                            addClasses="test-class"
                            handleChange={handleMNOBypass}
                        />
                        <p className="phoneNumber-bypass-helper-text">Check the box to send verification code to email instead of phone</p>
                    </div>
                </div> :
                ""
            }


            <h2><strong>Company information</strong></h2>

            <div className="sp-row sp-mr-b-16">

                <div className="sp-col ">
                    {/* {props.isAdmin && userDetails.status != 'Pending' ? (
                        <div>
                            {(props.parentList.length > 0 && props.parentListFull.length > 0) ?
                                <USBSelect
                                    id="parent"
                                    inputName="parent"
                                    labelText="Parent Company"
                                    initValue={userDetails.parentEIN}
                                    optionsArray={props.parentList}
                                    handleChange={(e) => {
                                        handleParentCompanyName(e);
                                    }}
                                    errorMessages={{
                                        224: 'Please select an option',
                                    }}
                                /> : ""}
                        </div>
                    ) : ( */}
                    <div>
                        <USBInput
                            id={'usrParentCompanyName'}
                            isError={formErrors.isParentCompanyNameError}
                            errorText={formErrors.isParentCompanyNameError ? 'Company name is required.' : ''}
                            helperText={props.isUserTypeNotListedVendor ? 'Required' : ''}
                            labelText="Company name"
                            initValue={userDetails.parentCompanyName}
                            name="companyName"
                            value={userDetails.parentCompanyName}
                            handleChange={handleParentCompanyName}
                            isReadOnly={props.isUserTypeNotListedVendor ? false : isReadOnlyField('companyName')} 
                            />
                    </div>
                    {/* )} */}
                </div>
            </div>

            <div className="sp-row sp-mr-b-16">
                <div className="sp-col ">
                    <USBInput
                        id={'usrBusinessPhone'}
                        maxLength={12}
                        errorText="Enter 10 characters, numbers only."
                        isError={formErrors.isBusinessPhoneError}
                        labelText="Business phone number"
                        initValue={userDetails.phoneNumbers.businessPhone}
                        handleChange={handlePhoneNumberChange}
                        name="businessPhone"
                        value={userDetails.phoneNumbers.businessPhone}
                        isReadOnly={isReadOnlyField('businessPhone')} />
                </div>
            </div>

            <div className="sp-row sp-mr-b-16">
                <div className="sp-col">
                    <USBInput id={'usrStreet'}
                        errorText="You’ve reached the 200-character limit."
                        isError={formErrors.isStreetError}
                        labelText="Street address"
                        initValue={userDetails.address.addressLine1}
                        handleChange={handleAddressChange}
                        name="addressLine1"
                        value={userDetails.address.addressLine1}
                        isReadOnly={isReadOnlyField('addressLine1')} />
                </div>
            </div>
            <div className="sp-row sp-mr-b-16">
                <div className="sp-col">
                    <USBInput id={'usrApt'}
                        errorText="You’ve reached the 200-character limit."
                        isError={formErrors.isAptError}
                        labelText="Apt./suite/unit"
                        initValue={userDetails.address.addressLine2}
                        handleChange={handleAddressChange}
                        name="addressLine2"
                        value={userDetails.address.addressLine2}
                        isReadOnly={isReadOnlyField('addressLine2')} />
                </div>
            </div>

            <div className="sp-row sp-mr-b-16">

                <div className="sp-col">
                    <USBInput id={'usrCity'} labelText="City"
                        errorText={userDetails.address.city.length > 200 ? 'You’ve reached the 200-character limit.' : 'Invalid city name.'}
                        isError={formErrors.isCityError}
                        initValue={userDetails.address.city}
                        handleChange={handleAddressChange}
                        name="city"
                        value={userDetails.address.city}
                        isReadOnly={isReadOnlyField('city')} />
                </div>

            </div>

            <div className="sp-row sp-mr-b-16">

                <div className="sp-col">
                    <USBInput id={'usrState'}
                        labelText={'State/province/subdistrict'}
                        errorText={userDetails.address.state.length > 50 ? 'You’ve reached the 50-character limit.' : 'Invalid state name.'}
                        isError={formErrors.isStateError}
                        initValue={userDetails.address.state}
                        handleChange={handleAddressChange}
                        name="state"
                        value={userDetails.address.state}
                        uniqueID={'state-select-id'}
                        isReadOnly={isReadOnlyField('state')}>
                    </USBInput>
                </div>

            </div>

            <div className="sp-row sp-mr-b-16">

                <div className="sp-col">
                    <USBInput id={'usrZipCode'} labelText="Postal code"
                        errorText="Invalid zip code."
                        isError={formErrors.isZipCodeError}
                        initValue={userDetails.address.zipCode}
                        handleChange={handleAddressChange}
                        name="zipCode"
                        value={userDetails.address.zipCode}
                        isReadOnly={isReadOnlyField('zipCode')} />
                </div>

            </div>

            {countryList && countryList.length > 0 ?

                <div className="sp-row sp-mr-b-32">
                
                    <div className="sp-col ">
                        <USBSelect id={'usrCountry'} labelText={'Country'}
                            initValue={userDetails.address.country}
                            optionsArray={countryList}
                            handleChange={handleAddressChange}
                            name="country"
                            uniqueID={'country-select-id'}>
                        </USBSelect>

                    </div>
                </div>
                : null}
            <div className="sp-action-button-wrapper">
                {
                    props.onCancelHandler ? <USBButton id={'empRegCancelBtn'} variant={"secondary"}
                        addClasses={"sp-button-spacing"}
                        handleClick={onCancelClick}>{props.secondaryButtonText ? props.secondaryButtonText : 'Cancel'}</USBButton> : null
                }
                <USBButton id={'empRegSubmitBtn'}
                    variant={"primary"} addClasses={"sp-button-spacing"}
                    type="submit" >{props.primaryButtonText ? props.primaryButtonText : 'Continue'}</USBButton>
            </div>
        </form>
    )
});

const mapStateToProps: MapStateToProps = state => {
    return {
        profileEditInfo: state.userProfileInformation.profileEditInfo,
    };
};

export default connect(mapStateToProps)(UserInformationForm);