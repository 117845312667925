import React, { createContext, useState, useEffect } from 'react';
import { endpoints } from '../../../config/connections';
import callApi from '../utilities/callApi';
import useUserRole from './use-UserRole'
export const PermissionRoleContext = createContext();

const PermissionRoleContextProvider = (props) => {
  const [permissionDetails, setPermissionDetails] = useState([]);
  const userCheck = useUserRole();
  const permissionURL = `${endpoints.apigee}/document-management/coreservice/user-management/v1/allowedPages`;

  useEffect(() => {
    if(permissionDetails.length===0){
      callApi
      .getApiDataLoggedUser(permissionURL)
      .then((response) => {
        if(response?.name=="AxiosError"){
          setPermissionDetails([])
          // window.alert("Session Expired. Please login again.")
          // if(response?.response?.status==401){
          //   setTimeout(() => {
          //    sessionStorage.removeItem("remote_user")
          //    sessionStorage.removeItem("portfolioFilters")
          //    if (userCheck !== 'employee') {
          //        window.location.href = '/';
          //        sessionStorage.clear();
          //    } else {
          //        sessionStorage.clear();
          //        window.location.href = '/EmployeeRedirect?tp=lg';
          //    }
          //   }, (10000));
          //  }
        }
        setPermissionDetails(response.data);
      })
    }
  }, []);

  return (
    <PermissionRoleContext.Provider
      value={{
        permissionDetails,
      }}
    >
      {props.children}
    </PermissionRoleContext.Provider>
  );
};

export default PermissionRoleContextProvider;
