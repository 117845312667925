/* eslint-disable no-nested-ternary */
/* eslint quote-props: ["error", "consistent"] */
import React, { useState, useEffect, useContext } from 'react';
// import Router from 'next/router'
import {
  USBGrid,
  USBColumn,
  USBButton,
  USBPagination,
} from '@usb-shield/components-react';
import USBBreadcrumb from '@usb-shield/components-react/dist/cjs/breadcrumb';
import { Checkbox } from 'primereact/checkbox';
import { DocContext } from '../context/DocContext';
import { endpoints } from '../../../config/connections';
import callApi from '../../components/utilities/callApi';
import Messages from '../../components/organisms/Messages';
import './notifications.css';
import { v4 as uuidv4 } from 'uuid';

const addAriaLabel = () => {
  const searchElement = document.getElementsByClassName('p-checkbox-box');
  for (let i = 0; i < searchElement.length; i++) {
    searchElement[i].setAttribute(
      'aria-label',
      'Opt out of email notification'
    );
  }
};

export const markSingleDocAsRead = (id,userType) => {
  const user = sessionStorage.getItem('assignedUser');
  let listUrl;
  if(userType==="PSA"){
listUrl=`${endpoints.apigee}/document-management/cdc/alerts/v1/users/userId/documents/${id}/notifications-read?userType=PSA`;
  }
  else
  {
listUrl=`${endpoints.apigee}/document-management/cdc/alerts/v1/users/userId/documents/${id}/notifications-read`;
  }
  callApi
    .postApi(listUrl, '')
    .then((response) => {
      if (response.status === 200) {
        if(userType==="PSA"){
          window.location.href = `/RequirementDetails?id=${id}&index=2`;
        }else{
          window.location.href = `/RequirementDetails?id=${id}&index=1`;
        }
      }
    })
    .catch(() => {
      // console.log(error)
    });
};

export const showMessages = (id,userType) => {
  const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
  const listUrl = `${endpoints.apigee}/document-management/cdc/messaging/v1/messages/${id}?userTimeZone=${timeZone}`;
  callApi
    .getApiData(listUrl, '', 'statusValue')
    .then((response) => {
      if (response.status === 200) {
        markSingleDocAsRead(id,userType);
      }
    })
    .catch(() => {
      // console.log(error)
    });
};

export const markAllAsRead = (user, setNotification, listNotifications) => {
  const listUrl = `${endpoints.apigee}/document-management/cdc/alerts/v1/users/userId/notifications-read`;
  callApi
    .postApi(listUrl, '')
    .then((response) => {
      if (response.status === 200) {
        listNotifications(sessionStorage.getItem('assignedUser'));
        setNotification(0);
      }
    })
    .catch(() => {
      // console.log(error)
    });
};

export const optForEmailNotification = (
  getNotification,
  setSuccessFlag,
  setMessageContent,
  setFailedFlag,
  canUserUpdate,
  listNotifications
) => {
  const userid = sessionStorage.getItem('assignedUser');
  const optEmailURL = `${endpoints.apigee}/document-management/coreservice/user-management/v1/users/userId/email-enrollment?emailOptFlag=${getNotification}`;
  let contentTxt = '';
  if (canUserUpdate) {
    callApi
      .putApi(optEmailURL, '')
      .then((response) => {
        if (response.status === 200) {
          listNotifications(userid);
          if (getNotification) {
            contentTxt = <div>You&apos;ve turned on email notifications.</div>;
          } else {
            contentTxt = <div>You&apos;ve turned off email notifications.</div>;
          }
          setSuccessFlag(true);
          setMessageContent(contentTxt);
          window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth',
          });
          setTimeout(() => {
            setSuccessFlag(false);
          }, 5000);
        }
      })
      .catch(() => {
        // console.log(error)
      });
  } else {
    contentTxt = (
      <div>
        You can&apos;t turn off your email notifications. At least one person
        from your organization must receive notifications.
      </div>
    );
    setFailedFlag(true);
    setMessageContent(contentTxt);
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
    setTimeout(() => {
      setFailedFlag(false);
    }, 5000);
  }
};

export const setOptOutCheckBox = (checked, setReceiveNotification) => {
  setReceiveNotification(checked);
};

export const closeMessage = (setFailedFlag, setSuccessFlag) => {
  setFailedFlag(false);
  setSuccessFlag(false);
};

export const messageList = (data) => {
  return (
    <div className="message-container" key={uuidv4()}>
      {data &&
        data.map((item) => {
          return (
            <div className="each-list" key={uuidv4()+1}>
              <div
                className={
                  item.readFlag ? 'message-space-read' : 'message-space'
                }
              >
                <div>
                  {!item.readFlag ? (
                    <span className="message-new">NEW</span>
                  ) : (
                    ''
                  )}
                  <div
                    className={
                      item.readFlag
                        ? 'notification-normal'
                        : 'notification-bold'
                    }
                  >
                    {item.docCategory}
                    {item.dealName !== null ? ` | ${item.dealName}, ` : ''}
                    {item.dealId !== 0 ? `${item.dealId} | ` : ''}
                    {item.reportingEntity !== null ? item.reportingEntity : ''}
                  </div>
                </div>
                <div className="time-frame">{item.timeStamp}</div>
                <div className="time-frame">{item.userType&& item.userType==="PSA"?"PSA Notification":""}</div>
              </div>
              <div className="button-space">
                <USBButton
                id="viewMessage"
                addClasses={item.readFlag ? 'white-button' : ''}
                  variant={  item.readFlag ? '' : 'primary'}
                  size="small"
                  ariaLabel={
                    item.readFlag ? 'View message' : 'View unread message'
                  }
                  handleClick={() => showMessages(item.id,item.userType)}
                >
                  View message
                </USBButton>
              </div>
            </div>
          );
        })}
    </div>
  );
};

export const markAllAsReadHtml = (
  notification,
  setNotification,
  listNotifications
) => {
  return (
    <div className="mark-all-read">
      {notification > 0 ? (
        <button
          type="button"
          className="inlinebtn"
          onClick={() =>
            markAllAsRead(
              sessionStorage.getItem('assignedUser'),
              setNotification,
              listNotifications
            )
          }
        >
          Mark all as read
        </button>
      ) : (
        <button type="button" className="inlinebtn">
          Mark all as read
        </button>
      )}
    </div>
  );
};

const Notifications = () => {
  const {
    notification,
    setNotification,
    assignedUserName,
    listNotifications,
    totalPages,
    notificationDetails,
    getNotification,
    setReceiveNotification,
    canUserUpdate,
    setCurrentMenu,
  } = useContext(DocContext);
  const [currentPage, setCurrentPage] = useState(1);
  const [isEmployee, setIsEmployee] = useState(false);
  const [successFlag, setSuccessFlag] = useState(false);
  const [failedFlag, setFailedFlag] = useState(false);
  const [messageContent, setMessageContent] = useState('');

  const forwardClickHandler = () => {
    setCurrentPage(currentPage + 1);
  };

  const backwardClickHandler = () => {
    setCurrentPage(currentPage === 1 ? 1 : currentPage - 1);
  };

  const controlOpts = {
    controlled: true,
    handleForwardClick: forwardClickHandler,
    handleBackwardClick: backwardClickHandler,
  };

  useEffect(() => {
    if (!sessionStorage.getItem('tok')) {
      window.location.href = '/';
    } else {
      if (sessionStorage.getItem('userrole') === 'employee') {
        setIsEmployee(true);
      }
      setCurrentMenu('notifications');
      listNotifications(sessionStorage.getItem('assignedUser'));
    }
    addAriaLabel();
  }, []);

  return (
    <>
      <USBGrid addClasses="content-frame">
        <USBColumn largeSpan={8}>
          <USBBreadcrumb
            id="notifications"
            text="Home"
            breadcrumbs={[
              {
                id: 2,
                text: 'Notifications',
                href: '',
              },
            ]}
          />
        </USBColumn>
        <USBColumn largeSpan={8} />
      </USBGrid>
      <USBGrid addClasses="content-frame">
        <USBColumn largeSpan={16}>
          <h1 className="heading-h1">
            {assignedUserName.firstName === 'You' ? (
              ''
            ) : (
              <span className="assigned-name">
                {assignedUserName.firstName}
                &nbsp;
                {assignedUserName.lastName}
                &rsquo;s&nbsp;
              </span>
            )}
            Notifications
          </h1>
          {successFlag || failedFlag ? (
            <div
              className="message-content"
              data-testid="messageContent"
              role="alert"
            >
              <Messages
                success={successFlag}
                closeMessage={closeMessage}
                singleUploadFail={failedFlag}
                messageContent={messageContent}
                setFailedFlag={setFailedFlag}
                setSuccessFlag={setSuccessFlag}
              />
            </div>
          ) : (
            ''
          )}
          <div className="page-details">
            You’ll see the most recent notifications about your reporting
            requirements listed first. Select
            <span className="message-note"> View message </span>
            to read or comment on the message.
          </div>
          {notificationDetails && notificationDetails.length > 10 ? (
            <USBPagination
              id="notification-paging"
              currentPage={currentPage}
              data={notificationDetails}
              pageSize={10}
              paginationAriaLabel="Pagination Navigation"
              totalPages={totalPages}
              controlOptions={controlOpts}
              backwardButtonAriaLabel="Previous page"
              forwardButtonAriaLabel="Next page"
              forwardIconTitle="Next page"
              backwardIconTitle="Previous page"
              currentPageAriaLabel="Current page"
              totalPagesAriaLabel="Total pages"
              addClasses="pagination-component"
              paginationLabelFrom="Page"
              paginationLabelOf="of"
              handlePaginatedComponent={({ data }) => {
                return (
                  <div className="message-list">
                    {messageList(data)}
                    {markAllAsReadHtml(
                      notification,
                      setNotification,
                      listNotifications
                    )}
                  </div>
                );
              }}
            />
          ) : notificationDetails.length > 0 ? (
            <div className="message-list">
              {messageList(notificationDetails)}
              {markAllAsReadHtml(
                notification,
                setNotification,
                listNotifications
              )}
              <div className="single-page-pagination notification-page">
                <p className="null-pagination">
                  Page 1 of 1
                  {/* &nbsp;<span className="active">1</span>&nbsp; */}
                </p>
              </div>
            </div>
          ) : (
            ''
          )}
          {/* ----------------------------  Email opt out section ----------------------- */}
          {!isEmployee ? (
            <div className="opt-email-notification" id="opt-out">
              <Checkbox
                id="chk-email-notification"
                className="chk-email-notification"
                onChange={(e) =>
                  setOptOutCheckBox(e.checked, setReceiveNotification)
                }
                checked={getNotification}
                ariaLabelledBy="opt-out"
              />
              <div className="chk-email-notification-text">
                <div className="label-text">Get email notification</div>
                <div className="sub-label-text">
                  By unchecking this box, you&apos;ll no longer receive email
                  notifications about past due reporting requirements and
                  message activity.
                </div>
              </div>
              <USBButton
                id="savechanges"
                variant="primary"
                addClasses="save-opt-out"
                size="small"
                ariaLabel="Save changes"
                handleClick={() =>
                  optForEmailNotification(
                    getNotification,
                    setSuccessFlag,
                    setMessageContent,
                    setFailedFlag,
                    canUserUpdate,
                    listNotifications
                  )
                }
              >
                Save changes
              </USBButton>
            </div>
          ) : (
            ''
          )}
          {/* Email opt out section ends */}
        </USBColumn>
      </USBGrid>
    </>
  );
};

export default Notifications;
