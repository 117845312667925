import { useState, useEffect } from "react";
function getNetworkConnection() {
  return (
    navigator.connection ||
    navigator.mozConnection ||
    navigator.webkitConnection ||
    null
  );
}
function getNetworkConnectionInfo() {
  const connection = getNetworkConnection();

if (!connection) {
    return {};
  }
return {
    effectiveType: connection.effectiveType,
  };
}


function useNetwork() {
  const [state, setState] = useState(() => {
    return {
      since: undefined,
      online: navigator.onLine,
      ...getNetworkConnectionInfo(),
    };
  });
useEffect(() => {
    const handleOnline = () => {
      setState((prevState) => ({
        ...prevState,
        online: true,
        since: new Date().toString(),
      }));
    };
const handleOffline = () => {
      setState((prevState) => ({
        ...prevState,
        online: false,
        since: new Date().toString(),
      }));
    };
const handleConnectionChange = () => {
      setState((prevState) => ({
        ...prevState,
        ...getNetworkConnectionInfo(),
      }));
    };
window.addEventListener("online", handleOnline);
window.addEventListener("offline", handleOffline);


// let batteryIsCharging = "";
// let batteryLevel = 0;

// navigator.getBattery().then((battery) => {
//   batteryIsCharging = battery.charging;
//   batteryLevel = battery.level;
//   battery.addEventListener("chargingchange", () => {
//   batteryIsCharging=battery.charging==true?"charging":"not charging. Please connect with charger.";
//   console.log(`This Laptop battery is ${batteryIsCharging} and charge is ${batteryLevel*100}%`);
//   });
// });

const connection = getNetworkConnection();
connection?.addEventListener("change", handleConnectionChange);

return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
      connection?.removeEventListener("change", handleConnectionChange);
    };

  }, []);
return state;
}
export default useNetwork;