import React from 'react';
import HeadLink from '../../atoms/HeadLink';

const Favicon = () => {
  return (
    <>
      {/* <link rel="icon" type="image/png" href="/favicon.png" /> */}
      <HeadLink rel="icon" type="image/png" sizes="16x16" href="/favicon.png" />
    </>
  );
};

export default Favicon;
