import React from 'react';
import { useSelector } from "react-redux";
import { withRouter } from 'react-router-dom';
import { USBButton, USBProgressIndicator } from '@usb-shield/components-react/dist/cjs';
import componentConfig from '../../config/component-config.json';
import { UserRoleConfig } from '../../config/user-role-config.js';

const ConfirmUser = (props) => {

    const userDetails = useSelector(state => state.userProfileInformation.profileData);
    const userType = useSelector(state => state.userProfileInformation.userType);
    const currentUser = useSelector(state => state.currentUser.user.custom_data.role);
    const bussinessProfileType = useSelector(state => state.parentCompanyInformation.parentCompany.businessProfileType);

    const submitBtnProps = {
        [componentConfig.USBButton.variant]: "primary",
        [componentConfig.USBButton.addClasses]: "sp-button-spacing"
    };
    
    const messageToBeDisplayed = () => {

        if (currentUser.includes(UserRoleConfig.superAdmin) && (bussinessProfileType?.toLowerCase() === 'vendor' || bussinessProfileType?.toLowerCase() === 'specialvendor')) {
            if(userDetails?.profileRole?.toLowerCase() === 'site administrator') {
                return <div className="sp-body-medium sp-mr-b-24">You’ve successfully added the vendor. They’ll see the reporting requirements you’ve provided access to in their <strong>My portfolio</strong> page in the portal.</div>
            } else {
                return <div className="sp-body-medium sp-mr-b-24">You’ve successfully added the vendor. They’ll receive an email invitation shortly with next steps.</div>
            }
        } else if (currentUser.includes(UserRoleConfig.customer) && userType && userType.type === 'Vendor') {
            if (userType.subType && userType.subType.parentCompanyName === 'Not Listed') {
                return <div className="sp-body-medium sp-mr-b-24">You’ve successfully added your vendor. They’ll receive an email invitation shortly with next steps.</div>
            } else {
                return <div className="sp-body-medium sp-mr-b-24">You’ve successfully added your vendor. They’ll see the reporting requirements you’ve provided access to in their <strong>My portfolio</strong> page in the portal.</div>
            }
        } else {
            return <div className="sp-body-medium sp-mr-b-24">You’ve successfully added a new user. They’ll receive an email invitation shortly with next steps.</div>
        }
    }

    const onDoneClick = () => {
        props.history.push('/ManageUser/');
    }


    return (
        <React.Fragment>

            <div className="sp-progress-bar-container sp-mr-b-24">
                <div className="sp-progress-bar">
                    <USBProgressIndicator
                        id={'confirmUserStep'}
                        percentSuffixLabel='% Complete'
                        PERCENTAGE='percentage'
                        ariaProgressLabelFirst='Your Progress: Step'
                        ariaProgressLabelMid='of'
                        ariaProgressLabelLast='Completed'
                        currentStep={2}
                        variant='percentage' >
                        <USBProgressIndicator.Step>
                            <span>Step1</span>
                        </USBProgressIndicator.Step>
                        <USBProgressIndicator.Step>
                            <span>Step2</span>
                        </USBProgressIndicator.Step>
                    </USBProgressIndicator>
                </div>
            </div>

            <div className="sp-container-fluid sp-progress-content-space">
                <div className="sp-row">
                    <div className="sp-col-6 sp-offset-left-3">
                        <div className="sp-page-title sp-mr-b-16">You're all set. </div>
                        {messageToBeDisplayed()}
                        <div>
                            <USBButton id={'confirmUserSubmitBtn'} {...submitBtnProps} handleClick={onDoneClick}>Done</USBButton>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}


export default withRouter(ConfirmUser);