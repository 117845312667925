import './toggle.css';
import React, {useEffect, useState} from 'react';


const Toggle = ({id,toggleTextOn,toggleTextOff,value,onToggleChange,optionalData}) => {

    const [toggleValue,setToggleValue] = useState(value)
    
    const updateToggle = () => {
        let toggle = document.querySelector(`#toggle${id}`);
        let updatedValue;
        toggle.classList.toggle("active")
        if(toggle.classList.contains("active")){
            updatedValue =  toggleTextOn  
        } else{
            updatedValue = toggleTextOff
        }
        setToggleValue(updatedValue)
        onToggleChange(updatedValue,optionalData)
    }

    useEffect(()=>{
        let toggle = document.querySelector(`#toggle${id}`);
        if(value==toggleTextOn){
            toggle.classList.add('active')
            setToggleValue(toggleTextOn)
        } else {
            toggle.classList.remove('active')
            setToggleValue(toggleTextOff)
        }
    },[])

    return (
        <>
            <div className="toggle-container">
                <div id={`toggle${id}`} className="toggle" onClick={updateToggle}>
                    <div className="toggle-button">
                    </div>
                </div>
                <div id={`toggle-text${id}`} className="toggle-text">{`${toggleValue}`}</div>
            </div>
        </>
    )
}

export default Toggle;