const ParentCompanyReducer = (state = {}, action) => {
  switch (action.type) {
    case "SET_PARENT_COMPANY_INFO":
      return {
        ...state,
        parentCompany: action.payload,
      };
    case "SET_PARENT_COMPANY_LIST":
      return {
        ...state,
        parentCompanyList: action.payload
      };
    case "SET_PARENT_COMPANY_SELECTION_MANAGE_USER":
      return {
        ...state,
        parentCompanySelection: action.payload
      }
    case "SET_PARENT_COMPANY_SELECTION_HAS_NO_PROFILE":
      return {
        ...state,
        parentCompanySelectionHasProfile: action.payload
      }
    default:
      return state;
  }
};

export default ParentCompanyReducer;
