import React, { Component } from 'react';
import { USBGrid, USBColumn, USBButton } from '@usb-shield/components-react';
import './error.css';
import './index.css';

class _noaccess extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  refreshPage() {
    if (typeof window !== 'undefined') {
      window.location.href = '/';
    }
  }

  render() {
    return (
      <>
        <USBGrid>
          <USBColumn largeSpan={2} />
          <USBColumn largeSpan={12}>
            <div className="image-section">
              <div className="alert-image" />
            </div>
          </USBColumn>
          <USBColumn largeSpan={2} />
        </USBGrid>
        <USBGrid>
          <USBColumn largeSpan={2} />
          <USBColumn largeSpan={12}>
            <div className="main-content">
              <div className="head-content">
                Access Blocked
              </div>
            </div>
            <div className="sub-content">
              <p>
                You do not have access to this page. Contact support to get access.
              </p>
            </div>
          </USBColumn>
          <USBColumn largeSpan={2} />
        </USBGrid>
        <USBGrid>
          <USBColumn largeSpan={2} />
          <USBColumn largeSpan={12}>
            {/* <div className="button-section">
              <USBButton variant="primary" handleClick={this.refreshPage}>
                Try again
              </USBButton>
            </div> */}
          </USBColumn>
          <USBColumn largeSpan={2} />
        </USBGrid>
      </>
    );
  }
}

export default _noaccess;
