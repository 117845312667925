import React from 'react';
import {
  USBIconEdit,
  USBIconAdd,
  USBIconRemove,
  USBIconEnvelope,
  USBIconLock,
  USBIconDelete,
  USBIconProfile,
  USBIconTransfer,
} from '@usb-shield/components-react/dist/cjs';
import { parseJwt } from '../../components/utilities/Utils'

export const ManageUserHelper = {
  getMenuItemsForProfileTableActions: (status, userRole, permissions,flags,parentEIN) => {
    const menuitem = {
      label: '',
      icon: '',
    };
    const editMenuItem = {
      label: 'Edit',
      icon: (
        <USBIconEdit
          addClasses="sp-sub-menu-icon-spacing"
          colorVariant="default"
        />
      ),
    };
    const deleteMenuItem = {
      label:'Delete',
      icon : (
        <USBIconDelete
          addClasses="sp-sub-menu-icon-spacing"
          colorVariant="default"
        />
      )

    }
    const moveMenuItem = {
      label : 'Move User',
      icon : <USBIconTransfer addClasses="sp-sub-menu-icon-spacing" colorVariant="default"/>
    }
    const impersonateMenuItem = {
      label: '',
      icon: '',
    };
    if (!status) {
      return [editMenuItem];
    }
    switch (status.toLowerCase()) {
      case 'delete':
        menuitem.label = 'Delete';
        menuitem.icon = (
          <USBIconDelete
            addClasses="sp-sub-menu-icon-spacing"
            colorVariant="default"
          />
        );
        if (parseJwt(sessionStorage.getItem('tok')).custom_data.role.toUpperCase()==='APP_8083_CDCWEB_SUPER_ADMINS') {
          deleteMenuItem.label = 'delete';
          deleteMenuItem.icon = (
            <USBIconDelete
              addClasses="sp-sub-menu-icon-spacing"
              colorVariant="default"
            />
          );
        }
        break;
      case 'active':
        menuitem.label = 'Deactivate';
        menuitem.icon = (
          <USBIconRemove
            addClasses="sp-sub-menu-icon-spacing"
            colorVariant="default"
          />
        );
        if (parseJwt(sessionStorage.getItem('tok')).custom_data.role.toUpperCase()==='APP_8083_CDCWEB_SUPER_ADMINS') {
          impersonateMenuItem.label = 'Impersonate';
          impersonateMenuItem.icon = (
            <USBIconProfile
              addClasses="sp-sub-menu-icon-spacing"
              colorVariant="default"
            />
          );
        }
        break;
      case 'deactivated':
        menuitem.label = 'Reactivate';
        menuitem.icon = (
          <USBIconAdd
            addClasses="sp-sub-menu-icon-spacing"
            colorVariant="default"
          />
        );

        break;
      case 'pending':
        menuitem.label = 'Resend invitation';
        menuitem.icon = (
          <USBIconEnvelope
            addClasses="sp-sub-menu-icon-spacing"
            colorVariant="default"
          />
        );
        break;
      
      case 'locked':
        menuitem.label = 'Unlock';
        menuitem.icon = (
          <USBIconLock
            addClasses="sp-sub-menu-icon-spacing"
            colorVariant="default"
          />
        );
        break;
      default:
        break;
    }
    let temp = [editMenuItem, menuitem]
    if(permissions?.includes("deleteUser") && flags.includes("feature_delete_users")){
      temp.push(deleteMenuItem)
    }
    if(permissions?.includes("moveUser") && flags.includes("feature_move_user") && (!parentEIN.includes("FRQ") && parentEIN!="Not Listed")){
      temp.push(moveMenuItem)
    }
    temp.push(impersonateMenuItem)
    return  temp;
  },

  getMenuItemsForUnlistedVendorProfileTableActions: (status,permissions,flags,parentEIN) => {
    const menuitem = {
      label: '',
      icon: '',
    };
    const editMenuItem = {
      label: 'Edit',
      icon: <USBIconEdit colorVariant="default" />,
    };
    const deleteMenuItem = {
      label : 'Delete',
      icon : <USBIconDelete colorVariant="default" />
    }
    const impersonateMenuItem = {
      label: '',
      icon: '',
    };
    const moveMenuItem = {
      label : 'Move User',
      icon : <USBIconTransfer addClasses="sp-sub-menu-icon-spacing" colorVariant="default"/>
    }
    if (!status) {
      return [editMenuItem];
    }
    switch (status.toLowerCase()) {
      case 'active':
        menuitem.label = 'Deactivate';
        menuitem.icon = (
          <USBIconRemove
            addClasses="sp-sub-menu-icon-spacing"
            colorVariant="default"
          />
        );
        if (parseJwt(sessionStorage.getItem('tok')).custom_data.role.toUpperCase()==='APP_8083_CDCWEB_SUPER_ADMINS') {
          impersonateMenuItem.label = 'Impersonate';
          impersonateMenuItem.icon = (
            <USBIconProfile
              addClasses="sp-sub-menu-icon-spacing"
              colorVariant="default"
            />
          );
        }
        break;
      case 'deactivated':
        menuitem.label = 'Reactivate';
        menuitem.icon = (
          <USBIconAdd
            addClasses="sp-sub-menu-icon-spacing"
            colorVariant="default"
          />
        );
        break;
      case 'pending':
        menuitem.label = 'Resend invitation';
        menuitem.icon = <USBIconEnvelope colorVariant="default" />;
        break;
      case 'locked':
        menuitem.label = 'Unlock';
        menuitem.icon = <USBIconLock colorVariant="default" />;
        break;
      default:
        break;
    }
    let temp = [editMenuItem, menuitem];
    if(permissions?.includes("deleteUser") && flags.includes("feature_delete_users")){
      temp.push(deleteMenuItem)
    }
    if(permissions?.includes("moveUser") && flags.includes("feature_move_user") && (!parentEIN.includes("FRQ") && parentEIN!="Not Listed")){
      temp.push(moveMenuItem)
    }
    return temp;
  },
};
