import React, { useState, useEffect, useContext } from 'react';
import { USBGrid, USBColumn, USBSelect } from '@usb-shield/components-react';
import USBButton from '@usb-inner-src/react-button';
import { endpoints } from '../../../../config/connections';
import USBBreadcrumb from '@usb-shield/components-react/dist/cjs/breadcrumb';
import Loader from '../../../components/utilities/Loader';
import '@usb-shield/react-forms-base/dist/library/styles/index.css';
import './customerAccess-report.css';
import callApi from '../../../components/utilities/callApi';
import useUserRole from '../../../components/Hooks/use-UserRole';
import _noaccess from '../../../pages/_noaccess'
import {downloadReport} from '../../../components/utilities/Utils';
import UsbTableData from '../../../components/utilities/UsbTable';

const CompanyAccessReport = () => {
  const [fullStatusData, setFullStatusData] = useState([]);
  const [vendorCompanyNameList, setVendorCompanyNameList] = useState([]);
  const [customerAccessList, setCustomerAccessList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [responseData, setResponseData] = useState([]);
  const [selections, setSelections] = useState('');
  const [statusSelections, setStatusSelections] = useState('');
  const [vendorStatusList, setVendorStatusList] = useState([]);
  
  const getFilterData = async() => {
    setLoading(true);
    const url = `${endpoints.apigee}/document-management/coreservice/user-management/v1/getParentCompanies`;
    try{
      const response= await callApi.getApiData(url);
      if(response){
        setResponseData(response?.data);
        filterResponse(response?.data);
      }
    }catch{
      setLoading(false);
    }
  };

  const getCustomerAccessNames = async (selectionsParam) => {
    const url = `${endpoints.apigee}/document-management/coreservice/user-management/v1/customer/access/report?vendorNumber=${selectionsParam}`;
   try{
    const response = await callApi.getApiData(url);
    if(response){
      setCustomerAccessList(response.data);
          setVendorStatusList([]);
          setVendorStatusList(formatFilterStatus(response.data));
          setFullStatusData(response.data)
          setLoading(false);
    }
   }catch{
    setLoading(false);
   }
   
  };

  const filterResponse = (response) => {
    const parentCompanyTemp = [];
    response.forEach((element) => {
      parentCompanyTemp.push(element.vendorName);
    });
    setVendorCompanyNameList(formatFilterData(parentCompanyTemp, 0));
    setLoading(false);
  };

  const onParentCompanyChange = (event, filterName) => {
    const eventValue = event?.target?.value;
      if (event.errorCode != 224) {
      setLoading(true);
      const data = responseData[eventValue]?.vendorNumber;
      const companyname = responseData[eventValue]?.vendorName;
      getCustomerAccessNames(data);
      setSelections(companyname);
      setStatusSelections('')
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    }
  };

  const onStatusChange = (event) => {
     const eventValue = event?.target?.value;
    if (event.errorCode != 224) {
    setLoading(true);
    setStatusSelections(eventValue)
    setCustomerAccessList(fullStatusData.filter(obj => obj.status === eventValue || eventValue === 'Select'));
    setLoading(false);
    }
  }

  const formatFilterStatus = (list) => {
    const newStatusData =[{ value: 'Select', content: 'Select' }]
    const uniqueStatusList = list.filter((value, index, self) =>
      index === self.findIndex((item) => (item.status === value.status))
    )
    uniqueStatusList.forEach((element, index) => {
        if(element.status !== ""){
        newStatusData.push({ value: element.status, content: element.status });
        }
      });
    return newStatusData;
  };

  const formatFilterData = (list, indicator) => {
    const newData = [];
    if (indicator == 0) {
      list.forEach((element, index) => {
        if (element!==null && element!==undefined) {
          newData.push({ value: index, content: element }); 
        }
      });
    }
    const sortedData = newData.sort((a, b) =>
      a.content.localeCompare(b.content)
    );
    sortedData.unshift({ value: '', content: 'Select' });
    return sortedData;
  };

  useEffect(() => {
    getFilterData();
  }, []);
  
  const tempUserRole = useUserRole();
  const columns=[
    {
      headerName: 'External User Name',
      key:'firstName',
      fieldFor: 'first',
      dataType: 'string',
      type: 'info',
    },
    {
      headerName: 'User email address',
      key:'emailAddress',
      fieldFor: 'second',
      dataType: 'node',
      type: 'default',
    },
    {
      headerName: 'Last accessed date',
      key:'lastLoginTime',
      fieldFor: 'third',
      dataType: 'node',
      type: 'default',
    },
    {
      headerName: 'Status',
      key: 'status',
      fieldFor: 'fourth',
      dataType: 'node',
      type: 'default',
    },
  ]

  return (
    tempUserRole && tempUserRole!="employee" ? <_noaccess/> :
    <>
      <USBGrid addClasses="content-frame">
        <USBColumn largeSpan={8}>
          <USBBreadcrumb
            id="reports"
            text="Reports"
            breadcrumbs={[
              // {
              //   id: 1,
              //   text: 'My portfolio',
              //   href: '/ReportingDashboard',
              // },
              { id: 2, text: 'Reports', href: '' },
              { id: 3, text: 'Customer Access', href: '' },
            ]}
          />
        </USBColumn>
        <USBColumn largeSpan={8}></USBColumn>
      </USBGrid>
      <USBGrid addClasses="content-frame content-bg-customer-access">
        {loading ? <Loader /> : null}
        <USBColumn largeSpan={16}>
          <h1 className="heading-h1">Report: Customer Access</h1>
          <div className="page-details-wrap-customer-access">
            <div className="page-details-container-customer-access">Select Filters</div>
          </div>

          <div className="sorting-inputs-customer-access">
            <div className="filter-dropdowns-customer-access">
              {vendorCompanyNameList.length > 0 ? (
                <USBSelect
                  id="companyName"
                  name="parentCompanyName"
                  inputName="companyName"
                  initValue={''}
                  labelText="Parent Company"
                  optionsArray={vendorCompanyNameList}
                  handleChange={(e) => {
                    onParentCompanyChange(e, 'vendorNumber');
                  }}
                  errorMessages={{ 224: 'Please select an option' }}
                />
              ) : (
                null
              )}
            </div>
            <div className="filter-dropdowns-customer-access">
              {vendorStatusList.length > 0 ? (
                <USBSelect
                  id="status"
                  name="statusDropdown"
                  inputName="status"
                  initValue={''}
                  labelText="Status"
                  optionsArray={vendorStatusList}
                  handleChange={(e) => {
                    onStatusChange(e);
                  }}
                  errorMessages={{ 224: 'Please select an option' }}
                />
              ) : (
                null
              )}
            </div>
            {vendorStatusList.length > 0 ? (
            <div>
              <USBButton
                id="downloadReport"
                name="downloadReport"
                variant="primary"
                handleClick={() => {
                  const filterSelections = `Filter selections : Parent Company = ${selections} Status = ${statusSelections == "" ? 'All' : statusSelections} ` + '';
                  const columnName = {
                    ' ': 'EXTERNAL USER NAME',
                    '  ': 'USER EMAIL ADDRESS ',
                    '   ': 'LAST ACCESSED DATE',
                    '    ': 'STATUS'
                  };
                  const headersFields = [
                    'firstName',
                    'emailAddress',
                    'lastLoginTime',
                    'status'
                  ]
                  downloadReport(customerAccessList,filterSelections,columnName,headersFields, 'Customer Access')}
                }
              >
                Download Report
              </USBButton>
            </div> ) : '' }
            {vendorStatusList.length > 0 ? (
              <div>
                <USBButton
                  type="button"
                  size="small"
                  id="clearFilterCustomerAccess"
                  name="clearfilter"
                  handleClick={() => {
                    setLoading(true);
                    setVendorCompanyNameList([]);
                    setVendorStatusList([]);
                    setCustomerAccessList([]);
                    getFilterData();
                    setLoading(false);
                  }}
                >
                  Clear filters
                </USBButton>
              </div>
            ) : (
              null
            )}
          </div>
          {customerAccessList.length > 0 ? (
            <div className="page-content-customer-access ">
              <UsbTableData
                data={customerAccessList}
                columns={columns}
              />
            </div>
          ) : (
            null
          )}
        </USBColumn>
      </USBGrid>
    </>
  );
};

export default CompanyAccessReport;
