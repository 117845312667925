import React, { useContext, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import TopLink from '../../atoms/TopLink';
import { DocContext } from '../../../pages/context/DocContext';
import down from '../../../../assets/img/down.png';
import { DashboardContext } from '../../../pages/context/DashboardContext';
import './topmenu.css';
import useUserRole from '../../Hooks/use-UserRole'
import {USBIconChevronDown } from '@usb-shield/components-react/dist/cjs';
import { PermissionRoleContext } from '../../../components/Hooks/usePermissionRoles';
export const AssignedTeamMembers = (
  contentOpen,
  setContentOpen,
  getNotificationCount,
  assignedUserName,
  getAssignedserName,
  listNotifications,
  currentMenu
) => {
  const { employeeName, setEmployeeName, employeeNameFull, clearFilter } =
    useContext(DashboardContext); // getAPIResponse

  const loadByEmployeeName = (item) => {
    sessionStorage.removeItem('view_as');
    sessionStorage.setItem('assignedUser', item.userId);
    // window.location.reload();
    if(window.location.pathname=="/Welcome"){
      getNotificationCount(item.userId);
    getAssignedserName(item.userId);
    setContentOpen(false);
    setEmployeeName(employeeNameFull);
      window.location.href = '/Welcome';
    } else {
      window.location.href = '/ReportingDashboard'
    }
    
    // setEmployeeNameId(item.fullName)
    if (currentMenu === 'portfolio') {
      // getAPIResponse(item.userId, 'Submitted')
      clearFilter(item.userId);
    }
    getNotificationCount(item.userId);
    getAssignedserName(item.userId);
    setContentOpen(false);
    setEmployeeName(employeeNameFull);
    if (currentMenu === 'notifications') {
      listNotifications(item.userId);
    }
  };

  const searchDrop = (value) => {
    let temp = [];
    if (value !== '') {
      employeeNameFull.forEach((item) => {
        if (
          (item.lastName !== null &&
            item.lastName !== '' &&
            item.lastName.toLowerCase().indexOf(value.toLowerCase()) > -1) ||
          (item.firstName !== null &&
            item.firstName !== '' &&
            item.firstName.toLowerCase().indexOf(value.toLowerCase()) > -1)
        ) {
          temp.push(item);
        }
      });
    } else {
      temp = employeeNameFull;
    }
    setEmployeeName(temp);
  };

  const toggleContent = () => {
    if (contentOpen) {
      setContentOpen(false);
    } else {
      setContentOpen(true);
      setEmployeeName(employeeNameFull);
    }
    setContentOpen(true);
  };

  const empNameKepress = (event, item) => {
    if (event.keyCode === 13) {
      loadByEmployeeName(item);
    }
    if (event.keyCode === 27) {
      setContentOpen(false);
    }
  };

  return (
    <div id="assigned-user-name">
      Viewing as&nbsp;
      <span className="topmenu-dropdown">
        <div className="dropdown">
          {sessionStorage.getItem('view_as') ? (
            <button
              type="button"
              onClick={() => toggleContent()}
              className="dropbtn"
            >
              {JSON.parse(sessionStorage.getItem('view_as')).userName}
              &nbsp; <img src={down} alt="down" className="down-image"></img>
            </button>
          ) : (
            <button
              type="button"
              onClick={() => toggleContent()}
              className="dropbtn"
            >
              {assignedUserName.firstName}
              {assignedUserName.lastName === '' ||
              assignedUserName.lastName === null ? (
                ''
              ) : (
                <span>&nbsp;&nbsp;</span>
              )}
              {assignedUserName.lastName}
              &nbsp;{ window.location.pathname=="/Welcome" ? <USBIconChevronDown addClasses={'iconDown'} colorVariant={'light'}></USBIconChevronDown> : <img src={down} alt="down" className="down-image"></img> }
            </button>
          )}

          {contentOpen ? (
            <div id="assigned-team" className="dropdown-content">
              <input
                type="text"
                className="dropdown-element"
                placeholder="Search by name"
                id="myInput"
                onChange={(e) => searchDrop(e.target.value)}
              />
              <div className="option-content dropdown-element">
                {employeeName.map((item) => (
                  <div
                    className="dropdown-each"
                    role="button"
                    tabIndex="0"
                    onKeyDown={(event) => empNameKepress(event, item)}
                    onClick={() => loadByEmployeeName(item)}
                  >
                    {item.fullName}
                    {/* {item.lastName !== '' ? (
                      <span>,&nbsp;</span>
                    ) : ('')}
                    {item.firstName} */}
                  </div>
                ))}
              </div>
            </div>
          ) : (
            ''
          )}
        </div>
      </span>
    </div>
  );
};

const TopMenu = (props) => {
  const {
    notification,
    userName,
    userType,
    getNotificationCount,
    assignedUserName,
    getAssignedserName,
    listNotifications,
    currentMenu,
  } = useContext(DocContext);
  const [contentOpen, setContentOpen] = useState(false);

  const {permissionDetails}=useContext(PermissionRoleContext);

  const handleClickDropdown = (event) => {
    const domNode = ReactDOM.findDOMNode(this);
    if (!domNode || !domNode.contains(event.target)) {
      if (
        event.srcElement.className !== 'dropdown-element' &&
        event.srcElement.className !== 'dropdown-each'
      ) {
        setContentOpen(false);
      }
    }
  };
  
 const tempUserRole = useUserRole();
  useEffect(() => {
    document.addEventListener('click', handleClickDropdown, true);
  }, []);

  return (
    <>
      <div className="top-nav">
        <ul>
          <li>
            <div id="user-name">
              Hi,&nbsp;
              {userName.firstName}
            </div>
          </li>
          {(userType === 'employee' && tempUserRole === 'employee') ? (
            <li>
              <div>
                {AssignedTeamMembers(
                  contentOpen,
                  setContentOpen,
                  getNotificationCount,
                  assignedUserName,
                  getAssignedserName,
                  listNotifications,
                  currentMenu
                )}
              </div>
            </li>
          ) : (
            ''
          )}
            { permissionDetails?.includes("inbox") && !permissionDetails?.includes("portfolio") ?
            (
              ''
            ): (
            <li>
            <TopLink
              href="/Notifications"
              title="Notifications"
              text="Notifications"
              notification={notification}
            />
          </li>
             ) }

          {userType !== 'employee' ? (
            <li>
              <TopLink href="/MyProfile" title="Profile" text="My Profile" />
            </li>
          ) : (
            ''
          )}
          <li>
            <a
              title="Log out"
              href="javascript:void(0);"
              onClick={props.onLogout}
            >
              <span>Log Out</span>
            </a>
          </li>
        </ul>
      </div>
    </>
  );
};

export default TopMenu;
