// @flow
import React, { useState, useEffect } from 'react';
import {
    USBModal, USBModalCloseIcon, USBModalHeader, USBModalBody, USBModalFooter, USBButtonGroup, USBButton, USBDropdown,USBNotification
} from '@usb-shield/components-react/dist/cjs';
import AutoCompleteDropdown from "./AutoCompleteDropdown/AutoCompleteDropdown"
import Loader from '../../components/utilities/Loader';


type ReportDownloadModalProps = {
    open: boolean,
    title: String,
    selectedCompnay: String,
    toggle: () => void,
    body: String,
    primaryButtonText: String,
    primaryClickHandler: () => void
}
export const testRef5 = React.createRef();
const ReportDownloadModal = (props) => {
    const fileTypeArr = ['Microsoft Excel (xlsx)'];
    //const fileTypeArr = ['Microsoft Excel (xlsx)' ,'Adobe Portable Document Format (PDF)' ];
    const [data , setData] = useState({
        fileType: "",
        company: props.companyList.length > 0 ? props.companyList[0].parentCompanyName : ""
    })
    const [errorMessages ,setErrorMessages] = useState([]);
    const [loading,setLoading]=useState(false)

    useEffect(() => {
        // console.log(props.companyList)
        let  value = props.selectedCompnay && props.selectedCompnay.ein ? props.selectedCompnay.ein:"";
        setData({fileType:data.fileType, company:value});
    }, [])

 
    const updateSelectedParentCompanyValue = (e) => {
        setLoading(true)
        setErrorMessages([])
        setData({fileType:data.fileType, company:e.ein});
        setTimeout(()=>{
            setLoading(false)
        },500)
    }

    const updateSelectedFileTypeValue = (e) => {
        
        let valueSelected =  ( e.target.value === undefined) ? e.target.innerText: e.target.value
        setErrorMessages([])
        setData({fileType:valueSelected, company:data.company});
        
    }


    const closeModal = () => {
        setErrorMessages([])
        props.toggle()
        setData({fileType:"", company:"" });
    }

    const downloadReport = (chosenItem) =>{  
        // console.log(chosenItem,"item")  
        if(chosenItem.company===""){
            chosenItem.company = props.selectedCompnay && props.selectedCompnay.ein ? props.selectedCompnay.ein:"";
        }
        let errorArr = [];
        if(props.isSuperAdmin && chosenItem.fileType == "" && chosenItem.company == ""){
            errorArr =[
                {text:"Select a parent company."},
                {text:"Select a file type."},
            ]
            setErrorMessages(errorArr)

        } else if (props.isSuperAdmin && chosenItem.company == "") {
            errorArr =[
                {text:"Please select a parent company to download the report."}
            ]
            setErrorMessages(errorArr)
        } else if (chosenItem.fileType == ""){
            errorArr =[
                {text:"Please select a file type to download the report."}
            ]
            setErrorMessages(errorArr)
        } else {
            props.primaryClickHandler(chosenItem);
            setData({fileType:"", company:"" });
        }
    }

    return (
        <>
        { loading ? <Loader></Loader> : ''}
        <USBModal
            isOpen={props.open}
            backdrop={'static'}
            >
            <USBModalCloseIcon
                handleModalClose={closeModal}
                ariaLabel='close modal'
                />
            <USBModalHeader
                modalTitle={props.title}
                >

            </USBModalHeader>

            <USBModalBody>
                <div>
                    {errorMessages.length > 0 && 
                        <USBNotification
                        variant='error'
                        id='multiple'
                        addClasses="sp-mr-b-12"
                        roleType='alert'
                        notificationData={errorMessages}
                        multipleNotificationMessage = "The following items need action:"
                        />
                        
                    }
                    

                    {props.body}
                </div>
                
                <div className="displayFlex">

                    {props.isSuperAdmin ?
                        <div className="company-dropdown-list">
                            <AutoCompleteDropdown onItemSelect={(e) => updateSelectedParentCompanyValue(e)}
                                comboboxId = "combobox-report"
                                autocompleteInputId = "autocomplete-input-report"
                                listboxID = "listbox-report"
                                arrowId= "combobox-arrow-report"
                                listItems ={props.companyList} />
                        </div>

                        : ""}
                { !loading ? 
                    <USBDropdown
                        id={'test-ID-5'}
                        label="File Type"
                        defaultSelected={data.fileType}
                        addClasses={'fileType-dropdown-list'}
                        dropdownType="outlined"
                        items={fileTypeArr}
                        handleChange={(e) => updateSelectedFileTypeValue(e)}
                        />
                        : ''}

                </div> 
            </USBModalBody>
            <USBModalFooter>
                <USBButtonGroup>
                    <USBButton
                        variant='secondary'
                        // handleClick={props.cancelHandler}
                        handleClick={closeModal}
                        ariaLabel='cancel'
                        >
                        Cancel
                    </USBButton>
                    <USBButton
                        variant='primary'
                        handleClick={(e) => downloadReport(data)}
                        ariaLabel={props.primaryButtonText}
                        >
                        {props.primaryButtonText}
                    </USBButton>
                </USBButtonGroup>
            </USBModalFooter>
        </USBModal>
        </>
        
    )
}

export default ReportDownloadModal;