import axios from 'axios';
import { endpoints } from '../../../config/connections';
import { getQS, saveAsFile , previewFile, getLastPathParam} from '../../components/utilities/Utils';
import callApi from "../../components/utilities/callApi";
// API calls

export async function getInboxRequirements({ setRequirements, setStatus }) {
  const url = `${endpoints.apigee}/document-management/cdc/requirements/v1/inbox/requirements`;
  const https = getAxiosInstance();
  const response = await https.get(url);
  if (response?.status === 200) {
    setRequirements(response?.data);
  } else {
    setStatus((state) => ({
      ...state,
      loading: false,
      error: 'Failed to get Inbox requirements.',
    }));
  }
}

export async function getInboxMetadata({ setInboxMetadata, setStatus }) {
  const inboxId = getLastPathParam(window.location.pathname);
  const url = `${endpoints.apigee}/document-management/cdc/requirements/v1/documents/${inboxId}/details`;
  const https = getAxiosInstance();
  const response = await https.get(url);
  if (response?.status === 200) {
    setInboxMetadata(response?.data);
  } else {
    setStatus((state) => ({
      ...state,
      loading: false,
      error: 'Failed to get Inbox metadata.',
    }));
  }
}
export async function getInboxFiles({ setFiles, setStatus }) {
  const inboxId = getLastPathParam(window.location.pathname);
  const url = `${endpoints.api}/document-management/file-download/v1/documents/${inboxId}/files?inboxFlag=true`;
  const https = getAxiosInstance();
  const response = await https.get(url);
  if (response?.status === 200) {
    setFiles(response?.data);
  } else {
    setStatus((state) => ({
      ...state,
      loading: false,
      error: 'Failed to get Inbox metadata.',
    }));
  }
}
export async function updateInboxItemStatus() {
  // const inboxId = getLastPathParam(window.location.pathname);

  var url = window.location.pathname;
   var inboxId = url.substring(url.lastIndexOf('/') + 1);
  const userData = JSON.parse(sessionStorage.getItem('userData'));
  const readUrlPut = `${endpoints.apigee}/document-management/cdc/requirements/v1/inbox/users?requirement-id=${inboxId}&firstName=${userData?.firstName}&lastName=${userData?.lastName}&emailId=${userData?.emailId}`
  const https = getAxiosInstance();
  await https.post(readUrlPut);
  const urlStatus = `${
    endpoints.apigee
  }/document-management/cdc/requirements/v1/inbox/update/status?requirement-id=${inboxId}`;
  if(getQS('status') === 'New'){
     await https.post(urlStatus);
  } 
}

export async function downloadFile({ metadata }) {
  var url = window.location.pathname;
  const requirementId = url.substring(url.lastIndexOf('/') + 1);
  const fileId =  metadata?.filenetId;
  const getApiURL = `${endpoints.api}/document-management/file-download/v1/inbox/${requirementId}/files/${fileId}`;
  await callApi
  .getApiData(getApiURL)
  .then((result) => {
    var values = result.data.fileName?.trimEnd();
    const fileurl = saveAsFile(
    result.data.binary,
    values
    );
    
  })
  .catch(() => {
    // setLoading(false);
    
  })

}

export function getAxiosInstance() {
  const loggedInUser = sessionStorage.getItem('loggedUser');
  const correlationId = sessionStorage.getItem('correlationId');
  const at = sessionStorage.getItem('at');
  const jwt = sessionStorage.getItem('tok');
  const userRole = sessionStorage.getItem('userrole');
  const assignedUser = sessionStorage.getItem('assignedUser');
  const remoteUser = sessionStorage.getItem('remote_user');

  return axios.create({
    headers: {
      Accept: 'application/json',
      'Correlation-ID': `${loggedInUser} ${correlationId}`,
      'Client-Application': 'CDC',
      Authorization: `Bearer ${at}`,
      jwt: `${jwt}`,
      'user-id': remoteUser ? JSON.parse(remoteUser).userID : `${assignedUser}`,
    },
  });
}

// Constants

export function isEmployee() {
  const userRole = sessionStorage.getItem('userrole');
  return userRole === 'employee';
}

export function getDefaultFilters() {
  if (isEmployee()) {
    return { status: { label: 'Read', value: 'Read' } };
  } else return { status: { label: 'New', value: 'New' } };
}

// returns value in key by checking the obj recursively and if it encounters an array of objects then it
// return first element and parses in that
export function getValueByNestedKey(obj, key) {
  const keys = key.split('.');
  return keys.reduce((nested, nestedKey) => {
    if (Array.isArray(nested?.[nestedKey])) {
      return nested && nested[nestedKey]?.[0];
    }
    return nested && nested[nestedKey];
  }, obj);
}
