import React from 'react';
import {HeaderInfo} from '@usb-edm/react-document-upload';
import finalized2 from '../../../assets/img/finalized.png';
import USBTooltip from '@usb-shield/react-tooltip';
import { USBIconShow, USBIconDownload ,USBIconUpload} from '@usb-shield/react-icons';
import {FileItems,DocumentInfo} from '@usb-edm/react-document-upload';

const columnHeaderData = [
    {
      columnType: 'select',
    },
    {
      displayName: 'Type',
      propertyName: 'extension',
      propertyLocation: 'root',
      columnType: 'icon',
      iconType: 'EDM',
      sortable: false,
      sortOptions: [
        { sortDirection: 'ascending', sortLabel: 'Alphabetically A-Z' },
        { sortDirection: 'descending', sortLabel: 'Alphabetically Z-A' },
      ]
    },    
    {
      displayName: 'File Name',
      propertyName: 'fileName',
      sortable: false,
      sortOptions: [
        { sortDirection: 'ascending', sortLabel: 'Alphabetically A-Z' },
        { sortDirection: 'descending', sortLabel: 'Alphabetically Z-A' },
      ],
    },
    {
      displayName: 'Document Title',
      propertyName: 'docTitle',
      sortable: false,
      sortOptions: [
        { sortDirection: 'ascending', sortLabel: 'Alphabetically A-Z' },
        { sortDirection: 'descending', sortLabel: 'Alphabetically Z-A' },
      ],
    },
    {
      displayName: 'Upload Info',
      propertyName: 'uploaded',
      sortable: false,
      sortOptions: [
        { sortDirection: 'ascending', sortLabel: 'Alphabetically A-Z' },
        { sortDirection: 'descending', sortLabel: 'Alphabetically Z-A' },
      ],
    },
    
    {
      displayName: 'Status',
      propertyName: 'uploadStatus',
      sortable: false,
      sortOptions: [
        { sortDirection: 'ascending', sortLabel: 'Alphabetically A-Z' },
        { sortDirection: 'descending', sortLabel: 'Alphabetically Z-A' },
      ],
    },
    {
      displayName: '',
      propertyName: 'previwDoc',
    } ,
    {
      columnType: 'action'
    } 
  ];
  
  
  export const gridHeaders = new HeaderInfo(
    columnHeaderData,
    'default',
    '0.5fr 0.5fr 2.5fr 2.5fr 2fr 1fr 0.5fr 0.5fr'
  );

  const getTableData = () => {
    let detailsData = [
      {
         "_id" : "643d2f4b1163b51f01db5097",
         "rowId" : 0,
         "filenm" : "a5mb800-files-file-10.pdf",
         "msgtxt" : "Edm Failed : 503 Service Unavailable: \"upstream connect error or disconnect/reset before headers. reset reason: connection failure, transport failure reason: delayed connect error: 111\"",
         "retrycnt" : 0,
         "requirementId" : "c15fc0cd-261f-4d36-af5e-0cfe0a7c0d1012",
         "uploadedBy" : "c076480@cdc",
         "legalEntity" : "Mid-City Community CDE-Loan Fund, LLC",
         "dealId" : "20301",
         "dealName" : "Louisiana Superdome Marketing and Promotional Fund",
         "filePhase" : "Closing Binder",
         "docType" : "Other",
         "cdcDocumentDate" : '2020-05-17T11:31:46.977Z',
         "monitoringApproval" : false,
         "constructionDraws" : false,
         "financeReporting" : false,
         "leasingAndOperating" : false,
         "loanServicing" : false,
         "fileCategory" : "Financial Statement",
         "index" : "OSS",
         "cdcOfficialDocIndicator" : "YES",
         "cdcSyndicationId" : "cdcSyndicationId",
         "retention" : "Anually",
         "syndicationTlRequest" : "syndicationTLRequest",
         "_class" : "com.usbank.interactionmanagement.documentmanagement.models.CbMetadata",
         "MarkedasFinalValue" : true,
         "firstName":"Test",
         "lastName":"Abc"
        },
      {
         "_id" : "643d2f4b1163b51f01db5097",
         "rowId" : 0,
         "filenm" : "b5mb800-files-file-10.xls",
         "msgtxt" : "Edm Failed : 503 Service Unavailable: \"upstream connect error or disconnect/reset before headers. reset reason: connection failure, transport failure reason: delayed connect error: 111\"",
         "retrycnt" : 0,
         "requirementId" : "c15fc0cd-261f-4d36-af5e-0cfe0a7c0d1012",
         "uploadedBy" : "c076480@cdcemp",
         "legalEntity" : "Mid-City Community CDE-Loan Fund, LLC",
         "dealId" : "20301",
         "dealName" : "Louisiana Superdome Marketing and Promotional Fund",
         "filePhase" : "Closing Binder",
         "docType" : "Other",
         "cdcDocumentDate" : '2020-05-17T11:31:46.977Z',
         "monitoringApproval" : false,
         "constructionDraws" : false,
         "financeReporting" : false,
         "leasingAndOperating" : false,
         "loanServicing" : false,
         "fileCategory" : "Financial Statement",
         "index" : "OSS",
         "cdcOfficialDocIndicator" : "YES",
         "cdcSyndicationId" : "cdcSyndicationId",
         "retention" : "Anually",
         "syndicationTlRequest" : "syndicationTLRequest",
         "_class" : "com.usbank.interactionmanagement.documentmanagement.models.CbMetadata",
         "MarkedasFinalValue" : false,
         "firstName":"Test",
         "lastName":"bcd"
        },
      {
         "_id" : "643d2f4b1163b51f01db5097",
         "rowId" : 0,
         "filenm" : "c5mb800-files-file-10.doc",
         "msgtxt" : "Edm Failed : 503 Service Unavailable: \"upstream connect error or disconnect/reset before headers. reset reason: connection failure, transport failure reason: delayed connect error: 111\"",
         "retrycnt" : 0,
         "requirementId" : "c15fc0cd-261f-4d36-af5e-0cfe0a7c0d1012",
         "uploadedBy" : null,
         "legalEntity" : "Mid-City Community CDE-Loan Fund, LLC",
         "dealId" : "20301",
         "dealName" : "Louisiana Superdome Marketing and Promotional Fund",
         "filePhase" : "Closing Binder",
         "docType" : "Other",
         "cdcDocumentDate" : '',
         "monitoringApproval" : false,
         "constructionDraws" : false,
         "financeReporting" : false,
         "leasingAndOperating" : false,
         "loanServicing" : false,
         "fileCategory" : "Financial Statement",
         "index" : "OSS",
         "cdcOfficialDocIndicator" : "YES",
         "cdcSyndicationId" : "cdcSyndicationId",
         "retention" : "Anually",
         "syndicationTlRequest" : "syndicationTLRequest",
         "_class" : "com.usbank.interactionmanagement.documentmanagement.models.CbMetadata",
         "MarkedasFinalValue" : false,
        }
      ]
    
    return detailsData.map((doc,index) => {

      let newDocument;
      newDocument = new DocumentInfo(
        `${index}`,
        `${doc.filenm}`,
        false,
        `10`
      ); 
      // Indicates failed uploads
      const errorElement = <USBTooltip
      variant="dark"
      ariaLabel="Failed"
      direction="top"
      id="test-1"
      toolTipIcon = {() => <img src='../../../assets/img/dateAlert.png'></img>}
      >
        {doc.uploadedBy ? doc.uploadedBy : 'Failed'}
      </USBTooltip>
  
      // Idicates sucessfull uploads
      const successElement = <div>
        {/* <div className='statusTooltip'>Finalized</div> */}
        { doc.MarkedasFinalValue ? 
        <img src={finalized2} className='finalized'></img> :
        <img src='../../../assets/img/green-tick.svg' className='greenTick'></img> }
        
      </div>
  
      // Data for uploaded column
      const uploadedElement = <div className='uploadedElement'>
        {/* Uploaded by name */}
        <div>{doc.firstName ? `${doc.firstName} ${doc.lastName}` : '-'}</div>
        {/* uploaded By email */}
        <div>{doc.uploadedBy ? doc.uploadedBy : '-' }</div> 
        {/* Upload date and time*/}
        <div>
          <span>{doc.cdcDocumentDate ? new Date(doc.cdcDocumentDate).toLocaleDateString() + " " : '-' } </span> 
          <span>{doc.cdcDocumentDate ? new Date(doc.cdcDocumentDate).toLocaleTimeString() : ""}</span>
        </div>
      </div>
  

      const statusElement = <div className='statusElements' >
        <div className='statusElements-icon'>
        <span>{doc.uploadedBy ? successElement : errorElement}</span>
        </div> 
        <div>{ doc.uploadedBy ? doc.MarkedasFinalValue ? 'Finalized' : 'Uploaded' : 'Failed'}</div>
         
      </div>
      // Setting column data
      newDocument.metadata = {
        name: newDocument.name,
        fileName: newDocument.name,
        docTitle: <div id='docTitle'>{newDocument.name}</div>,
        uploaded: uploadedElement,
        uploadStatus: statusElement,
        fileScan: doc.edmStatus
      };
      return newDocument;
    });
  }

  export const fileItems = new FileItems(gridHeaders, getTableData());