import React, { createContext, useState, useEffect } from 'react';
// import { useRouter } from 'next/router'
import axios from 'axios';
import { endpoints } from '../../../config/connections';
import callApi from '../../components/utilities/callApi';
import { parseJwt } from '../../components/utilities/Utils';
import {enableCDCServicing} from  '../../config/connections';

export const DocContext = createContext();

const DocContextProvider = (props) => {
  const [notification, setNotification] = useState(0);
  const [userType, setUserType] = useState(0);
  const [servicingUserRole, setServicingUserRole] = useState(0);
  const [currentMenu, setCurrentMenu] = useState(0);
  const [tncCheck, settncCheck] = useState('');
  const [totalPages, setTotalPages] = useState(0);
  const [notificationDetails, setNotificationDetails] = useState([]);
  const [getNotification, setReceiveNotification] = useState(false);
  const [canUserUpdate, setCanUserUpdate] = useState(false);
  const [userName, setUsername] = useState({
    userId: '',
    emailId: '',
    firstName: '',
    lastName: '',
    middleName: '',
    mobileNumber: [],
    profileId: '',
    source: '',
  });
  const [assignedUserName, setAssignedUsername] = useState({
    userId: '',
    emailId: '',
    firstName: '',
    lastName: '',
    middleName: '',
    mobileNumber: [],
    profileId: '',
    source: '',
    fullName: '',
  });

  const listNotifications = (user) => {
    const tmZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const listUrl = `${endpoints.apigee}/document-management/cdc/alerts/v1/users/userId/notifications?userTimeZone=${tmZone}`;

    callApi
      .getApiData(listUrl)
      .then((response) => {
        setTotalPages(Math.ceil(response.data.notifications.length / 10));
        setNotificationDetails(response.data.notifications);
        setReceiveNotification(response.data.receiveNotifications);
        setCanUserUpdate(response.data.canUserUpdate);
      })
      .catch(() => {
        // console.log(error)
      });
  };

  const getNotificationCount = (user) => {
    const notificationURL = `${endpoints.apigee}/document-management/cdc/alerts/v1/users/userId/notifications-count`;

    callApi
      .getApiDataLoggedUser(notificationURL)
      .then((notificationResponse) => {
        // const countRes = notificationResponse.request.response;
        // const countObj = JSON.parse(notificationResponse)
        setNotification(notificationResponse.data.count);
      })
      .catch(() => {
        // console.error(error)
      });
  };

  const getUserName = (user) => {
    const loggedUser = sessionStorage.getItem('loggedUser');
    const assignedMember = sessionStorage.getItem('assignedUser');
    const userNameURL = `${endpoints.apigee}/document-management/coreservice/user-management/v1/users/userId`;
    const userData = sessionStorage.getItem('userData');
    console.log('--userDate', userData);
    if (!userData) {
      callApi
        .getApiData(userNameURL)
        .then((userNameResponse) => {
          setUsername(userNameResponse.data);
          console.log('--userData being set in session');
          sessionStorage.setItem(
            'userData',
            JSON.stringify(userNameResponse.data)
          );
          if (assignedMember === loggedUser) {
            setAssignedUsername({
              firstName: 'You',
              lastName: '',
            });
            sessionStorage.setItem(
              'assignedUserData',
              JSON.stringify({
                firstName: 'You',
                lastName: '',
              })
            );
          }
        })
        .catch(() => {
          // console.error(error)
        });
    } else {
      setUsername(JSON.parse(userData));
      if (assignedMember === loggedUser) {
        setAssignedUsername({
          firstName: 'You',
          lastName: '',
        });
      }
    }
  };

  const getAssignedserName = (user) => {
    const loggedUser = sessionStorage.getItem('loggedUser');
    const assignedMember = sessionStorage.getItem('assignedUser');
    const userNameURL = `${endpoints.apigee}/document-management/coreservice/user-management/v1/users/userId`;
    const assgnUserDataString = sessionStorage.getItem('assignedUserData');
    const assgnUserData = assgnUserDataString
      ? JSON.parse(assgnUserDataString)
      : {};
    console.log('--AssigneduserData in session', assgnUserData);

    if (!assgnUserDataString || assgnUserData.userId != user) {
      callApi
        .getApiData(userNameURL)
        .then((userNameResponse) => {
          console.log('--AssigneduserData being set in session');

          if (assignedMember === loggedUser) {
            setAssignedUsername({
              firstName: 'You',
              lastName: '',
            });
            sessionStorage.setItem(
              'assignedUserData',
              JSON.stringify({
                firstName: 'You',
                lastName: '',
              })
            );
          } else {
            setAssignedUsername(userNameResponse.data);
            sessionStorage.setItem(
              'assignedUserData',
              JSON.stringify(userNameResponse.data)
            );
          }
        })
        .catch(() => {
          // console.error(error)
        });
    } else {
      setAssignedUsername(assgnUserData);
    }
  };

  const setServUserRole = (businessProfileType, usbRelationship) => {
    const usbRelationshipSmall = usbRelationship.toLowerCase();
   
    let servRole = '';
    if (usbRelationshipSmall === 'company_site_administrator__contact') {
      servRole = 'siteadmin';
    } else if (usbRelationshipSmall === 'company_contributor__contact') {
      servRole = 'contributor';
    } else if (
      usbRelationshipSmall === 'frequent_vendor_site_administrator__contact'
    ) {
      servRole = 'vendorsiteadmin';
    } else if (
      usbRelationshipSmall === 'frequent_vendor_contributor__contact'
    ) {
      servRole = 'vendorcontributor';
    } else if (
      usbRelationshipSmall === 'unlisted_vendor_contributor__contact'
    ) {
      servRole = 'unlistedvendor';
    } else if (businessProfileType === 'SpecialVendor') {
      servRole = 'employee';
    } else {
      servRole = 'customer';
    }
    sessionStorage.setItem('servicingrole', servRole);
    setServicingUserRole(servRole); // For navmenu, as session wont work
  };

  // const decodeJWTToken = (token) => {
  //   const base64Url = token.split(".")[1];
  //   const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  //   const jsonPayload = decodeURIComponent(
  //     atob(base64).split("")
  //       .map(c => `%${(`00${c.charCodeAt(0).toString(16)}`).slice(-2)}`).join("")
  //   );
  //   return JSON.parse(jsonPayload);
  // };

  const getUserRolefromServicing = () => {
    const jwtDSID = parseJwt(sessionStorage.getItem('tok'));
    const accessToken = sessionStorage.getItem('at');
    // const userId = sessionStorage.getItem('loggedUser');
    const userId = parseJwt(sessionStorage.getItem('tok')).sub;
    const roleId = sessionStorage.getItem('role');
    const token = sessionStorage.getItem('tok');

    axios({
      url: endpoints.servicingAPI,
      method: 'post',
      data: {
        // query: `{\n getProfileWithParentComType(userID: "${userId}") {\t\tprofileRole\n}\n}`
        query: `{\n getProfileWithParentComType(userID:"${userId}") {\t\tprofileRole\n \tbusinessProfileType\n \tusbRelationship}\n}`,
        variables: null,
        operationName: null,
      },
      headers: enableCDCServicing == false ? {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
        routingKey: endpoints.routingKey,
        // "Correlation-ID": "44afbcf2-1cd5-4aa1-9b08-f5adeff6ea56"
        'Correlation-ID': `${jwtDSID.sid}`,
        'Client-Data': `{"Role-ID": "${roleId}", "User-ID": "${userId}", "App-ID": "CDC", "Tenant-ID": "USB", "App-Version": "1", "Channel-ID": "web"}`,
        Accept: 'application/json',
        'Client-ID': 'CDC',
        'Service-Version': '2',
        SessionToken: token,
        Aid: 'cdc_web',
      } : {
        "Content-Type": "application/json",
        "Correlation-ID": `${jwtDSID.sid}`,
        "Accept": "application/json",      
        "origin": window.location.href,
        "Access-Control-Allow-Origin": "*" 
      },
    }).then((result) => {
      // console.log("GraphQL Response:", result.data.data.getProfileWithParentComType)
      setServUserRole(
        result.data.data.getProfileWithParentComType.businessProfileType,
        result.data.data.getProfileWithParentComType.usbRelationship
      );
    });
  };

  useEffect(() => {
    if (sessionStorage.getItem('tok') !== null) {
      if (sessionStorage.getItem('userrole') !== 'employee') {
        getUserRolefromServicing();
      }

      setUserType(sessionStorage.getItem('userrole')); // To pass userrole to Navmenu as session is not accessible there
      getUserName(sessionStorage.getItem('loggedUser'));
      if (
        sessionStorage.getItem('loggedUser') !==
        sessionStorage.getItem('assignedUser')
      ) {
        getAssignedserName(sessionStorage.getItem('assignedUser'));
      }
      getNotificationCount(sessionStorage.getItem('assignedUser'));
    }
  }, []);

  return (
    // eslint-disable-next-line react/jsx-filename-extension
    <DocContext.Provider
      value={{
        notification,
        userName,
        setNotification,
        userType,
        getNotificationCount,
        assignedUserName,
        getAssignedserName,
        servicingUserRole,
        currentMenu,
        setCurrentMenu,
        listNotifications,
        totalPages,
        setTotalPages,
        notificationDetails,
        setNotificationDetails,
        getNotification,
        setReceiveNotification,
        canUserUpdate,
        setCanUserUpdate,
      }}
    >
      {props.children}
    </DocContext.Provider>
  );
};

export default DocContextProvider;
