/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
import React, { useState, useEffect, useContext } from 'react';
import { USBGrid, USBColumn } from '@usb-shield/components-react';
import BannerWarning from '../common/banner'
// import Router from 'next/router'

import { useHistory } from 'react-router-dom';
import GlobalHead from '../organisms/GlobalHead';
import GlobalHeader from '../organisms/GlobalHeader';
import GlobalFooter from '../organisms/GlobalFooter';
import { endpoints } from '../../../config/connections';

const Layout = (props) => {
  let history = useHistory();
  let bgClass = 'content-frame';
  if (props.bgclass) {
    bgClass = props.bgclass;
  }

  const token =
    typeof sessionStorage !== 'undefined'
      ? sessionStorage.getItem('tok')
      : null;

  const parseJwt = (tok) => {
    const base64Url = tok !== null ? tok.split('.')[1] : '';
    const base64 = decodeURIComponent(
      atob(base64Url)
        .split('')
        .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
        .join('')
    );

    return JSON.parse(base64);
  };

  const [currentToken, setCurrentToken] = useState(token);

  const onSuccessChallenge = (response) => {
    console.log(response?.type);
    if (response && response?.type === 'logout') {
      if (sessionStorage.getItem('userrole') !== 'employee') {
        // sessionStorage.clear();
        setCurrentToken(null);
        const userDataTmp=JSON.parse(sessionStorage.getItem('userData'));
        sessionStorage.clear();
        sessionStorage.setItem("userData",JSON.stringify(userDataTmp));
        window.location.href = '/';
      } else {
        const userDataTmp=JSON.parse(sessionStorage.getItem('userData'));
        sessionStorage.clear();
        sessionStorage.setItem("userData",JSON.stringify(userDataTmp));
        setCurrentToken(null);
        window.location.href = '/EmployeeRedirect?tp=sto';
      }
    } else {
      sessionStorage.setItem('tok', null);
      sessionStorage.setItem('tok', response.token);
      localStorage.setItem('tok', response.token);
      sessionStorage.setItem('at', null);
      sessionStorage.setItem(
        'at',
        parseJwt(sessionStorage.getItem('tok')).custom_data.at
      );
      sessionStorage.setItem(
        'currentSession',
        sessionStorage.getItem('currentSession')
      );
      setCurrentToken(response.token);

      const element = document.getElementById('manageuser-app');

      if (element) {
        const content = element.contentWindow;
        content.postMessage(
          { type: 'token', data: response.token },
          endpoints.manageUser
        );
      }
    }
  };

  const extendSessionSuccess = (response) => {
    sessionStorage.setItem('tok', null);
    sessionStorage.setItem('tok', response.token);
    localStorage.setItem('tok', response.token);
    sessionStorage.setItem('at', null);
    sessionStorage.setItem(
      'at',
      parseJwt(sessionStorage.getItem('tok')).custom_data.at
    );
    sessionStorage.setItem(
      'currentSession',
      sessionStorage.getItem('currentSession')
    );
    setCurrentToken(response.token);

    const element = document.getElementById('manageuser-app');

    if (element) {
      const content = element.contentWindow;
      content.postMessage(
        { type: 'token', data: response.token },
        endpoints.manageUser
      );
    }
  };

  const onIdleTimeoutLogoutSuccess = () => {
    window.onbeforeunload = null;
    if (sessionStorage.getItem('userrole') !== 'employee') {
      // sessionStorage.clear();
      setCurrentToken(null);
      const userDataTmp=JSON.parse(sessionStorage.getItem('userData'));
      sessionStorage.clear();
      sessionStorage.setItem("userData",JSON.stringify(userDataTmp));
      window.location.href = '/';
    } else {
      const userDataTmp=JSON.parse(sessionStorage.getItem('userData'));
      sessionStorage.clear();
      sessionStorage.setItem("userData",JSON.stringify(userDataTmp));
      setCurrentToken(null);
      window.location.href = '/EmployeeRedirect?tp=sto';
    }
  };

  const onIdleTimeoutLogoutFailure = () => {
    window.onbeforeunload = null;
    if (sessionStorage.getItem('userrole') !== 'employee') {
      // sessionStorage.clear();
      setCurrentToken(null);
      const userDataTmp=JSON.parse(sessionStorage.getItem('userData'));
      sessionStorage.clear();
      sessionStorage.setItem("userData",JSON.stringify(userDataTmp));
      window.location.href = '/';
    } else {
        const userDataTmp=JSON.parse(sessionStorage.getItem('userData'));
        sessionStorage.clear();
        sessionStorage.setItem("userData",JSON.stringify(userDataTmp));
      setCurrentToken(null);
      window.location.href = '/EmployeeRedirect?tp=sto';
    }
  };

  const checkIfSessionExists = () => {
    let parsedToken;
    if (sessionStorage.getItem('tok') !== null) {
      parsedToken = parseJwt(sessionStorage.getItem('tok')).iat;
    } else {
      parsedToken = null;
    }
    return parsedToken;
  };

  const getCurrentExtendTime = () => {
    const currentIat =
      typeof sessionStorage !== 'undefined' ? checkIfSessionExists() : null;
    const sessionTimer = 600000;
    let refreshTime = currentIat * 1000 + sessionTimer - Date.now();
    if (refreshTime <= 60000) {
      refreshTime = 60000;
    }
    return refreshTime;
  };

  useEffect(() => {
    setCurrentToken(token);
  }, []);

  const authenticationPlugins = [
    '../../plugins/transmit/xmsdk.js',
    '../../plugins/transmit/xmui.js',
  ];

  const currentUrl = typeof window !== 'undefined' ? window.location.href : '';

  const urlCheck = () => {
    if (currentUrl.includes('/ManageUser')) {
      return <USBColumn largeSpan={16}>{props.children}</USBColumn>;
    }
    if (currentUrl.includes('/MyProfile')) {
      // return (<USBColumn largeSpan="16">
      return props.children;
      // </USBColumn>)
    }
    return <USBColumn largeSpan={12}>{props.children}</USBColumn>;
  };

  return (
    <>
      <GlobalHead title="Secure Document Portal" />
    
      <GlobalHeader
        currentToken={currentToken}
        refreshTimeCount={getCurrentExtendTime()}
        authenticationPlugins={authenticationPlugins}
        extendSessionSuccess={extendSessionSuccess}
        onSuccessChallenge={onSuccessChallenge}
        onIdleTimeoutLogoutSuccess={onIdleTimeoutLogoutSuccess}
        onIdleTimeoutLogoutFailure={onIdleTimeoutLogoutFailure}
      />
     <BannerWarning></BannerWarning>
      <USBGrid addClasses={bgClass}>
        <USBColumn largeSpan={2} />
        {/* {urlCheck()} */}
        <USBColumn largeSpan={12}>{props.children}</USBColumn>
      </USBGrid>
      <GlobalFooter />
    </>
  );
};

export default Layout;
