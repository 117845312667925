export const entitlementUpdateThunk = (selectedEntitlements, radioSelection, allSelection) => dispatch => {
    dispatch({
        type: "SET_ENTITLEMENT",
        payload: selectedEntitlements
    });
    dispatch({
        type: "SET_ENTITY",
        payload: radioSelection
    });
    dispatch({
        type: "SET_ALL_ENTITY_SELECTED",
        payload: allSelection
    });
    return Promise.resolve();
  };