import { combineReducers } from 'redux';
import UserProfilerRducer from './UserProfilerRducer';
import ParentCompanyReducer from './ParentCompanyReducer';
import CurrentUserReducer from './CurrentUserReducer';
import EntitlementReducer from './EntitlementReducer';
import GlobalLoadingReducer from './GlobalLoadingReducer';
export default combineReducers({
    showLoading: GlobalLoadingReducer,
    userProfileInformation: UserProfilerRducer,
    parentCompanyInformation: ParentCompanyReducer,
    currentUser: CurrentUserReducer,
    entitlement: EntitlementReducer,
});